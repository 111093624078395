import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PoDialogConfirmLiterals, PoDialogService, PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { AuthService } from 'app/service/auth.service';
import { Router } from '@angular/router';
import { LogService } from 'app/service/log.service';
import { SystemParametersModel } from 'app/models/systemparameters.model';
import { SystemParamsService } from 'app/service/systemparams.service';

@Component({
  selector: 'app-systemparameters',
  templateUrl: './systemparameters.component.html',
  styleUrls: ['./systemparameters.component.scss']
})
export class SystemparametersComponent implements OnInit {

  reactiveForm: FormGroup;

  systemParameters: any;

  nameUserLogged: any
  IdUserLogged: any

  //Po Notification
  message: string;
  duration = 2500;
  poType: string;

  constructor(
    private fb: FormBuilder,
    private poNotification: PoNotificationService,
    public auth: AuthService,
    private router: Router,
    private poAlert: PoDialogService,
    private logService: LogService,
    private service: SystemParamsService,
  ) {

    this.createReactiveForm();
  }

  ngOnInit(): void {
    //To control the user access, thsi acces if just for Admin users
    this.auth.user$.subscribe(credentials => {
      if (credentials.userPerfil != '4') {
        this.router.navigate(["/sample"])
      } else {

        this.nameUserLogged = credentials.displayName
        this.IdUserLogged = credentials.uid
        this.service.onSystemParamsChanged.subscribe(params => {
          this.systemParameters = params.length > 0? params[0]: []
          this.detailsCoin()
        });
      }
    })
  }

  saveChanges() {
    let fields = this.reactiveForm.value;
    let updateParam = {} as SystemParametersModel;
    if (this.reactiveForm.valid) {
      updateParam.webhookLink = fields.webhookLink;
      updateParam.gFunctionAPI = fields.gFunctionAPI;
      if(this.systemParameters.length > 0 || this.systemParameters.webhookLink || this.systemParameters.gFunctionAPI){
        this.service.updateParameter(this.systemParameters.id, updateParam)
        this.message = "Dados atualizados com sucesso!";

        this.showNotification("success")
      }else{
        this.service.saveParameter(updateParam)
        this.showNotification("success")
        this.message = "Dados salvos com sucesso!";
      }
      
      this.message = 'Parametro gravado com sucesso'
      this.logService.insertLog({
        pageName: 'systemparams',
        actionExecuted: 'Edição de parametros do sistema',
        responsible: this.nameUserLogged,
        userLoggedId: this.IdUserLogged,
        registeredDate: new Date(),
        objectTransacted: JSON.stringify(updateParam)
      })
    }else{
     this.showNotification("error")
        this.message = "Houve um erro ao salvar os dados.";
    }
  }

  createReactiveForm() {
    this.reactiveForm = this.fb.group({
      webhookLink: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      gFunctionAPI: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
    });
  }

  detailsCoin() {
    this.reactiveForm = this.fb.group({
      webhookLink: [this.systemParameters.webhookLink],
      gFunctionAPI: [this.systemParameters.gFunctionAPI],
    });
}

  showNotification(messageType: string) {
    const poNotification: PoNotification = {
        message: this.message,
        duration: this.duration,
        orientation: 1
    }
    switch (messageType) {
        case 'success': {
            this.poNotification.success(poNotification);
            break;
        }
        case 'error': {
            this.poNotification.error(poNotification);
            break;
        }
        case 'warning': {
            this.poNotification.warning(poNotification);
            break;
        }
        case 'information': {
            this.poNotification.information(poNotification);
            break;
        }
        default: {
            this.poNotification.success(poNotification);
            break;
        }

    }
    this.poType = ''
}

}
