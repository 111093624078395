import { FuseNavigation } from "@fuse/types";
import { UserService } from "app/service/user.service";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Applications",
        translate: "NAV.APPLICATIONS",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                translate: "NAV.DASHBOARD.TITLE",
                type: "item",
                icon: "dashboard",
                url: "/sample",
            },
            {
                id: "store",
                title: "Store",
                translate: "NAV.STORE.TITLE",
                type: "item",
                icon: "shopping_cart",
                url: "/store",
            },
            {
                id: "my_perfil",
                title: "My Perfil",
                translate: "NAV.MYPERFIL.TITLE",
                type: "item",
                icon: "account_circle",
                url: "/perfil",
            },
            {
                id: "my_purchases",
                title: "My Purchases",
                translate: "NAV.MYPURCHASES.TITLE",
                type: "item",
                icon: "widgets",
                url: "/purchases",
            },
            {
                id: "my_quests",
                title: "My Quests",
                translate: "NAV.MYQUESTS.TITLE",
                type: "item",
                icon: "dvr",
                url: "/myquests",
            },
            {
                id: "my_products",
                title: "My Products",
                translate: "NAV.MYPRODUCTS.TITLE",
                type: "item",
                icon: "local_offer",
                url: "/registerProduct",
            },
            // {
            //     id: "mysales",
            //     title: "My Sales",
            //     translate: "NAV.MYSALES.TITLE",
            //     type: "item",
            //     icon: "local_offer",
            //     url: "/mysales",
            // },
        ],
    },
    {
        id: "administration",
        title: "Administration",
        translate: "NAV.ADMINISTRATION",
        type: "group",
        children: [
            {
                id: "sales",
                title: "Sales",
                translate: "NAV.SALES.TITLE",
                type: "item",
                icon: "devices_other",
                url: "/sales",
            },
            {
                id: "typeReward",
                title: "typeReward",
                translate: "NAV.TYPEREWARD.TITLE",
                type: "item",
                icon: "devices_other",
                url: "/rewards-types",
            },
            {
                id: "approveReward",
                title: "approveReward",
                translate: "NAV.APPROVE.TITLE",
                type: "item",
                icon: "assignment_turned_in",
                url: "/rewards-approval",
            },
            {
                id: "quests",
                title: "Quests",
                translate: "NAV.QUESTS.TITLE",
                type: "item",
                icon: "format_list_bulleted",
                url: "/quests",
            },
            {
                id: "approval-quests",
                title: "Approval Quests",
                translate: "NAV.APPROVALQUESTS.TITLE",
                type: "item",
                icon: "assignment_turned_in",
                url: "/approvalQuests",
            },
            {
                id: "products",
                title: "Products",
                translate: "NAV.PRODUCTS.TITLE",
                type: "item",
                icon: "local_offer",
                url: "/products",
            },
            {
                id: "productstoapprove",
                title: "Approve Products",
                translate: "NAV.APPROVEPRODUCT.TITLE",
                type: "item",
                icon: "assignment_turned_in",
                url: "/approvalProducts",
            },
            {
                id: "superpointz",
                title: "Super Pointz",
                translate: "NAV.SUPERPOINTZ.TITLE",
                type: "item",
                icon: "assignment_turned_in",
                url: "/superPointz",
            },
            
        ],
    },
    {
        id: "configuration",
        title: "Configuration",
        translate: "NAV.CONFIGURATION",
        type: "group",
        children: [
            {
                id: "users",
                title: "Users",
                translate: "NAV.USERS.TITLE",
                type: "item",
                icon: "face",
                url: "/users",
            },
            {
                id: "coins",
                title: "Coins",
                translate: "NAV.COINS.TITLE",
                type: "item",
                icon: "attach_money",
                url: "/coins",
            },
            {
                id: "pageLog",
                title: "Logs",
                translate: "NAV.LOGS.TITLE",
                type: "item",
                icon: "format_list_bulleted",
                url: "/logs",
            },
            {
                id: 'systemparams',
                title: 'System Param',
                translate: 'NAV.SYSTEMPARAMS.TITLE',
                type: 'item',
                icon: 'perm_data_setting',
                url: '/systemparams'
            },
            {
                id: 'storemparams',
                title: 'Store Param',
                translate: 'NAV.STOREPARAMS.TITLE',
                type: 'item',
                icon: 'perm_data_setting',
                url: '/storeparams'
            },
            {
                id: "domains",
                title: "Domains",
                translate: "NAV.DOMAINS.TITLE",
                type: "item",
                icon: "devices_other",
                url: "/domains",
            },
        ],
    },
];
