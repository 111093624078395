import { NotificationService } from 'app/service/notification.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Userproducts, Userproducts2 } from 'app/models/userproducts';
import { PurchasesCollaboratorService } from 'app/service/purchases-collaborator.service';
import { AuthService } from 'app/service/auth.service';
@Component({
  selector: 'app-approval-products',
  templateUrl: './approval-products.component.html',
  styleUrls: ['./approval-products.component.scss'],
  providers: [PoNotificationService]
})
export class ApprovalProductsComponent implements OnInit {
  @ViewChild(PoModalComponent, {static: true}) addProductModal: PoModalComponent;
  @ViewChild('approveProductModal', {static: true}) approveProductModal: PoModalComponent;

  actions: Array<PoTableAction> = [
    { action: this.approveProduct.bind(this), icon: 'po-icon-star-filled', label: 'Aprovar/Reprovar' },
    // { action: this.detailsProduct.bind(this), icon: 'po-icon-info', label: 'Editar' },
    { action: this.deleteProduct.bind(this), icon: 'po-icon-delete', label: 'Excluir' },
  ];
  @ViewChild('fileInput') fileInput: ElementRef;



  p: 0;
  reactiveForm: FormGroup;
  srcImage: any;
  images: any[];
  isUpdate: boolean;
  productStatus: string;
  message: string;
  duration: number;
  poType: string;
  nameUserLogged: any;
  IdUserLogged: any;
  key: string = 'price'
  reverse: boolean = true;
  userName: ''
  public listProducts: Userproducts2[];
  public productUpdateId = '';


  columns: Array<PoTableColumn>;
  constructor(
    private fb: FormBuilder,
    private poNotification: PoNotificationService, private serviceProducts: PurchasesCollaboratorService, public auth: AuthService, private notificationService: NotificationService) {
      this.createReactiveForm();
      
      this.columns = [
        // { property: 'images', label: 'Imagem', type: 'columnTemplate', visible: true },
        { property: 'name', label: 'Nome', visible: true },
        { property: 'price', label: 'Valor', visible: true },
        { property: 'description', label: 'Descrição', visible: true },
        { property: 'dataPost', label: 'Data Postagem',  visible: true},
        { property: 'userName', label: 'Postado Por',  visible: true},
        { property: 'approve', label: 'Status',  visible: true}          
    ]
          
    }


  ngOnInit(): void {
    this.auth.user$.subscribe(credentials => {
      this.nameUserLogged = credentials.displayName;
      this.IdUserLogged = credentials.uid;
      this.serviceProducts.onProductChanged.subscribe(products => {
          this.listProducts = products.filter((data) =>{
              if (data.approve === 1){
                data.approve = 'Pendente'
      
              }else if (data.approve === 2){
                data.approve = 'Aprovado'
               
              }else if(data.approve === 3){
                data.approve = 'Reprovado'
              }else if(data.approve === 4){
                data.approve = 'Sem Estoque'
              }
             
               return data
            
            
          })
        
      });

    })

    


    }
  
  showNotification(messageType: string) {
    const poNotification: PoNotification = {
        message: this.message,
        duration: this.duration,
        orientation: 1
    }
    switch (messageType) {
        case 'success': {
            this.poNotification.success(poNotification);
            break;
        }
        case 'error': {
            this.poNotification.error(poNotification);
            break;
        }
        case 'warning': {
            this.poNotification.warning(poNotification);
            break;
        }
        case 'information': {
            this.poNotification.information(poNotification);
            break;
        }
        default: {
            this.poNotification.success(poNotification);
            break;
        }

    }
    this.poType = ''
}

  openNewModal(){
    this.srcImage = '';
    this.images = [];
    this.clearForm();
    this.isUpdate = false;
    this.addProductModal.open();
  }



    clearForm(){
      this.reactiveForm.reset();
      this.reactiveForm = this.fb.group({
          name: [''],
          description: [''], 
          category: [''], 
          images: [''], 
          price: [0], 
          quantity: [0], 
          showHome: [false], 
          dataPost: [''],
          approve: [false],
         
      });
      this.productStatus = ''
    }

    createReactiveForm() {
      this.reactiveForm = this.fb.group({
          name: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
          description: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(240)])],
          images: [''],
          price: [0],
          quantity: [0],
          dataPost: [''],
          approve: [false],
         
      });
    }  

    SaveAction: PoModalAction = {
      action: () => {
          let fields = this.reactiveForm.value;
          let saveProduct = {} as Userproducts2;
          let dataPost = new Date
          if (this.reactiveForm.valid) {
              saveProduct.name = fields.name,
              saveProduct.description = fields.description,
              saveProduct.userName = this.nameUserLogged,
              saveProduct.price = fields.price,
              saveProduct.quantity = fields.quantity
              saveProduct.userid = this.IdUserLogged
              saveProduct.dataPost = `${dataPost.getDate()}/${dataPost.getMonth()+1}/${dataPost.getFullYear()} as ${dataPost.getHours()}:${dataPost.getMinutes()}:${dataPost.getSeconds()}`
              saveProduct.approve = 1;
              if ( fields.name.length > 30 || fields.name.length <= 0 ) {
                  this.message = 'Nome do produto não deve conter mais que 30 caracteres ou ser menor ou igual a 0'
                  this.poType = 'warning'
                  this.showNotification(this.poType);
              }else if(fields.price <= 0) {
                  this.message = 'O valor nao pode ser menor ou igual a 0'
                  this.poType = 'warning'
                  this.showNotification(this.poType);
              }else {
                  if ( !this.isUpdate ) {
                      this.serviceProducts.insertProduct(saveProduct, this.fileInput.nativeElement.files)
                      this.message = 'Produdo cadastrado com sucesso!'
                      
                  } else {
                    this.serviceProducts.updateProduct(this.productUpdateId, this.fileInput.nativeElement.files, saveProduct);
                      // this.productUpdateId = '';
                    this.message = 'Produto alterado com sucesso!'
                  }                    
              //clear the modal form
              this.clearForm();
              this.poType = 'sucess'
              this.showNotification(this.poType);
              this.addProductModal.close();
              }
          } else {
              this.message = 'Preencha o formulário corretamente'
              this.poType = 'error'
              this.showNotification(this.poType)
          }        
      },
      label: 'Salvar',
  };




  RepproveAction: PoModalAction = {
    action: () => {
      let userid = ''
      let username = ''
      let productname = ''
      userid = this.reactiveForm.value.userid
      username = this.reactiveForm.value.userName
      productname = this.reactiveForm.value.name
  
      this.serviceProducts.approveProduct(this.reactiveForm.value.id, 3).then()
      this.message = 'Produto Reprovado com sucesso'
      this.poType = 'success'
      this.showNotification(this.poType)
      this.clearForm();
      this.approveProductModal.close()
      const notify = {
        dateTimestamp: new Date().getTime(),
        description: `Ola ${username}, Seu produto ${productname} Foi reprovado :(`,
        uid: userid,
        view: false,
        eraseNotify: false,
        typeNotify: 'ProdutoReprovado'
    };
   
    this.notificationService.newNotification(notify);
      
    }  
    ,
    label: 'Reprovar Produto',
};


  ApproveAction: PoModalAction = {
    action: () => {
        let userid = ''
        let username = ''
        let productname = ''
        userid = this.reactiveForm.value.userid
        username = this.reactiveForm.value.userName
        productname = this.reactiveForm.value.name

        this.serviceProducts.approveProduct(this.reactiveForm.value.id, 2).then()
        this.message = 'Produto aprovado com sucesso'
        this.poType = 'success'
        this.showNotification(this.poType)
        this.clearForm();
        this.approveProductModal.close()
        const notify = {
          dateTimestamp: new Date().getTime(),
          description: `Ola ${username}, Seu produto ${productname} Foi Aprovado :)`,
          uid: userid,
          view: false,
          eraseNotify: false,
          typeNotify: 'ProdutoAprovado'
        };
     
        this.notificationService.newNotification(notify);

      }  
    ,
    label: 'Aprovar Produto',
};


    CloseAction: PoModalAction = {
      action: () => {
          this.addProductModal.close();
      },
      label: 'Cancelar'
    };

  showUploadImage(file){
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
        this.srcImage = (<FileReader>event.target).result;
    }    
    reader.readAsDataURL(file.target.files[0]);
  }

  initUpload() {
    let uploadButton: HTMLElement = document.getElementById('uploadFile') as HTMLElement;
    uploadButton.click();
  }

  sort(key: any) {
    this.key = key.column.property
     (key.column.property)
    this.reverse = !this.reverse;
  }
  detailsProduct(item: Userproducts2) {
    this.clearForm();
    this.isUpdate = true;
    this.reactiveForm = this.fb.group({
        name: [item.name],
        description: [item.description], 
        userName: [item.userName], 
        price: [item.price], 
        quantity: [item.quantity],  
        userid: [item.userid],
        dataPost: [item.dataPost],  
    });
    this.images = item.images;
    this.productUpdateId = item.id;
    this.addProductModal.open();
}


approveProduct(item: Userproducts2) {
  this.clearForm();
  // console.log(item)
  this.isUpdate = true;
  this.reactiveForm = this.fb.group({
      name: [item.name],
      description: [item.description], 
      userName: [item.userName], 
      price: [item.price], 
      quantity: [item.quantity],  
      userid: [item.userid],
      dataPost: [item.dataPost],
      approve: [item.approve],
      id: [item.id]
  });

  this.images = item.images;
  this.productUpdateId = item.id;
  this.approveProductModal.open();
}


  deleteProduct(item) {
      this.serviceProducts.deleteProductColla(item.id)
      this.message = 'Produto deletado com sucesso!'
      this.poType = 'success'
      this.showNotification(this.poType)
  }


}
