import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';


import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { PoModalAction, PoModalComponent, PoSelectOption } from '@po-ui/ng-components';

@Component({
    selector     : 'modal-po',
    templateUrl  : './modal-po.component.html',
    styleUrls    : ['./modal-po.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalPoComponent implements OnInit
{

    @ViewChild(PoModalComponent, { static: true }) addCoinModal: PoModalComponent;

    userList: Array<PoSelectOption> = [];
    
    valuesCoins = [];

    reactiveFormRecompensa: FormGroup;

    constructor(
        private fb: FormBuilder,
        public _chatPanelService: ChatPanelService,
    )
    {
    }

    ngOnInit(): void
    {
        this.createReactiveForm();
        // // Load the contacts
        this._chatPanelService.getUsers();

    }

    onDelete(){
        
    }

    openNewModal() {
        this.addCoinModal.open();
        this._chatPanelService.getUsers()
    }

    createReactiveForm() {
        this.reactiveFormRecompensa = this.fb.group({
          users: ['', Validators.required],
          coins: ['', Validators.required],
          motivo: ['', Validators.required]
        });
    
        this.reactiveFormRecompensa.valueChanges.subscribe(
          form => {
            this.SaveAction.disabled = !this.reactiveFormRecompensa.valid 
          }
        ); 
        
      }

    SaveAction: PoModalAction = {
        action: () => {
            
        },
        disabled: true,
        label: 'Salvar',
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.addCoinModal.close();
        },
        label: 'Cancelar'
    };

    
}
