import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";
import { datastore } from "app/config";
import { PurchasesModel } from "app/models/purchases.model";
import * as _ from "lodash";
import { TypeOfRewardModel } from "app/Models/TypeOfReward.model";

@Injectable({
    providedIn: "root",
})
export class TypeOfRewardService {
    onTypeOfRewardChanged: BehaviorSubject<any>;
    typeReward;
    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) {
        this.onTypeOfRewardChanged = new BehaviorSubject([]);
    }
    geTypeOfRewards() {
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe((credentials) => {
                if (credentials) {
                    this.firestore
                        .collection(`${datastore}/RewardType`)
                        .snapshotChanges()
                        .subscribe((action) => {
                            this.typeReward = action.map((item) => {
                                return {
                                    id: item.payload.doc.id,
                                    ...(item.payload.doc.data() as TypeOfRewardModel),
                                } as TypeOfRewardModel;
                            });
                            this.onTypeOfRewardChanged.next(
                                _.orderBy(this.typeReward, ["title"], ["asc"])
                            );
                            resolve(this.typeReward);
                        });
                }
            });
        });
    }

    update(id: string, data: PurchasesModel) {
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/RewardType/${id}`).update(data);
        });
    }
    post(data: PurchasesModel) {
         ("??");
        return new Promise((resolve, reject) => {
            this.firestore
                .doc(`${datastore}`)
                .collection("RewardType")
                .add(data);
        });
    }
}
