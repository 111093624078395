
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">attach_money</mat-icon> Moedas
                    </h2>
                </div>
                <div class="po-lg-4">  
                    <po-button  p-label=" Cadastrar Moeda" p-icon="po-icon po-icon-plus" p-type="Primary" (p-click)="openNewModal() " class="pull-right"> </po-button>   
                </div>
            </div>
            <hr>
            <div class="po-row">
                <po-table [p-items]="listCoin" [p-striped]="true" class="po-table-coins" [p-actions]="actions" [p-columns]="columns"></po-table>
            </div>
            <po-modal #addCoinModal p-click-out="false" p-title="Nova Moeda" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction">
                <form [formGroup]="reactiveForm">
                    <div class="po-row">
                        <po-input class="po-sm-12 po-md-12" formControlName="name" p-clean p-icon="po-icon-money" p-label="Nome da Moeda"></po-input>
                    </div>
                    <div class="po-row">
                        <po-select class="po-sm-6 po-md-6" formControlName="type" p-clean p-label="Tipo da Moeda *" [p-options]="coinType" (p-change)="showConfigTable(false)"></po-select>
                        <po-switch class="po-sm-6 po-md-6" formControlName="status" p-label="Status da Moeda" p-label-off="Inativa" p-label-on="Ativa"></po-switch>
                    </div>
                    <!-- <div class="po-row">
                        <po-upload class="po-sm-12 po-md-12" formControlName="icon" p-drag-drop-height="150" p-label="Icone da Moeda" p-drag-drop="true" p-url="assets/images/produtos"></po-upload>
                    </div> -->
                <br/>
                    <div class="po-row" [hidden]="hiddenConfigCoin">
                        <po-number class="po-sm-4 po-md-4" formControlName="valueCoin" p-clean p-icon="po-icon-money" p-label="Valor Fixo para a Recompensa"></po-number>
                        <po-button class="po-md-3 btn-add-value" p-label="Novo Valor" (p-click)="addItem()"> </po-button>
                        <po-table [p-items]="coinConfigList" [p-striped]="true" class="po-table-coins" [p-actions]="actionsConfig"></po-table>
                    </div>
                </form>
            </po-modal> 
        </div>        
    </po-container>
</div>