import { Injectable } from '@angular/core';
import { 
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router 
} from '@angular/router';

import {Observable} from 'rxjs';
import { AuthService } from './auth.service';
import { take, tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private auth: AuthService, private router: Router){

  }

  canActivate(next, state): Observable<boolean>{
    
    return this.auth.user$.pipe(
      take(1),
      map(user => !!user),
      tap(loggedin => {
        if(!loggedin){
          document.getElementById("widget-_hw").style.display = "none"
          this.router.navigate(['auth/login'])
        }else{
          document.getElementById("widget-_hw").style.display = "block"
          console.log('logado')
        }
      })
      
    )
  }
}
