
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">format_list_bulleted</mat-icon> Quests
                    </h2>
                </div>
                <div class="po-lg-4">
                    <po-button p-label=" Nova Quest" p-icon="po-icon po-icon-plus" p-type="Primary"
                        (p-click)="openNewModal()" class="pull-right"> </po-button>
                </div>
            </div>
            <hr>
            <div class="po-row">
                <po-table p-sort="true" [p-items]="listQuests" [p-striped]="true" class="po-table-quests"
                    [p-actions]="actions" [p-columns]="columns"></po-table>
            </div>
            <po-modal #addQuestModal p-click-out="false" p-title="Nova Quest" [p-primary-action]="SaveAction"
                [p-secondary-action]="CloseAction">
                <form [formGroup]="reactiveForm">
                    <div class="po-row">
                        <po-input class="po-lg-8" formControlName="name" p-clean p-icon="po-icon-user" p-label="Nome *">
                        </po-input>
                        <div class="po-lg-4 po-text-center">
                            <po-switch formControlName="status" p-label="Quest Ativa?" p-label-off="Não"
                                p-label-on="Sim"></po-switch>
                        </div>
                    </div>
                    <div class="po-row">
                        <!-- <po-textarea class="po-md-12" formControlName="instructions" p-clean p-icon="po-icon-info" p-label="Instruções"> </po-textarea> -->
                        <po-rich-text class="po-md-12" formControlName="instructions" p-icon="po-icon-info"
                            p-label="Instruções *"></po-rich-text>
                    </div>
                    <div class="po-row">
                        <po-datepicker class="po-lg-4" formControlName="startDate" p-label="Data de Início *" p-clean
                            p-format="dd/mm/yyyy"> </po-datepicker>
                        <po-datepicker class="po-lg-4" formControlName="endDate" p-label="Data Fim *" p-clean>
                        </po-datepicker>
                        <po-number class="po-lg-4" formControlName="rewardValue" p-label="Recompensa *" p-clean
                            p-icon="po-icon-star"> </po-number>
                            <po-checkbox formControlName="evidenciaBol" style="margin-left: 8px;" p-label="Depende de uma evidencia? 'Imagem'">
                                
                            </po-checkbox>
                    </div>
                </form>
            </po-modal>
        </div>
    </po-container>
</div>