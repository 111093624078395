import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { datastore } from "app/config";
import Post from "./model-post";
import * as _ from "lodash";
import * as moment from "moment";
import { PoNotification, PoNotificationService } from "@po-ui/ng-components";
import { TimelineModel } from "app/Models/timeline.model";

@Injectable()
export class TimelineService implements Resolve<any> {
    timeline: any;

    message: string;
    duration = 3900;
    poType: string;

    timelineList = {
        posts: [],
    };

    about: any;
    photosVideos: any;
    isFilter: boolean = false;

    timelineOnChanged: BehaviorSubject<any>;
    timelineFilterOnChanged: BehaviorSubject<any>;
    timelineHasNext: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private poNotification: PoNotificationService,
        private _httpClient: HttpClient,
        private firestore: AngularFirestore
    ) {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject({});
        this.timelineFilterOnChanged = new BehaviorSubject([]);
        this.timelineHasNext = new BehaviorSubject([]);
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getTimeline(),
                this.getAbout(),
                this.getPhotosVideos(),
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get timeline
     */
    getTimeline(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/profile-timeline")
                .subscribe((timeline: any) => {
                    this.timeline = timeline;

                    this.timelineOnChanged.next(
                        _.orderBy(this.timeline, ["post.dateNow"], ["asc"])
                    );
                    resolve(this.timeline);
                }, reject);
        });
    }

    /**
     * Get about
     */
    getAbout(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/profile-about")
                .subscribe((about: any) => {
                    this.about = about;
                    this.aboutOnChanged.next(this.about);
                    resolve(this.about);
                }, reject);
        });
    }

    /**
     * Get photos & videos
     */
    getPhotosVideos(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/profile-photos-videos")
                .subscribe((photosVideos: any) => {
                    this.photosVideos = photosVideos;
                    this.photosVideosOnChanged.next(this.photosVideos);
                    resolve(this.photosVideos);
                }, reject);
        });
    }

    async newPost(data: Post) {
        const id: string = this.firestore.createId();
        return await this.firestore
            .collection(`${datastore}/timeline`)
            .doc(id)
            .set({ ...data, id: id });
    }
    async setDoc(data) {
        return await this.firestore
            .collection(`${datastore}/timeline`)
            .doc(data.id)
            .set(data);
    }

    // without pagination
    getData(): any {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/timeline`)
                .snapshotChanges()
                .subscribe((res) => {
                    this.timelineList.posts = [];
                    res.forEach((item) => {
                        let dataValues = item.payload.doc.data();
                        if (_.isNull(dataValues["dateNow"])) {
                            dataValues["time"] = _.replace(
                                dataValues["time"],
                                "as",
                                "-"
                            );
                        } else {
                            dataValues["time"] = moment(
                                dataValues["dateNow"].seconds * 1000
                            ).format("DD/MM/YYYY - HH:mm:ss");
                        }

                        this.timelineList.posts.push(dataValues);
                        this.orderDataByTimeStamp();
                    });

                    if (!this.isFilter) {
                        this, this.orderDataByTimeStamp();
                        this.timelineFilterOnChanged.next(
                            this.timelineList.posts
                        );
                        resolve(this.timelineList.posts);
                    } else {
                        this.timelineList.posts = _.orderBy(
                            _.intersectionBy(
                                this.timelineList.posts,
                                this.timelineFilterOnChanged.value,
                                "id"
                            ),
                            ["dateNow"],
                            ["desc"]
                        );
                        this.timelineFilterOnChanged.next(
                            this.timelineList.posts
                        );
                        resolve(this.timelineList.posts);
                    }
                });
        });
    }

    last;
    getDataPaginated() {
        // to clean on reset filters
        this.timelineList.posts = [];

        this.firestore
            .collection(`${datastore}/timeline`, (ref) =>
                ref.orderBy("dateNow", "desc").limit(10)
            )
            .valueChanges()
            .subscribe((response) => {
                response.forEach((dataValues: any) => {
                    if (_.isNull(dataValues["dateNow"])) {
                        dataValues["time"] = _.replace(
                            dataValues["time"],
                            "as",
                            "-"
                        );
                    } else {
                        dataValues["time"] = moment(
                            dataValues["dateNow"].seconds * 1000
                        ).format("DD/MM/YYYY - HH:mm:ss");
                    }
                    // remove os dados repetidos que acontecem por causa do real time
                    const indexOf = this.timelineList.posts.findIndex(
                        (item) => item.id === dataValues.id
                    );
                    if (indexOf >= 0) {
                        this.timelineList.posts.splice(indexOf, 1);
                    }
                    // /remove os dados repetidos que acontecem por causa do real time

                    this.timelineList.posts.push(dataValues);
                    this.orderDataByTimeStamp();
                });

                this.last = response[response.length - 1];

                this.timelineFilterOnChanged.next(this.timelineList.posts);

                this.handleHasNext(response.length);
            });
    }
    handleHasNext(length: number) {
        if (length === 10) {
            this.timelineHasNext.next(true);
        } else {
            this.timelineHasNext.next(false);
        }
    }
    getNextPage() {
        this.firestore
            .collection(`${datastore}/timeline`, (ref) =>
                ref
                    .orderBy("dateNow", "desc")
                    .startAfter(this.last?.dateNow)
                    .limit(10)
            )
            .valueChanges()
            .subscribe((response: any) => {
                response.forEach((dataValues) => {
                    if (_.isNull(dataValues["dateNow"])) {
                        dataValues["time"] = _.replace(
                            dataValues["time"],
                            "as",
                            "-"
                        );
                    } else {
                        dataValues["time"] = moment(
                            dataValues["dateNow"].seconds * 1000
                        ).format("DD/MM/YYYY - HH:mm:ss");
                    }
                    // remove os dados repetidos que acontecem por causa do real time
                    const indexOf = this.timelineList.posts.findIndex(
                        (item) => item.id === dataValues.id
                    );
                    if (indexOf >= 0) {
                        this.timelineList.posts.splice(indexOf, 1);
                    }
                    // /remove os dados repetidos que acontecem por causa do real time

                    this.timelineList.posts.push(dataValues);
                    this.orderDataByTimeStamp();
                });

                this.last = response[response.length - 1];

                this.timelineFilterOnChanged.next(this.timelineList.posts);
                this.handleHasNext(response.length);
            });
    }
    orderDataByTimeStamp() {
        this.timelineList.posts = _.orderBy(
            this.timelineList.posts,
            ["dateNow"],
            ["desc"]
        );
    }

    async filterTimeline({ tipoFilter, usersFilter }) {
        let userFilersReturn = [];

        if (tipoFilter == "Ambos") {
            const response = await this.firestore
                .collection(`${datastore}/timeline`, (ref) =>
                    ref.where("rewarded.name", "==", usersFilter)
                )
                .get()
                .toPromise();
            const response2 = await this.firestore
                .collection(`${datastore}/timeline`, (ref) =>
                    ref.where("user.name", "==", usersFilter)
                )
                .get()
                .toPromise();

            response.forEach((doc) => {
                userFilersReturn.push(doc.data());
            });
            response2.forEach((doc) => {
                userFilersReturn.push(doc.data());
            });
        }

        if (tipoFilter == "Recompensou") {
            const response = await this.firestore
                .collection(`${datastore}/timeline`, (ref) =>
                    ref.where("user.name", "==", usersFilter)
                )
                .get()
                .toPromise();

            response.forEach((doc) => {
                userFilersReturn.push(doc.data());
            });
        }

        if (tipoFilter == "Recompensado") {
            const response = await this.firestore
                .collection(`${datastore}/timeline`, (ref) =>
                    ref.where("rewarded.name", "==", usersFilter)
                )
                .get()
                .toPromise();

            response.forEach((doc) => {
                userFilersReturn.push(doc.data());
            });
        }

        this.isFilter = true;

        this.timelineHasNext.next(false);
        userFilersReturn = _.orderBy(userFilersReturn, ["dateNow"], ["desc"]);
        this.timelineFilterOnChanged.next(userFilersReturn);
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
}
