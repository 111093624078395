import { ConfigStore } from './../../Models/config-store';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PoDialogConfirmLiterals, PoDialogService, PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CoinService } from 'app/service/coin.service';
import { CoinsModel } from 'app/models/coins.model';
import { AuthService } from 'app/service/auth.service';
import { Router } from '@angular/router';
import { runInThisContext } from 'vm';
import { LogService } from 'app/service/log.service';
import { StoreConfigurationServiceService } from 'app/service/store-configuration.service';
@Component({
  selector: 'app-store-configuration',
  templateUrl: './store-configuration.component.html',
  styleUrls: ['./store-configuration.component.scss']
})
export class StoreConfigurationComponent implements OnInit {

  @ViewChild(PoModalComponent, { static: true }) configStore: PoModalComponent;


  actions: Array<PoTableAction> = [
      { action: this.detailsStore.bind(this), icon: 'po-icon-edit', label: 'Editar' },
      { action: this.verifyIfDeleteCoin.bind(this), icon: 'po-icon-edit', label: 'Excluir' }
  ];
  actionsConfig: Array<PoTableAction> = [
      { action: this.removeValueConfigCoin.bind(this), icon: 'po-icon-delete', label: 'Excluir' }
  ];

  listCoin: CoinsModel[];
  coinType = [];
  isUpdate = false
  coinUpdateId = '';
  columns: Array<PoTableColumn>;

  coinConfigList = [];
  hiddenConfigCoin = true;

  coinIdToDelete: any

  reactiveForm: FormGroup;

  //Po Notification
  message: string;
  duration = 2500;
  poType: string;

  literalsConfirm: PoDialogConfirmLiterals;

  nameUserLogged: any
  IdUserLogged: any

  constructor(
      private fb: FormBuilder,
      private service: CoinService,
      private poNotification: PoNotificationService,
      public auth: AuthService,
      private router: Router,
      private poAlert: PoDialogService,
      private logService: LogService,
      private serviceStore: StoreConfigurationServiceService
  ) {
      this.createReactiveForm();
      this.coinType = [
          { id: 1, label: 'Para a Loja', value: '1' },
          { id: 2, label: 'Para Recompensar', value: '2' }
      ]


      this.columns = [
          //{ property: 'icon', label: 'Icon', type: 'columnTemplate', visible: true },
          { property: 'name', label: 'Nome da loja', visible: true },
          { property: 'Data update', label: '', visible: true },
          { property: 'Status da loja', label: '', visible: true }
      ]
  }

  ngOnInit(): void {
      //To control the user access, thsi acces if just for Admin users
      this.auth.user$.subscribe(credentials => {
          if (credentials.userPerfil != '4') {
              this.router.navigate(["/sample"])
          } else {

              this.nameUserLogged = credentials.displayName
              this.IdUserLogged = credentials.uid
              
          }
      })

  }

  openNewModal() {
      this.clearForm();
      this.isUpdate = false;
      this.serviceStore.getStatusStore('colaboradores').then(async (res) =>{
          res.forEach( async (element) => {
              let state = await element.data().status 
               this.reactiveForm = this.fb.group({
                 status: [state]
               });
            //   console.log(state)
          });
          this.configStore.open();
      })
   
      
      
  }

  SaveAction: PoModalAction = {
      action: () => {
          let fields = this.reactiveForm.value;
          let saveConfig = {} as ConfigStore;
          if (this.reactiveForm.valid) {
              saveConfig.status = fields.status == null ? false : fields.status
        
              this.serviceStore.updateStatusStore('colaborador' ,saveConfig.status)
              this.poType = 'sucess'
              this.message = 'Atualizado com sucesso'
              this.configStore.close();

              this.clearForm();
              this.showNotification(this.poType)
          } else {
              this.message = 'Preencha o formulário corretamente'
              this.poType = 'error'
              this.showNotification(this.poType)
          }
      },
      label: 'Salvar',
  };

  CloseAction: PoModalAction = {
      action: () => {
          this.configStore.close();
      },
      label: 'Cancelar'
  };

  verifyCoinRules(saveStatus, saveType, coinId) {
      let countActive = 0
      let isValid = true;
      if (saveStatus) {
          this.listCoin.forEach(coin => {
              if (coin.status == true) {
                  countActive++
                  if (coin.type == saveType) {
                      if (!coinId || coin.id != coinId) {
                          isValid = false
                          this.message = 'Já existe uma moeda ativa com o memso tipo';
                          this.poType = 'error'
                      }

                  }
              }
          });
          if (countActive == 2 && isValid && !coinId) {
              isValid = false;
              this.message = 'Já existem duas moedas ativas';
              this.poType = 'error'
          }
      }
      if(saveType == 2 && this.coinConfigList.length == 0){

          isValid = false;
          this.message = 'Adicione ao menos um valor padrão de recomepensa';
          this.poType = 'error'
      }
      return isValid
  }

  detailsStore(item: ConfigStore) {
    //   console.log(item)
      this.clearForm();
      this.isUpdate = true;
      this.reactiveForm = this.fb.group({
          status: [item.status]
      });
      this.configStore.open();
  }

  createReactiveForm() {
      this.reactiveForm = this.fb.group({
          status: [],
      });
  }

  clearForm() {
      this.reactiveForm.reset();
      this.coinConfigList = [];
      this.hiddenConfigCoin = true;
      // this.reactiveForm = this.fb.group({
      //     name: [''],
      //     type: [''],
      //     icon: [''],
      //     status: [''],
      //     valorConfigMoeda: ['']
      // });
  }

  showNotification(messageType: string) {
      const poNotification: PoNotification = {
          message: this.message,
          duration: this.duration,
          orientation: 1
      }
      switch (messageType) {
          case 'success': {
              this.poNotification.success(poNotification);
              break;
          }
          case 'error': {
              this.poNotification.error(poNotification);
              break;
          }
          case 'warning': {
              this.poNotification.warning(poNotification);
              break;
          }
          case 'information': {
              this.poNotification.information(poNotification);
              break;
          }
          default: {
              this.poNotification.success(poNotification);
              break;
          }

      }
      this.poType = ''
  }

  addItem() {
      let value = this.reactiveForm.get('valueCoin').value
      if (value) {
          this.coinConfigList.push({ Id: (this.coinConfigList.length + 1), Valor: value })
      } else {
          this.message = 'Preencha o valor para adicionar uma opção!'
          this.showNotification('error')
      }
      this.reactiveForm.controls['valueCoin'].reset();
  }

  removeValueConfigCoin(element) {
      let index = element.Id;
      let result = index - 1
      this.coinConfigList.splice(result, 1);
      if (this.coinConfigList.length > 0) {
          this.coinConfigList.forEach((element, index) => {
              element.Id = index + 1
          });
      }

  }

  showConfigTable(sendValue) {
      let value
      if (!sendValue) {
          value = this.reactiveForm.get('type').value
      } else {
          value = sendValue;
      }

      if (value == "2") {
          this.hiddenConfigCoin = false
      } else {
          this.hiddenConfigCoin = true
      }
  }

  confirmDialog() {
      this.poAlert.confirm({
          literals: this.literalsConfirm,
          title: "Atenção",
          message: 'Deseja realmente deletar esse item?',
          confirm: () => {
              this.service.deleteCoin(this.coinIdToDelete)
              this.coinIdToDelete = undefined
          },
          cancel: () => (undefined),
          close: () => (undefined)
      });
  }

  verifyIfDeleteCoin(item){
      this.coinIdToDelete = item.id;
      this.confirmDialog()
  }
  
  deleteCoin(){ 
      this.service.deleteCoin(this.coinIdToDelete)
      this.coinIdToDelete = undefined
  }
}
