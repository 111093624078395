<div style="display: flex; flex-direction: column; flex: 1;">
    <po-container class="po-xl-16 po-lg-16" style="margin-bottom: 10px; margin-top: 10px;">
      
        <div class="container-custom-01">
            <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="5000" color="accent" maxWidth="auto" proportion="25"
            slides="3" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false"
            orientation="ltr">
        
            <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slideStore; let i = index" [image]="slide"
                overlayColor="#00000000" [hideOverlay]="false">
            </mat-carousel-slide>
        </mat-carousel>
        </div>
    </po-container>
    
    <div style="display: flex;">
        <div class="po-xl-8 po-lg-8" style="padding: 10px 0;">
            <po-container class="po-xl-12 po-lg-12">
                <div class="container-custom-01">
                    <h3>Últimas Compras</h3> <br>
                    
                    <po-table [p-items]="ultimasComprasConcat" [p-striped]="true" [p-columns]="tableColumns">
        
                        <ng-template p-table-column-template p-property="products" let-value>
                            <img [src]="value[0].images[0].imgLink" alt="imagem do produto"
                                style="width: 4rem; margin-right: 1rem;">
                            <span [innerText]="value[0].name"></span>
                        </ng-template>
        
                    </po-table>
                </div>
            </po-container>
            <!-- <po-container class="po-xl-12 po-lg-12" style="margin-top: 10px;">
                <div class="po-row">
                    <div class="po-md-12 po-lg-12" style="padding: 0;">
                        <div class="po-row">
                            <po-chart class="po-md-12 po-mt-2" p-title="Ranking de estrelas"
                                [p-categories]="rankingLabelByColumn" [p-series]="rankingDataByColumn" [p-type]="rankingType"
                                [p-options]="rankingOptions">
                            </po-chart>
                        </div>
                    </div>
                </div>
            </po-container> -->
 
            <!-- <div class="po-row">
                <po-container class="po-xl-12 po-lg-12" style="margin-top: 10px;">
                    <div class="po-md-12 po-lg-12">
                        <po-container>
        
                            <div class="po-row">
                                <ngx-charts-bar-horizontal [view]="view" [scheme]="colorScheme" [results]="rankGivers"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    xAxisLabel="Top De Doadores" style="width: 100% !important;">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </po-container>
        
                    </div>
                </po-container>
            </div> -->

            <po-container class="po-xl-12 po-lg-12" style="margin-top: 10px;">
                <div class="po-row">
                    <div class="po-md-12 po-lg-12" style="padding: 0;">
                        <po-container>
                            <div class="po-row">
                                <ngx-charts-bar-horizontal style="font-weight: 700;"  [view]="view" [scheme]="colorScheme" [results]="rankingRecived"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    xAxisLabel="Top De Recompensados">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </po-container>
                    </div>
                </div>
            </po-container>

            <po-container class="po-xl-12 po-lg-12" style="margin-top: 10px;">
                <div class="po-row">
                    <div class="po-md-12 po-lg-12" style="padding: 0;">
                        <po-container>
                            <div class="po-row">
                                <ngx-charts-bar-horizontal [view]="view" [scheme]="colorScheme" [results]="rankGivers"
                                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                xAxisLabel="Top De Doadores" style="font-weight: 700;">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </po-container>
                    </div>
                </div>
            </po-container>



            <po-container class="po-xl-12 po-lg-12" style="margin-top: 10px;">
                <div class="po-row">
                    <div class="po-md-12 po-lg-12" style="padding: 0;">
                        <po-container>
        
                            <div class="po-row">
                                <ngx-charts-bar-horizontal [view]="[550, 300]" [scheme]="colorScheme" [results]="rankingCoins"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    xAxisLabel="Top De Estrelas" style="font-weight: 700" yAxisLabel="">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </po-container>
                    </div>
                </div>
            </po-container>
        </div>
        
        <div class="po-xl-4 po-lg-4" style="padding: 10px 10px 10px 0; display: flex; flex-direction: column; gap: 15px;">
            <po-container  style=" width: 100%">
                <div class="container-custom-01">
                    <h3 class="po-text-center">
                        <strong>
                            <span class="po-icon po-icon-ok" style="font-size: 20px; color: green;"></span> Quests Ativas
                        </strong>
                    </h3>
                </div>
                <hr>
                <div class="container-custom-01">
                    <po-accordion *ngFor="let item of listQuests">
                        <po-accordion-item [p-label]="item.name" class="">
                            <div class="po-text-vertical-center">
                                <span class="username mr-12 po-text-center" style="width: 100%;">
                                    <strong>{{item.name}}</strong>
                                    <p style="font-size: 12px;" *ngIf="!item.endDate?.length">{{item.startDate | date:
                                        'dd/MM/yyyy'}}
                                    </p>
                                    <p style="font-size: 12px;" *ngIf="item.endDate?.length">{{item.startDate | date: 'dd/MM/yyyy'}}
                                        até
                                        {{item.endDate | date: 'dd/MM/yyyy'}}</p>
                                </span>
                            </div>
                            <hr>
                            <div style="margin-bottom: 5px; padding: 1rem;" [innerHtml]="item.instructions">
                            </div>
                            <hr>
        
                            <div class="po-row">
                                <div class="po-lg-2"></div>
                                <div class="po-lg-8 po-text-center">
                                    <strong style="font-size: 20px;">
                                        {{item.rewardValue}} <span class="po-icon po-icon-star-filled"
                                            style="color: yellow;"></span>
                                    </strong>
                                </div>
                                <div class="po-lg-2" style="padding: 0;">
                                    <po-button style="float: right;" (click)=detailsQuest(item) p-icon="po-icon-ok"
                                        p-type="primary">
                                    </po-button>
                                </div>
                            </div>
                        </po-accordion-item>
                    </po-accordion>
        
                </div>
                
                <po-modal #questModal p-click-out="false" p-title="Enviar Resultado" [p-primary-action]="SaveAction"
                    [p-secondary-action]="CloseAction">
                    <div *ngIf="quest">
                        <form [formGroup]="reactiveForm">
                            <div class="po-row">
                                <div class="po-lg-6 po-text-center">
                                    <po-info p-label="Quest" [p-value]="quest.name"> </po-info>
                                </div>
                                <div class="po-lg-6 po-text-center">
                                    <div class="po-info">
                                        <div class="po-info-container-title">
                                            <span class="po-info-label">a Recompensa </span>
                                        </div>
                                        <div class="po-info-container-content">
                                            <span class="po-info-value ng-star-inserted po-text-vertical-center">
                                                {{quest.rewardValue}} <mat-icon class="secondary-text" style="color: #ffb300;">
                                                    star_rate</mat-icon>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="po-row">
                                <div class="po-lg-12">
                                    <!-- <po-info p-label="Instruções" [p-value]="quest.instructions"> </po-info> -->
                                    <div [innerHtml]="quest.instructions"></div>
                                </div>
                            </div>
                            <hr>
                            <div class="po-row">
                                <div class="po-lg-12">
                                    <po-textarea p-help="Utilize este campo para enviar sua resposta para esta quest!"
                                        formControlName="questAnswer" p-clean p-icon="po-icon-info" p-label="Resposta para Quest">
                                    </po-textarea>
                                </div>
                                <div class="po-lg-12">
                                    <div class="po-row hlp-margin-top-15 hlp-margin-bottom-15">
                                        <div class="po-lg-3"></div>
                                        <div class="po-lg-6">
                                            <button *ngIf="quest['evidencia'] === true" for="uploadFile" class="po-button po-text-ellipsis" (click)="initUpload()">
                                                <span aria-hidden="true" class="po-icon po-icon-plus ng-star-inserted"></span>
                                                <span class="po-button-label ng-star-inserted">Adicionar Imagem</span>
                                                <input id="uploadFile" #fileInput style="display: none;" type="file"
                                                    (change)="showUploadImage($event)">
                                            </button>
                                            
                                        </div>
                                    </div>
                                    <div class="po-row">
                                        <div class="po-lg-12 po-text-center" *ngIf="srcQuestImage?.length">
                                            <img [src]="srcQuestImage" class="hlp-img-thumbnail">
                                        </div>
                                        <div class="po-lg-12 po-text-center" *ngIf="!srcQuestImage?.length">
                                            <img [src]="item.imgLink" *ngFor="let item of image" class="hlp-img-thumbnail">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </po-modal>
            </po-container>
        
            <div class="po-container">
                
                    <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="8000"
                    color="accent"
                    slides="3"
                    proportion="80"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="false"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    orientation="ltr">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slideStoreLat; let i = index" style="max-height:300px; margin:0 auto;" [image]="slide"
                            overlayColor="#00000040" [hideOverlay]="false">
                        </mat-carousel-slide>
                    </mat-carousel>
                
            </div>
            
        
        </div>
    </div>
</div>

