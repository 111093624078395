import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsModel } from 'app/models/products.model';
import { ProductsService } from 'app/service/products.service';
import { AuthService } from 'app/service/auth.service';
import { Router } from '@angular/router';
import { ExcelDownService } from 'app/service/excel-down.service';

@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    providers: [PoNotificationService]
})
export class ProductsComponent implements OnInit {
    @ViewChild(PoModalComponent, { static: true }) addProductModal: PoModalComponent;

    actions: Array<PoTableAction> = [
        { action: this.detailsProduct.bind(this), icon: 'po-icon-info', label: 'Editar' },
        { action: this.deleteProduct.bind(this), icon: 'po-icon-delete', label: 'Excluir' }
    ];

    public listProducts: ProductsModel[];
    public product: ProductsModel;
    public isUpdate = false;
    public productUpdateId = '';
    
    public productStatus = '';

    reactiveForm: FormGroup;
    files: Array<any>;    

    columns: Array<PoTableColumn>;

    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    nome: any = ''
    images: Array<any>;
    srcImage: any;
    p:Number = 0;
    itemPage:Number = 10;
    key: string = 'price'
    reverse: boolean = true;

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(
        private fb: FormBuilder,
        private service: ProductsService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
        private router: Router,
        private excelDown: ExcelDownService
    ) {
        this.createReactiveForm();
        
        this.columns = [
            // { property: 'images', label: 'Imagem', type: 'columnTemplate', visible: true },
            { property: 'name', label: 'Nome', visible: true },
            { property: 'price', label: 'Valor', visible: true },
            { property: 'description', label: 'Descrição', visible: true },
            { property: 'status', label: 'Produto Ativo?', type: 'label', labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : '',
                        color: 'color-11',
                        label: 'Ativa',
                        tooltip: 'Produto Ativo'
                    },
                    {
                        value: false,
                        color: 'color-07',
                        label: 'Inativa',
                        tooltip: 'Produto Inativo'
                    },
                ], visible: true
            }            
        ]      
    }

    ngOnInit(): void { 
        //To control the user access, thsi acces if just for Admin users
        this.auth.user$.subscribe(credentials => {
            if (credentials.userPerfil != '1') {
                this.router.navigate(["/sample"])
            } else {
                this.service.onProductChanged.subscribe(products => {
                    this.listProducts = products
                });
            }
        })
        
    }

    ngAfterViewInit(): void{
        
    }


    openNewModal(){
        this.srcImage = '';
        this.images = [];
        this.clearForm();
        this.isUpdate = false;
        this.addProductModal.open();
    }

    SaveAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveForm.value;
            let saveProduct = {} as ProductsModel;
            if ( this.reactiveForm.valid ) {
                saveProduct.name = fields.name,
                saveProduct.description = fields.description,
                saveProduct.category = fields.category,
                saveProduct.price = fields.price,
                saveProduct.quantity = fields.quantity,
                saveProduct.showHome = fields.showHome,
                saveProduct.status = fields.status

                if ( fields.name.length > 30 || fields.name.length <= 0 ) {
                    this.message = 'Nome do produto não deve conter mais que 30 caracteres e menos que 0'
                    this.poType = 'warning'
                    this.showNotification(this.poType);
                }else if(fields.price <= 0) {
                    this.message = 'O valor nao pode ser menor ou igual a 0'
                    this.poType = 'warning'
                    this.showNotification(this.poType);
                }else {
                    if ( !this.isUpdate ) {
                        this.service.insertProduct(saveProduct, this.fileInput.nativeElement.files);
                        this.message = 'Produdo cadastrado com sucesso!'
                    } else {
                        this.service.updateProduct(this.productUpdateId, this.fileInput.nativeElement.files, saveProduct);
                        this.productUpdateId = '';
                        this.message = 'Produto alterado com sucesso!'
                    }                    
                //clear the modal form
                this.clearForm();
                this.poType = 'sucess'
                this.showNotification(this.poType);
                this.addProductModal.close();
                }
            } else {
                this.message = 'Preencha o formulário corretamente'
                this.poType = 'error'
                this.showNotification(this.poType)
            }        
        },
        label: 'Salvar',
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.addProductModal.close();
        },
        label: 'Cancelar'
    };

    clearForm(){
        this.reactiveForm.reset();
        this.reactiveForm = this.fb.group({
            name: [''],
            description: [''], 
            category: [''], 
            images: [''], 
            price: [0], 
            quantity: [0], 
            showHome: [false], 
            status: [true] 
        });
        this.productStatus = ''
    }

    detailsProduct(item: ProductsModel) {
        this.clearForm();
        this.isUpdate = true;
        this.reactiveForm = this.fb.group({
            name: [item.name],
            description: [item.description], 
            category: [item.category], 
            price: [item.price], 
            quantity: [item.quantity], 
            showHome: [item.showHome], 
            status: [item.status]     
        });
        this.images = item.images;
        this.productUpdateId = item.id;
        this.addProductModal.open();
    }

    deleteProduct(item) {
        this.service.deleteProduct(item.id)
        this.message = 'Produto deletado com sucesso!'
        this.poType = 'success'
        this.showNotification(this.poType)
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            description: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(240)])],
            category: [''],
            images: [''],
            price: [0],
            quantity: [0],
            showHome: [false],
            status: [true]
        });
    }  

    showUploadImage(file){
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
            this.srcImage = (<FileReader>event.target).result;
        }    
        reader.readAsDataURL(file.target.files[0]);
    }
    
    initUpload() {
        let uploadButton: HTMLElement = document.getElementById('uploadFile') as HTMLElement;
        uploadButton.click();
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1
        }
        switch (messageType) {
            case 'success': {
                this.poNotification.success(poNotification);
                break;
            }
            case 'error': {
                this.poNotification.error(poNotification);
                break;
            }
            case 'warning': {
                this.poNotification.warning(poNotification);
                break;
            }
            case 'information': {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }

        }
        this.poType = ''
    }


    filterName(){
        const arrayOne = []
       
        this.service.onProductChanged.subscribe((quests) => { 
            quests.filter(e =>{
                if (e.name.toUpperCase().indexOf(this.nome) !== -1 || e.name.toLowerCase().indexOf(this.nome) !== -1 || e.name.indexOf(this.nome) !== -1){
                    arrayOne.push(e)
                }else if(this.nome === '' || this.nome == undefined){
                    arrayOne.push(e)
                }else if('ATIVA'.indexOf(this.nome) !== -1 || 'ativa'.indexOf(this.nome) !== -1){
                    if(e.status === true){
                        arrayOne.push(e)
                    }
                }else if('INATIVA'.indexOf(this.nome) !== -1 || 'inativa'.indexOf(this.nome) !== -1){
                    if(e.status === false){
                        arrayOne.push(e)  
                    }
                }
                })
                this.listProducts = arrayOne
            })
        } 


    downloadEx(){
        this.excelDown.downLoadProducts(this.listProducts)
    }
    sort(key: any) {
        this.key = key.column.property
         (key.column.property)
        this.reverse = !this.reverse;
    }
}

