import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { AuthService } from "app/service/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { StoreConfigurationServiceService } from "app/service/store-configuration.service";

@Component({
    selector: "fuse-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = "vertical";

    @Input()
    navigation: any;
    userPerfil: string;
    pageStatus: boolean
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {TranslateService} _translateService
     */
    constructor(
        public auth: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        private serviceStore: StoreConfigurationServiceService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit() {
        //to verificate the user perfil and set de correct menu,
        // /*User Perfil Legend:
        // 1 - Admin
        // 2 - Staff
        // 3 - User
        //  */
 
        await this.serviceStore.getStatusStore('colaboradores').then(async (res) =>{
            await res.forEach( async (element) => {
                let state = await element.data().status 
                 
                this.pageStatus  = state
            });
            
        })

      
        this.auth.user$.subscribe((credentials) => {
            if (credentials) {
                // Subscribe to the current navigation changes
                this._fuseNavigationService.onNavigationChanged
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        // Load the navigation
                        this.userPerfil = credentials.userPerfil;
                        this.navigation =
                            this.navigation ||
                            this._fuseNavigationService.getCurrentNavigation();

                            
                        let tempNavigation = new Array();
                        this.navigation.forEach((option) => {
                            // new navigation
                            if (
                                option.id === "configuration" &&
                                this.userPerfil == "2"
                            ) {
                                return;
                            }
                            if (
                                option.id !== "applications" &&
                                this.userPerfil == "3"
                            ) {
                                return;
                            }
                            const linkRef = { ...option };
                            linkRef.children = [];
                            

                            option.children.forEach((item) => {
                              
                                // todas os usuários possuem
                                if (
                                    item.id === "dashboard" ||
                                    item.id === "my_perfil"
                                    
                                ) {
                                    linkRef.children.push(item);
                                }
                                // /todas os usuários possuem

                                if (this.userPerfil == "4") {
                                    if (
                                        item.id === "approveReward" ||
                                        item.id === "users" ||
                                        item.id === "pageLog" ||
                                        item.id === "systemparams" ||
                                        item.id === "domains" ||
                                        item.id === "my_products" ||
                                        item.id === "storemparams" ||
                                        item.id === "store"
                                    )
                                    {
                                        linkRef.children.push(item);
                                    }
                                }
                                if (this.userPerfil == "3") {
                                    if (
                                        item.id === "store" ||
                                        item.id === "my_purchases" ||
                                        item.id === "my_quests" ||
                                        item.id === "my_products"  ||
                                        item.id === "superpointz"
                                    )
                                    {
                                        linkRef.children.push(item);
                                    }
                                }
                                if (this.userPerfil == "2") {
                                    if (
                                        item.id !== "users" &&
                                        item.id !== "coins" &&
                                        item.id !== "pageLog" &&
                                        item.id !== "dashboard" &&
                                        item.id !== "my_perfil"
                                    ) {
                                        linkRef.children.push(item);
                                    }
                                }
                                if (
                                    this.userPerfil == "1" &&
                                    item.id !== "dashboard" &&
                                    item.id !== "my_perfil" &&
                                    item.id !== "systemparams" &&
                                    item.id !== "domains" &&
                                    item.id !== "storemparams" 
                            
                                ) {
                                    linkRef.children.push(item);
                                }
                            });


                            
                            if (linkRef.id == 'applications' && this.pageStatus === false){
                               let testes = linkRef.children.filter((data) =>{
                                   return data.id != 'my_products'
                               })
                              
                               linkRef.children = []
                               linkRef.children = testes
                               tempNavigation.push(linkRef);
                            }else{
                                tempNavigation.push(linkRef);
                            }
                            


                           
                            // /new navigation
                            // if (
                            //     (this.userPerfil == "1" &&
                            //         option.id == "configuration") ||
                            //     (this.userPerfil == "4" &&
                            //         option.id == "configuration")
                            // ) {
                            //     if (this.userPerfil == "4") {
                            //         option.children.splice(1, 1);
                            //     }

                            //     tempNavigation.push(option);
                            // } else if (
                            //     (this.userPerfil == "2" ||
                            //         this.userPerfil == "1") &&
                            //     option.id == "administration"
                            // ) {
                            //     tempNavigation.push(option);
                            // } else if (
                            //     option.id != "configuration" &&
                            //     option.id != "administration"
                            // ) {
                            //     if (this.userPerfil == "4") {
                            //         console.log("???", option);
                            //         option.children.splice(1, 1);
                            //         option.children.splice(2, 2);
                            //     }

                            //     tempNavigation.push(option);
                            // } else if (
                            //     option.id === "administration" &&
                            //     this.userPerfil == "4"
                            // ) {
                            //     option.children.splice(
                            //         4,
                            //         option.children.length - 1
                            //     );
                            //     option.children.splice(0, 2);
                            //     option.children.splice(1, 1);
                            //     tempNavigation.push(option);
                            // }
                        });
                        this.navigation = tempNavigation;
                        
                        setTimeout(() => {
                            this._translateService.setDefaultLang("en");
                            this._translateService.setDefaultLang("br");
                        });

                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    });

                // Subscribe to navigation item
                merge(
                    this._fuseNavigationService.onNavigationItemAdded,
                    this._fuseNavigationService.onNavigationItemUpdated,
                    this._fuseNavigationService.onNavigationItemRemoved
                )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(() => {
                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    });
            }
        });
    }
}
