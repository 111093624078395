import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { SystemparametersComponent } from './systemparameters.component';
import { MatIconModule } from '@angular/material/icon';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';

import { SystemParamsService } from 'app/service/systemparams.service';

const routes = [
  {
      path : 'systemparams',
      component: SystemparametersComponent,
      resolve: {
          params: SystemParamsService
      }
  }
]

@NgModule({
  declarations: [
    SystemparametersComponent
],
imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,

    MatIconModule,

    //PO Modules
    PoContainerModule,
    PoPageModule,
    PoWidgetModule,
    PoModalModule,
    PoTableModule,
    PoButtonModule,
    PoFieldModule

],
exports:[
  SystemparametersComponent
]
})

export class SystemparametersModule { }
