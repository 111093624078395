import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { Observable, of, BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { UserModel } from "../Models/user.model2";
import { datastore } from "app/config";
import { TranslateService } from "@ngx-translate/core";
import { Perfis } from "../Models/perfis.model2";
import * as _ from "lodash";
import { PoNotification, PoNotificationService } from "@po-ui/ng-components";
import { MailerService } from "./mailer.service";
import { LogService } from "./log.service";
import { DomainCrudService } from "./domain-crud.service";
import { HttpClient } from "@angular/common/http";
import * as firebase from "firebase/app";





@Injectable({
    providedIn: "root",
})
export class AuthService {
    private eventAuthError = new BehaviorSubject<string>("");
    eventAuthErrors$ = this.eventAuthError.asObservable();
    user$: Observable<any>;
    newUser: any;
    userData: any;
    onEspectadorChanged: BehaviorSubject<any>;
    domains = [];
    execoes = []
    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router,
        private _translateService: TranslateService,
        private poNotification: PoNotificationService,
        private logService: LogService,
        public serviceMailer: MailerService,
        private firestore: AngularFirestore,
        private httpClient: HttpClient
    ) {
        this.onEspectadorChanged = new BehaviorSubject([]);

        this.user$ = this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    const response = this.afs
                        .doc<UserModel>(`${datastore}/users/${user.uid}`)
                        .valueChanges();
                    response.subscribe((event) => (this.userData = event));
                    this.onEspectadorChanged.next(
                        _.isUndefined(this.userData)
                            ? []
                            : this.userData.profile
                    );
                    return response;
                } else {
                    return of(null);
                }
            })
        );
    }

    async getDomains() {
        const data = await this.firestore.collection(`${datastore}/rwDomains`).get().toPromise();
        const domains = data.docs.map((item) => {
            return {
                ...(item.data() as any),
            } as any;
        });
        this.domains = domains.filter((domain) => { return domain.isExcecao == undefined });
        this.execoes = domains.filter((domain) => { return domain.isExcecao == true });

        
    }

    async googleSignin() {
        const that = this;
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                return that.afAuth.auth
                    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                    .then(async (userCredential) => {
                        await this.getDomains();
                        const domain = userCredential.user.email.split("@")[1];
                        if (this.domains.filter(e => e.title === domain).length > 0 || this.execoes.filter(e => e.email === userCredential.user.email).length > 0) {
                            return this.updateUserData(userCredential.user);
                        } else {
                            const notifiy: PoNotification = {
                                message:
                                    "Acesso não liberado pelo administrador, dominio não permitido!",
                                duration: 7200,
                                orientation: 1,
                            };
                            this.poNotification.error(notifiy);
                        }
                    })
                    .catch((error) => {
                        that.eventAuthError.next(error);
                    });
            });
    }

    async login(email: string, password: string) {
        this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .catch((error) => {
                this.eventAuthError.next(error);
                const notifiy: PoNotification = {
                    message:
                        "Usuario ou senha invalidos",
                    duration: 10000,
                    orientation: 1,
                };
                this.poNotification.error(notifiy);
            })
            .then(async (userCredential) => {
                if (userCredential) {
                    await this.getDomains();
                    // TODO remover isso após correção em produção
                    //this.domains[0].title = "iv2.com.br"
                    //this.execoes[0].email = "carlos.farias@iv2.com.br"
                    // TODO end

                    const domain = userCredential.user.email.split("@")[1];
                    if (this.domains.filter(e => e.title === domain).length > 0 || this.execoes.filter(e => e.email === userCredential.user.email).length > 0) {

                        this.getDataUser(userCredential.user.uid).subscribe(
                            (action) => {
                                if (!action.ativo) {
                                    const notifiy: PoNotification = {
                                        message:
                                            "Acesso não liberado pelo administrador!",
                                        duration: 10000,
                                        orientation: 1,
                                    };
                                    this.poNotification.error(notifiy);
                                    this.router.navigate(["/auth/login"]);
                                } else {
                                    this.logService.insertLog({
                                        pageName: "Auth",
                                        actionExecuted: "login do Usuário",
                                        responsible: action.displayName,
                                        userLoggedId: action.uid,
                                        description: "Usuário acessou o sistema",
                                        registeredDate: new Date(),
                                        objectTransacted: JSON.stringify(action),
                                    });

                                   
                                    this.router.navigate(["/sample/"]);
                                    

                                }
                            }
                        );

                    } else {
                        const notifiy: PoNotification = {
                            message:
                                "Acesso não liberado pelo administrador, dominio não permitido!",
                            duration: 7200,
                            orientation: 1,
                        };
                        this.poNotification.error(notifiy);
                    }

                }
            });
    }

    async signOut() {
        await this.afAuth.auth.signOut();
        return this.router.navigate(["/auth/login"]);
    }


    async createUserPredefine(user){
        const domain = user.email.split("@")[1];
        const credential = this.afAuth.auth
            .createUserWithEmailAndPassword(user.email, user.password)
            .then(async (userCredential) => {
                    this.firestore.collection(`${datastore}/users`).get().subscribe(async (res) =>{
                        let fireid = ''
                        let data = {}
                        res.docs.forEach((dados) =>{
                            if (dados.data().email == user.email){
                                    fireid = dados.id
                                    this.firestore.collection(`${datastore}/users`).doc(userCredential.user.uid).set(dados.data())
                                    this.firestore.collection(`${datastore}/users`).doc(userCredential.user.uid).update({predefinido: false})
                                    this.firestore.collection(`${datastore}/users`).doc(fireid).delete()
                                     
                                    data = {
                                        uid: userCredential.user.uid,
                                        email: userCredential.user.email,
                                        displayName: dados.data().displayName,
                                        firstName: dados.data().firstName,
                                        photoURL: dados.data().photoURL,
                                        ativo: dados.data().ativo,
                                        registerdate: dados.data().registerdate,
                                        cash: dados.data().cash,
                                        trade: dados.data().trade,
                                        cashRecivedToRank: dados.data().cashRecivedToRank

                                    }
                             }
                        })

                        await this.getDomains();
                        if (this.domains.filter(e => e.title === domain).length > 0 || this.execoes.filter(e => e.email === user.email).length > 0) {
                            
                        return this.updateUserData(data);
                        } else {
                            const notifiy: PoNotification = {
                                message: "Criação não liberada pelo administrador!",
                                duration: 10000,
                                orientation: 1,
                            };
                            this.poNotification.error(notifiy);
                        }
                        })
                //this.firestore.collection(`${datastore}/users`).doc(fireid).delete()
            
                
            })
            .catch((error) => {
                this.eventAuthError.next(error);
            });
    }



    async createUser(user) {
        const domain = user.email.split("@")[1];
        const credential = this.afAuth.auth
            .createUserWithEmailAndPassword(user.email, user.password)
            .then(async (userCredential) => {
                await this.getDomains();
                // TODO remover isso após correção em produção
                // this.domains[0].title = "iv2.com.br"
                //this.execoes[0].email = "carlos.farias@iv2.com.br"
                // TODO end
                if (this.domains.filter(e => e.title === domain).length > 0 || this.execoes.filter(e => e.email === user.email).length > 0) {

                    const data = {
                        uid: userCredential.user.uid,
                        email: userCredential.user.email,
                        displayName: user.name,
                        firstName: user.name,
                        photoURL: "assets/images/avatars/profile.jpg",
                        profile: Perfis.guest,
                        ativo: false,
                        registerdate: new Date(),
                    };

                    return this.updateUserData(data);
                } else {
                    const notifiy: PoNotification = {
                        message: "Criação não liberada pelo administrador!",
                        duration: 10000,
                        orientation: 1,
                    };
                    this.poNotification.error(notifiy);
                }
            })
            .catch((error) => {
                this.eventAuthError.next(error);
            });

    }

    async resetPassword(email: string) {
        this.afAuth.auth.sendPasswordResetEmail(email).catch((error) => {
            this.eventAuthError.next(error);
        });

        const notifiy: PoNotification = {
            message: "Link para resetar sua senha enviada no seu e-mail!",
            duration: 10000,
            orientation: 1,
        };

        this.poNotification.success(notifiy);
    }

    private updateUserData({
        uid,
        email,
        displayName,
        photoURL,
        ativo,
        providerData
    }: UserModel) {
        let aviso = 0;

        let googlechatId = '';

        if (providerData) {
            googlechatId = providerData[0].uid
        }
        const userRef: AngularFirestoreDocument<UserModel> = this.afs.doc(
            `${datastore}/users/${uid}`
        );
        this.getDataUser(uid).subscribe( (action) => {
            if (_.isUndefined(action)) {
                const data = {
                    uid,
                    email,
                    displayName,
                    photoURL,
                    ativo: false,
                    toApprove: true,
                    registerdate: new Date(),
                    userPerfil: "3",
                    cash: 0,
                    trade: 0,
                    cashRecivedToRank: 0,
                    tradesTotalToRank: 0,
                    googlechatId,
                    firstName : '',
                    lastName: '',
                    birthday: '2021-08-04',
                    termsAndConditions: false
                };
                
                userRef.set(data, { merge: true });

                const notifiy: PoNotification = {
                    message: "Seu cadastro foi efetuado com sucesso, aguarde o administrador liberar seu acesso!",
                    duration: 10000
                }

                this.poNotification.success(notifiy);
                aviso = 1;

                this.serviceMailer.senMailCreateLogin(email, displayName, photoURL);
                this.serviceMailer.senMailAdminLiberacao(email, displayName, photoURL);

                return this.router.navigate(["/auth/login"]);
            } else {
                
                if (action.ativo == false || action.ativo == null) {
                    const data = {
                        uid,
                        email,
                        displayName,
                        photoURL,
                        ativo: false,
                    };

                    userRef.set(data, { merge: true });

                    if (aviso == 0) {
                        const notifiy: PoNotification = {
                            message: "Acesso não liberado pelo administrador!",
                            duration: 10000,
                            orientation: 1,
                        };
                        this.poNotification.error(notifiy);
                    }

                    return this.router.navigate(["/auth/login"]);
                } else {
                    this.logService.insertLog({
                        pageName: "Auth",
                        actionExecuted: "login do Usuário",
                        responsible: action.displayName,
                        userLoggedId: action.uid,
                        description: "Usuário acessou o sistema",
                        registeredDate: new Date(),
                        objectTransacted: JSON.stringify(action),
                    });
                    const data = {
                        uid,
                        email,
                        displayName,
                        // photoURL,
                        ativo: true,
                        googlechatId
                    };
                 
                    // console.log('aeeae')
                   
                    userRef.set(data, { merge: true });
                    return this.router.navigate(["/sample/"]);
                
                    

                }
                
            }
        });

        //await userRef.set(data, {merge: true});
        //return this.router.navigate(['/apps/project'])
    }

    getDataUser = function (uid) {
        return this.afs
            .collection(`${datastore}/users`)
            .doc(uid)
            .valueChanges();
    };
}
