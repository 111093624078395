import { HttpClient } from '@angular/common/http';
// import { environment } from './environments/environment';
import { environmentPRD } from 'environments/environment.prod';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisparaEmailService {

  constructor(private http: HttpClient) { }


  sendEmailQuestApprove(email: string, mensagem:string) {
   
    const template = {
        from: `Pointz <${email}>`,
        assunto: mensagem['emailTitle'],
        corpo: "true",
        corpoHtml: mensagem['template'],
        destinatarios: email
    }

     return this.http.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao', template).toPromise()

    

  }


   sendEmail(email: string, mensagem:string) {
   
    const template = {
        from: `Pointz <${email}>`,
        assunto: mensagem['emailTitle'],
        corpo: "true",
        corpoHtml: mensagem['template'],
        destinatarios: email
    }

     return this.http.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao', template).toPromise()

    

  }





}
