import { NgModule } from '@angular/core';
import { PurchasesComponent } from './purchases.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { PoAccordionModule, PoContainerModule, PoInfoModule,PoModalModule,PoFieldModule, PoTagModule, PoTabsModule, PoButtonModule, PoCheckboxGroupModule } from '@po-ui/ng-components';
import { PurchasesService } from 'app/service/purchases.service';
import { AuthGuard } from 'app/service/auth.guard';

const routes = [
    {
        path: 'purchases',
        component: PurchasesComponent,
        resolve: {
            purcahses: PurchasesService
        },
        canActivate: [AuthGuard]
    }
]

@NgModule({
    declarations: [
        PurchasesComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatPaginatorModule,
        FuseSharedModule,
        NgxPaginationModule,
        PoModalModule,
        PoButtonModule,
        PoFieldModule,
        MatIconModule,
        PoCheckboxGroupModule,
        //PO Modules
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoTabsModule
    ],
    exports: [
        PurchasesComponent
    ]
})

export class PurchasesModule { }
