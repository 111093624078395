
<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/Logo01.png">
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div *ngIf="auth.user$ | async as user; else login" fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="{{user.photoURL}}">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user.displayName}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <!-- <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Meu Perfil</span>
                </button>

                <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>
            <div *ngIf="auth.user$ | async as user; else login" fxLayout="row" fxLayoutAlign="center center">
                <div class="quick-panel-toggle-button toolbar-stars" aria-label="Toggle quick panel">
                    <div>
                        <div>
                            <mat-icon class="secondary-text" style="color: #ffb300 !important;margin: -8px;">star_rate
                            </mat-icon> {{user.cash? user.cash : 0}}
                        </div>
                        <div style="text-align: center;margin: -12px;">
                            <p style="font-size: xx-small;">Para Loja</p>
                        </div>
                    </div>
                </div>

                <div class="toolbar-separator"></div>

                <div class="quick-panel-toggle-button toolbar-stars" aria-label="Toggle quick panel">
                    <div style="width: auto;">
                        <div>
                            <mat-icon class="secondary-text"
                                style="color: #11c7c7 !important;padding: 1px 4px 4px 8px;margin: 2px 0px 0px 0px;">
                                star_rate</mat-icon> {{user.trade? user.trade : 0}}
                        </div>
                        <div style="text-align: center;margin: -12px;padding-left: 7px;">
                            <p style="font-size: xx-small;">Para Recompensar</p>
                        </div>
                    </div>

                </div>
            </div>


            <div class="toolbar-separator"></div>

            <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('shoppingCart')"
                aria-label="Toggle quick panel" id="shoppingCart">
                <mat-icon class="secondary-text">shopping_cart</mat-icon>
            </button>

            <div class="toolbar-separator"></div>

            <!-- <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('timelinePanel')"
                aria-label="Toggle quick panel" id="timeline">
                <mat-icon class="secondary-text">chat</mat-icon>
            </button>

            <div class="toolbar-separator"></div> -->

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>
            <!-- notification -->
            <button mat-button [matMenuTriggerFor]="notification" class="user-button">
                <div *ngIf="auth.user$ | async as user; else login" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-20" fxHide.xs>notifications</mat-icon>
                    <div *ngIf="notifyPendent != 0" style="display: flex; justify-content: center; align-items: center; width: 18px; height: 18px; border-radius: 100px; background-color:rgba(255, 0, 0, 0.7); font-size: 11px; text-align: center; position: absolute; top: 11px; right: 12px;">
                        <span>{{notifyPendent}}</span>
                    </div>
                </div>
            </button>

            <mat-menu #notification="matMenu" [overlapTrigger]="false">
                <a (click)="$event.stopPropagation(); removeAllNotify()" style="font-size: 12px; width: 100%; display: flex; justify-content: flex-end; cursor: pointer; margin-bottom: 5px; padding-right: 10px;">Remover tudo</a>
                <hr>
                <div *ngFor="let ref of notificationsData"  class="content-notification"
                    (click)="$event.stopPropagation();" style="padding-left: 5px; padding-right: 5px;">
                    <div (click)="modalNotify(valueNotify.id)" #valueNotify style="width: 90%;" *ngIf="ref.view == false" id="{{ref.id}}">
                        <div (click)="$event.stopPropagation(); disableNotify(valueNotify.id)"  style="z-index: 1337; font-weight: bold; height: 20px; width: 20px; position: relative; left: 242px; text-align: center; right: 0; border-radius: 5px; margin-top: -10px;">
                            X
                        </div>
                        <div style="margin-top: -10px;">
                            <p style="font-size: 1rem; height: 1.6rem; font-weight: 800;">{{ref.dateTimestamp | date: 'dd/MM/yyyy hh:mm'}}
                            </p>
                            <p style="font-size: 1.1rem; font-weight: 800;">
                                {{ref.description}}
                            </p>
                        </div>
                    </div>
                    <div style="width: 90%; height: 100%;" (click)="modalNotifytoTrue(valueNotify2.id)" #valueNotify2 id="{{ref.id}}" *ngIf="ref.view != false">
                        <div (click)="$event.stopPropagation(); disableNotify(valueNotify2.id)" style="z-index: 1337; font-weight: bold; height: 20px; width: 20px; position: relative; left: 242px; text-align: center; right: 0; border-radius: 5px; margin-top: -10px;">
                            X
                        </div>
                        <div style="margin-top: -10px;">
                            <p style="font-size: 1rem; height: 1.6rem;">{{ref.dateTimestamp | date: 'dd/MM/yyyy hh:mm'}}
                        
                            </p>
                            
                            <p style="font-size: 1.1rem;">
                                {{ref.description}}
                            </p>
                        </div>
                        
                    </div>
                </div>

                <div *ngIf="!notificationsData.length" class="content-notification">
                    Você não possui notificações...
                </div>

                <!-- <p class="see-more" (click)="$event.stopPropagation();handleNextPage()" *ngIf="next">Ver mais...</p> -->
            </mat-menu>

            <!-- /notification -->
            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <!-- <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->


        </div>

    </div>

   
   
    
   



</mat-toolbar>