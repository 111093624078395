import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MySalesService {

  constructor() { }



  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
    // this.routeParams = route.params; 
    return new Promise<void>((resolve, reject) => {
        Promise.all([
            // this.getQuests(),
            // this.getUsers()
            this.getMySales()
        ]).then(() => {
            resolve();
        }, reject);
    });
}    


  async getMySales(){
    return 'teste'
  }

}
