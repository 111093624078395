<div *ngIf="isAuthenticated">
  <div class="approval-content" *ngIf="isVisible" [@enterAnimation]>
    <p>Leia atentamente nossos <span (click)="modal.open()">Termos e condições</span> antes de aceitá-lo</p>
    <!-- <button class="button-accept">Aceitar</button> -->

    <div>
      <div>
        <button class="btn btn--light" (click)="handleAccept()">
          <span class="btn__inner">
            <span class="btn__slide"></span>
            <span class="btn__content">Aceitar</span>
          </span>
        </button>
      </div>
    </div>
  </div>

  <div class="content-icon" *ngIf="!isVisible" (click)="modal.open()" [@enterAnimation] p-label="Open Tooltip"
    p-tooltip="po-tooltip">
    <span class="po-icon po-icon-document-filled"></span>
  </div>

  <po-modal #modal p-title="Termos e condições">
    <h1>Geral</h1>
    <po-table [p-items]="geral">
    </po-table>

    <br>
    <hr>

    <h1>Fábrica de Software
    </h1>
    <po-table [p-items]="factory">
    </po-table>

    <br>
    <hr>

    <h1>Comercial
    </h1>
    <po-table [p-items]="Commercial">
    </po-table>
  </po-modal>
</div>
