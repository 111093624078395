import { Component, OnInit, ViewChild } from "@angular/core";
import {
    PoModalAction,
    PoModalComponent,
    PoNotification,
    PoNotificationService,
    PoTableAction,
} from "@po-ui/ng-components";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeOfRewardModel } from "app/Models/TypeOfReward.model";
import { TypeOfRewardService } from "./../../service/type-of-reward.service";
@Component({
    selector: "app-type-of-reward",
    templateUrl: "./type-of-reward.component.html",
    styleUrls: ["./type-of-reward.component.scss"],
})
export class TypeOfRewardComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private service: TypeOfRewardService,
        private poNotification: PoNotificationService
    ) {}
    items;
    actions: Array<PoTableAction> = [
        { action: this.editItem.bind(this), label: "Editar" },
    ];
    columns = [
        { property: "title", label: "Título", visible: true },
        { property: "coins", label: "Estrelas", visible: true },
        {
            property: "typeOfReward",
            label: "Tipo",
            visible: true,
            type: "label",
            labels: [
                { value: "2", color: "color-1", label: "Para Recompensar" },
                { value: "1", color: "color-02", label: "Para Loja" },
            ],
        },
    ];
    reactiveForm: FormGroup;
    //Po Notification
    message: string;
    id: string;
    duration = 2500;
    poType: string;
    @ViewChild(PoModalComponent, { static: true }) modal: PoModalComponent;

    ngOnInit(): void {
        this.createReactiveForm();
        this.service.geTypeOfRewards();

        this.service.onTypeOfRewardChanged.subscribe((response) => {
            this.items = response;
        });
    }
    editItem(item) {
         
        this.id = item.id;
        this.reactiveForm.controls["title"].setValue(item.title);
        this.reactiveForm.controls["typeOfReward"].setValue(item.typeOfReward);
        this.reactiveForm.controls["coins"].setValue(item.coins);
        this.modal.open();
    }
    clearForm() {
        this.reactiveForm.reset();
        this.reactiveForm = this.fb.group({
            title: [""],
            typeOfReward: [""],
            coins: [""],
        });
        this.id = "";
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            title: ["", Validators.required],
            typeOfReward: ["", Validators.required],
            coins: ["", Validators.required],
        });
    }
    SaveAction: PoModalAction = {
        action: () => {
            var fields = this.reactiveForm.value;
            if (this.reactiveForm.valid) {
                if (!this?.id?.length) {
                    this.service.post(fields);
                } else {
                    this.service.update(this.id, fields);
                }

                this.message = "Tipo de recompensa alterada com sucesso!";
                //clear the modal form
                this.poType = "sucess";
                this.modal.close();
                this.showNotification(this.poType);
            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
            this.clearForm();
        },
        label: "Salvar",
    };
    CloseAction: PoModalAction = {
        action: () => {
            this.clearForm();
            this.modal.close();
        },
        label: "Cancelar",
    };
    showNotification(messageType) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
}
