

<div id="login" class="inner-scroll" fxLayout="row" style="height: 100%;" fxLayoutAlign="start">
    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" style="margin-left: -30px;" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <!-- <img src="assets/images/logos/gestor_logo_trans.png"> -->
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
        </div>
        
        <div class="logo" style="margin-top: 50px;" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <!-- <img src="assets/images/iv2branco.png"> -->
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/logo_gestor_trans.png">
            </div>
            <div class="alert" *ngIf="authError" >
                <mat-error>
                        {{authError?.message}}
                </mat-error>
                <hr>
            </div>
            <div class="title">ACESSE SUA CONTA</div>

            <form #form="ngForm" name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email é obrigatório
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Por favor entre com um e-mail valido!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Senha é obrigatório
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Lembre-me
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/auth/forgot-password-2'">
                        Esqueceu a senha?
                    </a>
                </div>

                <button mat-raised-button color="accent" style="color: white;" class="submit-button" aria-label="LOGIN"
                        [disabled]="loginForm.invalid" (click)="login(form)">
                    LOGIN
                </button>

            </form>

            <div class="separator">
                <span class="text">OR</span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start center">
                
                <button mat-raised-button class="google" (click)="auth.googleSignin()">
                    Log in with Google
                </button>

                <!-- <button mat-raised-button class="facebook">
                    Log in with Facebook
                </button> -->

            </div>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Não tem uma conta?</span>
                <a class="link" [routerLink]="'/auth/register-2'">Criar uma conta</a>
            </div>
            <div style="left: 7px;bottom: -24px;position: fixed;font-size: smaller;" class="register" fxLayout="column" fxLayoutAlign="start start">
                <a class="link">Version: 1.4.6</a>
            </div>
            
        </div>
    </div>
</div>
