import { Component, OnInit, ViewChild } from "@angular/core";
import {
    PoModalAction,
    PoModalComponent,
    PoMultiselectOption,
    PoNotification,
    PoNotificationService,
    PoTableAction,
    PoTableColumn,
    PoTableColumnLabel,
    PoTableComponent,
    PoTabsComponent 
} from "@po-ui/ng-components";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserModel } from "app/models/user.model";

import { UserService } from "app/service/user.service";
import { LogService } from "app/service/log.service";
import { AuthService } from "app/service/auth.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { TypeOfRewardService } from "app/service/type-of-reward.service";
import { ApprovalCoinsService } from "app/service/aproval-solic-coins.service";
import { ApprovalCoins } from "app/Models/ApprovalCoins.model";
import { MailerService } from "app/service/mailer.service";
import { ExcelDownService } from "app/service/excel-down.service";
import { ChangeDetectorRef, ElementRef } from '@angular/core';

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"],
    providers: [PoNotificationService],
})
export class UsersComponent implements OnInit {
    @ViewChild(PoModalComponent, { static: true })
    addUserModal: PoModalComponent;
    @ViewChild("userModalMoedas", { static: true })
    userModalMoedas: PoModalComponent;
    @ViewChild("modalSolicitation", { static: true })
    ModalSolicitation: PoModalComponent;
    @ViewChild("modalSuperPointz", { static: true })
    modalSuperPointz: PoModalComponent;
    @ViewChild("updateUserModal", { static: true })
    updateUserModal: PoModalComponent;
    @ViewChild("deleteUser", { static: true })
    deleteUserByadmin: PoModalComponent;
    @ViewChild("activeuser", { static: true })
    activeUserByadmin: PoModalComponent;   
    @ViewChild("test") test: ElementRef

  
    actions: Array<PoTableAction> = [
        {
            action: this.detailsUser2.bind(this),
            icon: "po-icon-edit",
            label: "Editar",
        },
        {
            action: this.deleteUser.bind(this),
            icon: "po-icon po-icon-minus-circle",
            label: "Desativar",
        },
    ];

    actions2: Array<PoTableAction> = [
        {
            action: this.detailsUser2.bind(this),
            icon: "po-icon-edit",
            label: "Editar",
        },
        {
            action: this.activeUser.bind(this),
            icon: "po-icon po-icon-plus-circle",
            label: "Ativar",
        },
    ];

    public listusers: UserModel[];
    public listusersPending: UserModel[];
    public user: UserModel;
    public isUpdate = false;
    public userUpdateId = "";
    
    public isActiveUser = true;
    typeOfReward;
    @ViewChild(PoTableComponent, { static: true }) poTable: PoTableComponent;


    userProfile = [];
    userStatus = "Inativo";
    coinType = [];
    listGroupsSp: any = []
    idUserLogged: any;
    nameUserLogged: any;

    _details2: boolean = false
    columns: Array<PoTableColumn>;
    columnsPending: Array<PoTableColumn>; 
    p: Number = 0;
    c: Number = 0;

    itemPage:Number = 10;
    
    key: string = 'sendDate'
    reverse: boolean = true;
    reactiveForm: FormGroup;
    reactiveFormUpdate: FormGroup;
    reactiveFormMoeda: FormGroup;
    reactiveModalFormReward: FormGroup;
    nome:any = ''
    //Po Notification
    message: string;
    duration = 4500;
    poType: string;
    reward;
    selectedItems: any;
    checkedAllUsers: boolean;
    checkedArredondar: boolean;
    options: Array<PoMultiselectOption> = [];
    optionsRewards: Array<PoMultiselectOption> = [];
    totalList: number;
    totalPending: number;

    constructor(
        private fb: FormBuilder,
        private service: UserService,
        private logService: LogService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
        private typeOfRewardService: TypeOfRewardService,
        private approvalCoinsService: ApprovalCoinsService,
        private router: Router,
        private serviceMailer: MailerService,
        private excelDown: ExcelDownService,
        private cd: ChangeDetectorRef
    ) {
        this.createReactiveForm();
        this.createReactiveFormMoeda();
        this.createReactiveModalFormReward();
        this.createReactiveFormUpdate();
        this.coinType = [
            { id: 1, label: "Para a Loja", value: "1" },
            { id: 2, label: "Para Recompensar", value: "2" },
        ];

        this.userProfile = [
            { id: 1, label: "Admin", value: "1" },
            { id: 1, label: "Staff", value: "2" },
            { id: 1, label: "Usuário", value: "3" },
        ];

        this.columns = [
            {
                property: "photoURL",
                label: "Foto",
                type: "columnTemplate",
                visible: true,
            },
            { property: "firstName", label: "Nome", visible: true },
            { property: "lastName", label: "Sobrenome", visible: true },
            {
                property: "displayName",
                label: "Nome de Exibição",
                visible: true,
            },
            { property: "email", label: "Email", visible: true },
            { property: "cash", label: "Moedas Loja", visible: true },
            { property: "trade", label: "Moedas Recompensa", visible: true },
            {
                property: "birthday",
                label: "Data de Nacimento",
                type: "date",
                visible: true,
            },
            {
                property: "userPerfil",
                label: "Perfil",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: "1",
                        color: "color-03",
                        label: "Admin",
                        tooltip: "Administrador do sistema",
                    },
                    {
                        value: "2",
                        color: "color-05",
                        label: "Staff",
                        tooltip: "Organizador do sistema",
                    },
                    {
                        value: "3",
                        color: "color-08",
                        label: "User",
                        tooltip: "Usuário padrão do sistema",
                    },
                    {
                        value: "4",
                        color: "color-12",
                        label: "User",
                        tooltip: "Usuário padrão do sistema",
                    },
                ],
                visible: true,
            },
            {
                property: "ativo",
                label: "Usuário Ativo?",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : "",
                        color: "color-11",
                        label: "Ativo",
                        tooltip: "Usuário Ativo",
                    },
                    {
                        value: false,
                        color: "color-07",
                        label: "Inativo",
                        tooltip: "Usuário Inativo",
                    },
                ],
                visible: true,
            },
            { property: "uid", label: "", visible: false },
        ];

        this.columnsPending = [
            {
                property: "photoURL",
                label: "Foto",
                type: "columnTemplate",
                visible: true,
            },
            {
                property: "displayName",
                label: "Nome de Exibição",
                visible: true,
            },
            { property: "email", label: "Email", visible: true },
            {
                property: "userPerfil",
                label: "Perfil",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: "1",
                        color: "color-03",
                        label: "Admin",
                        tooltip: "Administrador do sistema",
                    },
                    {
                        value: "2",
                        color: "color-05",
                        label: "Staff",
                        tooltip: "Organizador do sistema",
                    },
                    {
                        value: "3",
                        color: "color-08",
                        label: "User",
                        tooltip: "Usuário padrão do sistema",
                    },
                    {
                        value: "4",
                        color: "color-12",
                        label: "User",
                        tooltip: "Usuário padrão do sistema",
                    },
                ],
                visible: true,
            },
            {
                property: "ativo",
                label: "Usuário Ativo?",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : "",
                        color: "color-11",
                        label: "Ativo",
                        tooltip: "Usuário Ativo",
                    },
                    {
                        value: false,
                        color: "color-07",
                        label: "Inativo",
                        tooltip: "Usuário Inativo",
                    },
                ],
                visible: true,
            },
            { property: "uid", label: "", visible: false },
        ];
    }

 

    ngOnInit(): void {
        //To control the user access, thsi acces if just for Admin users
          
        this.auth.user$.subscribe((credentials) => {
            this.idUserLogged = credentials.uid;
            this.nameUserLogged = credentials.displayName;
            if (
                credentials.userPerfil == "1" ||
                credentials.userPerfil == "4"
            ) {
                this.service.onUserChanged.subscribe(async(users) => {
                    this.listusers = await users.filter((user) =>{
                        return user.ativo == true;
                    })
                    
                    this.listusersPending = await users.filter((user) =>{
                        return user.ativo == false || user.ativo == null ;
                    })
                   
             
                });
            } else {
                this.router.navigate(["/sample"]);
            }
        });

        this.service.onUserMoedasChanged.subscribe((users) => {
            this.options = users;
        });
        this.typeOfRewardService.geTypeOfRewards();

        this.typeOfRewardService.onTypeOfRewardChanged.subscribe((reward) => {
         
            this.reward = reward;
            this.optionsRewards = reward.map((item) => ({
                label: item.title,
                value: item.id,
            }));
        });
    }



    openNewModal() {
        this.clearForm();
        this.isUpdate = false;
        this.addUserModal.open();
    }

    modalMoedas() {
        this.clearFormMoeda();
        //this.isUpdate = false;
        this.userModalMoedas.open();
        this.service.getUsers().then(resp =>{
             (resp)
        })
     
    }

    dataAtualFormatada(receveddata: Date) {
        let data = receveddata,
            dia = data.getDate().toString(),
            diaF = dia.length == 1 ? "0" + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = mes.length == 1 ? "0" + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

    SaveAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveForm.value;
            let saveUser = {} as UserModel;
            if (this.reactiveForm.valid) {
                saveUser.firstName = fields.firstName;
                saveUser.lastName = fields.lastName;
                saveUser.email = fields.email;
                saveUser.ativo = fields.userStatus;
                saveUser.userPerfil = fields.userPerfil;
                saveUser.cash = fields.cash;
                saveUser.trade = fields.trade;
                saveUser.password = fields.passwd;
                saveUser.predefinido = true;
                saveUser.tradesTotalToRank = 0
                saveUser.registerdate = new Date();
                saveUser.termsAndConditions =  false
                saveUser.cashRecivedToRank = 0;
                saveUser.photoURL = "assets/images/avatars/profile.jpg";
                saveUser.displayName = fields.firstName
                if(saveUser.ativo == true){
                    saveUser.toApprove = true
                }else{
                    saveUser.toApprove = false
                }
                if (!this.isUpdate) {
                    saveUser.registerdate = new Date();
                 
                this.service.veryficaExistenciaEmail().then((res) =>{
                    let emailsCadastrados = []
                   
                    res.docs.forEach((dados) =>{
                        emailsCadastrados.push({user: dados.data().email})
                    })
                   
                    if (emailsCadastrados.find(email => email.user === saveUser.email)){
                        this.message = "O EMAIL ESTA CADASTRADO NO BANCO DE DADOS !!";
                        this.poType = "error";
                        this.showNotification(this.poType);
                        return
                    }else{
                        this.service.insertUser(saveUser)
                        this.message = "Usuario inserido com sucesso!";
                        this.poType = "success";
                        this.showNotification(this.poType);
                        this.clearForm();
                        this.addUserModal.close();
                        
                    }
                })
                
             
                } 
            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
        },
        label: "Salvar",
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.addUserModal.close();
        },
        label: "Cancelar",
    };

    
    


    // desativar user modal//
    deleteSuccess: PoModalAction = {
        action: () => {
            this.service.deleteUser(this.userUpdateId).then() 
            this.message = "O usuario foi desativado com sucesso";
            this.poType = "success";
            this.showNotification(this.poType); 
            this.userUpdateId = "";
            this.deleteUserByadmin.close();
        },
        label: "Desativar usuario"
    };
    //

    // ativar user modal//
    activeSuccess: PoModalAction = {
        action: () => {
            this.service.activeUser(this.userUpdateId).then() 
            this.message = "O usuario foi ativado com sucesso";
            this.poType = "success";
            this.showNotification(this.poType); 
            this.userUpdateId = "";
            this.activeUserByadmin.close();
        },
        label: "Ativar usuario"
    };
    //



    closeDelete: PoModalAction = {
        action: () => {
            this.deleteUserByadmin.close();
        },
        label: "Cancelar",
    }; 

    closeActive: PoModalAction = {
        action: () => {
            this.activeUserByadmin.close();
        },
        label: "Cancelar",
    }; 
 


    
    //update action //

    UpdateAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveFormUpdate.value;
            let saveUser = {} as UserModel;
            if (this.reactiveFormUpdate.valid) {
                saveUser.firstName = fields.firstName;
                saveUser.lastName = fields.lastName;
                saveUser.ativo = fields.userStatus;
                saveUser.userPerfil = fields.userPerfil;
                saveUser.cash = fields.cash;
                saveUser.trade = fields.trade; 
                if(saveUser.ativo == false || saveUser.ativo == null){
                    saveUser.toApprove = true
                }else{
                    saveUser.toApprove = false
                }

                this.service.updateUser(this.userUpdateId, saveUser);
                this.logService.insertLog({
                    pageName: "Usuário",
                    actionExecuted: "Update de Usuário",
                    responsible: this.nameUserLogged,
                    userLoggedId: this.idUserLogged,
                    registeredDate: new Date(),
                    objectTransacted: JSON.stringify(saveUser),
                });
                this.userUpdateId = "";
                this.message = "Usuário atualizado com sucesso!";
                this.poType = "sucess";
                this.clearForm();
                this.updateUserModal.close();
                this.showNotification(this.poType);
            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
        },
        label: "Atualizar",
    };
    //


    CloseActionUpdate: PoModalAction = {
        action: () => {
            this.updateUserModal.close();
        },
        label: "Cancelar",
    };
    
   
    
    
    SaveActionSolicMoeda: PoModalAction = {
        action: () => {
            if (this.reactiveModalFormReward.status === "INVALID") return;
            try {
                const usersSelecteds = this.poTable.getSelectedRows();
                for (let index = 0; index < usersSelecteds.length; index++) {
                    const element = usersSelecteds[index];
                    const data: ApprovalCoins = {
                        uid: element.uid,
                        displayName: element.displayName,
                        photoURL: element.photoURL,
                        status: 1,
                        titleOfReward: this.labelFromSelectModal,
                        typeOfReward: this.typeOfReward,
                        dateTimeStamp: new Date().getTime(),
                        approvalFrom: "Recompensa",
                        ...this.reactiveModalFormReward.value,
                    };
                    //  (this.reactiveModalFormReward.value)
                    this.approvalCoinsService.newPost(data);
                }

                this.message = "Recompensas requisitadas com sucesso...";
                this.poType = "success";
            } catch (error) {
                this.message =
                    "Erro inesperado, tente novamente ou contate o adm...";
                this.poType = "error";
            }

            this.showNotification(this.poType);

            this.clearFormModalRewardSolic();
        },
        label: "Salvar",
    };
    SaveActionMoedas: PoModalAction = {
        action: () => {
            let fieldsMoedas = this.reactiveFormMoeda.value;
             (this.reactiveFormMoeda.valid)
            if (this.checkedArredondar) {
                
                    this.service.updateMoedas2(
                        this.checkedArredondar
                    );
                    this.message = "Moedas azuis arredondadas com sucesso!";
                    this.poType = "sucess";
                    this.showNotification(this.poType);
                    this.userModalMoedas.close();
                    this.logService.insertLog({
                        pageName: "Usuário",
                        actionExecuted: "Enviado moedas para todos os usuários",
                        responsible: "Super Admin",
                        userLoggedId: "",
                        description:
                            "Enviado " +
                            fieldsMoedas.valorSelect +
                            " moedas " +
                            (fieldsMoedas.coinTypeChoice == "1"
                                ? " Para Loja "
                                : " Para Recompensar "),
                        registeredDate: new Date(),
                        objectTransacted: JSON.stringify(fieldsMoedas),
                })}
            else if (this.checkedAllUsers) {
                if (this.reactiveFormMoeda.valid) {
                    this.service.updateMoedas(
                        this.checkedAllUsers,
                        fieldsMoedas
                    );
                    this.message = "Moedas cadastradas com sucesso!";
                    this.poType = "sucess";
                    this.showNotification(this.poType);
                    this.userModalMoedas.close();
                    this.logService.insertLog({
                        pageName: "Usuário",
                        actionExecuted: "Enviado moedas para todos os usuários",
                        responsible: "Super Admin",
                        userLoggedId: "",
                        description:
                            "Enviado " +
                            fieldsMoedas.valorSelect +
                            " moedas " +
                            (fieldsMoedas.coinTypeChoice == "1"
                                ? " Para Loja "
                                : " Para Recompensar "),
                        registeredDate: new Date(),
                        objectTransacted: JSON.stringify(fieldsMoedas),
                    });
                } else {
                    this.message = "Preencha os campos obrigatórios com *";
                    this.poType = "error";
                    this, this.showNotification(this.poType);
                }
            } else {
                if (
                    _.isArray(fieldsMoedas.userSelect) &&
                    fieldsMoedas.userSelect.length > 0
                ) {
                    if (this.reactiveFormMoeda.valid) {
                        this.service.updateMoedas(
                            this.checkedAllUsers,
                            fieldsMoedas
                        );
                        this.message = "Moedas cadastradas com sucesso!";
                        this.poType = "sucess";
                        this.showNotification(this.poType);
                        this.userModalMoedas.close();
                    } else {
                        this.message = "Preencha os campos obrigatórios com *";
                        this.poType = "error";
                        this, this.showNotification(this.poType);
                    }
                } else {
                    this.message = "Preencha os campos obrigatórios com *";
                    this.poType = "error";
                    this, this.showNotification(this.poType);
                }
            }
        },
        label: "Salvar",
    };

    CloseActionMoedas: PoModalAction = {
        action: () => {
            this.userModalMoedas.close();
        },
        label: "Cancelar",
    };
    CloseSoliciModal: PoModalAction = {
        action: () => {
            this.ModalSolicitation.close();
        },
        label: "Cancelar",
    };

    clearForm() {
        this.reactiveForm.reset();
        this.userStatus = "";
    }

    clearFormMoeda() {
        this.reactiveFormMoeda.reset();
        this.userStatus = "";
    }
    clearFormModalRewardSolic() {
        this.ModalSolicitation.close();
        this.reactiveModalFormReward.reset();
        this.labelFromSelectModal = "";
        this.typeOfReward = "";
        // this.poTable.un
    }

    detailsUser(item: UserModel) {
        this.clearForm();
        this.isActiveUser = true;
        this.isUpdate = true;
        this.reactiveForm.controls["firstName"].setValue(item.firstName);
        this.reactiveForm.controls["lastName"].setValue(item.lastName);
        // if(item.birthday){
        //     let dob = new Date(item.birthday)
        //     this.reactiveForm.controls['birthday'].setValue(new Date(dob.setDate(dob.getDate() + 1)));
        // }
        this.reactiveForm.controls["email"].setValue(item.email);
        this.reactiveForm.controls["userStatus"].setValue(item.ativo);
        this.isActiveUser = item.ativo;
        this.reactiveForm.controls["userPerfil"].setValue(item.userPerfil);
        this.reactiveForm.controls["cash"].setValue(item.cash);
        this.reactiveForm.controls["trade"].setValue(item.trade);

        this.userUpdateId = item.uid;
        this.addUserModal.open();
    }



    detailsUser2(item: UserModel) {
        this.clearForm();
        this.isActiveUser = true;
        this.isUpdate = true;
        this.reactiveFormUpdate.controls["firstName"].setValue(item.firstName);
        this.reactiveFormUpdate.controls["lastName"].setValue(item.lastName);
        // this.reactiveFormUpdate.controls["email"].setValue(item.email);
        this.reactiveFormUpdate.controls["userStatus"].setValue(item.ativo);
        this.isActiveUser = item.ativo;
        this.reactiveFormUpdate.controls["userPerfil"].setValue(item.userPerfil);
        this.reactiveFormUpdate.controls["cash"].setValue(item.cash);
        this.reactiveFormUpdate.controls["trade"].setValue(item.trade);
        this.userUpdateId = item.uid;
         (this.userUpdateId)
        this.updateUserModal.open();

    }

    deleteUser(item: any) {
       this.userUpdateId = item.id;
       this.deleteUserByadmin.open()     
    }

    activeUser(item: any) {
        this.userUpdateId = item.id;
        this.activeUserByadmin.open()     
     }
   
    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            firstName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(30),
                ]),
            ],
            lastName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(30),
                ]),
            ],
            passwd: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(50),
                ]),
            ],
            //birthday: [new Date()],
            email: ["", Validators.required],
            userStatus: [""],
            cash: [0],
            trade: [0],
            userPerfil: ["", Validators.compose([Validators.required])],
        });
    }


    createReactiveFormUpdate() {
        this.reactiveFormUpdate = this.fb.group({
            firstName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(30),
                ]),
            ],
            lastName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(30),
                ]),
            ],
            userStatus: [""],
            cash: [0],
            trade: [0],
            userPerfil: ["", Validators.compose([Validators.required])],
        });
    }


    createReactiveFormMoeda() {
        this.reactiveFormMoeda = this.fb.group({
            allUsers: [""],
            userSelect: [""],
            coinTypeChoice: ["", Validators.required],
            valorSelect: ["", Validators.required],
        });
    }
    createReactiveModalFormReward() {
        this.reactiveModalFormReward = this.fb.group({
            reward: ["", Validators.required],
            coins: [""],
            typeOfReward: [""],
        });
    }

    selectAllUsers(values: any) {
        this.checkedAllUsers = !values.target.checked;
    }

    selectAllUsers2(values: any) {
        this.checkedArredondar = !values.target.checked;
       
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
    total: number = 0;
    sumTotal(row: any) {
        if (row) {
            this.total += 1;
        }
    }
    decreaseTotal(row: any) {
        if (row) {
            this.total -= 1;
        }
    }

    
    addSelecteds() {
        this.selectedItems = this.poTable.getSelectedRows();
        if (this.selectedItems.length) {
            this.ModalSolicitation.open();
        }else{
            this.message = "Voce precisa selecionar pelo menos 1 usuario";
            this.poType = "warning";
       
            this.showNotification(this.poType)
        }
    }
  



    labelFromSelectModal;
    handleSelectModelChange(event) {
        const [value] = this.reward.filter((item) => item.id === event);
        this.labelFromSelectModal = value.title;
        this.typeOfReward = value.typeOfReward;
        this.reactiveModalFormReward.controls.coins.setValue(value.coins);
        const refTypeOfReward =
            value.typeOfReward == "1" ? "Para Loja" : "Para Recompensar";
        this.reactiveModalFormReward.controls.typeOfReward.setValue(
            refTypeOfReward
        );
    }

    filterName(){
        const arrayOne = []
        this.service.onUserChanged.subscribe((quests) => { 
            quests.filter(e =>{
                if (e.displayName.toUpperCase().indexOf(this.nome) !== -1 || e.displayName.toLowerCase().indexOf(this.nome) !== -1 || e.displayName.indexOf(this.nome) !== -1){
                    arrayOne.push(e)
                    
                }else if(this.nome === '' || this.nome == undefined){
                    arrayOne.push(e)
                }
                })
                this.listusers = arrayOne
            })
        } 

    sort(key: any) {
        this.key = key.column.property
        
        this.reverse = !this.reverse;
    }
    downloadEx(){
        this.excelDown.downLoadColaboradores(this.listusers)
    }



    //Super pointz

      
    addSuperPointz() {
        this.modalSuperPointz.open();
     }
   
 
     CloseSpModal: PoModalAction = {
         action: () => {
             this.modalSuperPointz.close();
         },
         label: "Cancelar",
     };


     SaveActionSp: PoModalAction = {
        action: () => {
            if (this.reactiveModalFormReward.status === "INVALID") return;
            try {
                const usersSelecteds = this.poTable.getSelectedRows();
                for (let index = 0; index < usersSelecteds.length; index++) {
                    const element = usersSelecteds[index];
                    const data: ApprovalCoins = {
                        uid: element.uid,
                        displayName: element.displayName,
                        photoURL: element.photoURL,
                        status: 1,
                        titleOfReward: this.labelFromSelectModal,
                        typeOfReward: this.typeOfReward,
                        dateTimeStamp: new Date().getTime(),
                        approvalFrom: "Recompensa",
                        ...this.reactiveModalFormReward.value,
                    };
                    //  (this.reactiveModalFormReward.value)
                    this.approvalCoinsService.newPost(data);
                }

                this.message = "Recompensas requisitadas com sucesso...";
                this.poType = "success";
            } catch (error) {
                this.message =
                    "Erro inesperado, tente novamente ou contate o adm...";
                this.poType = "error";
            }

            this.showNotification(this.poType);

            this.clearFormModalRewardSolic();
        },
        label: "Salvar",
    };


}
