import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AutenticationCrudComponent } from "./autentication-crud.component";
import { RouterModule } from "@angular/router";
import {
    PoAccordionModule,
    PoButtonModule,
    PoFieldModule,
    PoInfoModule,
    PoModalModule,
    PoTableModule,
    PoTagModule,
    PoTabsModule,
} from "@po-ui/ng-components";
import { PoContainerModule } from "@po-ui/ng-components";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthGuard } from "app/service/auth.guard";
const routes = [
    {
        path: "domains",
        component: AutenticationCrudComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [AutenticationCrudComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        PoFieldModule,
        PoContainerModule,
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoModalModule,
        PoTableModule,
        MatIconModule,
        PoButtonModule,
        ReactiveFormsModule,
        PoTabsModule,
    ],
})
export class AutenticationCrudModule {}
