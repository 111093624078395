<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
  <po-container class="po-xl-12 po-lg-12">
    <div class="container-custom-01">
      <div class="po-row">
        <div class="po-lg-8">
          <h2 class="align-title">
            <mat-icon class="secondary-text hlp-margin-right-10">devices_other</mat-icon> Aprovação de recompensas
          </h2>
        </div>

      </div>
      <hr>
      <!-- <po-select name="select" p-label="PO Select" [p-options]="[
          { value: '2',  label: 'Aprovado' },
          { value: '3',  label: 'Reprovado' },
          { value: '1', color:  label: 'Aguardando' }
          ]">
          </po-select> -->

      <div class="po-row">
        <div class="po-xl-4 po-lg-4 po-md-4">
          <po-select name="select" p-label="Status" [p-options]="[
            { label: 'Aguardando', value: 1 },
            { label: 'Aprovado', value: 2 },
            { label: 'Reprovado', value: 3 }
            ]" [(ngModel)]="searchStatus">
          </po-select>

        </div>
        <div class="po-xl-3 po-lg-3 po-md-3">
          <po-datepicker name="startDate" [(ngModel)]="startDate" p-format="dd/mm/yyyy" p-label="Data de inicio"
            p-required [p-max-date]="endDate">
          </po-datepicker>
        </div>


        <div class="po-xl-3 po-lg-3 po-md-3">
          <po-datepicker name="endDate" [(ngModel)]="endDate" p-format="dd/mm/yyyy" p-label="Data Fim" p-required
            [p-min-date]="startDate">
          </po-datepicker>
        </div>
        <div class="content-search po-md-2">
          <po-button p-label="Pesquisar" (p-click)="searchWithFilter()" c> </po-button>
        </div>
      </div>
      <hr>
      <div class="po-row">
        <po-table [p-columns]="columns" [p-items]="items | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p}" (p-sort-by)="sort($event)" [p-actions]="actions" p-sort="true" class="po-xl-12 po-lg-12">
          <!-- <ng-template p-table-column-template p-property="photoURL" let-value
            style="display: flex; justify-content: center;">
            <po-avatar p-size="sm" [p-src]="value">
            </po-avatar>
          </ng-template> -->
        </po-table>
      </div>
      <pagination-controls previousLabel="Voltar" nextLabel="Proximo" (pageChange)="p = $event"></pagination-controls>
    </div>
  </po-container>
</div>



<po-modal #modalDetails [p-primary-action]="closeModal">
  <h1>Detalhes da recompensa</h1>
  <form action="" [formGroup]="reactiveForm">
      <po-input formControlName="approvalFrom" p-label="Tipo"  p-disabled="true" ></po-input>
      <po-input formControlName="titleOfReward"  p-disabled="true" p-label="Motivo" ></po-input>
      <po-input formControlName="coins" p-label="Quantidade de moedas" p-disabled="true" ></po-input>
      
      <div *ngIf="reactiveForm.value.displayName != 'Diversos'">
        <po-input formControlName="displayName" p-label="Para" p-disabled="true" ></po-input>
      </div>
      <div *ngIf="reactiveForm.value.displayName == 'Diversos'">
          <po-input formControlName="usersGroup" p-label="Para" p-disabled="true" ></po-input>
      </div>

  </form>
</po-modal>