
import { DisparaEmailService } from './../../service/dispara-email.service';
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
    PoModalAction,
    PoModalComponent,
    PoNotification,
    PoNotificationService,
    PoTableAction,
    PoTableColumn,
    PoTableColumnLabel,
} from "@po-ui/ng-components";
import { PurchasesCollabModel, PurchasesModel } from "app/models/purchases.model";
import { AuthService } from "app/service/auth.service";
import { LogService } from "app/service/log.service";
import { NotificationService } from "app/service/notification.service";
import { SalesService } from "app/service/sales.service";
import { timingSafeEqual } from "crypto";
import * as _ from "lodash";
import { toArray, toNumber } from "lodash";
import { ExcelDownService } from "app/service/excel-down.service"; 
import { PageEvent } from "@angular/material/paginator";
import { MatPaginator } from '@angular/material/paginator';
import { EventEmitter } from "events";
import { UserService } from 'app/service/user.service';
import { PoTabsComponent } from '@po-ui/ng-components';
@Component({
    selector: "sales",
    templateUrl: "./sales.component.html",
    styleUrls: ["./sales.component.scss"],
})
export class SalesComponent implements OnInit {
    @ViewChild("saleColab", { static: true }) saleColab: PoModalComponent;
    @ViewChild("saleModal", { static: true }) saleModal: PoModalComponent;

   


   
    public listSales: PurchasesModel[];
    public listSalesCollab: PurchasesCollabModel[];
    public saleUpdateId = "";
    public carditems = [{cardNumber: 1}, {cardNumber: 2}, {cardNumber: 3}, {cardNumber: 4}, {cardNumber: 5},{cardNumber: 6}]
    public pageSlice = this.carditems.slice(0, 10)
    reactiveForm: FormGroup;
    

    sale: PurchasesModel;
    columns: Array<PoTableColumn>;

    columns2: Array<PoTableColumn>;
    
    actions: Array<PoTableAction> = [
        { action: this.detailsSale.bind(this), label: "Detalhes" },
    ];

    actionColab: Array<PoTableAction> = [
        { action: this.detailsSaleCollab.bind(this), label: "Detalhes" },
    ];

    optionsStatus = [
        { label: "Em Aberto", value: false },
        { label: "Em Andamento", value: "2" },
        { label: "Aguardando Entrega", value: "3" },
        { label: "Entregue", value: true },
        { label: "Recusado", value: "4" }
    ];
    statusOptions = [
        { label: "Em Aberto", value: false },
        { label: "Em Andamento", value: "2" },
        { label: "Aguardando Entrega", value: "3" },
        { label: "Entregue", value: true },
        { label: "Recusado", value: "4" }
    ];
    searchStatus: any
    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    nameUserLogged: any;
    IdUserLogged: any;
    startDate: any;
    endDate: any;
    nome:any = '';
    email: any = '';
    emailToSend: any;
    valueAberto: any;
    valueAndamento: any;
    valueAguardando: any;
    valueEntregue: any;
    valueTotal: any;
    p:Number = 0;
    c:Number = 0;
    itemPage:Number = 10;
    itemPage2:Number = 10;
    key: string = 'purchaseDate';
    reverse: boolean = true;
    observacao: string = ''
    moedasParaColaborador: any;
    saleUpdateIdCollab: string;



    constructor(
        private fb: FormBuilder,
        private poNotification: PoNotificationService,
        private service: SalesService,
        public auth: AuthService,
        private router: Router,
        private logService: LogService,
        private notificationService: NotificationService,
        private excelDown: ExcelDownService,
        private emailService: DisparaEmailService,
        private userservice: UserService
    ) {
        
        this.createReactiveForm();

        this.columns2 = [
            { property: "userName", label: "Colaborador", visible: true, 
                
            },
            {
                property: "purchaseDate",
                label: "Data da Compra",
                visible: true,
                type: "columnTemplate",
            },
            { property: "amount", label: "Valor Total", visible: true,  },
            {
                property: "deliveryStatus",
                label: "Status da Entrega",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : "",
                        color: "color-11",
                        label: "Entregue",
                        tooltip: "Produtos entregues!",
                    },
                    {
                        value: false,
                        color: "color-14",
                        label: "Em Aberto",
                        tooltip:
                            "Aguardando a confirmação para compra dos produtos!",
                    },
                    {
                        value: "3",
                        color: "color-05",
                        label: "Aguardando Entrega",
                        tooltip: "Aguardando a entrega dos produtos!",
                    },
                    {
                        value: "2",
                        color: "color-06",
                        label: "Em Andamento",
                        tooltip:
                            "Produto aprovado e aguardando procedimentos para compra-lo ou recebê-lo.",
                    },
                    {
                        value: "4",
                        color: "color-07",
                        label: "Recusado",
                        tooltip:
                            "Produto Recusado",
                    }
                ],
                visible: true,
            },
            {
                property: "deliveryDate",
                label: "Data da Entrega",
                visible: true,
                type: "columnTemplate",
            },
        ];

        this.columns = [
            { property: "userName", label: "Colaborador", visible: true, 
                
            },
            {
                property: "purchaseDate",
                label: "Data da Compra",
                visible: true,
                type: "columnTemplate",
            },
            { property: "amount", label: "Valor Total", visible: true,  },
            {
                property: "deliveryStatus",
                label: "Status da Entrega",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : "",
                        color: "color-11",
                        label: "Entregue",
                        tooltip: "Produtos entregues!",
                    },
                    {
                        value: false,
                        color: "color-14",
                        label: "Em Aberto",
                        tooltip:
                            "Aguardando a confirmação para compra dos produtos!",
                    },
                    {
                        value: "3",
                        color: "color-05",
                        label: "Aguardando Entrega",
                        tooltip: "Aguardando a entrega dos produtos!",
                    },
                    {
                        value: "2",
                        color: "color-06",
                        label: "Em Andamento",
                        tooltip:
                            "Produto aprovado e aguardando procedimentos para compra-lo ou recebê-lo.",
                    },
                    {
                        value: "4",
                        color: "color-07",
                        label: "Recusado",
                        tooltip:
                            "Produto Recusado",
                    }
                ],
                visible: true,
            },
            {
                property: "deliveryDate",
                label: "Data da Entrega",
                visible: true,
                type: "columnTemplate",
            },
        ];
    }


    ngOnInit(): void {
        this.auth.user$.subscribe((credentials) => {
            if (credentials.userPerfil != "1") {
                this.router.navigate(["/sample"]);
               
            } else {
            
                this.email = credentials.email
                this.nameUserLogged = credentials.displayName;
                this.IdUserLogged = credentials.uid;
       
                this.service.onSalesChangedCollab.subscribe((sales1) => { 
                    this.listSalesCollab = _.orderBy(
                        sales1,
                        ["deliveryStatus", "purchaseDate"],
                        ["asc", "asc"]
                    );
                });
                //console.log(this.listSalesCollab)

                this.service.onSalesChanged.subscribe((sales) => { 
                    this.listSales = _.orderBy(
                        sales,
                        ["deliveryStatus", "purchaseDate"],
                        ["asc", "asc"]
                    );
                });

                this.listSales.forEach(element => {
                   //console.log(element.purchaseDate)
                });

            }
        });
       
            
    }
   
    ngAfterViewInit(): void{
        
    }

    ngDoCheck(){
        this.getOpen()
        this.getAndamento()
        this.getAguardando()
        this.getEntregue()
        this.getTotal()
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            deliveryStatus: [false, Validators.required],
            deliveryDate: [new Date()],

        });
    }

    
    
    


    sort(key: any) {
        this.key = key.column.property
        this.reverse = !this.reverse;
    }



    detailsSaleCollab(item: PurchasesCollabModel) {
    
        this.reactiveForm.controls["deliveryStatus"].setValue(
            item.deliveryStatus
        );
           
      
            this.reactiveForm.controls["deliveryDate"].setValue(
                item.deliveryDate
            );
            // console.log(item.deliveryDate)
        
   
        this.sale = item;
        
        this.observacao = this.sale.observacao 

        this.saleUpdateIdCollab = item.id;

        
       
        // Remove duplicidade para pegar os IDS que existem na compra, para sim calcular as moedas que cada colaborador ira receber (Para nao bugar quando o usuario comprar de 2 ou mais colaboradores diferentes ao mesmo tempo)
       
        let values = this.sale.products.filter(function (a) {
            return !this[JSON.stringify(a.userid)] && (this[JSON.stringify(a.userid)] = true && a.purchaseColla == true)
        }, Object.create(null))
            
              
        // Esvaziar o array toda vez
        this.moedasParaColaborador = []
        let auxARR = []
        values.forEach((data, index) => {
            let valorParaReceber = 0
            this.sale.products.forEach(function(e, i) {
                if (e.purchaseColla === true && e.purchaseColla != undefined){
                    if (e.userid == data.userid){
                        valorParaReceber += e.price
                    }
                    auxARR[index] = {
                        valorTotal:  valorParaReceber,
                        userName: data.userName,
                        userId: data.userid
                    }
                    
                }else{
                    
                }
              
            })
           
        });
       
        this.moedasParaColaborador = auxARR
        this.saleColab.open();
      
        
      
       
    }










    detailsSale(item: PurchasesModel) {
     
        this.reactiveForm.controls["deliveryStatus"].setValue(
            item.deliveryStatus
        );
      
        // this.service.getSalesCollab(item.id)


        if (item.deliveryDate) {
            this.reactiveForm.controls["deliveryDate"].setValue(
                item.deliveryDate
            );
        }
   
        this.sale = item;
        
        this.observacao = this.sale.observacao 

        this.saleUpdateId = item.id;

    
      
      
        this.saleModal.open();
    }

    filterName(){
        const arrayOne = []
        const dataInicio = new Date(this.startDate)
        const dataFim = new Date(this.endDate)
     
        this.service.onSalesChanged.subscribe((sales) => { 
            sales.filter(e =>{
               
                if (e.userName.toUpperCase().indexOf(this.nome) !== -1 || e.userName.toLowerCase().indexOf(this.nome) !== -1 || e.userName.indexOf(this.nome) !== -1){
                    arrayOne.push(e)
                    if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                        arrayOne.pop()
                    }
                    if ((dataFim.getTime() / 1000) + 86400 < e.purchaseDate.seconds){
                        arrayOne.pop()
                    }   
                }else if(this.nome === '' || this.nome == undefined){
                    arrayOne.push(e)
                }else if('EM ABERTO'.indexOf(this.nome) !== -1 || 'em aberto'.indexOf(this.nome) !== -1){
                    if(e.deliveryStatus == 0){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime()/1000) + 86400 < e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                    }
                 }else if('ENTREGUE'.indexOf(this.nome) !== -1 || 'entregue'.indexOf(this.nome) !== -1){
                    if(e.deliveryStatus == 1){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime()/1000) + 86400 < e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                    }
                }else if('EM ANDAMENTO'.indexOf(this.nome) !== -1 || 'em andamento'.indexOf(this.nome) !== -1){
                    if(e.deliveryStatus == 2){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime()/1000) + 86400 < e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                    }
                }else if('AGUARDANDO ENTREGA'.indexOf(this.nome) !== -1 || 'aguardando entrega'.indexOf(this.nome) !== -1){
                    if(e.deliveryStatus == 3){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime()/1000) + 86400 < e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                    }
                }else if('RECUSADO'.indexOf(this.nome) !== -1 || 'recusado'.indexOf(this.nome) !== -1){
                    if(e.deliveryStatus == 4){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime()/1000) + 86400 < e.purchaseDate.seconds){
                            arrayOne.pop()
                        }
                    }
                }

            }) 
            this.listSales = _.orderBy(
                arrayOne,
                ["deliveryStatus", "purchaseDate"],
                ["asc", "asc"]
            );
        });
    }

    saveCollab: PoModalAction = {
        action: async () => {
            let dadosDoProduto = ''
            let imgs = ''
            let usermail = ''
            var fields = this.reactiveForm.value;
            let updateSale = {} as PurchasesCollabModel;
            if (this.sale.observacao === undefined){
               updateSale.observacao = ''
            }else{
                updateSale.observacao = this.observacao
            }
            
            if (this.reactiveForm.valid) {
                updateSale.deliveryStatus = fields.deliveryStatus;
                updateSale.deliveryDate = fields.deliveryDate != undefined ? fields.deliveryDate : ''
                
              
                //this.service.updateSale(this.sale.id, this.sale)
                // console.log(updateSale)
                this.service.updateSaleCollab(this.saleUpdateIdCollab, updateSale)
                
                let product = this.sale.products;

                

               await this.userservice.getMailById(this.sale.userId).then((res) =>{
                    this.emailToSend = res.data().email
               })
         
                const status = {
                    false: "Em Aberto",
                    "2": "Em Andamento",
                    "3": "Aguardando Entrega",
                    true: "Entregue",
                    "4": "Recusado"
                };
                const notify = {
                    dateTimestamp: new Date().getTime(),
                    description: `A(s) sua(s) compra(s) esta(o) com o status atualizado em ${
                        status[fields.deliveryStatus]
                    }`,
                    uid: this.sale.userId,
                    view: false,
                    idPurchase: this.saleUpdateIdCollab,
                    typeNotify: 'purchaseCol',
                    eraseNotify: false
                };
               
            
                this.notificationService.newNotification(notify);
                
                this.logService.insertLog({
                    pageName: "Vendas",
                    actionExecuted:
                        "Alteração do status da entrega dos produtos",
                    responsible: this.nameUserLogged,
                    userLoggedId: this.IdUserLogged,
                    registeredDate: new Date(),
                    objectTransacted: JSON.stringify(updateSale),
                    description:
                        "Status da entrega dos produtos da venda " +
                        this.sale.id +
                        " alterado para: " +
                        fields.deliveryStatus,
                });
                
                if(product.length != 1){
                    product.forEach(element => {
                        imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                    });
                }else{ 
                    imgs = `<td><img style="width:100px;" src="${product[0].images[0].imgLink}" alt="" /><p style="color: black;">Produto : ${product[0].name}</p><p style="color: black;">Quantidade : ${product[0].amount}</p> <p style="color: black;">Valor : ${product[0].price}</p></td>`
                }
              
                this.saleUpdateId = "";
                this.message = "Venda alterada com sucesso!";
               
                


                // Envia as moedas apos colocar o status entregue ! //
                 switch (fields.deliveryStatus) {
                     case true:
                        this.userservice.updateCoinsAfterDelivered(this.moedasParaColaborador).then((resp) =>{
        
                            this.moedasParaColaborador.forEach(dadosUser => {
                                let notifySell = {
                                   dateTimestamp: new Date().getTime(),
                                   description: `Ola ${dadosUser.userName}, O Processo da sua venda foi concluido e voce recebeu ${dadosUser.valorTotal} Moedas, Aproveite !`,
                                   uid: dadosUser.userId,
                                   view: false,
                                   typeNotify: 'sellProductCollaborator',
                                   eraseNotify: false
                               };
                               this.notificationService.newNotification(notifySell);
    
                            });
                             
                         }).catch((error) =>{
                            //  console.log(error)
                         })
                         break;
                    case '4':
                         this.userservice.updateCoinsAfterCancel(this.sale.userId, this.sale.amount).then((res) =>{
                            let notifySell = {
                                dateTimestamp: new Date().getTime(),
                                description: `Ola ${this.sale.userName}, Seu pedido foi cancelado, Foi estornado ${this.sale.amount} Moedas, Desculpa pelo transtorno !`,
                                uid: this.sale.userId,
                                view: false,
                                typeNotify: 'produtoCancelado',
                                eraseNotify: false
                            };
                            this.notificationService.newNotification(notifySell);
                         }).catch((error) =>{
                             return error
                         })
                     default:
                         break;
                 }


                let data = { 
                    template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                <p style="font: message-box; color: teal; font-weight:bold; font-size:20px;">Atualização da compra</p>
                <p style="font: message-box; color: teal; font-weight:bold; font-size:20px"">Dados do(s) Produto(s)</p>
                <table style="width:900px;"><tr>${imgs}</tr></table>
                <h2>Valor total da compra : ${this.sale.amount}</h2>
                <p style="font: message-box; color: black"><b style="color: teal;">${notify.description}</b></p>
                <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                <br />
                <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                <br />
                <p style="font: message-box;">Att. Administrador do sistema</p>
                </div>`,
                emailTitle: `- Atualização da compra`
                } as any
                //clear the modal form
                this.poType = "success";
                this.saleColab.close();
                this.showNotification(this.poType);
                this.emailService.sendEmail(this.emailToSend, data).then((res) =>{
                
                }).catch((error) =>{
                   
                })


            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
        },
        label: "Salvar",
    };







    SaveAction: PoModalAction = {
        action: async () => {
            let dadosDoProduto = ''
            let imgs = ''
            let usermail = ''
            var fields = this.reactiveForm.value;
            let updateSale = {} as PurchasesModel;
            if (this.sale.observacao === undefined){
               updateSale.observacao = ''
            }else{
                updateSale.observacao = this.observacao
            }
            
            if (this.reactiveForm.valid) {
                updateSale.deliveryStatus = fields.deliveryStatus;
                updateSale.deliveryDate = fields.deliveryDate != undefined ? fields.deliveryDate : ''
                
              
                //this.service.updateSale(this.sale.id, this.sale)
                this.service.updateSale(this.saleUpdateId, updateSale)
                
                let product = this.sale.products;

                

               await this.userservice.getMailById(this.sale.userId).then((res) =>{
                    this.emailToSend = res.data().email
               })
         
                const status = {
                    false: "Em Aberto",
                    "2": "Em Andamento",
                    "3": "Aguardando Entrega",
                    true: "Entregue",
                    "4": "Recusado"
                };
                const notify = {
                    dateTimestamp: new Date().getTime(),
                    description: `A(s) sua(s) compra(s) esta(o) com o status atualizado em ${
                        status[fields.deliveryStatus]
                    }`,
                    uid: this.sale.userId,
                    view: false,
                    idPurchase: this.saleUpdateId,
                    typeNotify: 'purchase',
                    eraseNotify: false
                };
               
            
                this.notificationService.newNotification(notify);
                
                this.logService.insertLog({
                    pageName: "Vendas",
                    actionExecuted:
                        "Alteração do status da entrega dos produtos",
                    responsible: this.nameUserLogged,
                    userLoggedId: this.IdUserLogged,
                    registeredDate: new Date(),
                    objectTransacted: JSON.stringify(updateSale),
                    description:
                        "Status da entrega dos produtos da venda " +
                        this.sale.id +
                        " alterado para: " +
                        fields.deliveryStatus,
                });
                
                if(product.length != 1){
                    product.forEach(element => {
                        imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                    });
                }else{ 
                    imgs = `<td><img style="width:100px;" src="${product[0].images[0].imgLink}" alt="" /><p style="color: black;">Produto : ${product[0].name}</p><p style="color: black;">Quantidade : ${product[0].amount}</p> <p style="color: black;">Valor : ${product[0].price}</p></td>`
                }
              
                this.saleUpdateId = "";
                this.message = "Venda alterada com sucesso!";
               
                


                // Envia as moedas apos colocar o status entregue ! //
                 switch (fields.deliveryStatus) {
                    case '4':
                         this.userservice.updateCoinsAfterCancel(this.sale.userId, this.sale.amount).then((res) =>{
                            let notifySell = {
                                dateTimestamp: new Date().getTime(),
                                description: `Ola ${this.sale.userName}, Seu pedido foi cancelado, Foi estornado ${this.sale.amount} Moedas, Desculpa pelo transtorno !`,
                                uid: this.sale.userId,
                                view: false,
                                typeNotify: 'produtoCancelado',
                                eraseNotify: false
                            };
                            this.notificationService.newNotification(notifySell);
                         }).catch((error) =>{
                             return error
                         })
                     default:
                         break;
                 }


                let data = { 
                    template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                <p style="font: message-box; color: teal; font-weight:bold; font-size:20px;">Atualização da compra</p>
                <p style="font: message-box; color: teal; font-weight:bold; font-size:20px"">Dados do(s) Produto(s)</p>
                <table style="width:900px;"><tr>${imgs}</tr></table>
                <h2>Valor total da compra : ${this.sale.amount}</h2>
                <p style="font: message-box; color: black"><b style="color: teal;">${notify.description}</b></p>
                <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                <br />
                <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                <br />
                <p style="font: message-box;">Att. Administrador do sistema</p>
                </div>`,
                emailTitle: `- Atualização da compra`
                } as any
                //clear the modal form
                this.poType = "success";
                this.saleModal.close();
                this.showNotification(this.poType);
                this.emailService.sendEmail(this.emailToSend, data).then((res) =>{
                
                }).catch((error) =>{
                   
                })


            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
        },
        label: "Salvar",
    };


    CloseAction: PoModalAction = {
        action: () => {
            this.saleModal.close();
            this.reactiveForm.reset();
        },
        label: "Voltar",
    };
    CloseActionCollab: PoModalAction = {
        action: () => {
            this.saleModal.close();
            this.reactiveForm.reset();
        },
        label: "Voltar",
    };
   
    showNotification(messageType) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }



    
    getOpen(){
        const arr = []
        this.valueAberto = 0
        this.service.onSalesChanged.subscribe((sales) => {   
            sales.filter(e =>{
                if (e.deliveryStatus == 0){
                    arr.push(e)   
                }
            })
           this.valueAberto = arr.length
        });
    }
    getAndamento(){
        const arr = []
        this.valueAndamento = 0
        this.service.onSalesChanged.subscribe((sales) => {   
            sales.filter(e =>{
                if (e.deliveryStatus == 2){
                    arr.push(e)   
                }
            })
           this.valueAndamento = arr.length
        });
    }

    getAguardando(){
        const arr = []
        this.valueAguardando = 0
        this.service.onSalesChanged.subscribe((sales) => {   
            sales.filter(e =>{
                if (e.deliveryStatus == 3){
                    arr.push(e)   
                }
            })
           this.valueAguardando = arr.length
        });
    }

    getEntregue(){
        const arr = []
        this.valueEntregue = 0
        this.service.onSalesChanged.subscribe((sales) => {   
            sales.filter(e =>{
                if (e.deliveryStatus == 1){
                    arr.push(e)   
                }
            })
           this.valueEntregue = arr.length
        });
    }

    getTotal(){
        const arr = []
        this.valueTotal = 0
        this.service.onSalesChanged.subscribe((sales) => {   
            sales.filter(e =>{
                arr.push(e)   
            })
           this.valueTotal = arr.length
        });
    }

    downloadEx(){
        this.excelDown.downLoad(this.listSales)
    }


    teste(event: any, text: any){
         (event.srcElement.value = text)
    }



    //ALTERA STATUS DO PRODUTO DO COLABORADOR
    produtoColStatus(idProduct, status){
       
        switch (status) {
            case true:
                // console.log('ok')
                break;
        
            default:
                break;
        }
    }


}



