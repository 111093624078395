
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-12">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">widgets</mat-icon> Minhas Compras
                    </h2>
                </div>
            </div>
            <hr>
            <div class="po-row" *ngIf="myPurchases?.length || myPurchasesCol?.length">
                <po-tabs>
                    <po-tab p-label="Compras Loja Empresa" [p-active]="true">
                        <po-accordion class="hlp-width-100">
                            <po-accordion-item
                                p-label="Data da Compra: {{purchase.purchaseDate.toDate() | date: 'dd/MM/yyyy h:mm a'}} - {{getStatus(purchase.deliveryStatus)}}"
                                *ngFor="let purchase of myPurchases | paginate: {itemsPerPage: itemPage, currentPage: p, id: 'listnormal'}">
                                <div class="po-row hlp-border-bottom" *ngFor="let item of purchase.products">
                                    <div class="po-lg-2 hlp-no-padding-left po-text-center">
                                        <img *ngFor="let image of item.images" [src]="image.imgLink" class="hlp-img-thumbnail">
                                    </div>
                                    <div class="po-lg-10">
                                        <div class="po-row">
                                            <div class="po-lg-3">
                                                <po-info p-label="Nome" [p-value]="item.name"> </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <po-info p-label="Quantidade" [p-value]="item.amount ? item.amount : 1">
                                                </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <div class="po-info">
                                                    <div class="po-info-container-title">
                                                        <span class="po-info-label"> Valor </span>
                                                    </div>
                                                    <div class="po-info-container-content">
                                                        <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                            {{item.price}} <mat-icon class="secondary-text"
                                                                style="color: #ffb300;">star_rate</mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="po-lg-5">
                                                <po-info p-label="Descrição" [p-value]="item.description"> </po-info>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="po-row">
                                    <div class="po-lg-2 po-text-center info-purchase">
                                        Informações da compra
                                    </div>
                                    <div class="po-lg-10">
                                        <div class="po-row">
                                            <div class="po-lg-3">
                                                <po-tag p-inverse p-label="Entrega dos Produtos" p-type="warning"
                                                    p-value="Em Aberto" *ngIf="purchase.deliveryStatus == false">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Entrega dos Produtos" p-type="success"
                                                    p-value="Entrega Realizada" *ngIf="purchase.deliveryStatus == true">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Em Andamento" p-type="warning" p-value="Em Andamento"
                                                    *ngIf="purchase.deliveryStatus == '2'">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Aguardando Entrega" p-type="warning"
                                                    p-value="Aguardando Entrega" *ngIf="purchase.deliveryStatus == '3'">
                                                </po-tag>
                                            </div>
                                            <div class="po-lg-2">
                                                <po-info p-label="Quantidade" [p-value]="purchase.products.length"> </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <div class="po-info">
                                                    <div class="po-info-container-title">
                                                        <span class="po-info-label"> Valor Total </span>
                                                    </div>
                                                    <div class="po-info-container-content">
                                                        <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                            {{purchase.amount}} <mat-icon class="secondary-text"
                                                                style="color: #ffb300;">star_rate</mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="po-lg-5">
                                                <po-info *ngIf="purchase.deliveryDate" p-label="Data de Entrega"
                                                    [p-value]="purchase.deliveryDate | date: 'dd/MM/yyyy'"></po-info>
                                                <po-info *ngIf="!purchase.deliveryDate" p-label="Data de Entrega"
                                                    p-value="Aguardando..."></po-info>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </po-accordion-item>
                            
                        </po-accordion>

                        <div style="margin-top: 15px;">
                            <pagination-controls id="listnormal" previousLabel="Voltar" nextLabel="Proximo" directionLinks="true" (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </po-tab>

                    <po-tab p-label="Compras Loja Colaboradores" [p-active]="true">
                        <po-accordion class="hlp-width-100">
                            <po-accordion-item
                                p-label="Data da Compra: {{purchase.purchaseDate.toDate() | date: 'dd/MM/yyyy h:mm a'}} - {{getStatus(purchase.deliveryStatus)}}"
                                *ngFor="let purchase of myPurchasesCol | paginate: {itemsPerPage: itemPage2, currentPage: c, id: 'listcollab'}">
                                <div class="po-row hlp-border-bottom" *ngFor="let item of purchase.products">
                                    <div class="po-lg-2 hlp-no-padding-left po-text-center">
                                        <img *ngFor="let image of item.images" [src]="image.imgLink" class="hlp-img-thumbnail">
                                    </div>
                                    <div class="po-lg-10">
                                        <div class="po-row">
                                            <div class="po-lg-3">
                                                <po-info p-label="Nome" [p-value]="item.name"> </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <po-info p-label="Quantidade" [p-value]="item.amount ? item.amount : 1">
                                                </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <div class="po-info">
                                                    <div class="po-info-container-title">
                                                        <span class="po-info-label"> Valor </span>
                                                    </div>
                                                    <div class="po-info-container-content">
                                                        <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                            {{item.price}} <mat-icon class="secondary-text"
                                                                style="color: #ffb300;">star_rate</mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="po-lg-5">
                                                <po-info p-label="Descrição" [p-value]="item.description"> </po-info>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="po-row">
                                    <div class="po-lg-2 po-text-center info-purchase">
                                        Informações da compra
                                    </div>
                                    <div class="po-lg-10">
                                        <div class="po-row">
                                            <div class="po-lg-3">
                                                <po-tag p-inverse p-label="Entrega dos Produtos" p-type="warning"
                                                    p-value="Em Aberto" *ngIf="purchase.deliveryStatus == false">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Entrega dos Produtos" p-type="success"
                                                    p-value="Entrega Realizada" *ngIf="purchase.deliveryStatus == true">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Em Andamento" p-type="warning" p-value="Em Andamento"
                                                    *ngIf="purchase.deliveryStatus == '2'">
                                                </po-tag>
                                                <po-tag p-inverse p-label="Aguardando Entrega" p-type="warning"
                                                    p-value="Aguardando Entrega" *ngIf="purchase.deliveryStatus == '3'">
                                                </po-tag>
                                            </div>
                                            <div class="po-lg-2">
                                                <po-info p-label="Quantidade" [p-value]="purchase.products.length"> </po-info>
                                            </div>
                                            <div class="po-lg-2">
                                                <div class="po-info">
                                                    <div class="po-info-container-title">
                                                        <span class="po-info-label"> Valor Total </span>
                                                    </div>
                                                    <div class="po-info-container-content">
                                                        <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                            {{purchase.amount}} <mat-icon class="secondary-text"
                                                                style="color: #ffb300;">star_rate</mat-icon>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="po-lg-5">
                                                <po-info *ngIf="purchase.deliveryDate" p-label="Data de Entrega"
                                                    [p-value]="purchase.deliveryDate | date: 'dd/MM/yyyy'"></po-info>
                                                <po-info *ngIf="!purchase.deliveryDate" p-label="Data de Entrega"
                                                    p-value="Aguardando..."></po-info>
                                                    
                                            </div>

                                            <div class="po-lg-2" style="margin-top: 10px; margin-bottom: 10px;" *ngIf="purchase.deliveryStatus != true">
                                                <po-button p-type="primary" style="margin: 0 auto; color: greenyellow;" p-label="Recebi o produto" (p-click)="confirmRecive(purchase)"></po-button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </po-accordion-item>
                            
                        </po-accordion>

                        <div style="margin-top: 15px;">
                            <pagination-controls style="margin-top: 20px;" id="listcollab" previousLabel="Voltar" nextLabel="Proximo" directionLinks="true" (pageChange)="c = $event"></pagination-controls>
                        </div>
                        
                    </po-tab>
                </po-tabs>

                
            </div>

            <div class="po-row" *ngIf="!myPurchases?.length">
                <div class="po-lg-12 po-text-center">
                    <h3 class="hlp-text">Nenhuma compra efetuada!</h3>
                </div>
            </div>
        </div>
    </po-container>



    <po-modal #modalconfirmRecive p-click-out="false" p-size="xl" p-title="Confirmação do Recebimento da Mercadoria" [p-primary-action]="SaveAction"
    [p-secondary-action]="CloseAction">
        <div *ngFor="let item2 of moedasParaColaborador" style="margin-top: 35px;">
            <p>
                Ao Alterar o status para <span style="color: rgb(7, 160, 2);">ENTREGUE</span> O usuario <span style="font-weight: 800;">{{item2.userName}}</span>
                Recebera o valor Total de {{item2.valorTotal}} Moedas
            </p>
        </div>
        <p>Esse procedimento é definitivo, so continue se recebeu o produto correto e sem defeitos</p>
        <po-checkbox (p-change)="acceptTerm()" p-label="Eu confirmo que recebi o produto corretamente">
                                
        </po-checkbox>
    </po-modal>



</div>