
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-12">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">dvr</mat-icon> Minhas Quests
                    </h2>
                </div>
            </div>
            <hr>

            <div class="po-row" *ngIf="quests?.length">
                <div class="po-row hlp-border-bottom" *ngFor="let quest of quests">
                    <div class="po-md-2 po-lg-2 hlp-no-padding-left po-text-center">
                        <img *ngIf="quest.images" class="hlp-img-thumbnail" [src]="quest.images[0].imgLink">
                    </div>
                    <div class="po-md-10 po-lg-10 hlp-no-padding">
                        <div class="po-row hlp-padding-top-5">  
                            <div class="po-md-6 po-lg-6 hlp-no-padding-right">                          
                                <po-info p-label="Quest" [p-value]="quest.questName"></po-info>
                            </div>   
                            <div class="po-md-6 po-lg-4 hlp-no-padding-right">              
                                <po-info p-label="Data de Entrega" [p-value]="quest.sendDate.toDate() | date: 'dd/MM/yyyy h:mm a'"></po-info>
                            </div>
                            <div class="po-md-2 po-lg-2 hlp-no-padding-right">
                                <po-tag p-inverse p-label="Status" p-type="warning" p-value="Em Analise" *ngIf="quest.questStatus == 1"></po-tag>  
                                <po-tag p-inverse p-label="Status" p-type="success" p-value="Aprovado" *ngIf="quest.questStatus == 2"></po-tag>
                                <po-tag p-inverse p-label="Status" p-type="error" p-value="Não Aprovado" *ngIf="quest.questStatus == 3"></po-tag>  
                            </div>
                        </div>        
                        <hr>     
                        <div class="po-row hlp-padding-top-5">  
                            <div class="po-md-6 po-lg-6 hlp-no-padding-right">                          
                                <po-info p-label="Aprovador" [p-value]="quest.userApprovalName"></po-info>
                            </div>   
                            <div class="po-md-4 po-lg-4 hlp-no-padding-right">              
                                <po-info *ngIf="quest.approvalDate" p-label="Data de Aprovação" [p-value]="quest.approvalDate.toDate() | date: 'dd/MM/yyyy h:mm a'"></po-info>       
                                <po-info *ngIf="!quest.approvalDate" p-label="Data de Aprovação" p-value="---"></po-info>
                            </div>
                            <div class="po-md-2 po-lg-2 hlp-no-padding-right">
                                <div class="po-info">
                                    <div class="po-info-container-title">
                                        <span class="po-info-label"> Recompensa </span>
                                    </div>
                                    <div class="po-info-container-content">
                                        <span class="po-info-value ng-star-inserted po-text-vertical-center">
                                            {{quest.reward}} <mat-icon class="secondary-text" style="color: #ffb300;">star_rate</mat-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>        
                        <hr>             
                        <div class="po-row">
                            <div class="po-md-12 po-lg-12">
                                <po-info p-label="Instruções" [p-value]="quest.questInstruction"></po-info>
                            </div> 
                        </div>       
                        <hr>             
                        <div class="po-row">
                            <div class="po-md-12 po-lg-12">
                                <po-info p-label="Resposta Enviada" [p-value]="quest.questAnswer"></po-info>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="po-row" *ngIf="!quests?.length">
                <div class="po-lg-12 po-text-center">
                    <h3 class="hlp-text">Nenhuma Quest encontrada!</h3>
                </div>
            </div>
        </div>
    </po-container>
</div>

