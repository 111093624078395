

<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
  <po-container class="po-xl-12 po-lg-12">
    <div class="container-custom-01">
      <div class="po-row">
        <div class="po-lg-8">
          <h2 class="align-title">
            <mat-icon class="secondary-text hlp-margin-right-10">devices_other</mat-icon> Cadastro de Dominios
          </h2>
        </div>
        <div class="po-lg-2">
          <po-button p-label="Novo Dominio" p-icon="po-icon po-icon-plus" p-type="Primary" (p-click)="modal.open()"
            class="pull-right"> </po-button>
        </div>
        <div class="po-lg-2">
          <po-button p-label="Nova Excecão" p-icon="po-icon po-icon-plus" p-type="Primary" (p-click)="modalExcecao.open()"
            class="pull-right"> </po-button>
        </div>
      </div>
      <hr>
      <po-tabs>
        <po-tab p-label="Dominios Liberados" [p-active]="true">
          <div class="po-row">
            <po-table [p-columns]="columns" [p-items]="listaDominios" class="po-xl-12 po-lg-12" [p-actions]="actions">
            </po-table>
          </div>
        </po-tab>
        <po-tab p-label="Excecões">
          <div class="po-row">
            <po-table [p-columns]="columnsExcecao" [p-items]="listaExcecoes" class="po-xl-12 po-lg-12" [p-actions]="actionsExcecao">
            </po-table>
          </div>
        </po-tab>
      </po-tabs>
      
    </div>
  </po-container>
</div>

<po-modal #modal p-title="Dominio" p-click-out="false" [p-primary-action]="SaveAction"
  [p-secondary-action]="CloseAction">
  <po-container class="po-xl-12 po-lg-12">
    <form [formGroup]="reactiveForm">

      <div class="container-custom-01">
        <div class="po-row">
          <div class="po-lg-8">
            <po-input name="title" p-label="Dominio" formControlName="title"> </po-input>
          </div>
        </div>
      </div>
    </form>
  </po-container>
</po-modal>

<po-modal #modalExcecao p-title="Excecão" p-click-out="false" [p-primary-action]="SaveExcecaoAction"
  [p-secondary-action]="CloseExcecaoAction">
  <po-container class="po-xl-12 po-lg-12">
    <form [formGroup]="reactiveFormExcecao">

      <div class="container-custom-01">
        <div class="po-row">
          <div class="po-lg-8">
            <po-input name="nome" p-label="Nome" formControlName="nome"> </po-input>
          </div>
          <div class="po-lg-8">
            <po-email name="email" p-label="E-mail" formControlName="email" p-required="true"> </po-email>
          </div>
        </div>
      </div>
    </form>
  </po-container>
</po-modal>

<po-modal #deleteConfimation p-title="Deletar" p-click-out="false" [p-primary-action]="DeleteOK"
  [p-secondary-action]="DeleteDeny">
  <po-container class="po-xl-12 po-lg-12">
    <form [formGroup]="reactiveFormExcecao">

      <div class="container-custom-01">
        <div class="po-row">
          <div class="po-lg-8">
            <h2 class="align-title">Deseja realmente excluir este item?</h2>
          </div>        
        </div>
      </div>
    </form>
  </po-container>
</po-modal>