import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { UserModel } from 'app/Models/user.model';
import { UsersQuestModel } from 'app/Models/usersQuest.model';
import { UserService } from './user.service';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})

export class ApprovalQuestsService implements Resolve<any> {
    
    quests = [];
    usersResult = [];
    users: any;
    routeParams: any;

    onQuestsChanged: BehaviorSubject<any>;
    onUserChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore,
        private userService: UserService,
        private logService: LogService
    ) { 
        this.onQuestsChanged = new BehaviorSubject([]);  
        this.onUserChanged = new BehaviorSubject([]);            
    }       

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getQuests(),
                this.getUsers()
            ]).then(() => {
                resolve();
            }, reject);
        });
    }    
    
    getQuests(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/usersQuest`).snapshotChanges().subscribe(action => {
                this.quests = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as UsersQuestModel),
                        favorite: ['detalhes']
                    } as UsersQuestModel
                });
                this.onQuestsChanged.next(_.orderBy(this.quests, ['sendDate'],['desc']));
                resolve(this.quests);
            });    
        })
    }
    
    getUsers() {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/users`).snapshotChanges().subscribe(action => {
                this.users = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as UserModel),
                        favorite: ['detalhes']
                    } as UserModel
                });
                this.onUserChanged.next(_.orderBy(this.users, ['displayName'], ['asc']));
                resolve(this.users);
            });
        })
    }

    // Status Quest
    // 1 = Em Analise
    // 2 = Aprovado
    // 3 = Negado

    updateUserQuest(id: string, data: UsersQuestModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/usersQuest/${id}`).update(data);
        })       
    }  

    updateCashUserQuest(id: string, valueQuest: any, userLoggedAprovedId, userLoggedAprovedName, ObjecQuest){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/users/${id}`).get().subscribe(action => {
                let updateUser = {} as UserModel;

                let moedaLog = action.data().cash;

                updateUser.cash = action.data().cash + valueQuest
                this.userService.updateUser(action.id, updateUser);

                this.logService.insertLog({
                    pageName: 'Aprovar Quest',
                    actionExecuted: 'Aprovação de quest',
                    responsible: userLoggedAprovedName,
                    userLoggedId: userLoggedAprovedId,
                    registeredDate: new Date(),
                    objectTransacted: JSON.stringify(ObjecQuest),
                    description: 'Quest Aprovada para o Usuário: ' + action.data().displayName + " Moedas antes da aprovação: " + moedaLog + " Moedas depois da aprovação: " + updateUser.cash
                })   
            });
        })       
    } 
}
