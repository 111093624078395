import { PurchasesCollabModel } from 'app/models/purchases.model';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PurchasesModel } from 'app/models/purchases.model';
import { Resolve } from '@angular/router';
import { Subscription } from 'rxjs';
import { datastore } from 'app/config';
import { AuthService } from './auth.service';
import { UserModel } from 'app/models/user.model';

@Injectable({
    providedIn: 'root'
})

export class ShoppingCartService implements Resolve<any> {

    invokeShoppingCart = new EventEmitter;
    subsVar: Subscription;
    products: any[];

    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) { }

    resolve(){ 
    };

    onUpdateShoppingCart() {
        this.invokeShoppingCart.emit();
    }
    
    async insertPurchase(data: PurchasesModel) {
        return this.firestore.collection(`${datastore}/purchases`).add(data).then((res) =>{
            return res.id
        })
    }

    async insertPurchaseCollab(data: PurchasesModel) {
        return this.firestore.collection(`${datastore}/purchasesCollaborator`).add(data).then((res) =>{
            return res.id
        })
    }
}
