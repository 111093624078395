

<po-modal #modalRecompensa p-click-out="false" p-size="xl" p-title="Nova Moeda" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction">
    <form [formGroup]="reactiveFormRecompensa">
        <div class="po-row">
            <po-select  formControlName="users" p-clean p-label="Usuário *" [p-options]="[]"></po-select>
        </div>
        <div class="po-row">
            <po-select class="po-sm-12 po-md-12" formControlName="coins" p-clean p-label="Recompensa *" [p-options]="[]"></po-select>
        </div>
        <div class="po-row">
            <po-textarea class="po-sm-12 po-md-12" formControlName="motivo" name="textarea *" p-label="PO Textarea"> </po-textarea>
        </div>
    </form>
</po-modal> 