import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/service/auth.service';

import { PoModalAction, PoModalComponent, PoMultiselectOption, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { LogService } from 'app/service/log.service';
import { LogsModule } from './logs.module';
import { ExcelDownService } from 'app/service/excel-down.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {


  columns: Array<PoTableColumn>;
  listlogs: LogsModule[]
  p: Number = 0
  startDate: any
  endDate: any
  nome: any = ''
  
  itemPage:Number = 10;
  key: string = 'purchaseDate'
  reverse: boolean = true;
  constructor(
    public auth: AuthService,
    private service: LogService,
    private router: Router,
    private excelDown: ExcelDownService
  ) {
    this.columns = [
      { property: 'pageName', label: 'Nome da Página', type: 'string', visible: true },
      { property: 'actionExecuted', label: 'Ação Executada', type: 'string', visible: true },
      { property: 'responsible', label: 'Responsável', type: 'string', visible: true },
      { property: 'registeredDate', label: 'Data do Registro', type: 'columnTemplate', visible: true },
      { property: 'description', label: 'Descrição', type: 'string', visible: true },
      { property: 'objectTransacted', label: 'Objeto Transitado', type: 'string', visible: true },
    ]
  }

  ngOnInit(): void {
    this.auth.user$.subscribe(credentials => {
      if (credentials.userPerfil == '1' || credentials.userPerfil == '4') {
        this.service.onLogChanged.subscribe(logs => {
           (logs)
          this.listlogs = _.orderBy(logs, ['registeredDate'], ['asc'])
        });
      } else {
        this.router.navigate(["/sample"])
      }
    })
  }


  filterName(){
    const arrayOne = []
    const dataInicio = new Date(this.startDate)
    const dataFim = new Date(this.endDate)
    this.service.onLogChanged.subscribe((quests) => { 
        quests.filter(e =>{
            if (e.responsible.toUpperCase().indexOf(this.nome) !== -1 || e.responsible.toLowerCase().indexOf(this.nome) !== -1 || e.responsible.indexOf(this.nome) !== -1){
                arrayOne.push(e)
                if((dataInicio.getTime()/1000) >= e.registeredDate.seconds){
                    arrayOne.pop()
                }
                if ((dataFim.getTime() / 1000) + 86400 < e.registeredDate.seconds){
                    arrayOne.pop()
                }   
            }else if(e.pageName.toUpperCase().indexOf(this.nome) !== -1 || e.pageName.toLowerCase().indexOf(this.nome) !== -1 || e.pageName.indexOf(this.nome) !== -1 ){
              arrayOne.push(e)
                if((dataInicio.getTime()/1000) >= e.registeredDate.seconds){
                    arrayOne.pop()
                }
                if ((dataFim.getTime() / 1000) + 86400 < e.registeredDate.seconds){
                    arrayOne.pop()
                }   
            }
            })
            this.listlogs = arrayOne
        })
    } 


    downloadEx(){
      this.excelDown.downLoadLogs(this.listlogs)
    }
    sort(key: any) {
      this.key = key.column.property
      this.reverse = !this.reverse;
    }
}
