import { NgModule } from '@angular/core';

import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { PoButtonModule, PoFieldModule } from '@po-ui/ng-components';

import { TimelinePanelComponent } from 'app/layout/components/timeline-panel/timeline-panel.component';

@NgModule({
    declarations: [
        TimelinePanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,

        FuseSharedModule,

        PoFieldModule,
        PoButtonModule
    ],
    exports: [
        TimelinePanelComponent
    ]
})
export class TimelinePanelModule { }
