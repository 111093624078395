export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD' : {
                'TITLE': 'Dashboard'
            },
            'STORE' : {
                'TITLE': 'Store'
            },
            'MYPERFIL' : {
                'TITLE': 'My Perfil'
            },
            'MYPURCHASES' : {
                'TITLE': 'My Purchases'
            },
            'MYQUESTS' : {
                'TITLE': 'My Quests'
            },
            'MYPRODUCTS': {
                'TITLE': "Product Announce",
            },
            'MYSALES': {
                'TITLE': "My Sales",
            },
            
    //--------------------------------------------------------------//

            'ADMINISTRATION': 'Administration',            
            'SALES' : {
                'TITLE': 'Sales'
            },           
            'QUESTS' : {
                'TITLE': 'Quests'
            },
            'APPROVALQUESTS' : {
                'TITLE': 'Approval Quests'
            },       
            'PRODUCTS' : {
                'TITLE': 'Products'
            },  
            'TYPEREWARD': {
                'TITLE': "Rewards Registration",
            },   
            'APPROVE': {
                'TITLE': "Approve Rewards",
            }, 
            'APPROVEPRODUCT': {
                'TITLE': "Approve Products",
            },

    //--------------------------------------------------------------//

            'CONFIGURATION': 'Configuration',
            'USERS' : {
                'TITLE': 'Users'
            },
            'COINS' : {
                'TITLE': 'Coins'
            },
            'SYSTEMPARAMS':{
                'TITLE': 'Parâmetros do Sistema'
            }
        }
    }
};
