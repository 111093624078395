import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductsModel } from 'app/models/products.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ProductsService implements Resolve<any> {

    products: any[];
    product: any;

    routeParams: any;

    formData: ProductsModel;

    onProductChanged: BehaviorSubject<any>;
    onUseChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore,        
        private firestorage: AngularFireStorage
    ) { 
        this.onProductChanged = new BehaviorSubject([]);
        this.onUseChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getProducts(),
                this.getProductId()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getProducts(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/products`).snapshotChanges().subscribe(action => {
                this.products = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as ProductsModel),
                        favorite: ['detalhes']
                    } as ProductsModel
                });
                this.onProductChanged.next(_.orderBy(this.products, ['displayName'],['asc']));
                resolve(this.products);
            });
        })
    }


    getProductsColla(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/productsCollaborator`).snapshotChanges().subscribe(action => {
                this.products = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as ProductsModel),
                        favorite: ['detalhes']
                    } as ProductsModel
                });
                this.onProductChanged.next(_.orderBy(this.products, ['displayName'],['asc']));
                resolve(this.products);
            });
        })
    }

    getProductId(){
        if(this.routeParams.productId){
            return new Promise((resolve, reject) => {
                this.firestore.collection(`${datastore}/products`).doc(this.routeParams.productId).valueChanges().subscribe(action => {
                    this.product = action
                    this.onUseChanged.next(this.product)
                    resolve(this.product)
                })
            })
        }        
    }

    insertProduct(data: ProductsModel, file: any) {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/products`).add(data).then(docRef => {
                this.uploadFiles(file, docRef.id, resolve)
            });
        })
    }

    updateProduct(id: string, file: any, data: ProductsModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/products/${id}`).update(data);
            this.uploadFiles(file, id, resolve);
        })       
    }  

    updateProductAmount(id: string){
        this.firestore.doc(`${datastore}/productsCollaborator/${id}`).get().toPromise().then((res) =>{
           
        })
     
    }  


    deleteProduct(id: string){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/products`).doc(id).get().toPromise().then((res) =>{
                let imgref = res.data().images[0].imgLocation
                this.firestorage.ref(imgref).delete()
                res.ref.delete()
            })
          
            
        })       
    }  


    uploadFiles(files: any, productId: string, resolve) {
        if (files.length == 0) {
          resolve(true)
        }
        for ( let i = 0; i < files.length; i++ ) {
            let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
            let arquivo = files.item(i);
            let location = (`${datastore}/product/${productId}/${dateNow}/${arquivo.name}`);
            let fileRef = this.firestorage.ref(`${datastore}/product/${productId}/${dateNow}/${arquivo.name}`);
            let task = fileRef.put(arquivo);
            // get notified when the download URL is available
            return task.snapshotChanges().pipe(finalize(() => {
                fileRef.getDownloadURL().subscribe(link => {
                    this.firestore.doc(`${datastore}/products/${productId}`).update({
                        images: [{
                            productId: productId,
                            date: new Date().toLocaleString(),
                            time: new Date().getTime(),
                            imgLocation: location,
                            filename: arquivo.name,
                            imgLink: link
                        }]
                    });
                });
            })
          ).subscribe()
        }
    }
}
