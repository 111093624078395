import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FuseSharedModule } from '@fuse/shared.module'
import { SampleComponent } from './sample.component'
import {
  PoButtonModule,
  PoChartModule,
  PoContainerModule,
  PoFieldModule,
  PoInfoModule,
  PoModalModule,
  PoTableModule,
  PoPageModule, 
  PoSlideModule, 
  PoTabsModule, 
  PoWidgetModule
} from '@po-ui/ng-components'
import { SampleService } from 'app/service/sample.service'
import { MatIconModule } from '@angular/material/icon'
import { AuthGuard } from '../../service/auth.guard'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { PoAccordionModule } from '@po-ui/ng-components'
import { MatCarouselModule } from '@ngmodule/material-carousel'
import { SalesService } from 'app/service/sales.service'


const routes = [
  {
    path: 'sample',
    component: SampleComponent,
    resolve: {
      sample: SampleService,
      sales: SalesService
    },
 
    canActivate: [AuthGuard],
  },
]

@NgModule({
  declarations: [SampleComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatIconModule,
    MatCarouselModule.forRoot(),
    //PO Modules
    PoContainerModule,
    PoTableModule,
    PoChartModule,
    PoFieldModule,
    PoModalModule,
    PoButtonModule,
    PoInfoModule,
    PoAccordionModule,
    PoSlideModule,
    NgxChartsModule,
    PoTabsModule, 
    PoWidgetModule,
    PoPageModule,
   
  ],
  exports: [SampleComponent],
})
export class SampleModule {}
