import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';

@Component({
    selector: 'timeline-panel',
    templateUrl: './timeline-panel.component.html',
    styleUrls: ['./timeline-panel.component.scss'],  
    encapsulation: ViewEncapsulation.None,
    providers: [PoNotificationService]
})
export class TimelinePanelComponent {

    @ViewChild('timeLine') teste;

    itemTimeline = []
    usersTimeline = []

    //Po Notification
    message: string;
    orientation: number;
    duration: number;

    constructor(
        private poNotification: PoNotificationService
    ){ 
        this.itemTimeline = [
            {id: 1, nome: 'Geovanni Tonin Moron', img:'assets/images/avatars/Geva.jpg', mensagem: 'Toma ai Carlão essas estrelas pra tu men', valor: 15, destinatario: '@Carlos.Farias'},
            {id: 2, nome: 'Carlos Farias', img:'assets/images/avatars/Helen.jpg', mensagem: 'Geva, use com sabedoria essas estrelas, beijo!', valor: 50, destinatario: '@Geovanni.Moron'},
            {id: 3, nome: 'Geovanni Tonin Moron', img:'assets/images/avatars/Geva.jpg', mensagem: 'Toma ai men', valor: 30, destinatario: '@Carlos.Farias'},
            {id: 3, nome: 'Carlos Farias', img:'assets/images/avatars/Helen.jpg', mensagem: 'Mensagem teste 987', valor: 80, destinatario: '@Geovanni.Moron'},
            {id: 3, nome: 'Geovanni Tonin Moron', img:'assets/images/avatars/Geva.jpg', mensagem: 'Presentinho pra tu!', valor: 140, destinatario: '@Carlos.Farias'}
        ]

        this.usersTimeline = [
            {label: 'Amanda'},
            {label: 'Bruno'},
            {label: 'Carlos'},
            {label: 'Geovanni'},
            {label: 'Pedro'}
        ]
    }

    sendValue(item){
        var itemValue = parseInt(item.substring(item.indexOf("$")).split(" ")[0].slice(1))
        var destinationUser = item.substring(item.indexOf("@")).split(" ")[0]
        var message = item.substring(item.indexOf("#")).slice(1)
        this.itemTimeline.push(      
            {id: 1, nome: 'Geovanni Tonin Moron', img:'assets/images/avatars/Geva.jpg', mensagem: message, valor: itemValue, destinatario: destinationUser},   
        )
        this.teste.nativeElement.value = " "
        alert('Estrelas enviadas com sucesso!');
    }

    showNotification(){
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1
        }
        this.poNotification.success(poNotification);
    }
}
