import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { PoButtonModule, PoContainerModule, PoFieldModule, PoModalModule, PoPageModule, PoTableModule, PoWidgetModule } from '@po-ui/ng-components';
import { ProductsService } from 'app/service/products.service';
import {NgxPaginationModule} from 'ngx-pagination'
import { ProductsComponent } from './products.component';
import { OrderModule } from 'ngx-order-pipe';
const routes = [
    {
        path: 'products',
        component: ProductsComponent,
        resolve: {
            products: ProductsService
        }
    }
]

@NgModule({
    declarations: [
        ProductsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,

        MatIconModule,
        NgxPaginationModule,
        //PO Modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoTableModule,
        PoButtonModule,
        PoFieldModule,
        OrderModule
    ],
    exports: [
        ProductsComponent
    ]
})
export class ProductsModule { }
