import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { PurchasesService } from 'app/service/purchases.service';
import { Observable, interval } from 'rxjs';

@Component({
  selector: 'app-modal-notify',
  templateUrl: './modal-notify.component.html',
  styleUrls: ['./modal-notify.component.scss']
})
export class ModalNotifyComponent implements OnInit {
   dados: any
   dataPurchase: any
   dataPurchaseColla: any
   images: any
    statusCompra: any;
    motivoCancelamento: any;
   constructor(public diagRef: MatDialogRef<ModalNotifyComponent>, public purchase: PurchasesService) { }


  
  async ngOnInit() {
      this.dados = this.diagRef._containerInstance._config.data
      // console.log(this.dados)
      if (this.dados.idPurchase != ''){
        await this.purchase.getPurchaseById(this.dados.idPurchase).then(async (res) =>{
          // console.log(res)
          this.dataPurchase = res.data()
          this.images = res.data().products
          
        }).catch((error) =>{
          // console.log(error)
        })

        await this.purchase.getPurchaseByIdCol(this.dados.idPurchase).then(async (res) =>{
          this.dataPurchaseColla = res.data()
          this.images = res.data().products
          
        }).catch((error) =>{
          // console.log(error)
        })

       
      }

      

     this.statusCompra = (await this.purchase.getPurchaseById(this.dados.idPurchase)).data().deliveryStatus
     this.motivoCancelamento = (await this.purchase.getPurchaseById(this.dados.idPurchase)).data().observacao
     
  }

  onNoClick(): void {
    this.diagRef.close();
    
  }


}
