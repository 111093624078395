import { Component, OnInit, ViewChild } from '@angular/core'
import {
  PoModalAction,
  PoModalComponent,
  PoNotification,
  PoNotificationService,
  PoTableAction,
  PoTableColumn,
  PoTableColumnLabel,
} from '@po-ui/ng-components'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { QuestsModel } from 'app/Models/quests.model'
import { QuestsService } from 'app/service/quests.service'
import { AuthService } from 'app/service/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'quests',
  templateUrl: './quests.component.html',
  styleUrls: ['./quests.component.scss'],
  providers: [PoNotificationService],
})
export class QuestsComponent implements OnInit {
  @ViewChild(PoModalComponent, { static: true }) addQuestModal: PoModalComponent

  actions: Array<PoTableAction> = [
    {
      action: this.detailsQuest.bind(this),
      icon: 'po-icon-info',
      label: 'Editar',
    },
  ]

  public listQuests: QuestsModel[]
  public quest: QuestsModel
  public isUpdate = false
  public questUpdateId = ''

  reactiveForm: FormGroup
  columns: Array<PoTableColumn>

  //Po Notification
  message: string
  duration = 5000
  poType: string

  constructor(
    private fb: FormBuilder,
    private poNotification: PoNotificationService,
    private service: QuestsService,
    public auth: AuthService,
    private router: Router,
  ) {
    this.createReactiveForm()
    this.columns = [
      { property: 'name', label: 'Nome', visible: true },
      { property: 'rewardValue', label: 'Recompensa', visible: true },
      {
        property: 'startDate',
        label: 'Data Início',
        type: 'date',
        visible: true,
      },
      {
        property: 'endDate',
        label: 'Data Término',
        type: 'date',
        visible: true,
      },
      {
        property: 'status',
        label: 'Quest Ativa?',
        type: 'label',
        labels: <Array<PoTableColumnLabel>>[
          {
            value: true ? true : '',
            color: 'color-11',
            label: 'Ativa',
            tooltip: 'Quest Ativa',
          },
          {
            value: false,
            color: 'color-07',
            label: 'Inativa',
            tooltip: 'Quest Inativa',
          },
        ],
        visible: true,
      },
    ]
  }

  ngOnInit(): void {
    this.auth.user$.subscribe((credentials) => {
      if (credentials.userPerfil == '3') {
        this.router.navigate(['/sample'])
      } else {
        this.service.onQuestChanged.subscribe((quests) => {
          this.listQuests = quests
        })
      }
    })
    
  }

  ngAfterViewInit(): void{
    
}

  openNewModal() {
    this.clearForm()
    this.isUpdate = false
    this.addQuestModal.open()
  }

  SaveAction: PoModalAction = {
    action: () => {
      var fields = this.reactiveForm.value
      let saveQuest = {} as QuestsModel
       ('aqui')
      // if (this.reactiveForm.valid) {
      if (fields.startDate && fields.rewardValue && fields.name) {
        saveQuest.name = fields.name
        saveQuest.instructions = fields.instructions
        saveQuest.status = fields.status
        saveQuest.rewardValue = fields.rewardValue
        saveQuest.startDate = fields.startDate
        saveQuest.endDate = fields.endDate
        saveQuest.userResults = []
        saveQuest.evidencia = fields.evidenciaBol

        if (!this.isUpdate) {
          this.service.insertQuest(saveQuest)
          this.message = 'Quest cadastrada com sucesso!'
        } else {
          this.service.updateQuest(this.questUpdateId, saveQuest)
          this.questUpdateId = ''
          this.message = 'Quest alterada com sucesso!'
        }
        this.poType = 'sucess'
        this.message = 'Quest cadastrada com sucesso!'
        this.clearForm()
        this.addQuestModal.close()
        this.showNotification(this.poType)
        // } else {
        //   this.poType = 'warning'
        //   this.message =
        //     'Preencha os campos obrigatórios (Nome, Data de Inicio e Recompensa)!'
        //   this.showNotification(this.poType)
        // }
      } else {
        this.message = 'Preencha o formulário corretamente'
        this.poType = 'error'
        this.showNotification(this.poType)
      }
    },
    label: 'Salvar',
  }

  CloseAction: PoModalAction = {
    action: () => {
      this.addQuestModal.close()
    },
    label: 'Cancelar',
  }

  detailsQuest(item: QuestsModel) {
    this.isUpdate = true

    this.reactiveForm.controls['name'].setValue(item.name)
    this.reactiveForm.controls['instructions'].setValue(item.instructions)
    this.reactiveForm.controls['status'].setValue(item.status)
    this.reactiveForm.controls['rewardValue'].setValue(item.rewardValue)
    this.reactiveForm.controls['evidenciaBol'].setValue(item.evidencia)
    if (item.startDate) {
      let startDate = new Date(item.startDate)
      this.reactiveForm.controls['startDate'].setValue(
        new Date(startDate.setDate(startDate.getDate() + 1)),
      )
    }
    if (item.endDate) {
      let endDate = new Date(item.endDate)
      this.reactiveForm.controls['endDate'].setValue(
        new Date(endDate.setDate(endDate.getDate() + 1)),
      )
    }

    this.questUpdateId = item.id
    this.addQuestModal.open()
  }

  createReactiveForm() {
    this.reactiveForm = this.fb.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(30),
        ]),
      ],
      instructions: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(1000),
        ]),
      ],
      status: [true, Validators.required],
      rewardValue: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
      evidenciaBol: [false]
    })
  }

  clearForm() {
    this.reactiveForm.reset()
    this.reactiveForm = this.fb.group({
      name: [''],
      instructions: [''],
      status: [true],
      rewardValue: [''],
      startDate: [''],
      endDate: [''],
      evidenciaBol: [false],
    })
  }

  showNotification(messageType) {
    const poNotification: PoNotification = {
      message: this.message,
      duration: this.duration,
      orientation: 1,
    }
    switch (messageType) {
      case 'success': {
        this.poNotification.success(poNotification)
        break
      }
      case 'error': {
        this.poNotification.error(poNotification)
        break
      }
      case 'warning': {
        this.poNotification.warning(poNotification)
        break
      }
      case 'information': {
        this.poNotification.information(poNotification)
        break
      }
      default: {
        this.poNotification.success(poNotification)
        break
      }
    }
    this.poType = ''
  }


}
