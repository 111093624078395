
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">format_list_bulleted</mat-icon> Super pointz
                    </h2>
                </div>
                <div class="po-lg-4" style="display: flex; gap: 25px; justify-content: flex-end;">
                    <po-button p-label="Recompensar" p-icon="po-icon po-icon-plus" p-type="Primary"
                        (p-click)="openNewModalReward()" class="pull-right"> </po-button>
                    <po-button p-label="Adicionar grupo" p-icon="po-icon po-icon-plus" p-type="Primary"
                    (p-click)="openNewModal()" class="pull-right"> </po-button>
                </div>
            </div>
            <hr>
            <div class="po-row">
                <h2 class="align-title">Grupos cadastrados</h2>
                <po-table p-sort="true" [p-items]="groupsSp" [p-striped]="true" class="po-table-quests"
                    [p-actions]="actions" [p-columns]="columns"></po-table>
            </div>
        </div>
    </po-container>
</div>

<po-modal #addGroup p-click-out="false" p-title="Adicionar novo grupo" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction">
    <form [formGroup]="reactiveFormGroup">
        <po-input p-required="true" p-label="Nome do grupo" id="nameGroup" formControlName="nameGroup"></po-input>
        <po-multiselect p-required="true" [p-options]="users" id="membersGroup" formControlName="membersGroup" p-label="Adicionar colaboradores"></po-multiselect>   
    </form>
</po-modal>


<po-modal #rewardGroup p-click-out="false" p-title="Recompensar" [p-primary-action]="SaveActionReward" [p-secondary-action]="CloseActionRewards">
    <form [formGroup]="reactiveFormRecomp">
        <po-multiselect [p-options]="users" id="membersGroup" formControlName="membersGroup" p-label="Selecionar colaboradores"></po-multiselect> 
        <po-number p-label="Valor da recompensa" p-minlength="1" p-required="true" id="coinsValue" formControlName="coinsValue"></po-number>  
        <po-input p-label="Motivo da Recompensa"  p-required="true" id="reasonText" formControlName="reasonText"></po-input>
    </form>        
</po-modal>


<po-modal #rewardTheGroup p-click-out="false" p-title="Recompensar o grupo" [p-primary-action]="SaveRewardTheGroup" [p-secondary-action]="CloseActionTheGroup">
    <form [formGroup]="reactiveForm">
        <po-input [p-disabled]='true' p-label="Nome do grupo" id="nameGroup" formControlName="nameGroup"></po-input>  
        <po-number id="coinsValue" p-minlength="1" p-required="true" formControlName="coinsValue" p-label="Valor da recompensa" ></po-number>
        <po-input p-label="Motivo da Recompensa" p-required="true" id="reasonText" formControlName="reasonText"></po-input>
        <table>
            <thead>
                <td style="font-family: Arial, Helvetica, sans-serif; font-weight: 700;">Colaboradores</td>
            </thead>
            <tbody *ngFor="let item of groupColabs">
                <td>{{item.displayName}}</td>
            </tbody>
        </table>
    </form>
</po-modal>


<po-modal #deleteGroup p-click-out="false" p-title="Aviso" [p-primary-action]="deleteGroupByID" [p-secondary-action]="CloseDeleteGroup">
    <h2>Essa ação sera permanente, deseja realmente deletar o grupo?</h2>
</po-modal>