import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { FuseUtils } from "@fuse/utils";
import { AngularFirestore } from "@angular/fire/firestore";
import { datastore } from "app/config";
import { UserModel } from "app/Models/user.model2";
import { BehaviorSubject } from "rxjs";
import * as _ from "lodash";
import { AuthService } from "app/service/auth.service";
import { SelectsModel } from "app/Models/selects.model";
import Post from "../timeline/model-post";
import { TimelineService } from "../timeline/timeline.service";
import { PoNotification, PoNotificationService } from "@po-ui/ng-components";
import { LogService } from "app/service/log.service";
import * as firebase from "firebase";

@Injectable()
export class ChatPanelService {
    contacts: any[];
    chats: any[];
    user: any;
    users: SelectsModel[];
    usersFilters: SelectsModel[];
    coins: SelectsModel[];
    userListService: any;
    userListFiltersService: any;
    modal: boolean = false;
    userLogged: any;
    rewarded: any;

    onUserChanged: BehaviorSubject<any>;
    onUserFiltersChanged: BehaviorSubject<any>;
    onCoinsChanged: BehaviorSubject<any>;

    message: string;
    duration = 3900;
    poType: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private poNotification: PoNotificationService,
        public auth: AuthService,
        public timelineService: TimelineService,
        private firestore: AngularFirestore,
        private _httpClient: HttpClient,
        private logService: LogService,
    ) {
        this.onUserChanged = new BehaviorSubject([]);
        this.onUserFiltersChanged = new BehaviorSubject([]);
        this.onCoinsChanged = new BehaviorSubject([]);
    }

    /**
     * Loader
     *
     * @returns {Promise<any> | any}
     */
    loadUsers(): Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                //this.getContacts(),
                //this.getUser(),
                this.getUsers(),
                this.getUsersFilters(),
            ]).then(([userListService]) => {
                //   this.contacts = contacts;
                //  this.user = user;
                this.userListService = userListService;
                resolve(userListService);
            }, reject);
        });
    }

    getUsers(): Promise<any> {
        var promise = new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .get()
                .then((action) => {
                    this.users = [];
                    this.auth.user$.subscribe((credentials) => {
                        action.docs.forEach((item) => {
                            if (credentials.uid != item.id) {
                                this.users.push({
                                    label: item.data()["displayName"],
                                    value: item.id,
                                });
                            }
                        });
                        this.userListService = this.users;
                        this.onUserChanged.next(
                            _.orderBy(this.users, ["label"], ["asc"])
                        );
                        resolve(this.userListService);
                    });
                });
        });
        return promise;
    }

    getUsersFilters(): Promise<any> {
        var promise = new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .get()
                .then((action) => {
                    this.usersFilters = [];
                    this.auth.user$.subscribe((credentials) => {
                        action.docs.forEach((item) => {
                            this.usersFilters.push({
                                label: item.data()["displayName"],
                                value: item.data()["displayName"],
                            });
                        });
                        this.userListFiltersService = this.usersFilters;
                        this.onUserFiltersChanged.next(
                            _.orderBy(
                                this.userListFiltersService,
                                ["label"],
                                ["asc"]
                            )
                        );
                        resolve(this.userListFiltersService);
                    });
                });
        });
        return promise;
    }

    getCoinsDefinition(): Promise<any> {
        var promise = new Promise((resolve, reject) => {
            this.auth.user$.subscribe((credentials) => {
                this.firestore
                    .collection(`${datastore}/coins`)
                    .ref.where("type", "==", "2")
                    .get()
                    .then((action) => {
                        this.coins = [];
                        action.docs.forEach((item) => {
                            if (item.data()["status"]) {
                                if (_.isArray(item.data()["config"])) {
                                    item.data()["config"].forEach((coin) => {
                                        this.coins.push({
                                            label: "Recompensa: " + coin.Valor,
                                            value: coin.Valor,
                                        });
                                    });
                                }

                                this.coins.push({
                                    label: item.data()["displayName"],
                                    value: item.id,
                                });
                            }
                        });
                        this.onCoinsChanged.next(
                            _.orderBy(this.coins, ["label"], ["asc"])
                        );
                        resolve(this.coins);
                    });
            });
        });
        return promise;
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any> {
        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });

        // Get the chat
        return new Promise((resolve, reject) => {
            // If there is a chat with this user, return that.
            if (chatItem) {
                this._httpClient
                    .get("api/chat-panel-chats/" + chatItem.chatId)
                    .subscribe((chat) => {
                        // Resolve the promise
                        resolve(chat);
                    }, reject);
            }
            // If there is no chat with this user, create one...
            else {
                this.createNewChat(contactId).then(() => {
                    // and then recall the getChat method
                    this.getChat(contactId).then((chat) => {
                        resolve(chat);
                    });
                });
            }
        });
    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any> {
        return new Promise((resolve, reject) => {
            // Generate a new id
            const chatId = FuseUtils.generateGUID();

            // Prepare the chat object
            const chat = {
                id: chatId,
                dialog: [],
            };

            // Prepare the chat list entry
            const chatListItem = {
                chatId: chatId,
                contactId: contactId,
                lastMessageTime: "2017-02-18T10:30:18.931Z",
            };

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItem);

            // Post the created chat to the server
            this._httpClient
                .post("api/chat-panel-chats", { ...chat })
                .subscribe(() => {
                    // Post the updated user data to the server
                    this._httpClient
                        .post("api/chat-panel-user/" + this.user.id, this.user)
                        .subscribe(() => {
                            // Resolve the promise
                            resolve(chat);
                        });
                }, reject);
        });
    }

    /**
     * Update the chat
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateChat(chatId, dialog): Promise<any> {
        return new Promise((resolve, reject) => {
            const newData = {
                id: chatId,
                dialog: dialog,
            };

            this._httpClient
                .post("api/chat-panel-chats/" + chatId, newData)
                .subscribe((updatedChat) => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/chat-panel-contacts")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get("api/chat-panel-user")
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, reject);
        });
    }

    getModal() {
        this.modal = true;
    }

    rewardedCoins(values: any) {
        return new Promise((resolve, reject) => {
            let count = 0;
            this.auth.user$.subscribe((credentials) => {
                this.firestore
                    .collection(`${datastore}/users`)
                    .doc(credentials.uid)
                    .get()
                    .subscribe((user) => {
                        this.userLogged = user;

                        this.firestore
                            .collection(`${datastore}/users`)
                            .doc(values.users)
                            .get()
                            .subscribe((actionRewarded) => {
                                this.rewarded = actionRewarded;

                                if (
                                    this.userLogged.exists &&
                                    this.rewarded.exists
                                ) {
                                    if (
                                        this.userLogged.data().trade != 0 &&
                                        this.userLogged.data().trade >=
                                        values.coins
                                    ) {
                                        // para usuários já cadastrados
                                        const tradeRef = this.userLogged.data()
                                            .tradesTotalToRank
                                            ? this.userLogged.data()
                                                .tradesTotalToRank
                                            : 0;
                                        let dataUser = {
                                            trade:
                                                this.userLogged.data().trade -
                                                values.coins,
                                            tradesTotalToRank:
                                                tradeRef + values.coins,
                                        };
                                        // para usuários já cadastrados
                                        const cashRecivedRef = this.rewarded.data()
                                            .cashRecivedToRank
                                            ? this.rewarded.data()
                                                .cashRecivedToRank
                                            : 0;
                                        let dataRewarded = {
                                            cash:
                                                this.rewarded.data().cash +
                                                values.coins,
                                            cashRecivedToRank:
                                                cashRecivedRef + values.coins,
                                        };
                                        if (count == 0) {
                                            this.firestore
                                                .doc(
                                                    `${datastore}/users/${this.userLogged.id}`
                                                )
                                                .update(dataUser);
                                            this.firestore
                                                .doc(
                                                    `${datastore}/users/${this.rewarded.id}`
                                                )
                                                .update(dataRewarded);
                                            //Call a Function to send a reward metion to a google chat group by a webhook configurated on the system parameters page

                                            //Ativar/desativar google chat
                                            this.sendRewardToGoogleChat(credentials, values.users, values.coins, values.motivo);

                                            //const id: string = this.firestore.createId();

                                            let dateNow = new Date();
                                            let formatDate =
                                                dateNow.toLocaleDateString() +
                                                " as " +
                                                dateNow.toLocaleTimeString();

                                            let post: Post = {
                                                comments: [],
                                                like: [],
                                                message: values.motivo,
                                                time: formatDate,
                                                user: {
                                                    avatar:
                                                        credentials.photoURL,
                                                    name:
                                                        credentials.displayName,
                                                    id: credentials.uid,
                                                },
                                                rewarded: {
                                                    avatar: this.rewarded.data()
                                                        .photoURL,
                                                    name: this.rewarded.data()
                                                        .displayName,
                                                    id: this.rewarded.id,
                                                },
                                                type: "post",
                                                coins: values.coins,
                                                dateNow: firebase.firestore.FieldValue.serverTimestamp(),
                                            };

                                            this.timelineService.newPost(post);

                                            this.logService.insertLog({
                                                pageName: "Recompensas",
                                                actionExecuted: "Recompensa",
                                                responsible:
                                                    credentials.displayName,
                                                userLoggedId: credentials.uid,
                                                description: `${credentials.displayName
                                                    } fez uma recompensa para ${this.rewarded.data()
                                                        .displayName
                                                    } no valor de ${values.coins}`,
                                                registeredDate: new Date(),
                                                objectTransacted: JSON.stringify(
                                                    post
                                                ),
                                            });

                                            this.message =
                                                "Recompensa realizada com sucesso!";
                                            this.poType = "success";
                                            this.showNotification(this.poType);

                                            count++;
                                        } else {
                                            false;
                                        }
                                    }
                                } else {
                                    false;
                                }
                            });
                    });
            });
        });
    }

    sendRewardToGoogleChat(credentials, receiverId, amontCoins, motivo) {
        let identitySender =''
        let identityReceiver =''
        
        let headers = new HttpHeaders().set("Content-Type", "application/json");
        
    
        this.firestore.collection(`${datastore}/systemparameters`).valueChanges().subscribe(sytemParams => {
            let params = sytemParams[0];
            this.firestore.collection(`${datastore}/users`).ref.where("uid", "==", receiverId).get().then(actionRewarded => {
                let RewardedObject 
                //actionRewarded.docs.forEach((item) => { (item.data()["displayName"])})
                actionRewarded.docs.forEach((item) => { RewardedObject = item.data()})
               
    
                //verify if the user has a id chat code
    
                if(credentials.googlechatId){
                    identitySender = "<users/" + credentials.googlechatId + ">"
                }else{
                    identitySender = "*" + credentials.displayName.trim() + "*" 
                }
    
                if(RewardedObject['googlechatId']){
                    identityReceiver = "<users/" + RewardedObject['googlechatId'] + ">"
                }else{
                    identityReceiver = "*" + RewardedObject['displayName'].trim() + "*"
                }
    
                if(params['webhookLink']){
                    return new Promise((resolve, reject) => {
    

                        //Ativar/desativar o google chat
                        // Its needed to change de URI to the other GCloud Function
                        this._httpClient.post('https://southamerica-east1-rewardsly-iv2.cloudfunctions.net/rewardslyHangoutMetions '/*params['gFunctionAPI']*/, {
                            "webhooklink": params['webhookLink'],
                            "message": identitySender + " Recompensou " + identityReceiver + " com *" + amontCoins + "* moedas 🎉",
                            "motivo": motivo
                        }, { headers }).subscribe(response => {
                             
                        },
                            erro => {
                                if (erro.status == 400) {
                                     
                                }
                            });
                    });
                }         
            });
        });
    }



    sendRewardToGoogleChatSP(credentials, objeto) {
        let identitySender =''
        let identityReceiver =''
        
        let headers = new HttpHeaders().set("Content-Type", "application/json");
        
 
        this.firestore.collection(`${datastore}/systemparameters`).valueChanges().subscribe(sytemParams => {
            let params = sytemParams[0];
            
                if(params['webhookLink']){
                    // https://southamerica-east1-rewardsly-iv2.cloudfunctions.net/SuperPointzMentions
                    return new Promise((resolve, reject) => {
                        //Ativar/desativar o google chat
                        // Its needed to change de URI to the other GCloud Function
                        this._httpClient.post('https://southamerica-east1-rewardsly-iv2.cloudfunctions.net/SuperPointzMentions'/*params['gFunctionAPI']*/, {
                            webhooklink: params['webhookLink'],
                            data: objeto
                        }, { headers }).toPromise().then((resposta) =>{
                            
                        })
                    });
                }         
            });
       
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
}
