import { ApprovalProductsComponent } from './approval-products.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasesCollaboratorService } from 'app/service/purchases-collaborator.service';
import { AuthGuard } from 'app/service/auth.guard';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { PoButtonModule, PoContainerModule, PoFieldModule, PoModalModule, PoPageModule, PoTableModule, PoWidgetModule } from '@po-ui/ng-components';
import {NgxPaginationModule} from 'ngx-pagination'
import { OrderModule } from 'ngx-order-pipe';



const routes = [
  {
      path: "approvalProducts",
      component: ApprovalProductsComponent,
      resolve: {
        productstoapprove: PurchasesCollaboratorService
    }
  },
];


@NgModule({
  declarations: [ApprovalProductsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    FuseSharedModule,
    PoButtonModule,
    PoContainerModule,
    PoFieldModule,
    PoModalModule,
    PoPageModule,
    PoTableModule, 
    PoWidgetModule,
    NgxPaginationModule,
    OrderModule
  ]
})



export class ApprovalProductsModule { }
