
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
  <po-container class="po-xl-12 po-lg-12">
    <div class="container-custom-01">
      <div class="po-row">
        <div class="po-lg-8">
          <h2 class="align-title">
            <mat-icon class="secondary-text hlp-margin-right-10">devices_other</mat-icon> Cadastro de tipo de
            recompensas
          </h2>
        </div>
        <div class="po-lg-4" style="display: flex; justify-content: flex-end;">
          <po-button p-label="Novo Tipo de Recompensa" p-icon="po-icon po-icon-plus" p-type="Primary"
            (p-click)="modal.open()" class="pull-right"> </po-button>
        </div>
      </div>
      <hr>
      <div class="po-row">
        <po-table [p-columns]="columns" [p-items]="items" class="po-xl-12 po-lg-12" [p-actions]="actions">
        </po-table>
      </div>
    </div>
  </po-container>
</div>

<po-modal #modal p-title="Tipo de recompensa" p-click-out="false" [p-primary-action]="SaveAction"
  [p-secondary-action]="CloseAction">
  <po-container class="po-xl-12 po-lg-12">
    <form [formGroup]="reactiveForm">

      <div class="container-custom-01">
        <div class="po-row">
          <div class="po-lg-4">
            <po-select name="select" p-label="Tipo *" formControlName="typeOfReward" [p-options]="[
            { label: 'Para Loja', value: '1' },
            { label: 'Para Recompensar', value: '2' }
          ]" p-clean>
            </po-select>
          </div>
          <div class="po-lg-5">
            <po-input name="input" p-label="Titulo *" formControlName="title"> </po-input>
          </div>
          <div class="po-lg-3">
            <po-number formControlName="rewardValue" p-label="Recompensa *" p-clean p-icon="po-icon-star"
              formControlName="coins"> </po-number>
          </div>

        </div>
      </div>
    </form>
  </po-container>
</po-modal>