import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserModel } from '../Models/user.model2';
import { datastore } from 'app/config';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private poNotification: PoNotificationService,
    private _httpClient: HttpClient,
  ) {

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<UserModel>(`${datastore}/users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  senMail() {

    this._httpClient.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao', {
      assunto: "teste2",
      corpo: "true",
      corpoHtml: "<b>TOP COME</b>",
      destinatarios: "master10_@live.com"
    }, { responseType: 'text' }).subscribe(data => {

    })
  }

  senMailCreateLogin(email, displayName, photo) {
    let html = `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
        <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="" /><br />
        <p style="font: message-box; font-weight:bold; font-size:20px">Olá <b style="color: teal;">${displayName}</b> </p>
        <br /><br />
        <p>Bem vindo ao sistema Pointz, para que seu acesso aconteça, aguarde a liberação do adminsitrador do sistema!</p>
        <br />
        <p style="font: message-box; color: teal;">Aguarde, logo você receberá um e-mail com aviso que seu acesso foi liberado!</p>
        <br />
        <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
        <br />
        <p>Att. Administrador do sistema</p>
    </div>`;

    let data = {
      destinatarios: email,
      corpoHtml: html,
      corpo: '',
      assunto: "Bem-vindo ao Pointz"
    }

    this._httpClient.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao',
      data
      , { responseType: 'text' }).subscribe(data => {

      })
  }

  senMailAdminLiberacao(email, displayName, photo) {

    let date = new Date().toLocaleString();

    let html = `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
        <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
        <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Olá Administrador,</p>
        <p style="font: message-box;">O usuário abaixo efetuou o cadastro e está aguardando a liberação dele no sistema, segue dados:</p>
        
        <p style="font: message-box;">Usuário: <b style="color: teal;">${displayName}</b></p>
        <p style="font: message-box;">E-mail: <b style="color: teal;">${email}</b></p>
        <p style="font: message-box;">Data da criação: <b style="color: teal;">${date}</b></p>
        <br />
        <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/#/auth/login">clicando aqui!</a> e analise a liberação!</p>
        <br />
        <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
        <br />
        <p style="font: message-box;">Att. Administrador do sistema</p>
    </div>`;

     // admin comum recebe o email para liberacao
    this.afs.collection(`${datastore}/users`).ref.where('userPerfil', '==', '1').get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let value = doc.data();
       
        let data = {
          destinatarios: value.email,
          corpoHtml: html,
          corpo: '',
          assunto: "Pointz - Liberação de usuário!"
        }

        // this._httpClient.post('https://southamerica-east1-rewardsly-iv2-dev.cloudfunctions.net/sendMailRW',
        this._httpClient.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao',
          data
          , { responseType: 'text' }).subscribe(data => {

          })
      })
    })

    // Super admin recebe o email para liberacao
    this.afs.collection(`${datastore}/users`).ref.where('userPerfil', '==', '4').get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let value = doc.data();
       
        let data = {
          destinatarios: value.email,
          corpoHtml: html,
          corpo: '',
          assunto: "Pointz - Liberação de usuário!"
        }

        // this._httpClient.post('https://southamerica-east1-rewardsly-iv2-dev.cloudfunctions.net/sendMailRW',
        this._httpClient.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao',
          data
          , { responseType: 'text' }).subscribe(data => {

          })
      })
    })

  }

  senMailLoginAccept(email, displayName, photo) {
    let html = `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
        <img style="width:900px;"src="https://i.ibb.co/StL1KmQ/banner01.png" alt="" /><br /><br />
        <p style="font: message-box; font-weight:bold; font-size:20px">Olá <b style="color: teal;">${displayName}</b>,
        </p>
        <p><br /><br /> seu acesso ao Pointz foi liberado!</p>
        <br />
        <p style="font: message-box; color: teal;">Efetue o login <a href="${location.origin}">Clicando aqui!</a></p>
        <br />
        <img style="width:500px;"src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
        <br />
        <p style="font: message-box;">Att. Administrador do sistema</p>
    </div>`;

    let data = {
      destinatarios: email,
      corpoHtml: html,
      corpo: '',
      assunto: "Pointz - Acesso liberado!"
    }

    this._httpClient.post('https://us-central1-apps-iv2.cloudfunctions.net/sendMailAdmissao',
      data
      , { responseType: 'text' }).subscribe(data => {

      })
  }



}
