import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { PoButtonModule, PoContainerModule, PoFieldModule, PoModalModule, PoPageModule, PoTableModule, PoWidgetModule } from '@po-ui/ng-components';
import { QuestsService } from 'app/service/quests.service';

import { QuestsComponent } from './quests.component';

const routes = [
    {
        path : 'quests',
        component : QuestsComponent,
        resolve: {
            quests: QuestsService
        },
    }
]

@NgModule({
    declarations: [
        QuestsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,

        MatIconModule,

        //PO Modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoTableModule,
        PoButtonModule,
        PoFieldModule
    ],
    exports: [
        QuestsComponent
    ]
})

export class QuestsModule { }

