

<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10"></mat-icon> Logs
                    </h2>
                </div>
            </div>
            <section class="sectionFilter">
                <div class="masterSearch">
                    <div class="btnBox">
                        <button class="buttonExcell" (click)="downloadEx()"><img src="/assets/icons/exceldown/excelIcon.png" alt=""></button>
                    </div>
                    <div class="search">
                        <po-input p-label="Pagina ou Responsavel" [(ngModel)]="nome" (ngModelChange)="filterName()" class="inputSearch" name="nome" id="nome" type="text"></po-input>
                    </div>
                    <div class="startDate">
                        <po-datepicker name="startDate"  [(ngModel)]="startDate" (ngModelChange)="filterName()"  p-format="dd/mm/yyyy" p-label="Data Inicio">
                        </po-datepicker>
                    </div>
                    <div>
                        <po-datepicker name="endDate" [(ngModel)]="endDate" (ngModelChange)="filterName()"  p-format="dd/mm/yyyy" p-label="Data Fim">
                        </po-datepicker>
                    </div>
                </div>
            </section>
            <hr>
            <div class="po-row">
                <po-table p-sort="true" [p-items]="listlogs | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p}" (p-sort-by)="sort($event)" [p-striped]="true" class="po-table-logs"
                    [p-columns]="columns">
                    <ng-template p-table-column-template [p-property]="'registeredDate'" let-value>
                        <div class="badge {{ value }}">{{ value.toDate() | date: 'dd/MM/yyyy h:mm a'  }}</div>
                    </ng-template>
                </po-table>
                <pagination-controls previousLabel="Voltar" nextLabel="Proximo" style="margin-top: 15px;" (pageChange)="p = $event"></pagination-controls> 
            </div>
        </div>
    </po-container>
</div>