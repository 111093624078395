<main>

    <!-- NOTIFICAÇÃO DE STATUS DA COMPRA/CANCELAMENTO -->
    <section *ngIf="dados.typeNotify == 'purchase'">
        <headear>
            <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
        </headear>
        <main>
            <h1 style="text-align: center;">NOTIFICAÇÃO DE COMPRA</h1>
            <div *ngIf="statusCompra == false">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item" >
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>

            <div *ngIf="statusCompra == 2">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item">
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>
            <div *ngIf="statusCompra == 3">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>
            <div  *ngIf="statusCompra == true">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
                  <h1 style="text-align: center; margin-bottom: 25px;">APROVEITE SEUS PRODUTO(S)</h1>
            </div>

            <div  *ngIf="statusCompra == 4">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item cancel" >
                      <div class="step-counter" style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item cancel" >
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item cancel">
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item cancel">
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                    <div class="stepper-item cancel">
                        <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                        <div class="step-name">Produto RECUSADO</div>
                      </div>
                  </div>
                  <h1 style="text-align: center; margin-bottom: 25px;">Compra Cancelada, Motivo : {{motivoCancelamento}}</h1>
            </div>


            <div style="display: flex; justify-content: space-around; margin-bottom: 25px;">
                <div *ngFor="let item of dataPurchase.products">
                    <div style="box-shadow: 0px 0px 15px rgb(199, 199, 199); text-align: center; padding: 5px; border-radius: 10px;">
                        <p style="font-weight: 800;">Produto : {{item.name}}</p>
                        <p style="font-weight: 800;">Quantidade : {{item.amount}}</p>
                        <p style="font-weight: 800;">Valor : {{item.price}}</p>
                        <img width="120px" src="{{item.images[0].imgLink}}" alt="">
                    </div>
                </div>
            </div>
            
        </main>
        <footer style="display: flex; justify-content: center;">
            <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
        </footer>
    </section>



    <!-- inicio NOTIFICAÇÃO DE STATUS DA COMPRA/CANCELAMENTO COLABORADOR -->

    <section *ngIf="dados.typeNotify == 'purchaseCol'">
          <headear>
            <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
        </headear>
        <main>
            <h1 style="text-align: center;">NOTIFICAÇÃO DE COMPRA</h1>
            <div *ngIf="dataPurchaseColla.deliveryStatus == false">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item" >
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>

            <div *ngIf="dataPurchaseColla.deliveryStatus == 2">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item">
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>
            <div *ngIf="dataPurchaseColla.deliveryStatus == 3">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item">
                      <div class="step-counter"><mat-icon>hourglass_empty</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
            </div>
            <div  *ngIf="dataPurchaseColla.deliveryStatus == true">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item completed" >
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item completed">
                      <div class="step-counter"><mat-icon style="color: rgb(0, 201, 0); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">done</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                  </div>
                  <h1 style="text-align: center; margin-bottom: 25px;">APROVEITE SEUS PRODUTO(S)</h1>
            </div>

            <div  *ngIf="dataPurchaseColla.deliveryStatus == 4">
                <div class="stepper-wrapper" style="margin-top: 40px; margin-bottom: 40px;">
                    <div class="stepper-item cancel" >
                      <div class="step-counter" style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto em Aberto</div>
                    </div>
                    <div class="stepper-item cancel" >
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto em Andamento</div>
                    </div>
          
                    <div class="stepper-item cancel">
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto Aguardando Entrega</div>
                    </div>
                    <div class="stepper-item cancel">
                      <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                      <div class="step-name">Produto Entregue</div>
                    </div>
                    <div class="stepper-item cancel">
                        <div class="step-counter"  style="background-color: red;"><mat-icon style="color: rgb(255, 255, 255); font-size: 32px; margin-bottom: 2px; margin-left: 4px;" [inline]="true">error_outline</mat-icon></div>
                        <div class="step-name">Produto RECUSADO</div>
                      </div>
                  </div>
                  <h1 style="text-align: center; margin-bottom: 25px;">Compra Cancelada, Motivo : {{dataPurchaseColla.observacao}}</h1>
            </div>


            <div style="display: flex; justify-content: space-around; margin-bottom: 25px;">
                <div *ngFor="let item of dataPurchaseColla.products">
                    <div style="box-shadow: 0px 0px 15px rgb(199, 199, 199); text-align: center; padding: 5px; border-radius: 10px;">
                        <p style="font-weight: 800;">Produto : {{item.name}}</p>
                        <p style="font-weight: 800;">Quantidade : {{item.amount}}</p>
                        <p style="font-weight: 800;">Valor : {{item.price}}</p>
                        <img width="120px" src="{{item.images[0].imgLink}}" alt="">
                    </div>
                </div>
            </div>
            
          </main>
          <footer style="display: flex; justify-content: center;">
              <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
          </footer>
    </section>

    <!-- FIM NOTIFICAÇÃO DE STATUS DA COMPRA/CANCELAMENTO COLABORADOR -->


    <section *ngIf="dados.typeNotify == 'sellProductCollaborator'">
      <headear>
        <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
    </headear>
      <h1 style="text-align: center;">NOTIFICAÇÃO</h1>
      <div style="display: flex; justify-content: center;">
        <p style="font-weight: 800;">{{dados.description}}</p>
      </div>
      <footer style="display: flex; justify-content: center;">
        <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
    </footer>
    </section>




    <section *ngIf="dados.typeNotify == 'ProdutoReprovado'">
      <headear>
        <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
    </headear>
      <h1 style="text-align: center;">NOTIFICAÇÃO</h1>
      <div style="display: flex; justify-content: center;">
        <p style="font-weight: 800;">{{dados.description}}</p>
      </div>
      <footer style="display: flex; justify-content: center;">
        <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
    </footer>
    </section>


    <section *ngIf="dados.typeNotify == 'ProdutoAprovado'">
      <headear>
        <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
    </headear>
    <h1 style="text-align: center; margin-bottom: 20px;">NOTIFICAÇÃO</h1>
      <div style="display: flex; justify-content: center;">
        <p style="font-weight: 800;">{{dados.description}}</p>
      </div>
      <footer style="display: flex; justify-content: center;">
        <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
    </footer>
    </section>
 

    <section *ngIf="dados.typeNotify == 'recompensa'">
      <headear>
        <img src="https://ci4.googleusercontent.com/proxy/Eciek8Wlun5VMQ5pOBWwyFCbyaUEwBGoumdlTZT4EwU5vyieaL7KVWako5tq2FIgRI2DoDLDQUQ=s0-d-e1-ft#https://i.ibb.co/StL1KmQ/banner01.png" alt="">
    </headear>
    <h1 style="text-align: center; margin-bottom: 20px;">NOTIFICAÇÃO</h1>
      <div style="display: flex; justify-content: center;">
        <p style="font-weight: 800;">{{dados.description}}</p>
      </div>
      <footer style="display: flex; justify-content: center;">
        <img width="40%" src="https://ci3.googleusercontent.com/proxy/yqGgF7p3ziSpUxeg0AbOIZMxcjsFzTHrjLd9GJH7E5iwlnHF7vNhs6nOYfZa7MKm45Sd0hDysDRA_w0cscOCH75gw0n8kH1RyxO2lVkeQvoJ_3XZyWza08ExXVlLPrm1dCefuLzV4yGplmJGwb0WL2rg17fiQg=s0-d-e1-ft#https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="">
    </footer>
    </section>


</main>

