import { Component, OnInit, ViewChild } from "@angular/core";
import {
    PoModalAction,
    PoModalComponent,
    PoNotificationService,
    PoTableAction,
    PoTableColumn,
} from "@po-ui/ng-components";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeOfRewardService } from "../../service/type-of-reward.service";
import { ApprovalCoinsService } from "app/service/aproval-solic-coins.service";
import { UserService } from "app/service/user.service";
import { withLatestFrom } from "rxjs/operators";
import { AuthService } from "app/service/auth.service";
import { UserModel } from "app/models/user.model";
import { NotificationService } from "app/service/notification.service";
import { ChatPanelService } from "app/layout/components/chat-panel/chat-panel.service";

import { DisparaEmailService } from "app/service/dispara-email.service";
@Component({
    selector: "app-approval-reward",
    templateUrl: "./approval-reward.component.html",
    styleUrls: ["./approval-reward.component.scss"],
})
export class ApprovalRewardComponent implements OnInit {
    @ViewChild('modalDetails') modalDetails: PoModalComponent
    searchStatus = 1;
    searchMaxDate = new Date();
    endDate = <any>new Date();
    startDate = <any>new Date();
    p: Number = 0;
    itemPage: Number = 10;
    reactiveForm: FormGroup;
    userPerfil: UserModel;
    items: Array<any>;
    testeeee: any;
    dataDetails = {
        approvalFrom: '',
        titleOfReward: ''
    } as any
    credent: any;
    key: string = ''
    reverse: boolean = true;
 
    columns: Array<PoTableColumn> = [
        {
            property: "displayName",
            label: "Usuário",
            visible: true,
        },
        {
            property: "approvalFrom",
            label: "Natureza",
            visible: true,

        },
        {
            property: "titleOfReward",
            label: "Título da Recompensa",
            visible: true,
        },
        {
            property: "coins",
            label: "Estrelas",
            visible: true,
        },
        {
            property: "typeOfReward",
            label: "Tipo da Moeda",
            visible: true,
        },
        {
            property: "dateTimeStamp",
            label: "Data",
            visible: true,
            type: "date",
        },
        {
            property: "status",
            type: "label",
            width: "8%",
            labels: [
                { value: 2, color: "color-11", label: "Aprovado" },
                { value: 3, color: "color-07", label: "Reprovado" },
                { value: 1, color: "color-08", label: "Aguardando" },
            ],
        },
        
    ]

    actions: Array<PoTableAction> = [
        {
            action: this.handleApproveReward.bind(this),
            icon: "po-icon-ok",
            label: "Aprovar Recompensa",
            disabled: this.handleDisableItem.bind(this),
        },
        {
            action: this.handleDetailsReward.bind(this),
            icon: "po-icon-ok",
            label: "Detalhes",
            disabled: this.handleDisableItem.bind(this),
        },
        {
            action: this.handleReproveReward.bind(this),
            icon: "po-icon-close",
            label: "Reprovar Recompensa",
            disabled: this.handleDisableItem.bind(this),
        },
    ];
    
    constructor(
        private fb: FormBuilder,
        private service: ApprovalCoinsService,
        private userService: UserService,
        public auth: AuthService,
        private notifyService: NotificationService,
        private sendToGoogleChat: ChatPanelService,
        private email: DisparaEmailService,

    ) { }
    ngOnInit() {
        this.createReactiveForm();
        var date = new Date();
        date.setDate(date.getDate() - 15);
        this.startDate = date;
        this.endDate.setHours(23, 59, 59, 59);
        this.auth.user$.subscribe((credentials) => {
            this.userPerfil = credentials;
        });
         

        const refInitDate = new Date(this.startDate).getTime();
        const refEndDate = new Date(this.endDate).setHours(23, 59, 59, 59);
        this.service.getDataFiltred(this.searchStatus, refInitDate, refEndDate);
        this.service.onAprovalCoinsChanged.subscribe((response) => {
            let namesToString = ''
          

            if (response.length != 0){
                response.forEach(element => {
                   if (element.usersGroup != undefined){
                    element.usersGroup.filter((res) =>{
                        namesToString += `${res.displayName}, `
                    })
                   }
                })
            }

            response.displayName = namesToString
            this.items = response;

        });
    }
    
    closeModal: PoModalAction = {
        action: () => {
            this.reactiveForm.reset();
            this.modalDetails.close();
        },
        label: "Cancelar",
    };

    handleDetailsReward(item) {
       
       
       this.reactiveForm.controls["approvalFrom"].setValue(item.approvalFrom)
       this.reactiveForm.controls["titleOfReward"].setValue(item.titleOfReward)
       this.reactiveForm.controls["coins"].setValue(item.coins)
       if(item.usersGroup != undefined){
            this.reactiveForm.controls["usersGroup"].setValue(item.usersGroup.map((res) => { return ` ${res.displayName}`}))
       }
       this.reactiveForm.controls["displayName"].setValue(item.displayName)

      

       this.modalDetails.open()
    
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            approvalFrom: ["", Validators.required],
            titleOfReward: ["", Validators.required],
            coins: ["", Validators.required],
            usersGroup: [],
            displayName: [""]
        });

    }
    
    handleApproveReward(item) {
        
        if (item.usersGroup != undefined){

           
            item.usersGroup.forEach(element => {
                
                let data = {
                    userSelect: [
                        {
                            id: element.uid,
                            uid: element.uid,
                        },
                    ],
                    coinTypeChoice: "1",
                    valorSelect: item.coins,
                };
            
                data.coinTypeChoice = item.typeOfReward;
                
                if (item.typeOfReward == "Para Loja" || item.typeOfReward == "Para Recompensar") {
                    data.coinTypeChoice = item.typeOfReward == "Para Loja" ? "1" : "2"
                }
               
                this.userService.updateMoedas(false, data);
                
                const notify = {
                    dateTimestamp: new Date().getTime(),
                    description: `Super Admin recompensou ${item.displayName} com ${data.valorSelect} moedas. Motivo : ${item.titleOfReward}`,
                    uid: element.uid,
                    view: false,
                    eraseNotify: false,
                    typeNotify: 'recompensa'
                };
            
                this.userService.getMailById(element.uid).then(async (res) =>{
                    let emailTempl = {
                        template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                        <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                        <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Parabens pela Recompensa ${element.displayName}</p>
                        <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Voce recebeu ${data.valorSelect} Moedas, Motivo: ${item.titleOfReward}</p>
                        <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                        <br />
                        <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                        <br />
                        <p style="font: message-box;">Att. Administrador do sistema</p>
                        </div>`,
                        emailTitle: "Recompensa POINTZ"
                    } as any
    
                    setTimeout(() => {
                        this.email.sendEmailQuestApprove(res.data().email, emailTempl)
                    }, 1500);
    
                })
        
                this.notifyService.newNotification(notify)
            });
            item.status = 2
            this.service.update(item.id, item);

            //Ativar - desativar google chat
            this.sendToGoogleChat.sendRewardToGoogleChatSP(this.userPerfil, item)

        }else{
            const data = {
                userSelect: [
                    {
                        id: item.uid,
                        uid: item.uid,
                    },
                ],
                coinTypeChoice: "1",
                valorSelect: item.coins,
            };
        
            data.coinTypeChoice = item.typeOfReward;

            if (item.typeOfReward == "Para Loja" || item.typeOfReward == "Para Recompensar") {
                data.coinTypeChoice = item.typeOfReward == "Para Loja" ? "1" : "2"
            }
            item.status = 2
            this.userService.updateMoedas(false, data);
            this.service.update(item.id, item);
            const notify = {
                dateTimestamp: new Date().getTime(),
                description: `Super Admin recompensou ${item.displayName} com ${data.valorSelect} moedas. Motivo : ${item.titleOfReward}`,
                uid: item.uid,
                view: false,
                eraseNotify: false,
                typeNotify: 'recompensa'
            };
        
            this.userService.getMailById(item.uid).then(async (res) =>{
                let emailTempl = {
                    template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                    <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                    <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Parabens pela Recompensa ${item.displayName}</p>
                    <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Voce recebeu ${data.valorSelect} Moedas, Motivo: ${item.titleOfReward}</p>
                    <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                    <br />
                    <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                    <br />
                    <p style="font: message-box;">Att. Administrador do sistema</p>
                    </div>`,
                    emailTitle: "Recompensa POINTZ"
                } as any

                await this.email.sendEmailQuestApprove(res.data().email, emailTempl)

            })
           
            this.notifyService.newNotification(notify)
            
            //Ativar - desativar google chat
            this.sendToGoogleChat.sendRewardToGoogleChat(this.userPerfil, item.uid, data.valorSelect, notify.description)
           
           
        }
        
    }

    handleReproveReward(item) {
        item.status = 3;
        this.service.update(item.id, item);
    }

    handleDisableItem(item) {
        if (this.userPerfil.userPerfil !== "4") {
            return true;
        }
        if (item.status !== 1) {
            return true;
        }

        return false;
    }

    searchWithFilter() {
        
        var date = new Date(this.endDate);
        date.setDate(date.getDate() + 1);
        this.service.getDataFiltred(
            this.searchStatus,
            new Date(this.startDate).getTime(),
            new Date(date).setHours(23, 59, 59, 59)
        );
    }

    sort(key: any) {
     
        this.key = key.column.property
        this.reverse = !this.reverse;
    }
 
}
