import { NgModule } from '@angular/core';
import { ApprovalQuestsComponent } from './approval-quests.component';
import { ApprovalQuestsService } from 'app/service/approval-quests.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { PoAccordionModule, PoContainerModule, PoInfoModule, PoModalModule, PoTableModule, PoTagModule, PoFieldModule } from '@po-ui/ng-components';
import {NgxPaginationModule} from 'ngx-pagination'
import { OrderModule } from 'ngx-order-pipe';
const routes = [
    {
        path: 'approvalQuests',
        component: ApprovalQuestsComponent,
        resolve: {
            quests: ApprovalQuestsService
        }
    }
]

@NgModule({
    declarations: [
        ApprovalQuestsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,

        MatIconModule,
        NgxPaginationModule,
        //PO Modules
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoModalModule,
        PoTableModule,
        PoFieldModule,
        OrderModule
    ],
    exports: [
        ApprovalQuestsComponent
    ]
})

export class ApprovalQuestsModule { }
