import { Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from 'app/service/auth.service';
import { ThemeService } from 'app/service/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalNotifyComponent } from 'app/layout/components/modal-notify/modal-notify.component';
import { ModalUploadbannerComponent } from 'app/layout/modal-uploadbanner/modal-uploadbanner.component';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';

@Component({
    selector: 'fuse-theme-options',
    templateUrl: './theme-options.component.html',
    styleUrls: ['./theme-options.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseThemeOptionsComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    form: FormGroup;
    @ViewChild('teste') teste;
    @HostBinding('class.bar-closed')
    barClosed: boolean;
    cont = 0
    userPerfil: string

    themeDBId = ''
    savedTheme: any
    optionsSelectTop = [
        { label: "Em Aberto", value: false },
        { label: "Em Andamento", value: "2" },
        { label: "Aguardando Entrega", value: "3" },
        { label: "Entregue", value: true },
        { label: "Recusado", value: "4" }
    ];

    // Private
    private _unsubscribeAll: Subject<any>;
    poType: string;
    message: string;
    duration: number;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FormBuilder} _formBuilder
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Renderer2} _renderer
     * @param {ThemeService} themeDBservice
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _renderer: Renderer2,
        public auth: AuthService,
        private themeDBservice: ThemeService,
        public dialog: MatDialog,
        private poNotification: PoNotificationService
    ) {
        // Set the defaults
        this.barClosed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
   
        
        // Build the config form
        // noinspection TypeScriptValidateTypes
        this.form = this._formBuilder.group({
            colorTheme: new FormControl(),
            customScrollbars: new FormControl(),
            layout: this._formBuilder.group({
                style: new FormControl(),
                width: new FormControl(),
                navbar: this._formBuilder.group({
                    primaryBackground: new FormControl(),
                    secondaryBackground: new FormControl(),
                    folded: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    variant: new FormControl()
                }),
                toolbar: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                footer: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                sidepanel: this._formBuilder.group({
                    hidden: new FormControl(),
                    position: new FormControl()
                })
            })
        });
          
       


        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                // Update the stored config
                this.fuseConfig = config
                this.themeDBservice.getTheme().then((theme: any) => {
                   
                    if (theme.data().colorTheme == undefined){
                       
                        this.themeDBservice.insertTheme(this.fuseConfig).then((res) =>{
                        })
                    }else{
                     
                        this.fuseConfig = theme.data();
                    }
                })


                this.form.setValue(config, { emitEvent: false });
               
               
            });



       

        // Subscribe to the form value changes
         this.form.valueChanges
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe(async (config) => {

             // Update the config
            this._fuseConfigService.config = config;
     
         });


       

        this.auth.user$.subscribe(credentials => {
            if (credentials) {
                // Add customize nav item that opens the bar programmatically
                this.userPerfil = credentials.userPerfil;
                if (this.userPerfil == '2' || this.userPerfil == '1') {
                    const customFunctionNavItem = {
                        id: 'custom-function',
                        title: 'Custom Function',
                        type: 'group',
                        icon: 'settings',
                        children: [
                            {
                                id: 'customize',
                                title: 'Customize',
                                type: 'item',
                                icon: 'settings',
                                function: () => {
                                    this.toggleSidebarOpen('themeOptionsPanel');
                                }
                            }
                        ]
                    };

                    this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
                }
            }

        })

    }

    /**
     * On destroy
*/

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Remove the custom function menu
        this._fuseNavigationService.removeNavigationItem('custom-function');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset the form values based on the
     * selected layout style
     *
     * @param value
     * @private
     */
    private _resetFormValues(value): void {
        switch (value) {
            // Vertical Layout #1
            case 'vertical-layout-1':
                alert('alterado')
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'below-static'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'below-static'
                            },
                            sidepanel: {
                                hidden: false,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Vertical Layout #2
            case 'vertical-layout-2':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'below'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'below'
                            },
                            sidepanel: {
                                hidden: false,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Vertical Layout #3
            case 'vertical-layout-3':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'left',
                                layout: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'above-static'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'above-static'
                            },
                            sidepanel: {
                                hidden: false,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }

            // Horizontal Layout #1
            case 'horizontal-layout-1':
                {
                    this.form.patchValue({
                        layout: {
                            width: 'fullwidth',
                            navbar: {
                                primaryBackground: 'fuse-navy-700',
                                secondaryBackground: 'fuse-navy-900',
                                folded: false,
                                hidden: false,
                                position: 'top',
                                variant: 'vertical-style-1'
                            },
                            toolbar: {
                                background: 'fuse-white-500',
                                customBackgroundColor: false,
                                hidden: false,
                                position: 'above'
                            },
                            footer: {
                                background: 'fuse-navy-900',
                                customBackgroundColor: true,
                                hidden: false,
                                position: 'above-fixed'
                            },
                            sidepanel: {
                                hidden: false,
                                position: 'right'
                            }
                        }
                    });

                    break;
                }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }


    uploadImage(){
        const dialogRef = this.dialog.open(ModalUploadbannerComponent, {
            width: '50%',
            height: '80%'
          });
      
          dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
          });
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }

    saveColors(){
        
        try {
            this.themeDBservice.updateTheme(this.form.value)
            this.message = "Alterações feitas com sucesso"
            this.duration = 5000
            this.showNotification('success')
        } catch (error) {
            this.message = error.message
            this.duration = 5000
            this.showNotification('error')
        }
        
    }

}
