import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'app/service/auth.guard';
import { MySalesComponent } from './my-sales.component';
import { MySalesService } from 'app/service/my-sales.service';
import { RouterModule } from '@angular/router';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';

const routes = [
  {
      path: "mysales",
      component: MySalesComponent,
      resolve: {
        mysales: MySalesService
    }
  },
];

@NgModule({
  declarations: [MySalesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule,
    FuseSharedModule,
    MatIconModule
  ]
})
export class MySalesModule { }
