import { Component, OnInit, ViewChild } from "@angular/core";
import {
    PoModalAction,
    PoModalComponent,
    PoNotification,
    PoNotificationService,
    PoTableAction,
} from "@po-ui/ng-components";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeOfRewardModel } from "app/Models/TypeOfReward.model";
import { TypeOfRewardService } from "../../service/type-of-reward.service";
import { DomainCrudService } from "app/service/domain-crud.service";
@Component({
    selector: "autentication-crud",
    templateUrl: "./autentication-crud.component.html",
    styleUrls: ["./autentication-crud.component.scss"],
})
export class AutenticationCrudComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private domainsService: DomainCrudService,
        private poNotification: PoNotificationService
    ) {}
    listaDominios;
    listaExcecoes;
    excecaoIdDelete;
    actions: Array<PoTableAction> = [
        { action: this.editItem.bind(this), label: "Editar" },
        { action: this.deleteItem.bind(this), label: "Excluir" },
    ];
    actionsExcecao: Array<PoTableAction> = [
        { action: this.editItemExcecao.bind(this), label: "Editar" },
        { action: this.deleteItem.bind(this), label: "Excluir" },
    ];
    columns = [
        { property: "title", label: "Dominio", visible: true },
    ];
    columnsExcecao = [
        { property: "nome", label: "Nome", visible: true },
        { property: "email", label: "Email", visible: true },
    ];
    reactiveForm: FormGroup;
    reactiveFormExcecao: FormGroup;
    //Po Notification
    message: string;
    id: string;
    duration = 2500;
    poType: string;
    @ViewChild(PoModalComponent, { static: true }) modal: PoModalComponent;
    @ViewChild('modalExcecao', { static: true }) modalExcecao: PoModalComponent;
    @ViewChild('deleteConfimation', { static: true }) deleteConfimation: PoModalComponent;

    ngOnInit(): void {
        this.createReactiveForm();
        this.createReactiveFormExcecao();
        this.domainsService.getDomains();

        this.domainsService.onTypeOfDomainChanged.subscribe((response) => {
            this.listaDominios = response.filter((user) =>{
                return user.isExcecao != true;
            })

            this.listaExcecoes = response.filter((user) =>{
                return user.isExcecao == true;
            })
        });
    }
    editItem(item) {
        this.id = item.id;
        this.reactiveForm.controls["title"].setValue(item.title);
        this.modal.open();
    }

    editItemExcecao(item) {
        this.id = item.id;
        this.reactiveFormExcecao.controls["email"].setValue(item.email);
        this.reactiveFormExcecao.controls["nome"].setValue(item.nome);
        this.modalExcecao.open();
    }
    deleteItem(item) {
        
        this.excecaoIdDelete = item.id
        this.deleteConfimation.open()
        
    }
    DeleteOK: PoModalAction = {
        action: () => {
            if(this.excecaoIdDelete){
                this.domainsService.deleteDomain(this.excecaoIdDelete)
                this.excecaoIdDelete = null
                this.deleteConfimation.close()
            } 
        },
        label: "Ok"
        
    }
    DeleteDeny: PoModalAction = {
        action: () => {
            if(this.excecaoIdDelete){
                this.excecaoIdDelete = null
            } 
            this.deleteConfimation.close()
        },
        label: "Cancelar"
        
    }
    clearForm() {
        this.reactiveForm.reset();
        this.reactiveFormExcecao.reset()
        this.reactiveForm = this.fb.group({
            title: [""],
        });
        this.reactiveFormExcecao = this.fb.group({
            nome: [""],
            email: [""],
        });
        this.id = "";
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            title: ["", Validators.required],
        });
    }
    createReactiveFormExcecao() {
        this.reactiveFormExcecao = this.fb.group({
            nome: ["", Validators.required],
            email: ["", Validators.required],
        });
    }
    SaveAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveForm.value;
            if (this.reactiveForm.valid) {
                if (!this?.id?.length) {
                    this.domainsService.createDomain(fields);
                    this.message = "Dominio cadastrado com sucesso";
                } else {
                    this.domainsService.updateDomain(this.id, fields);
                    this.message = "Dominio alterado com sucesso";
                }
                
                //clear the modal form
                this.poType = "sucess";
                this.modal.close();
                this.modalExcecao.close();
                this.showNotification(this.poType);
            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
            this.clearForm();
        },
        label: "Salvar",
    };
    CloseAction: PoModalAction = {
        action: () => {
            this.clearForm();
            this.modal.close();
        },
        label: "Cancelar",
    };

    SaveExcecaoAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveFormExcecao.value;
            fields.isExcecao = true;
            if (this.reactiveFormExcecao.valid) {
                if (!this?.id?.length) {
                    this.domainsService.createDomain(fields);
                    this.message = "Excecao cadastrada com sucesso";
                } else {
                    this.domainsService.updateDomain(this.id, fields);
                    this.message = "Excecao alterada com sucesso";
                }

                //clear the modal form
                this.poType = "sucess";
                this.modal.close();
                this.modalExcecao.close();
                this.showNotification(this.poType);
            } else {
                this.message = "Preencha o formulário corretamente";
                this.poType = "error";
                this.showNotification(this.poType);
            }
            this.clearForm();
        },
        label: "Salvar",
    };
    CloseExcecaoAction: PoModalAction = {
        action: () => {
            this.clearForm();
            this.modalExcecao.close();
        },
        label: "Cancelar",
    };
    showNotification(messageType) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
}
