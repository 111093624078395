
<div class="titulo-timeline">
    <h3 class="po-text-center">
        <strong>Timeline</strong>
    </h3>
</div>
<div class="timeline-area" id="timeline-area">
    <div class="card-timeline" *ngFor="let item of itemTimeline">
        <div class="po-text-vertical-center">
            <img src="{{item.img}}" class="avatar-timeline avatar mr-0 mr-sm-16">
            <span class="username mr-12" >{{item.nome}}</span>
        </div>
        <hr>
        <div class="po-text-center hlp-font-italic">
            <strong>"{{item.mensagem}}"</strong>
        </div>
        <div class="po-text-center">
            Doou <strong>{{item.valor}}</strong> estrelas para <strong>{{item.destinatario}}</strong>
        </div>
        <div class="po-text-right" style="margin-top: 10px; font-size: 10px;">
            <p>02/03/2021 as 10:35</p>
        </div>
    </div>
</div>
<div class="text-area-timeline">
    <div class="po-row">
        <div class="po-lg-10">
            <!-- <input type="text" mentio mentio-typed-text = "typedTerm" mentio-items="usersTimeline | filter:label:typedTerm" ng-model="myval"/> -->
            <h3 class="po-field-title">Enviar Estrelas!</h3>
            <textarea class="text-area-custom" #timeLine name="textarea" p-auto-focus="true" p-label="Enviar Estrelas"></textarea>
        </div>
        <div class="po-lg-2">
            <po-button class="button-send" (click)="sendValue(timeLine.value)" p-label="Enviar"></po-button>
        </div>
    </div>
</div>