import { NgModule } from '@angular/core';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { PoButtonModule, PoFieldModule } from '@po-ui/ng-components';

import { ShoppingCartComponent } from './shopping-cart.component';

@NgModule({
    declarations: [
        ShoppingCartComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatIconModule,

        FuseSharedModule,

        PoFieldModule,
        PoButtonModule
    ],
    exports: [
        ShoppingCartComponent
    ]
})

export class ShoppingCartModule { }
