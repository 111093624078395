
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">attach_money</mat-icon> Minhas Vendas
                    </h2>
                </div>
                <!-- <div class="po-lg-4">  
                    <po-button  p-label="Desativar Loja Colaboradores" p-icon="po-icon po-icon-plus" p-type="Primary" (p-click)="openNewModal() " class="pull-right"> </po-button>   
                </div> -->
            </div>
            <hr>
            <div class="po-row">
                <po-table [p-items]="listSales" [p-striped]="true" class="po-table-coins" [p-actions]="actions" [p-columns]="columns"></po-table>
            </div>
            <po-modal #configStore p-click-out="false" p-title="Configurações" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction">
                <form [formGroup]="reactiveForm">
                    
                </form>
            </po-modal> 
        </div>        
    </po-container>
</div>