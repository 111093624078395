

<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01" *ngFor="let item of user">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">account_circle</mat-icon> Meu Perfil
                    </h2>
                </div>
                <div class="po-lg-4">
                    <po-button (click)="editUser(item)" p-icon="po-icon-edit" p-type="primary" class="ng-star-inserted pull-right" p-label="Editar"></po-button>
                </div>
            </div>
            <hr>
            <div class="po-row">
                <div class="po-lg-2 po-md-2 hlp-no-padding-left">
                    <img [src]="item.avatar" class="hlp-img-thumbnail">
                </div>
                <div class="po-lg-10 po-md-10">
                    <div class="po-row">
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="Nome" [p-value]="item.primeiroNome"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="Sobrenome" [p-value]="item.segundoNome"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="Nickname" [p-value]="item.displayName"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="E-mail" [p-value]="item.email"> </po-info>
                        </div>
                    </div>
                    <hr>
                    <div class="po-row">
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="Data de Nascimento" [p-value]="item.datadeNascimento | date: 'dd/MM/yyyy'"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-3 po-lg-3">
                            <po-info p-label="Data de Cadastro" [p-value]="item.datadeCadastro.toDate() | date: 'dd/MM/yyyy'"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-6 po-lg-3">
                            <po-info p-label="Perfil" [p-value]="item.perfil"> </po-info>
                        </div>
                        <div class="po-sm-12 po-md-6 po-lg-3">              
                            <po-tag class="po-md-6 po-lg-3" p-inverse p-label="Status" p-type="success" [p-value]="item.status"></po-tag>
                        </div>
                    </div>
                    <hr>
                    <div class="po-row">
                        <div class="po-lg-3 po-md-6">
                            <div class="po-info">
                                <div class="po-info-container-title">
                                    <span class="po-info-label"> Moeda de Compra </span>
                                </div>
                                <div class="po-info-container-content">
                                    <span class="po-info-value ng-star-inserted hlp-display-flex">
                                        <mat-icon class="secondary-text" style="color: #ffb300;">star_rate</mat-icon> {{item.cash? item.cash : 0}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="po-lg-3 po-md-6">
                            <div class="po-info">
                                <div class="po-info-container-title">
                                    <span class="po-info-label"> Moeda de Troca </span>
                                </div>
                                <div class="po-info-container-content">
                                    <span class="po-info-value ng-star-inserted hlp-display-flex">
                                        <mat-icon class="secondary-text" style="color: #11c7c7;">star_rate</mat-icon> {{item.trade? item.trade : 0}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </po-container>
</div>  

<po-modal #editUserModal p-click-out="false" p-title="Editar Usuário" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction">
    <form [formGroup]="reactiveForm">
        <div class="po-row">
            <po-input class="po-sm-12 po-md-6" formControlName="firstName" p-clean p-icon="po-icon-user" p-label="Nome"> </po-input>
            <po-input class="po-sm-12 po-md-6" formControlName="lastName" p-clean p-icon="po-icon-user" p-label="Sobrenome"> </po-input>
        </div>
        <div class="po-row">
            <po-datepicker class="po-sm-12 po-md-6" formControlName="birthday" p-label="Data de Nascimento" p-clean p-format="dd/mm/yyyy"></po-datepicker>
            <po-input class="po-sm-12 po-md-6" formControlName="displayName" p-clean p-icon="po-icon-user" p-label="Nickname" p-disabled="false"> </po-input>
        </div>
        <div class="po-row" style="display: flex; flex-direction: column; width: 95%; text-align: center; margin: 0 auto; gap: 10px; margin-top: 10px;">
            <!--Po upload necessita de backend, pois precisa passar a url de requisicao em p-url -->
            <!-- <po-upload class="po-lg-12" (p-upload)="uploadImage($event)" formControlName="image" p-drag-drop-height="150" p-label="Avatar" p-drag-drop="true" p-url="/"> </po-upload>   -->
            <p style="font-weight: 800;">Avatar</p>
            <button for="uploadFile" class="po-button po-text-ellipsis" (click)="uploadImage()"  style="margin-bottom: 20px;">
                <span aria-hidden="true" class="po-icon po-icon-plus ng-star-inserted"></span>
                <span class="po-button-label ng-star-inserted">Adicionar avatar</span>
                <input id="uploadFile"  #fileInput style="display: none;" type="file" (change)="showUploadImage($event)">
            </button>
        </div>
        
        <div class="po-row"  *ngIf="srcImage?.length" style="width: 150px; margin: 0 auto; padding: 15px; margin-bottom: 10px;">
            <p style="font-weight: 800; margin: 0 auto;">Preview do avatar</p>
            <img width="150px" height="150px" [src]="srcImage" class="hlp-img-thumbnail">
            <button style="margin: 0 auto; margin-top: 10px; width: 100%;" class="po-button po-text-ellipsis" (click)="uploadAvatar()"> Upload </button>
        </div>
    </form>
</po-modal>
<div *ngIf="load == false" style="z-index: 9999; position: fixed; top: 0; left: 0; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, 0.1); display: flex; align-items: center; justify-content: center;">
    <!-- <img width="80px" src="/assets/images/logos/Logo01.png" class="lds-circle"> -->
    <div class="animation-container">
        <div class="lightning-container">
            <div class="lightning white"></div>
            <div class="lightning red"></div>
        </div>
        <div class="boom-container">
            <div class="shape circle big white"></div>
            <div class="shape circle white"></div>
            <div class="shape triangle big yellow"></div>
            <div class="shape disc white"></div>
            <div class="shape triangle blue"></div>
        </div>
        <div class="boom-container second">
            <div class="shape circle big white"></div>
            <div class="shape circle white"></div>
            <div class="shape disc white"></div>
            <div class="shape triangle blue"></div>
        </div>
    </div>
</div>