import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PurchasesModel } from 'app/models/purchases.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class PurchasesService implements Resolve<any> {

    purchases: any[];
    purchasesCol: any[];
    routeParams: any;
    userId: string;

    onPurchasesChanged: BehaviorSubject<any>;
    onPurchasesChangedColla: BehaviorSubject<any>;
    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) { 
        this.onPurchasesChanged = new BehaviorSubject([]); 
        this.onPurchasesChangedColla = new BehaviorSubject([]);
    }   

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getPurchases(),
                this.getPurchasesColla()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }
    
    async getPurchaseById(id: string){
        return this.firestore.collection(`${datastore}/purchases`).doc(id.toString()).get().toPromise()
    }

    async getPurchaseByIdCol(id: string){
        return this.firestore.collection(`${datastore}/purchasesCollaborator`).doc(id.toString()).get().toPromise()
    }



    getPurchases(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/purchases`, ref => ref.where('userId', '==', credentials.uid)).snapshotChanges().subscribe(action => {
                        this.purchases = action.map(item => {
                            return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as PurchasesModel),
                                favorite: ['detalhes']
                            } as PurchasesModel
                        });
                        this.onPurchasesChanged.next(_.orderBy(this.purchases, ['purchaseDate'],['desc']));
                        resolve(this.purchases);
                    });
                }                
            });             
        })
    }


    getPurchasesColla(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/purchasesCollaborator`, ref => ref.where('userId', '==', credentials.uid)).snapshotChanges().subscribe(action => {
                        this.purchasesCol = action.map(item => {
                            return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as PurchasesModel),
                                favorite: ['detalhes']
                            } as PurchasesModel
                        });
                        this.onPurchasesChangedColla.next(_.orderBy(this.purchasesCol, ['purchaseDate'],['desc']));
                        resolve(this.purchasesCol);
                    });
                }                
            });             
        })
    }
}
