import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PoModalAction, PoModalComponent, PoNotification, PoNotificationService } from '@po-ui/ng-components';
import { UserModel } from 'app/models/user.model';
import { AuthService } from 'app/service/auth.service';

import { UserService } from 'app/service/user.service';


@Component({
    selector: 'perfil',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.scss'],
    providers: [PoNotificationService]
})

export class PerfilComponent implements OnInit {

    @ViewChild(PoModalComponent, { static: true }) editUserModal: PoModalComponent;

    reactiveForm: FormGroup;
    user = []  
    userLogged: UserModel;
    userId: any;
    @ViewChild('fileInput') fileInput: ElementRef;
    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    srcImage: any
    load: boolean;

    constructor(
        private fb: FormBuilder,
        private service: UserService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
    ){ 
        auth.user$.subscribe(credentials =>{
            this.userId = credentials.uid;
        })
        this.createReactiveForm();
        this.service.onloggedUserChanged.subscribe(users => {
            this.userLogged = users  
            this.user = [
                {
                id: '18', 
                primeiroNome: this.userLogged.firstName  == undefined? '': this.userLogged.firstName , 
                segundoNome: this.userLogged.lastName == undefined? '': this.userLogged.lastName, 
                datadeNascimento: this.userLogged.birthday == undefined? '': this.userLogged.birthday.toLocaleString(),  
                displayName: this.userLogged.displayName, 
                email: this.userLogged.email, 
                status: this.userLogged.ativo ? 'Ativo': "Inativo", 
                datadeCadastro: this.userLogged.registerdate,
                perfil: this.getPerfilName(this.userLogged.userPerfil), 
                avatar: this.userLogged.photoURL,
                cash: this.userLogged.cash,
                trade: this.userLogged.trade
            }]
        });
       
    }

    ngOnInit(): void {
       
    }
  

    ngAfterViewInit(): void{
        
    }

    getPerfilName(perfilNumber){
        switch (perfilNumber) {
            case "1":
                return "Admin"
            case "2":
                return "Staff"
            case "3":
                return "User"
                break;
    
            default:
                return "User";
        }
    }
    
    SaveAction: PoModalAction = {
        action: () => {
            var fields = this.reactiveForm.value;
            let saveProfile= {} as UserModel;
            saveProfile.firstName = fields.firstName
            saveProfile.displayName = fields.displayName
            saveProfile.lastName = fields.lastName
            saveProfile.birthday = fields.birthday
            if (this.reactiveForm.valid) {
                this.service.updateUser(this.userId, saveProfile)
                this.message = 'Usuário alterado com sucesso!'
                this.poType = 'sucess'
                this.editUserModal.close();
            } else {
                this.poType = 'error'
                this.message = 'Preencha o formulário corretamente'
            }    
            this.showNotification(this.poType)
        },
        label: 'Salvar',
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.editUserModal.close();
        },
        label: 'Cancelar'
    };

    editUser(item) {
        this.reactiveForm.controls['firstName'].setValue(item.primeiroNome);
        this.reactiveForm.controls['lastName'].setValue(item.segundoNome);
        this.reactiveForm.controls['displayName'].setValue(item.displayName);
        if(item.datadeNascimento){
            let dob = new Date(item.datadeNascimento)
            this.reactiveForm.controls['birthday'].setValue(new Date(dob.setDate(dob.getDate() + 1)));
        } 
        this.editUserModal.open();
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            firstName: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            lastName: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            displayName: [''],
            birthday: [new Date()],
        });
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1
        }
        switch (messageType) {
            case 'success': {
                this.poNotification.success(poNotification);
                break;
            }
            case 'error': {
                this.poNotification.error(poNotification);
                break;
            }
            case 'warning': {
                this.poNotification.warning(poNotification);
                break;
            }
            case 'information': {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }

        }
        this.poType = ''
    }


    uploadImage(){
        let uploadButton: HTMLElement = document.getElementById('uploadFile') as HTMLElement;
        uploadButton.click();
    }

    showUploadImage(file){
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
            this.srcImage = (<FileReader>event.target).result;
        }    
        reader.readAsDataURL(file.target.files[0]);
      }

     
    async uploadAvatar(){
        this.load = false
        // console.log(this.fileInput.nativeElement.files)
        await (await this.service.uploadImage(this.fileInput.nativeElement.files, this.userId)).toPromise().then((res)=>{
          setTimeout(() => {
            this.load = true
            this.message = `Upload do avatar com sucesso !!!`
            this.poType = "success";
            this.showNotification(this.poType);
           
          }, 1000);
        }).catch((error) =>{
            this.load = true
            this.message = `Upload do avatar falhou !!!`
            this.poType = "error";
            this.showNotification(this.poType);
        })
    }

}
