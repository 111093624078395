import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreConfigurationComponent } from './store-configuration.component';
import { RouterModule } from '@angular/router';
import { StoreConfigurationServiceService } from 'app/service/store-configuration.service';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';


const routes = [
  {
      path: "storeparams",
      component: StoreConfigurationComponent,
      resolve: {
        storeConfig: StoreConfigurationServiceService
    }
  },
];

@NgModule({
  declarations: [StoreConfigurationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule,
    MatIconModule
  ]
})
export class StoreConfigurationModule { }
