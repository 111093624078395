import { Component, Inject, OnInit } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { AuthService } from 'app/service/auth.service'
import { UserService } from 'app/service/user.service'
import Post from '../model-post'
import { TimelineService } from '../timeline.service'

@Component({
  selector: 'modal-accept',
  templateUrl: 'modal.component.html',
})
export class ModalConfirmComent implements OnInit {
  constructor(
    private databaseService: TimelineService,
    public matDialogRef: MatDialogRef<ModalConfirmComent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {}
  userData
  comment = ''
  post: Post
  ngOnInit() {
    this.userData = this._data.userData
    this.post = this._data.post
  }

  handleSaveComment() {
    const dataPost = { ...this.post }
    dataPost.comments.push({
      message: this.comment,
      time: `${new Date().toLocaleDateString()} as ${new Date().toLocaleTimeString()}`,
      dateNow: new Date(),
      user: {
        avatar: this.userData.photoURL,
        name: this.userData.displayName,
      },
    })
    try {
      this.databaseService.setDoc(dataPost)
    } catch (error) {
      console.error(error)
    }
  }
}
