import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoinsModel } from 'app/models/coins.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class ThemeService implements Resolve<any> {

    theme: any;

    routeParams: any;

    formData: CoinsModel;

    onThemeChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore
    ) { 
        this.onThemeChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getTheme(),
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    async getTheme(){
        return this.firestore.doc(datastore).collection('pageLayout').doc('stylepage').get().toPromise()
    }
 

    async insertTheme(data: any) {
        return this.firestore.doc(datastore).collection('pageLayout').doc('stylepage').set(data)
    }

    updateTheme(data: any){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/pageLayout/stylepage`).update(data);
        })       
    }
  
}
