import { Component, OnInit } from '@angular/core';
import { MyQuestsService } from 'app/service/myquests.service';

@Component({
  selector: 'myquests',
  templateUrl: './myquests.component.html',
  styleUrls: ['./myquests.component.scss']
})
export class MyQuestsComponent implements OnInit {

    public quests: string[] = [];

    constructor(
        private service: MyQuestsService
    ) { }

    ngOnInit(): void { 
        this.service.onQuestsChanged.subscribe(quests => {
            this.quests = quests            
        })
    } 
    ngAfterViewInit(): void{
        
    }
}
