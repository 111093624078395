<section>
    <h1 style="text-align: center; font-weight: 800;">Banners DASHBOARD</h1>
    <div class="main">
        <div class="upHeader">
           
            <p style="font-weight: 800; font-size: 20px;">Banners do Top</p>

            <select #valueImage (change)="selectImageTop($event.target.value)" class="selection po-input" name="" id="">
                <option>Escolha a imagem</option>
                <option value="0">Foto 1</option>
                <option value="1">Foto 2</option>
                <option value="2">Foto 3</option>
            </select>

            <div>
                <button for="uploadFile" class="po-button po-text-ellipsis" (click)="initUpload()"  style="margin-bottom: 20px;">
                    <span aria-hidden="true" class="po-icon po-icon-plus ng-star-inserted"></span>
                    <span class="po-button-label ng-star-inserted">Adicionar Imagem</span>
                    <input id="uploadFile" #fileInputTop style="display: none;" type="file" (change)="showUploadImage($event)">
                </button>
            </div>
            <button style="margin-bottom: 20px;" class="po-button po-text-ellipsis" (click)="uploadImageTop()"> Upload </button>

            <div *ngIf="load3 == false" style="position: absolute; top: 0; left: 0; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, 0.8); display: flex; align-items: center; justify-content: center;">
                <!-- <img width="80px" src="/assets/images/logos/Logo01.png" class="lds-circle"> -->
                <div class="animation-container">
                    <div class="lightning-container">
                        <div class="lightning white"></div>
                        <div class="lightning red"></div>
                    </div>
                    <div class="boom-container">
                        <div class="shape circle big white"></div>
                        <div class="shape circle white"></div>
                        <div class="shape triangle big yellow"></div>
                        <div class="shape disc white"></div>
                        <div class="shape triangle blue"></div>
                    </div>
                    <div class="boom-container second">
                        <div class="shape circle big white"></div>
                        <div class="shape circle white"></div>
                        <div class="shape disc white"></div>
                        <div class="shape triangle blue"></div>
                    </div>
                </div>
            </div>

            <div class="po-row">
                <div class="po-lg-12 po-text-center" *ngIf="srcImage?.length">
                    <h1>Preview da nova imagem</h1>
                    <img width="150px" [src]="srcImage" class="hlp-img-thumbnail">
                </div>
            </div>

            <div>
                <div *ngIf="previewTopImage?.length">
                    <h1>Preview da imagem existente</h1>
                    <img [src]="previewTopImage" width="70%" alt="">
                </div>
                <div class="spinner" *ngIf="load === false">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
            </div>
        </div>

        <div class="upLat">
           
            <p style="font-weight: 800; font-size: 20px;">Banners da Lateral</p>

            <select (change)="selectImageLat($event.target.value)" class="selection po-input" name="" id="">
                <option>Escolha a imagem</option>
                <option value="0">Foto 1</option>
                <option value="1">Foto 2</option>
                <option value="2">Foto 3</option>
            </select>

            <div>
                <button for="uploadFileLat" class="po-button po-text-ellipsis" (click)="initUploadLat()" style="margin-bottom: 20px;">
                    <span aria-hidden="true" class="po-icon po-icon-plus ng-star-inserted"></span>
                    <span class="po-button-label ng-star-inserted">Adicionar Imagem</span>
                    <input id="uploadFileLat" #fileInputLat style="display: none;" type="file" (change)="showUploadImageLat($event)">
                </button>
            </div>

         

            <button style="margin-bottom: 20px;" class="po-button po-text-ellipsis" (click)="uploadImageLat()"> Upload </button>

            <div *ngIf="load4 == false" style="position: absolute; top: 0; left: 0; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, 0.8); display: flex; align-items: center; justify-content: center;">
                <!-- <img width="80px" src="/assets/images/logos/Logo01.png" class="lds-circle"> -->
                <div class="animation-container">
                    <div class="lightning-container">
                        <div class="lightning white"></div>
                        <div class="lightning red"></div>
                    </div>
                    <div class="boom-container">
                        <div class="shape circle big white"></div>
                        <div class="shape circle white"></div>
                        <div class="shape triangle big yellow"></div>
                        <div class="shape disc white"></div>
                        <div class="shape triangle blue"></div>
                    </div>
                    <div class="boom-container second">
                        <div class="shape circle big white"></div>
                        <div class="shape circle white"></div>
                        <div class="shape disc white"></div>
                        <div class="shape triangle blue"></div>
                    </div>
                </div>
            </div>



            <div class="po-row">
                <div class="po-lg-12 po-text-center" *ngIf="srcImageLat?.length">
                    <h1>Preview da nova imagem</h1>
                    <img width="150px" [src]="srcImageLat" class="hlp-img-thumbnail">
                </div>
            </div>

            <div>
                <div *ngIf="previewLatImage?.length">
                    <h1>Preview da imagem existente</h1>
                    <img [src]="previewLatImage" width="70%" alt="">
                </div>
                
                <div class="spinner" *ngIf="load2 === false">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
            </div>


        </div>
    </div>
</section>
