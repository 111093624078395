

<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-12">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">assignment_turned_in</mat-icon> Quests para
                        Aprovação
                    </h2>
                </div>
            </div>
            <section class="sectionFilter">
                <div class="masterSearch">
                    <div class="btnBox">
                        <button class="buttonExcell" (click)="downloadEx()"><img src="/assets/icons/exceldown/excelIcon.png" alt=""></button>
                    </div>
                    <div class="search">
                        <po-input p-label="Nome ou Status" class="inputSearch" name="nome" id="nome" [(ngModel)]="nome" (ngModelChange)="filterName()"   type="text"></po-input>
                    </div>
                    <div class="startDate">
                        <po-datepicker name="startDate" [(ngModel)]="startDate" (ngModelChange)="filterName()"    p-format="dd/mm/yyyy" p-label="Data Inicio">
                        </po-datepicker>
                    </div>
                    <div>
                        <po-datepicker name="endDate" [(ngModel)]="endDate" (ngModelChange)="filterName()"  p-format="dd/mm/yyyy" p-label="Data Fim">
                        </po-datepicker>
                    </div>
                </div>
            </section>
            <hr>
            <div class="po-row">
                <po-table p-sort="true" [p-items]="listQuests | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p}" (p-sort-by)="sort($event)" [p-striped]="true" [p-actions]="actions"
                    [p-columns]="columns" class="po-table-quests">
                    <ng-template p-table-column-template [p-property]="'sendDate'" let-value>
                        <div class="badge {{ value }}">{{ value.toDate() | date: 'dd/MM/yyyy h:mm a' }}</div>
                    </ng-template>
                </po-table>
                <pagination-controls previousLabel="Voltar" nextLabel="Proximo" (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </po-container>
</div>

<po-modal #questApprovalModal p-click-out="false" p-title="Avaliar Resultado" [p-primary-action]="ApprovalAction"
    [p-secondary-action]="NoApprovalAction">
    <div *ngIf="quest">
        <div class="po-row">
            <div class="po-lg-8 po-text-center">
                <po-info p-label="Colaborador" [p-value]="quest.userName"> </po-info>
            </div>
            <div class="po-lg-4 po-text-center">
                <po-info p-label="Data de Envio" [p-value]="quest.sendDate.toDate() | date: 'dd/MM/yyyy h:mm a'">
                </po-info>
            </div>
        </div>
        <hr>
        <div class="po-row">
            <div class="po-lg-4 po-text-center">
                <po-info p-label="Quest" [p-value]="quest.questName"> </po-info>
            </div>
            <div class="po-lg-3 po-text-center">
                <po-info p-label="Inicio da Quest" [p-value]="quest.questStartDate | date: 'dd/MM/yyyy'"> </po-info>
            </div>
            <div class="po-lg-3 po-text-center" *ngIf="quest.questEndDate != ''">
                <po-info p-label="Fim da Quest" [p-value]="quest.questEndDate | date: 'dd/MM/yyyy'"> </po-info>
            </div>
            <div class="po-lg-3 po-text-center" *ngIf="quest.questEndDate == ''">
                <po-info p-label="Fim da Quest" p-value="Sem data Fim"> </po-info>
            </div>
            <div class="po-lg-2 po-text-center">
                <div class="po-info">
                    <div class="po-info-container-title">
                        <span class="po-info-label"> Recompensa </span>
                    </div>
                    <div class="po-info-container-content">
                        <span class="po-info-value ng-star-inserted po-text-vertical-center">
                            {{quest.reward}} <mat-icon class="secondary-text" style="color: #ffb300;">star_rate
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="po-row">
            <div class="po-lg-12">
                <!-- <po-info p-label="Instruções" [p-value]="quest.questInstruction"> </po-info> -->
                <div [innerHtml]="quest.questInstruction"></div>
            </div>
        </div>
        <hr>
        <div class="po-row">
            <div class="po-lg-12">
                <po-info *ngIf="quest.questAnswer" p-label="Resposta do colaborador" [p-value]="quest.questAnswer">
                </po-info>
            </div>
        </div>
        <hr>
        <div class="po-row">
            <div class="po-lg-12 po-text-center">
                <span *ngIf="quest.images[0] != 'nulo'" class="po-info-label"> Imagem enviada pelo colaborador </span> <br>
                <img class="hlp-img-thumbnail" [src]="quest.images[0].imgLink">
            </div>
        </div>
    </div>
</po-modal>