import { NgModule } from '@angular/core';

import { Login2Module } from './login-2/login-2.module';
import { Register2Module } from './register-2/register-2.module';
import { ForgotPassword2Module } from './forgot-password-2/forgot-password-2.module';
import { ResetPassword2Module } from './reset-password-2/reset-password-2.module';

@NgModule({
    imports: [
        Login2Module,
        Register2Module,
        ForgotPassword2Module,
        ResetPassword2Module,
    ]
})
export class AuthModule
{

}
