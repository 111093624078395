import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { PerfilComponent } from './perfil.component';
import { PoButtonModule, PoContainerModule, PoFieldModule, PoInfoModule, PoModalModule, PoTagModule } from '@po-ui/ng-components';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from 'app/service/user.service';
import { AuthGuard } from 'app/service/auth.guard';

const routes = [
    {
        path: 'perfil',
        component: PerfilComponent,
        resolve: {
            users: UserService
        },
        canActivate: [AuthGuard]
    }
]

@NgModule({
    declarations: [
        PerfilComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,

        MatIconModule,

        //PO Modules
        PoContainerModule,
        PoFieldModule,
        PoModalModule,
        PoButtonModule,
        PoInfoModule,
        PoTagModule
    ],
    exports: [
        PerfilComponent
    ]
})

export class PerfilModule { }
