

<div id="register" class="inner-scroll" style="height: 100%" fxLayout="row" fxLayoutAlign="start">

    <div id="register-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <!-- <img src="assets/images/logos/gestor_logo_trans.png"> -->
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
        </div>

    </div>

    <div id="register-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="register-form">

            <div class="logo" fxHide.gt-xs>
                <!-- <img src="assets/images/logos/logo_gestor_trans.png"> -->
            </div>
            <div class="alert" *ngIf="authError" >
                <mat-error>
                        {{authError?.message}}
                </mat-error>
                <hr>
            </div>

            <div class="title">PREENCHA AS INFORMAÇÕES</div>

            <form #form="ngForm" name="registerForm" [formGroup]="registerForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        Nome é obrigatório
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        E-mail é obrigatório
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        Por favor entre com um e-mail valido!
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Senha é obrigatório
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        Senha de confirmação é obrigatório
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        As senhas devem corresponder
                    </mat-error>
                </mat-form-field>

                <!-- <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="Accept" required>
                        <span>Accept</span>
                    </mat-checkbox>
                    <a href="#">terms and conditions</a>
                </div> -->

                <button mat-raised-button style="color: white !important;" color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT" (click)="creaUser(form)"
                        [disabled]="registerForm.invalid">
                    Criar uma conta
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Já tem uma conta?</span>
                <a class="link" [routerLink]="'/auth/login'">Login</a>
            </div>
        </div>
    </div>
</div>
