import { UploadBannerServiceService } from 'app/service/upload-banner-service.service';
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import {
    PoChartType,
    PoChartSerie,
    PoChartOptions,
    PoModalComponent,
    PoModalAction,
    PoNotificationService,
    PoNotification,
    PoTableColumn,
    PoTableColumnLabel,
    PoSlideItem,
} from "@po-ui/ng-components";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { QuestsModel } from "app/Models/quests.model";
import { SampleService } from "app/service/sample.service";
import { AuthService } from "app/service/auth.service";
import { DatePipe } from "@angular/common";
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as _ from "lodash";
import { UsersQuestModel } from "app/Models/usersQuest.model";
import { UserModel } from "app/models/user.model";
import { LogService } from "app/service/log.service";
import { SalesService } from "app/service/sales.service";

import { ChartOptions } from "chart.js";
import { Label, ThemeService } from "ng2-charts";
import { ChartType } from "chart.js";
import { UserService } from "app/service/user.service";
import { StoreService } from "app/service/store.service";
import { ShoppingCartService } from "app/service/shopping-cart.service";

@Component({
    selector: "sample",
    templateUrl: "./sample.component.html",
    styleUrls: ["./sample.component.scss"],
    providers: [PoNotificationService],
})
export class SampleComponent implements OnInit {
    // grafico
    view: any[] = [550, 300];
    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    showYAxisLabel = true;
    userCash: any
    userName: any
    email: any
    colorScheme = {
        domain: ["#FF6200", "#E1005A", "#9A006E", "#4A007B", "#009CBE"],
    };
    rankingCoins;
    rankingRecived;
    rankGivers;

    // fim grafico
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     *  @param {TranslateService} _translateService
     */

    @ViewChild(PoModalComponent, { static: true }) questModal: PoModalComponent;

    ultimasCompras = [];
    categories: Array<string> = [];
    rankingComprasType: PoChartType = PoChartType.Column;
    rankingCompras: Array<PoChartSerie> = [];
    options: PoChartOptions = {};
    quest = [];
    templateDateTarget = "purchaseDate";
    tableColumns: Array<PoTableColumn> = [
        { property: "products", label: "Produto", type: "columnTemplate" },
        { property: "userName", label: "Usuário" },
        { property: "amount", label: "Estrelas" },
    ];
    reactiveForm: FormGroup;
    columns: Array<PoTableColumn>;

    public listQuests: QuestsModel[] = [];
    public questUpdateId = "";

    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    reload: any;
    questImage: Array<any>;
    srcQuestImage: any;
    userId = "";
    credentials: UserModel;
    slideStore: any = []
    today: any = new Date();
    ultimasComprasConcat: any[];
    nameUserLogged: any;
    IdUserLogged: any;

    @ViewChild("fileInput") fileInput: ElementRef;
    slideStoreLat: any = [];
    ultimasCompras2: any[];

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private poNotification: PoNotificationService,
        private _translateService: TranslateService,
        private service: SampleService,
        private salesService: SalesService,
        public auth: AuthService,
        private datePipe: DatePipe,
        private logService: LogService,
        private usersService: UserService,
        private teste: ShoppingCartService,
        private getbannersService: UploadBannerServiceService,
      
    ) {
        this.today = this.datePipe.transform(this.today, "yyyy-MM-dd");
        
        this.createReactiveForm();

        //this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        setTimeout(() => {
            this._translateService.setDefaultLang("en");
            this._translateService.setDefaultLang("br");
        });

        this.auth.user$.subscribe((credentials) => {
            this.credentials = credentials;
            this.userId = credentials.uid;
            this.nameUserLogged = credentials.displayName;
        });
        

       

        

        this.categories = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Maio",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ];

        this.rankingCompras = [];

        this.options = {
            axis: {
                minRange: 0,
                maxRange: 40,
                gridLines: 5,
            },
        };
        
        
    }

   async ngOnInit() {

    

    this.salesService.onSalesChanged.subscribe((sales) => {
       
        this.ultimasCompras = _.orderBy(
            sales,
            ["purchaseDate"],
            ["asc", "asc"]
        )
        .reverse()
        .splice(0, 5)
        
        });


        this.salesService.onSalesChangedCollab.subscribe((salescolab) =>{
            this.ultimasCompras2 = _.orderBy(
            salescolab,
            ["purchaseDate"],
            ["asc", "asc"]
        )
        .reverse()
        .splice(0, 5)
        })    


      
        this.ultimasComprasConcat = this.ultimasCompras.concat(this.ultimasCompras2)
        this.ultimasComprasConcat = _.orderBy(
        this.ultimasComprasConcat,
        ["purchaseDate"],
        ["asc", "asc"]
        )
        .reverse()
        .splice(0, 5)

     


        await this.getbannersService.getBannersTop().then((res) =>{
            res.docs.forEach(async (imagesTop) =>{
                this.slideStore.push(imagesTop.data().image)
            })
        })

        await this.getbannersService.getBannersLat().then((res) =>{
            res.docs.forEach(async (imagesTop) =>{
                this.slideStoreLat.push(imagesTop.data().image)
            })
        })

        
        this.usersService.getUsers();

        this.salesService.getSales();
        
        this.usersService.onUserRankingChanged.subscribe((item) => {
            
            if (item.length === 0) return;
            const rankingCoinsRef = item.rankCoins.map((user) => ({
                name: user.displayName,
                value: Number.isNaN(user.cash) ? 0 : user.cash,
            }));

            this.rankingCoins = _.orderBy(
                rankingCoinsRef,
                ["value"],
                ["desc"]
            ).splice(0, 5);
        

            const ref = item.rankingRecived.map((user: any) => ({
                name: user.displayName,
                value: user.cashRecivedToRank ? user.cashRecivedToRank : 0,
            }));
            this.rankingRecived = _.orderBy(ref, ["value"], ["desc"]).splice(
                0,
                5
            );
            const ref2 = item.rankGivers.map((user: any) => ({
                name: user.displayName,
                value: user.tradesTotalToRank ? user.tradesTotalToRank : 0,
            }));
            this.rankGivers = _.orderBy(ref2, ["value"], ["desc"]).splice(0, 5);
        });

        this.service.onQuestChanged.subscribe((quests) => {
            this.listQuests = quests;
           
            
        });

        this.columns = [
            { property: "userName", label: "Colaborador", visible: true },
            { property: 'products[0]["description"]', label: "Produto" },
            { property: "amount", label: "Valor" },
            {
                property: "deliveryStatus",
                label: "Status da Entrega",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : "",
                        color: "color-11",
                        label: "Entregue",
                        tooltip: "Produtos entregues!",
                    },
                    {
                        value: false,
                        color: "color-07",
                        label: "Aguardando",
                        tooltip: "Aguardando a entrega dos produtos!",
                    },
                ],
                visible: true,
            },
        ];
        
    }



    ngAfterViewInit(): void{
        
    }



    SaveAction: PoModalAction = {
        action: () => {
            var fields = this.reactiveForm.value;
            let saveUserQuest = {} as UsersQuestModel;

            if (
                this.quest['evidencia'] == true &&
                fields.questAnswer && this.reactiveForm.valid
            ) {
                saveUserQuest.questId = this.questUpdateId;
                saveUserQuest.userId = this.userId;
                saveUserQuest.userName = this.credentials.displayName;
                saveUserQuest.userApprovalId = "";
                saveUserQuest.userApprovalName = "";
                saveUserQuest.questName = this.quest["name"];
                saveUserQuest.questInstruction = this.quest["instructions"];
                saveUserQuest.reward = this.quest["rewardValue"];
                saveUserQuest.sendDate = new Date();
                saveUserQuest.approvalDate = null;
                saveUserQuest.questStartDate = this.quest["startDate"];
                saveUserQuest.questEndDate = this.quest["endDate"];
                saveUserQuest.questStatus = 1;
                saveUserQuest.questAnswer = fields.questAnswer;

              
                if (this.fileInput.nativeElement.files.length == 0){
                     this.message = "Quest não pode ser enviada sem uma imagem!";
                      this.poType = "warning";
                      this.duration = 5000;
                     this.showNotification(this.poType);
                     return
                }else{
                     (this.fileInput.nativeElement.files)
                    this.service.insertUserQuest(
                        this.fileInput.nativeElement.files, 
                        saveUserQuest
                    );
                    this.questUpdateId = "";

                    this.message = "Quest enviada para aprovação!";
                    this.poType = "sucess";
                    this.questModal.close();
                    this.showNotification(this.poType);

                    this.logService.insertLog({
                        pageName: "Home",
                        actionExecuted: "Quest Enviada",
                        responsible: this.nameUserLogged,
                        userLoggedId: this.userId,
                        registeredDate: new Date(),
                        objectTransacted: JSON.stringify(saveUserQuest),
                    });
                }

                 ('1')

                
            } else if (fields.questAnswer && this.reactiveForm.valid) {
                saveUserQuest.questId = this.questUpdateId;
                saveUserQuest.userId = this.userId;
                saveUserQuest.userName = this.credentials.displayName;
                saveUserQuest.userApprovalId = "";
                saveUserQuest.userApprovalName = "";
                saveUserQuest.questName = this.quest["name"];
                saveUserQuest.questInstruction = this.quest["instructions"];
                saveUserQuest.reward = this.quest["rewardValue"];
                saveUserQuest.sendDate = new Date();
                saveUserQuest.approvalDate = null;
                saveUserQuest.questStartDate = this.quest["startDate"];
                saveUserQuest.questEndDate = this.quest["endDate"];
                saveUserQuest.questStatus = 1;
                saveUserQuest.questAnswer = fields.questAnswer;

                this.service.insertUserQuest(
                    null, 
                    saveUserQuest
                );
                 (this.quest['evidencia'])
                this.questUpdateId = "";

                this.message = "Quest enviada para aprovação!";
                this.poType = "sucess";
                this.questModal.close();
                this.showNotification(this.poType);

                this.logService.insertLog({
                    pageName: "Home",
                    actionExecuted: "Quest Enviada",
                    responsible: this.nameUserLogged,
                    userLoggedId: this.userId,
                    registeredDate: new Date(),
                    objectTransacted: JSON.stringify(saveUserQuest),
                });
                
                
                // this.message = "Quest não pode ser enviada sem uma imagem!";
                // this.poType = "warning";
                // this.duration = 5000;
                // this.showNotification(this.poType);
               
            }else{
                this.message = "Preencha as informações corretamente";
                this.poType = "warning";
                this.duration = 5000;
                this.showNotification(this.poType);
            }
        },
        label: "Enviar Quest",
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.questModal.close();
        },
        label: "Cancelar",
    };

    detailsQuest(item) {
        this.clearForm();
        this.quest = item;
        this.questImage = item.image;
        this.questUpdateId = item.id;
        this.questModal.open();
    }

    showUploadImage(file) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
            this.srcQuestImage = (<FileReader>event.target).result;
        };
        reader.readAsDataURL(file.target.files[0]);
    }

    initUpload() {
        let uploadButton: HTMLElement = document.getElementById(
            "uploadFile"
        ) as HTMLElement;
        uploadButton.click();
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            questAnswer: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(250),
                ]),
            ],
        });
    }

    clearForm() {
        this.reactiveForm.reset();
        this.reactiveForm = this.fb.group({
            questAnswer: [""],
        });
    }

    showNotification(messageType) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }
    // grafico

    // /grafico


}

