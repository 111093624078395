import { Component, ViewChild } from "@angular/core";
import { UserModel } from "app/Models/user.model2";
import { AuthService } from "app/service/auth.service";
import { UserService } from "app/service/user.service";
import { trigger, style, animate, transition } from "@angular/animations";
@Component({
    selector: "approval-term",
    templateUrl: "./approval-term.component.html",
    styleUrls: ["./approval-term.component.scss"],
    animations: [
        trigger("enterAnimation", [
            transition(":enter", [
                style({ transform: "translateX(100%)", opacity: 0 }),
                animate(
                    "500ms",
                    style({ transform: "translateX(0)", opacity: 1 })
                ),
            ]),
            transition(":leave", [
                style({ transform: "translateX(0)", opacity: 1 }),
                animate(
                    "500ms",
                    style({ transform: "translateX(100%)", opacity: 0 })
                ),
            ]),
        ]),
    ],
})
export class ApprovalTermComponent {
    geral = [
        {
            Ação: "Quest",
            Conversão: "A definir",
            Estrelas: "A definir",
            Observação: "Defnir por quests",
        },
        {
            Ação: "NPS qualidade > 50",
            Conversão: "Única",
            Estrelas: "25",
            Observação: "Analisado por trimestre",
        },
        {
            Ação: "Sugestão de Melhorias",
            Conversão: "Única",
            Estrelas: "10",
            Observação: "Classificada e aprovada pela direção",
        },
        {
            Ação: "Destaque do Semana",
            Conversão: "Única",
            Estrelas: "20",
            Observação: "Será definido nas reuniões",
        },
        {
            Ação: "Destaque NPS",
            Conversão: "Única",
            Estrelas: "20",
            Observação: "Será definido nas pesquisas de NPS",
        },
        {
            Ação: "Webinar/lives",
            Conversão: "Única",
            Estrelas: "10",
            Observação: "Convidado",
        },
        {
            Ação: "Convidado no B@T",
            Conversão: "Única",
            Estrelas: "5",
            Observação: "Convidado",
        },
        {
            Ação: "Aniversário",
            Conversão: "Única",
            Estrelas: "30",
            Observação: "Aniversário de cada um ",
        },
        {
            Ação: "Aniversário iv2",
            Conversão: "Única",
            Estrelas: "5x o tempo",
            Observação: "5x o tempo de empresa",
        },
    ];
    factory = [
        {
            Ação: "Entrega de MIF962 (Termo de Encerramento)",
            Conversão: "Única",
            Estrelas: "10",
            Observação: "A Cada MIF assinada e enviada para o mkt",
        },
        {
            Ação: "Destaque do mês (CP)",
            Conversão: "Única",
            Estrelas: "25",
            Observação: "Mensal, definido nas reuniões",
        },
    ];
    Commercial = [
        {
            Ação: "Serviço - Meta Batida 100%",
            Conversão: "Única",
            Estrelas: "100",
            Observação: "Meta mensal individual",
        },
        {
            Ação: "Serviço - Meta Batida 120%",
            Conversão: "Única",
            Estrelas: "200",
            Observação: "Meta mensal individual",
        },
        {
            Ação: "Serviço - Meta Batida 150%",
            Conversão: "Única",
            Estrelas: "500",
            Observação: "Meta mensal individual",
        },
        {
            Ação: "APN - Meta Semanal",
            Conversão: "Única",
            Estrelas: "5",
            Observação: "Meta semanal (6 agendas na semana)",
        },
        {
            Ação: "Novo cliente - New deals",
            Conversão: "Única",
            Estrelas: "5",
            Observação:
                "Assistente de vendas A cada novo cliente (Não vale para a base)",
        },
        {
            Ação: "Projetos fechado >500 até 999 horas",
            Conversão: "Única",
            Estrelas: "50",
            Observação: "Arquiteto participante ",
        },
        {
            Ação: "Projetos fechado >1000 até 99999 horas",
            Conversão: "Única",
            Estrelas: "100",
            Observação: "Arquiteto participante ",
        },
    ];
    credentials;

    isVisible: Boolean = true;
    isAuthenticated: Boolean = false;
    constructor(public auth: AuthService, public userService: UserService) {
        this.auth.user$.subscribe((credentials) => {
            this.credentials = credentials;

            if (credentials?.termsAndConditions) {
                this.isVisible = false;
            }

            if (credentials) {
                this.isAuthenticated = true;
            }
        });
    }
    handleAccept() {
        this.credentials.termsAndConditions = true;
        this.userService.updateUser(this.credentials.uid, this.credentials);

        this.isVisible = false;
    }
}
