import { ExcelDownService } from './../../service/excel-down.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    PoModalAction,
    PoModalComponent,
    PoNotification,
    PoNotificationService,
    PoTableAction,
    PoTableColumn,
    PoTableColumnLabel,
} from "@po-ui/ng-components";
import { ApprovalCoins } from "app/Models/ApprovalCoins.model";
import { UserModel } from "app/models/user.model";
import { UsersQuestModel } from "app/Models/usersQuest.model";
import { ApprovalQuestsService } from "app/service/approval-quests.service";
import { ApprovalCoinsService } from "app/service/aproval-solic-coins.service";
import { AuthService } from "app/service/auth.service";
import { LogService } from "app/service/log.service";
import { UserService } from "app/service/user.service";
import * as _ from "lodash";

@Component({
    selector: "approval-quests",
    templateUrl: "./approval-quests.component.html",
    styleUrls: ["./approval-quests.component.scss"],
})
export class ApprovalQuestsComponent implements OnInit {
    @ViewChild(PoModalComponent, { static: true })
    questApprovalModal: PoModalComponent;

    actions: Array<PoTableAction> = [
        { action: this.detailsQuest.bind(this), label: "Avaliar / Detalhes" },
    ];

    public listQuests = [];
    public questUpdateId = "";

    quest: UsersQuestModel;
    columns: Array<PoTableColumn>;
    credentials: UserModel;

    //Po Notification
    message: string;
    duration = 2500;
    poType: string;

    nameUserLogged: any;
    IdUserLogged: any;
    nome:any = ''
    startDate: any;
    endDate: any;
    p: Number = 0;
    itemPage:Number = 10;
    key: string = 'sendDate'
    reverse: boolean = true;
    constructor(
        private service: ApprovalQuestsService,
        private userService: UserService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
        private excelDown: ExcelDownService,
        private router: Router,
        private logService: LogService,
        private approvalCoinsService: ApprovalCoinsService
    ) {
        this.auth.user$.subscribe((credentials) => {
            this.credentials = credentials;
        });

        this.columns = [
            { property: "userName", label: "Colaborador", visible: true },
            { property: "questName", label: "Quest", visible: true },
            { property: "reward", label: "Recompensa", visible: true },
            {
                property: "sendDate",
                label: "Data de Envio",
                visible: true,
                type: "columnTemplate",
            },
            {
                property: "questStatus",
                label: "Quest Status",
                type: "label",
                labels: <Array<PoTableColumnLabel>>[
                    {
                        value: 1,
                        color: "color-08",
                        label: "Em Analise",
                        tooltip: "Quest em analise",
                    },
                    {
                        value: 2,
                        color: "color-11",
                        label: "Aprovada",
                        tooltip: "Quest Aprovada",
                    },
                    {
                        value: 3,
                        color: "color-07",
                        label: "Não Aprovada",
                        tooltip: "Quest não foi aprovada",
                    },
                ],
                visible: true,
            },
        ];
    }

    ngOnInit(): void {

        this.auth.user$.subscribe((credentials) => {
            if (credentials.userPerfil != "1") {
                this.router.navigate(["/sample"]);
            } else {
                this.nameUserLogged = credentials.displayName;
                this.IdUserLogged = credentials.uid;
                this.service.onQuestsChanged.subscribe((quests) => {
                    this.listQuests = quests;
                    
                });
            }
        });
      
    }

    detailsQuest(quest) {
        if (quest.images != undefined){
            this.quest = quest;
            this.questUpdateId = quest.id;
            this.questApprovalModal.open();
        }else{
            this.quest = quest;
            this.quest.images = ['nulo']
            this.questUpdateId = quest.id;
            this.questApprovalModal.open();
        }
    }

    ApprovalAction: PoModalAction = {
        action: () => {
            

            if (this.quest.questStatus == 1) {
                let updateQuest = {} as UsersQuestModel;

                updateQuest.questStatus = 2;
                updateQuest.approvalDate = new Date();
                updateQuest.userApprovalId = this.credentials.uid;
                updateQuest.userApprovalName = this.credentials.displayName;
                this.service.updateUserQuest(this.questUpdateId, updateQuest);
                this.questUpdateId = "";
                updateQuest.questId = this.questUpdateId;

                //  abre uma requisição
                const data = {
                    uid: this.quest.userId,
                    displayName: this.quest.userName,
                    status: 1,
                    titleOfReward: `Quest: ${this.quest.questName}`,
                    typeOfReward: "1",
                    dateTimeStamp: new Date().getTime(),
                    approvalFrom: "Quest",
                    coins: this.quest.reward,
                };
                this.approvalCoinsService.newPost(data);

                // this.service.updateCashUserQuest(this.quest.userId, this.quest.reward, this.credentials.uid, this.credentials.displayName, updateQuest);
                //  abre uma requisição

                this.message = "Quest aprovada com sucesso!";
                this.poType = "sucess";
            } else {
                if (this.quest.questStatus == 2) {
                    this.message = "Quest Já Aprovada!";
                    this.poType = "sucess";
                } else {
                    this.message = "Quest Reprovada!";
                    this.poType = "error";
                }
            }
            this.showNotification(this.poType);
            this.questApprovalModal.close();
        },
        label: "Aprovar Quest",
    };

    NoApprovalAction: PoModalAction = {
        action: () => {
            if (this.quest.questStatus == 1) {
                let updateQuest = {} as UsersQuestModel;

                updateQuest.questStatus = 3;
                this.service.updateUserQuest(this.questUpdateId, updateQuest);
                this.questUpdateId = "";

                this.message = "Quest não aprovada!";
                this.poType = "warning";
                this.showNotification(this.poType);
            }
            this.questApprovalModal.close();
        },
        label: "Reprovar Quest",
    };

    showNotification(messageType) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }


    filterName(){
        const arrayOne = []
        const dataInicio = new Date(this.startDate)
        const dataFim = new Date(this.endDate)
     
        this.service.onQuestsChanged.subscribe((quests) => { 
            quests.filter(e =>{
                
                if (e.userName.toUpperCase().indexOf(this.nome) !== -1 || e.userName.toLowerCase().indexOf(this.nome) !== -1 || e.userName.indexOf(this.nome) !== -1){
                    arrayOne.push(e)
                    if((dataInicio.getTime()/1000) >= e.sendDate.seconds){
                        arrayOne.pop()
                    }
                    if ((dataFim.getTime() / 1000) + 86400 < e.sendDate.seconds){
                        arrayOne.pop()
                    }   
                }else if(this.nome === '' || this.nome == undefined){
                    arrayOne.push(e)
                }else if('APROVADA'.indexOf(this.nome) !== -1 || 'aprovada'.indexOf(this.nome) !== -1){
                    if(e.questStatus === 2){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.sendDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime() / 1000) + 86400 < e.sendDate.seconds){
                            arrayOne.pop()
                        }   
                    }
                }else if('EM ANALISE'.indexOf(this.nome) !== -1 || 'em analise'.indexOf(this.nome) !== -1){
                    if(e.questStatus === 1){
                        arrayOne.push(e)
                        if((dataInicio.getTime()/1000) >= e.sendDate.seconds){
                            arrayOne.pop()
                        }
                        if ((dataFim.getTime() / 1000) + 86400 < e.sendDate.seconds){
                            arrayOne.pop()
                        }   
                    }
                }
                })
                this.listQuests = arrayOne
            })
        } 

    downloadEx(){
        this.excelDown.downLoadQuests(this.listQuests)
    }
    
    sort(key: any) {
        this.key = key.column.property
        this.reverse = !this.reverse;
    }

}
