import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { QuestsModel } from 'app/Models/quests.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class QuestsService implements Resolve<any> {

    quests: any[];
    quest: any;

    routeParams: any;

    formData: QuestsModel;

    onQuestChanged: BehaviorSubject<any>;
    onUseChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore
    ) { 
        this.onQuestChanged = new BehaviorSubject([]);
        this.onUseChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getQuests(),
                this.getQuestId()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getQuests(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/quests`).snapshotChanges().subscribe(action => {
                this.quests = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as QuestsModel),
                        favorite: ['detalhes']
                    } as QuestsModel
                });
                this.onQuestChanged.next(_.orderBy(this.quests, ['displayName'],['asc']));
                resolve(this.quests);
            });
        })
    }

    getQuestId(){
        if(this.routeParams.questId){
            return new Promise((resolve, reject) => {
                this.firestore.collection(`${datastore}/quests`).doc(this.routeParams.questId).valueChanges().subscribe(action => {
                    this.quest = action
                    this.onUseChanged.next(this.quest)
                    resolve(this.quest)
                })
            })
        }        
    }

    insertQuest(data: QuestsModel) {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/quests`).add(data);
        })
    }

    updateQuest(id: string, data: QuestsModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/quests/${id}`).update(data);
        })       
    }
  


    deleteGroupSp(data: any){
        return this.firestore.collection(`${datastore}/spGroups`).doc(data.id).delete()
    }
}
