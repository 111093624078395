import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { PoButtonGroupModule, PoContainerModule, PoInfoModule, PoModalModule, PoPageModule, PoSlideModule, PoTabsModule, PoWidgetModule, PoTableModule } from '@po-ui/ng-components';
import { ShoppingCartModule } from 'app/layout/components/shopping-cart/shopping-cart.module';
import { PurchasesCollaboratorService } from 'app/service/purchases-collaborator.service';
import { StoreService } from 'app/service/store.service';
import { PoTooltipModule } from '@po-ui/ng-components';
import { CarouselModule } from 'primeng/carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { StoreComponent } from './store.component';
import { AuthGuard } from 'app/service/auth.guard';

const routes = [
    {
        path : 'store',
        component: StoreComponent,
        resolve: {
            store: StoreService,
            products: PurchasesCollaboratorService
        },
        canActivate: [AuthGuard],
    }
]

@NgModule({
    declarations: [
        StoreComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        ShoppingCartModule,
        NgxPaginationModule,
        //PO Modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoContainerModule,
        PoSlideModule,
        PoInfoModule,
        PoButtonGroupModule,
        PoTabsModule,
        PoTableModule,
        //PrimeNG
        CarouselModule,
        PoTooltipModule
    ],
    exports : [
        StoreComponent
    ]
})

export class StoreModule {}
