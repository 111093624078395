import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PurchasesModel } from 'app/models/purchases.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { AuthService } from './auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Userproducts2 } from 'app/models/userproducts';

@Injectable({
  providedIn: 'root'
})
export class PurchasesCollaboratorService implements Resolve<any> {
  products: any[];
  product: any;

  routeParams: any;

  formData: Userproducts2;

  onProductChanged: BehaviorSubject<any>;
  
  onUseChanged: BehaviorSubject<any>;

  constructor(
      private firestore: AngularFirestore,        
      private firestorage: AngularFireStorage
  ) { 
      this.onProductChanged = new BehaviorSubject([]);
      
      this.onUseChanged = new BehaviorSubject([]);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
      this.routeParams = route.params; 
      return new Promise<void>((resolve, reject) => {
          Promise.all([
              this.getProducts(),
              this.getProductId()
          ]).then(() => {
             
                  resolve();
              },
              reject
          );
      });
  }

  getProducts(){
      return new Promise((resolve, reject) => {
          this.firestore.collection(`${datastore}/productsCollaborator`).snapshotChanges().subscribe(action => {
              this.products = action.map(item => {
                  return {
                      id: item.payload.doc.id,
                      ...(item.payload.doc.data() as Userproducts2),
                      favorite: ['detalhes']
                  } as Userproducts2
              });
              this.onProductChanged.next(_.orderBy(this.products, ['displayName'],['asc']));
              resolve(this.products);
          });
      })
  }


  async getProducts2(){

    return new Promise((resolve, reject) => {
        this.firestore
            .collection(`${datastore}/productsCollaborator`)
            .snapshotChanges()
            .subscribe((action) => {
                const data = action.map((item) => {
                    return {
                        ...(item.payload.doc.data() as any),
                    } as any;
                });
                resolve(data);
            });
    });


  }
  getProductId(){
     
      if(this.routeParams.productId){
          return new Promise((resolve, reject) => {
              this.firestore.collection(`${datastore}/productsCollaborator`).doc(this.routeParams.productId).valueChanges().subscribe(action => {
                  this.product = action
                  this.onUseChanged.next(this.product)
                  resolve(this.product)
              })
          })
      }        
  }

  approveProduct(id: string, value: any){
    return new Promise((resolve, reject) => {
        this.firestore.doc(`${datastore}/productsCollaborator/${id}`).update({approve: value});
    })       
}  



  insertProduct(data: Userproducts2, file: any) {
      return new Promise((resolve, reject) => {
          this.firestore.collection(`${datastore}/productsCollaborator`).add(data).then(docRef => {
              docRef.update({id: docRef.id})
              this.uploadFiles(file, docRef.id, resolve)
          });
      })
  }

  updateProduct(id: string, file: any, data: Userproducts2){
      return new Promise((resolve, reject) => {
          this.firestore.doc(`${datastore}/productsCollaborator/${id}`).update(data);
          this.uploadFiles(file, id, resolve);
      })       
  }  

  updateStatus(id: string, status: any){
    let date = new Date().toLocaleString()
    // let dataTratada = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} as ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    return new Promise((resolve, reject) => {
        this.firestore.doc(`${datastore}/purchasesCollaborator/${id}`).update({deliveryStatus: status, deliveryDate: date});
    })       
} 

  deleteProduct(id: string){
      return new Promise((resolve, reject) => {
          this.firestore.collection(`${datastore}/products`).doc(id).get().toPromise().then((res) =>{
                let imgref = res.data().images[0].imgLocation
                this.firestorage.ref(imgref).delete()
                res.ref.delete()
                // console.log('deletado')
          })
      })       
  }  

  deleteProductColla(id: string){
    return new Promise((resolve, reject) => {
        this.firestore.collection(`${datastore}/productsCollaborator`).doc(id).get().toPromise().then((res) =>{
            
            // Descomentar para ativar remoção das imagens do storage, isso era afetar o painel de ultimas vendas !
            // let imgref = res.data().images[0].imgLocation
            // this.firestorage.ref(imgref).delete()


            res.ref.delete()


            
        })
    })       
}  

  uploadFiles(files: any, productId: string, resolve) {
      if (files.length == 0) {
        resolve(true)
      }
      for ( let i = 0; i < files.length; i++ ) {
          let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
          let arquivo = files.item(i);
          let location = (`${datastore}/productsCollaborator/${productId}/${dateNow}/${arquivo.name}`);
          let fileRef = this.firestorage.ref(`${datastore}/productsCollaborator/${productId}/${dateNow}/${arquivo.name}`);
          let task = fileRef.put(arquivo);
          // get notified when the download URL is available
          return task.snapshotChanges().pipe(finalize(() => {
              fileRef.getDownloadURL().subscribe(link => {
                  this.firestore.doc(`${datastore}/productsCollaborator/${productId}`).update({
                      images: [{
                          productId: productId,
                          date: new Date().toLocaleString(),
                          time: new Date().getTime(),
                          imgLocation: location,
                          filename: arquivo.name,
                          imgLink: link
                      }]
                  });
              });
          })
        ).subscribe()
      }
  }


  updateEstoque(data){
    //  console.log(data)
        data.products.forEach((dadosProduto) => {
        let estoqueAtualizado = 0
        estoqueAtualizado = (dadosProduto.quantity - dadosProduto.amount)
            // console.log(dadosProduto, 'uiehauiheia')
        if (estoqueAtualizado <= 0){
            dadosProduto.approve = 4
            dadosProduto.quantity = estoqueAtualizado
            this.firestore.doc(`${datastore}/productsCollaborator/${dadosProduto.id}`).update({quantity: estoqueAtualizado, approve: 4});
        }else{
            dadosProduto.quantity = estoqueAtualizado
            // console.log(dadosProduto.quantity)
            this.firestore.doc(`${datastore}/productsCollaborator/${dadosProduto.id}`).update({quantity: estoqueAtualizado});
        }
        
    });
  }



}
