import { MySalesModule } from './main/my-sales/my-sales.module';
import { ApprovalProductsModule } from './main/approval-products/approval-products.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSortModule } from "@angular/material/sort";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
//import { environment } from "environments/environment";
import { environmentPRD } from "environments/environment.prod";
import { NgxPaginationModule } from 'ngx-pagination'
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { TranslateModule } from "@ngx-translate/core";


import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import {
    PoChartModule,
    PoContainerModule,
    PoTableModule,
    PoWidgetModule,
} from "@po-ui/ng-components";
import { PoPageModule } from "@po-ui/ng-components";
import { PoModalModule } from "@po-ui/ng-components";
import { PoTabsModule } from "@po-ui/ng-components";
import { PoPageLoginModule, PoTemplatesModule } from "@po-ui/ng-templates";

import { CarouselModule } from "primeng/carousel";

import { AppComponent } from "app/app.component";

import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { StoreModule } from "./main/store/store.module";

import { UsersModule } from "./main/users/users.module";
import { QuestsModule } from "./main/quests/quests.module";
import { ProductsModule } from "./main/products/products.module";
import { CoinModule } from "./main/coin/coin.module";
import { PerfilModule } from "./main/perfil/perfil.module";
import { PurchasesModule } from "./main/purchases/purchases.module";
import { MyQuestsModule } from "./main/myquests/myquests.module";
import { AuthModule } from "./main/auth/auth.module";

import { ShoppingCartService } from "./service/shopping-cart.service";
import { ApprovalQuestsModule } from "./main/approval-quests/approval-quests.module";
import { LogsModule } from "./main/logs/logs.module";
import { SalesComponent } from "./main/sales/sales.component";
import { SalesModule } from "./main/sales/sales.module";
import { TypeOfRewardModule } from "./main/type-of-reward/type-of-reward.module";
import { ApprovalRewardModule } from "./main/approval-reward/approval-reward.module";
import { AutenticationCrudModule } from "./main/autentication-crud/autentication-crud.module";
import { ApprovalTermModule } from "@fuse/components/approval-term/approval-term.module";
import { DomainCrudService } from "./service/domain-crud.service";
import { SystemparametersComponent } from './main/systemparameters/systemparameters.component';
import { SystemparametersModule } from './main/systemparameters/systemparameters.module';
import { MyproductsComponent } from './main/myproducts/myproducts.component';
import { MyproductsModule } from './main/myproducts/myproducts.module';
import { StoreConfigurationComponent } from './main/store-configuration/store-configuration.component';
import { StoreConfigurationModule } from './main/store-configuration/store-configuration.module';
import { MySalesComponent } from './main/my-sales/my-sales.component';
import { SuperPointzComponent } from './main/super-pointz/super-pointz.component';
import { SuperPointzModule } from './main/super-pointz/super-pointz.module';



const appRoutes: Routes = [
    {
        path: "auth",
        loadChildren: () =>
            import("./main/auth/auth.module").then((m) => m.AuthModule),
    },
    {
        path: "**",
        redirectTo: "auth/login",
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            relativeLinkResolution: "legacy",
        }),
        AngularFireModule.initializeApp(environmentPRD.firebaseConfig),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
      
        MatButtonModule,
        MatIconModule,
        

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
       
        // PO UI modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoTableModule,
        PoChartModule,
        PoPageLoginModule,
        PoTemplatesModule,
        PoTabsModule,

        //PrimeNG
        CarouselModule,
      
        // App modules
        LayoutModule,
        SampleModule,
        StoreModule,
        UsersModule,
        QuestsModule,
        AuthModule,
        ProductsModule,
        CoinModule,
        PerfilModule,
        PurchasesModule,
        MyQuestsModule,
        ApprovalQuestsModule,
        LogsModule,
        SalesModule,
        TypeOfRewardModule,
        ApprovalRewardModule,
        AutenticationCrudModule,
        SystemparametersModule,
        ApprovalTermModule,
        NgxPaginationModule,
        FormsModule,
        MatPaginatorModule,
        MatSortModule,
        MyproductsModule,
        ApprovalProductsModule, 
        StoreConfigurationModule,
        MySalesModule,
        SuperPointzModule
    ],
    providers: [ShoppingCartService, DomainCrudService],
    bootstrap: [AppComponent],
})
export class AppModule { }
