import { SuperPointzComponent } from './super-pointz.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';

const routes = [
  {
      path: "superPointz",
      component: SuperPointzComponent,
      
  },
];

@NgModule({
  declarations: [SuperPointzComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule,
    MatIconModule,
    ReactiveFormsModule
  ]
})
export class SuperPointzModule { }
