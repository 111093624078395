import { NgModule } from '@angular/core';
import { MyQuestsComponent } from './myquests.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatIconModule } from '@angular/material/icon';
import { PoContainerModule, PoInfoModule, PoListViewModule, PoTagModule } from '@po-ui/ng-components';
import { MyQuestsService } from 'app/service/myquests.service';
import { AuthGuard } from 'app/service/auth.guard';

const routes = [
    {
        path: 'myquests',
        component: MyQuestsComponent,
        resolve: {
            quests: MyQuestsService
        },
        canActivate: [AuthGuard]
    }
]

@NgModule({
    declarations: [
        MyQuestsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,

        MatIconModule,

        //PO Modules
        PoContainerModule,
        PoListViewModule, 
        PoInfoModule,
        PoTagModule
    ],
    exports: [
        MyQuestsComponent
    ]
})

export class MyQuestsModule { }
