import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { datastore } from "app/config";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import * as _ from "lodash";
import { UserModel } from "app/Models/user.model";
import { UsersQuestModel } from "app/Models/usersQuest.model";
import { AnyARecord } from "dns";
import { ApprovalCoins } from "app/Models/ApprovalCoins.model";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ApprovalCoinsService implements Resolve<any> {
    routeParams: any;

    timelineList = {
        posts: [],
    };
    timelineHasNext: BehaviorSubject<any>;

    onAprovalCoinsChanged: BehaviorSubject<any>;

    constructor(private firestore: AngularFirestore) {
        this.onAprovalCoinsChanged = new BehaviorSubject([]);
        this.timelineHasNext = new BehaviorSubject([]);
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getData()]).then(() => {
                resolve();
            }, reject);
        });
    }

    getData() {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/aprovalCoinsRequest`)
                .snapshotChanges()
                .subscribe((action) => {
                    const data = action.map((item) => {
                        return {
                            id: item.payload.doc.id,
                            ...(item.payload.doc.data() as any),
                        } as any;
                    });
                    this.onAprovalCoinsChanged.next(data);
                    resolve(data);
                });
        });
    }

    update(id: string, data: UsersQuestModel) {
        return new Promise((resolve, reject) => {
            this.firestore
                .doc(`${datastore}/aprovalCoinsRequest/${id}`)
                .update(data);
        });
    }

    
    newPost(data) {
        const pushkey = this.firestore.createId();
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/aprovalCoinsRequest`)
                .doc(pushkey)
                .set({ ...data, id: pushkey });
        });
    }

    // paginação
    last;
    getDataPaginated() {
        // to clean on reset filters
        this.timelineList.posts = [];

        this.firestore
            .collection(`${datastore}/aprovalCoinsRequest`, (ref) =>
                ref.orderBy("id", "asc").orderBy("status", "asc").limit(30)
            )
            .valueChanges()
            .subscribe((response) => {
                
                response.forEach((dataValues: any) => {
                    // remove os dados repetidos que acontecem por causa do real time
                    const indexOf = this.timelineList.posts.findIndex(
                        (item) => item.id === dataValues.id
                    );
                    if (indexOf >= 0) {
                        this.timelineList.posts.splice(indexOf, 1);
                    }
                    // /remove os dados repetidos que acontecem por causa do real time

                    this.timelineList.posts.push(dataValues);
                    this.orderData();
                });

                if (response.length < 30) {
                    this.last = [];
                } else {
                    this.last = response[response.length - 1];
                }

                this.onAprovalCoinsChanged.next(this.timelineList.posts);

                this.handleHasNext(response.length);
            });
    }
    handleHasNext(length: number) {
        if (length === 30) {
            this.timelineHasNext.next(true);
        } else {
            this.timelineHasNext.next(false);
        }
    }
    getNextPage() {
         (this.last);
        this.firestore
            .collection(`${datastore}/aprovalCoinsRequest`, (ref) =>
                ref
                    .orderBy("id", "asc")
                    .orderBy("status", "asc")
                    .startAfter(this.last?.id)
                    .limit(10)
            )
            .valueChanges()
            .subscribe((response: any) => {
                

                response.forEach((dataValues) => {
                    // remove os dados repetidos que acontecem por causa do real time
                    const indexOf = this.timelineList.posts.findIndex(
                        (item) => item.id === dataValues.id
                    );
                    if (indexOf >= 0) {
                        this.timelineList.posts.splice(indexOf, 1);
                    }
                    // /remove os dados repetidos que acontecem por causa do real time

                    this.timelineList.posts.push(dataValues);
                    this.orderData();
                });

                this.last = response[response.length - 1];

                this.onAprovalCoinsChanged.next(this.timelineList.posts);
                this.handleHasNext(response.length);
            });
    }
    orderData() {
        this.timelineList.posts = _.orderBy(
            this.timelineList.posts,
            ["status"],
            ["asc"]
        );
    }

    // get data filtred
    getDataFiltred(status, initDate, endDate) {
       
        return new Promise((resolve, reject) => {
            let dados1 = this.firestore
                .collection(`${datastore}/aprovalCoinsRequest`, (ref) =>
                    ref
                        .where("status", "==", status)
                        .where("dateTimeStamp", ">=", initDate)
                        .where("dateTimeStamp", "<=", endDate)
                ).valueChanges()

            let dados2 = this.firestore
            .collection(`${datastore}/aprovalCoinsRequest`, (ref) =>
                ref
                    .where("status", "==", status.toString())
                    .where("dateTimeStamp", ">=", initDate)
                    .where("dateTimeStamp", "<=", endDate)
            ).valueChanges()

            
            let data = combineLatest(dados1, dados2).pipe(
                map(([dataOne, dataTwo]) => [...dataOne, ...dataTwo])
            )

           data.subscribe((res) =>{
                const dataA = res.map((item: any) => {
                       item.status = parseInt(item.status)
                        return {
                            id: item.id,
                            ...(item as any),
                        } as any;
                    });

                    this.onAprovalCoinsChanged.next(dataA);
                    resolve(dataA);
           })
            
            // METODO ANTIGO -- Metodo atual acima pega tanto string como number na variavel status no firebase
            //     .snapshotChanges()
            //     .subscribe((action) => {
            //         const data = action.map((item: any) => {
            //             console.log(typeof item.payload.doc.data().status)
            //             return {
            //                 id: item.payload.doc.id,
            //                 ...(item.payload.doc.data() as any),
            //             } as any;
            //         });

            //         this.onAprovalCoinsChanged.next(data);
            //         resolve(data);
            //     });

        });
     
    }
}
