import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductsModel } from 'app/models/products.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class StoreService implements Resolve<any> {

    products: any[];

    routeParams: any;

    onProductChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) { 
        this.onProductChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getProducts()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getProducts(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/products`, ref => ref.where('status', '==', true)).snapshotChanges().subscribe(action => {
                        this.products = action.map(item => {
                            return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as ProductsModel),
                                favorite: ['detalhes']
                            } as ProductsModel
                        });
                        this.onProductChanged.next(_.orderBy(this.products, ['displayName'],['asc']));
                        resolve(this.products);
                    });
                }
            })
        })
    }
}
