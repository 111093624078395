import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TypeOfRewardComponent } from "./type-of-reward.component";
import { RouterModule } from "@angular/router";
import {
    PoAccordionModule,
    PoButtonModule,
    PoFieldModule,
    PoInfoModule,
    PoModalModule,
    PoTableModule,
    PoTagModule,
} from "@po-ui/ng-components";
import { PoContainerModule } from "@po-ui/ng-components";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthGuard } from "app/service/auth.guard";
const routes = [
    {
        path: "rewards-types",
        component: TypeOfRewardComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [TypeOfRewardComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        PoFieldModule,
        PoContainerModule,
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoModalModule,
        PoTableModule,
        MatIconModule,
        PoButtonModule,
        ReactiveFormsModule,
    ],
})
export class TypeOfRewardModule {}
