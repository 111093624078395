import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { SalesComponent } from './sales.component';
import { SalesService } from 'app/service/sales.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { PoAccordionModule, PoButtonModule, PoContainerModule, PoFieldModule, PoInfoModule, PoModalModule, PoTableModule, PoTabsModule, PoTagModule } from '@po-ui/ng-components';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import {NgxPaginationModule} from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';


const routes = [
    {
        path: 'sales',
        component: SalesComponent,
        resolve: {
            sales: SalesService
        }
    }
]

@NgModule({
    declarations: [
        SalesComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        MatPaginatorModule,
        MatTableModule,
        PoButtonModule,
        MatIconModule,
        //PO Modules
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoModalModule,
        PoTableModule,        
        PoFieldModule,
        NgxPaginationModule,
        OrderModule,
        MatSortModule,
        PoTabsModule
      
    ],
    exports: [
        SalesComponent
    ]
})

export class SalesModule { }
