import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import {
    PoModalModule,
    PoTableModule,
    PoTooltipModule,
} from "@po-ui/ng-components";

import { ApprovalTermComponent } from "./approval-term.component";

@NgModule({
    declarations: [ApprovalTermComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        PoModalModule,
        PoTableModule,
        PoTooltipModule,
    ],
    entryComponents: [],
    exports: [ApprovalTermComponent],
})
export class ApprovalTermModule {}
