import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { AuthService } from "app/service/auth.service";
import { NotificationService } from "app/service/notification.service";
import { ShoppingCartService } from "app/service/shopping-cart.service";
import { PoModalAction, PoModalComponent } from "@po-ui/ng-components";
import { ModalNotifyComponent } from '../modal-notify/modal-notify.component';

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    login: any;
    userData: any;
    notificationsData: any;
    next;
    notifyPendent: any = 0
    // Private
    private _unsubscribeAll: Subject<any>;
    @ViewChild("modalNotificacao", { static: true }) modalNotificacao: PoModalComponent;


 
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public auth: AuthService,
        public notificationService: NotificationService,
        private shoppingCartService: ShoppingCartService,
        public notificationService2: NotificationService,
        public dialog: MatDialog
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "br",
                title: "Portuguese",
                flag: "br",
            },
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            // {
            //     id: "tr",
            //     title: "Turkish",
            //     flag: "es",
            // },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    
        this.auth.user$.subscribe(async (credentials) => {  
            this.userData = await credentials;
            await this.notificationService.getNotifications(this.userData.uid);
            await this.notificationService.getAllNotifications2(this.userData.uid)

        });
        
        this.notificationService.onNextChanged.subscribe((response) => {
            this.next = response;
        });
    }
    

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Quantidade de notificações do usuario
        this.notificationService.onNotificationChanged2.subscribe((response) => {
            setTimeout(async () => {
                this.notifyPendent = await response.length
            }, 200);
        });
       
        this.notificationService.onNotificationChanged.subscribe(async (response) => {
            let newArr = [];
            this.notificationsData = [];
            newArr = await response.filter( (el: { eraseNotify: boolean; }) => {
                return el.eraseNotify != true;
            });

            this.notificationsData = newArr;

        });
        

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (settings) => {
                this.horizontalNavbar =
                    await settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = await settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });
       
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

  

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

  
    


    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    handleNextPage() {
        this.notificationService.getNextPage(this.userData.uid);
    }

    async modalNotify(id){
        let interfaceNotify = {
            dateTimestamp: Date,
            description: '',
            uid: '',
            view: Boolean,
            idPurchase: String
        } as any
        let dadosUser = await this.notificationService.getNotificationByID(id).then((res) =>{
             return res.toPromise()
        })
        
        interfaceNotify = dadosUser.data()
        interfaceNotify.view = true
        
        if (dadosUser.data().idPurchase != undefined){
            await this.notificationService.updateNotificationByID(id, interfaceNotify)
            this.openDialog(interfaceNotify)
        }else{
            await this.notificationService.updateNotificationByID(id, interfaceNotify)
            this.openDialog(interfaceNotify)
        }

 
    }

    async modalNotifytoTrue(id){
        let interfaceNotify = {
            dateTimestamp: Date,
            description: '',
            uid: '',
            view: Boolean,
            idPurchase: String,
            eraseNotify: Boolean,
            typeNotify: String
        } as any
        let dadosUser = await this.notificationService.getNotificationByID(id).then((res) =>{
             return res.toPromise()
        })
        
        interfaceNotify = dadosUser.data()
        
        
        if (dadosUser.data().idPurchase != undefined){
            this.openDialog(interfaceNotify)
        }else{
            this.openDialog(interfaceNotify)
        }
        

    }
    
  
    async disableNotify(id){
        let interfaceNotify = {
            dateTimestamp: Date,
            description: '',
            uid: '',
            view: Boolean,
            idPurchase: String,
            eraseNotify: Boolean,
            typeNotify: String
        } as any
        let dadosUser = await this.notificationService.getNotificationByID(id).then((res) =>{
            return res.toPromise()
       })

        interfaceNotify = dadosUser.data()
        interfaceNotify.eraseNotify = true
        interfaceNotify.view = true
        this.notificationService.updateNotificationByID(id, interfaceNotify)
        
       
    }

    openDialog(dados: any): void {
        const dialogRef = this.dialog.open(ModalNotifyComponent, {
          width: '50%',
          data: dados,
        });
    
        dialogRef.afterClosed().subscribe(result => {
        //   console.log('The dialog was closed');
        });
      }

    async removeAllNotify(){
       this.notificationsData.forEach(dados => {
        dados.eraseNotify = true
        dados.view = true
        this.notificationService.updateNotificationByID(dados.id, dados)
        });

    }

    logout(){
        document.getElementById("widget-_hw").style.display = "none"
        this.auth.signOut()
        
    }
   

}
