import { Observable } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadBannerServiceService } from 'app/service/upload-banner-service.service';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';

@Component({
  selector: 'app-modal-uploadbanner',
  templateUrl: './modal-uploadbanner.component.html',
  styleUrls: ['./modal-uploadbanner.component.scss']
})
export class ModalUploadbannerComponent implements OnInit {
  srcImage: any
  srcImageLat: any
  numberImageLat: any;
  load: boolean;
  load2: boolean;
  load3: boolean;
  load4: boolean
  previewLatImage: string;
  message: string;
  duration: number;
  poType: string;
 
  constructor(public diagRef: MatDialogRef<ModalUploadbannerComponent>, private service: UploadBannerServiceService, private poNotification: PoNotificationService) { }
  numberImage: any
  bannersTop: any = []
  bannersLat: any = []
  previewTopImage: string

  @ViewChild('fileInputTop') fileInputTop: ElementRef;
  @ViewChild('fileInputLat') fileInputLat: ElementRef;
  
  
  
  
  
  
  async ngOnInit(): Promise<void> {
    

    await this.service.getBannersTop().then((res) =>{
      res.forEach((dados) =>{
        this.bannersTop.push(dados.data())
      })
    })

    await this.service.getBannersLat().then((res2) =>{
      res2.forEach((dados2) =>{
        this.bannersLat.push(dados2.data())
      })
    })
    // 
  

    // Cria os dados na colection caso esteja vazia, colocando uma imagem padrao 
    if (this.bannersLat.length != 3){
      this.service.createInitialPhotos('bannersLateral')
    }
    if (this.bannersTop.length != 3){
      this.service.createInitialPhotos('bannersTop')
    }


  }

  showNotification(messageType: string) {
    const poNotification: PoNotification = {
        message: this.message,
        duration: this.duration,
        orientation: 1,
    };
    switch (messageType) {
        case "success": {
            this.poNotification.success(poNotification);
            break;
        }
        case "error": {
            this.poNotification.error(poNotification);
            break;
        }
        case "warning": {
            this.poNotification.warning(poNotification);
            break;
        }
        case "information": {
            this.poNotification.information(poNotification);
            break;
        }
        default: {
            this.poNotification.success(poNotification);
            break;
        }
    }
    this.poType = "";
} 
  


  initUpload() {
    let uploadButton: HTMLElement = document.getElementById('uploadFile') as HTMLElement;
    uploadButton.click();
  }

  showUploadImage(file){
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
        this.srcImage = (<FileReader>event.target).result;
    }    
    reader.readAsDataURL(file.target.files[0]);
  }


  async uploadImageTop(){
    this.load4 = false
    await (await this.service.uploadFilesTop(this.fileInputTop.nativeElement.files, this.numberImage.toString())).toPromise().then((res)=>{
      setTimeout(() => {
        this.selectImageTop(this.numberImage)
        this.load4 = true
        this.message = `Upload Da imagem com sucesso !!!`
        this.poType = "success";
        this.showNotification(this.poType);
      }, 1000);
  }).catch((error) =>{
      this.load4 = true
      this.message = `Upload Da imagem falhou !!!`
      this.poType = "error";
      this.showNotification(this.poType);
  })
 
  }

  async uploadImageLat(){
    this.load3 = false
    await (await this.service.uploadFilesLat(this.fileInputLat.nativeElement.files, this.numberImageLat.toString())).toPromise().then((res)=>{
        setTimeout(() => {
          this.selectImageLat(this.numberImageLat)
          this.load3 = true
          this.message = `Upload da imagem Feito com sucesso !!!`
          this.poType = "success";
          this.showNotification(this.poType);
        }, 1000);
    }).catch((error) =>{
      this.load3 = true
      this.message = `Upload Da imagem falhou !!!`
      this.poType = "error";
      this.showNotification(this.poType);
    })
    
  }


  initUploadLat() {
    let uploadButton: HTMLElement = document.getElementById('uploadFileLat') as HTMLElement;
    uploadButton.click();
  }


  showUploadImageLat(file){
    var reader = new FileReader();
    reader.onload = (event: ProgressEvent) => {
        this.srcImageLat = (<FileReader>event.target).result;
    }    
    reader.readAsDataURL(file.target.files[0]);
  }


  selectImageTop(value){
    this.load = false
    this.previewTopImage = ''
    this.service.getImageLink(value, 'bannersTop').then((res) =>{
      res.docs.forEach(async (element) => {
          this.previewTopImage = await element.data().image
          this.load = true
      });
    })

    this.numberImage = value
  }

  selectImageLat(value){
    this.load2 = false
    this.previewLatImage = ''
    this.service.getImageLink(value, 'bannersLateral').then((res) =>{
      res.docs.forEach(async (element) => {
          this.previewLatImage = await element.data().image
          this.load2 = true
      });
    })

    this.numberImageLat = value
  }


  


}
