import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { RouterModule } from '@angular/router';

import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';
import { MatIconModule } from '@angular/material/icon';
import { LogsComponent } from './logs.component';
import { LogService } from 'app/service/log.service';
import {NgxPaginationModule} from 'ngx-pagination'
import { OrderModule } from 'ngx-order-pipe';
const routes = [
  {
      path: 'logs',
      component: LogsComponent,
      resolve: {
          logs: LogService
      }
  }
]

@NgModule({
  declarations: [
    LogsComponent
  ],
  imports: [
    RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        NgxPaginationModule,
        MatIconModule,
        PoContainerModule,
        PoModalModule,
        PoPageModule,
        PoWidgetModule,
        PoTableModule, 
        PoButtonModule, 
        PoFieldModule,
        OrderModule
  ],
  exports: [
    LogsComponent
  ]

})
export class LogsModule { }
