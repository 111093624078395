import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { datastore } from 'app/config';
import { PurchasesCollabModel, PurchasesModel } from 'app/models/purchases.model';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class SalesService implements Resolve<any> {

    sales: any[];
    sales2: any[];
    routeParams: any;

    onSalesChanged: BehaviorSubject<any>;
    onSalesChangedCollab: BehaviorSubject<any>;
    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) {
        this.onSalesChanged = new BehaviorSubject([]);
        this.onSalesChangedCollab = new BehaviorSubject([])
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        // this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getSales(),
                this.getSalesCollab()
            
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }
    
    getSales(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/purchases`).snapshotChanges().subscribe(action => {
                        this.sales = action.map(item => {
                             return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as PurchasesModel),
                                favorite: ['detalhes']
                            } as PurchasesModel
 
                        });
                        this.onSalesChanged.next(_.orderBy(this.sales, ['deliveryStatus'],['asc']));
                        resolve(this.sales);
                    });
                }                
            });             
        })
    }
    
    getSalesCollab(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/purchasesCollaborator`).snapshotChanges().subscribe(action => {
                        this.sales2 = action.map(item => {
                             return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as PurchasesCollabModel),
                                favorite: ['detalhes']
                            } as PurchasesCollabModel
 
                        });
                        this.onSalesChangedCollab.next(_.orderBy(this.sales2, ['deliveryStatus'],['asc']));
                        resolve(this.sales2);
                    });
                }                
            });             
        })
    }


    
    updateSale(id: string, data: PurchasesModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/purchases/${id}`).update(data);
        })       
    }  
 
    updateSaleCollab(id: string, data: PurchasesModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/purchasesCollaborator/${id}`).update(data);
        })       
    }  

  

}
