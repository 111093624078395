import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { AuthService } from './auth.service';
import { UsersQuestModel } from 'app/Models/usersQuest.model';

@Injectable({
    providedIn: 'root'
})

export class MyQuestsService implements Resolve<any> {

    quests: any;
    routeParams: any;

    onQuestsChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) { 
        this.onQuestsChanged = new BehaviorSubject([]);           
    }   

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params; 
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getMyQuests()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }    
    
    getMyQuests(){
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe(credentials =>{
                if(credentials){
                    this.firestore.collection(`${datastore}/usersQuest`, ref => ref.where('userId', '==', credentials.uid)).snapshotChanges().subscribe(action => {
                        this.quests = action.map(item => {
                            return {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data() as UsersQuestModel),
                                favorite: ['detalhes']
                            } as UsersQuestModel
                        });
                        this.onQuestsChanged.next(_.orderBy(this.quests, ['questName'],['asc']));
                        resolve(this.quests);
                    });
                }                
            });             
        })
    }
}
