
<h2 mat-dialog-title>Deseja realmente postar?</h2>
<mat-dialog-content class="mat-typography">
  <!-- <h3>Develop across all platforms</h3>
  <p>Learn one way to build applications with Angular and reuse your code and abilities.</p> -->

</mat-dialog-content>
<br>
<br>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="handleSavePost()">Confirmar</button>
</mat-dialog-actions>