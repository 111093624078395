import { Component, Inject, OnInit } from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthService } from "app/service/auth.service";
import { UserService } from "app/service/user.service";
import Post from "../model-post";
import { TimelineService } from "../timeline.service";

@Component({
    selector: "modal-accept",
    templateUrl: "modal.component.html",
})
export class DialogContentExampleDialog implements OnInit {
    constructor(
        private databaseService: TimelineService,
        public matDialogRef: MatDialogRef<DialogContentExampleDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {}
    userData;
    ngOnInit() {
        this.userData = this._data.userData;
    }

    handleSavePost() {
        const dataPost: Post = {
            user: {
                name: this.userData.displayName,
                avatar: this.userData.photoURL,
                id: this.userData.id
            },
            message: this._data.newPost.nativeElement.value,
            time: `${new Date().toLocaleDateString()} as ${new Date().toLocaleTimeString()}`,
            type: "post",
            like: [],
            comments: [],
        };
        try {
            this.databaseService.newPost(dataPost);
        } catch (error) {
            console.error(error);
        }
    }
}
