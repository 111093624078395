import { UserService } from './../../service/user.service';
import { Component, OnInit, ViewChild } from '@angular/core'
import {
  PoModalAction,
  PoModalComponent,
  PoNotification,
  PoNotificationService,
  PoTableAction,
  PoTableColumn,
  PoTableColumnLabel,
} from '@po-ui/ng-components'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { QuestsModel } from 'app/Models/quests.model'
import { QuestsService } from 'app/service/quests.service'
import { AuthService } from 'app/service/auth.service'
import { Router } from '@angular/router'
import { log } from 'console';
import { SPointzUsers } from 'app/Models/s-pointz-users';
import { ApprovalCoinsService } from 'app/service/aproval-solic-coins.service';

@Component({
  selector: 'app-super-pointz',
  templateUrl: './super-pointz.component.html',
  styleUrls: ['./super-pointz.component.scss'],
  providers: [PoNotificationService],
})


export class SuperPointzComponent implements OnInit {
  @ViewChild("addGroup") addGroup: PoModalComponent
  @ViewChild("rewardGroup") rewardGroup: PoModalComponent
  @ViewChild("rewardTheGroup") rewardTheGroup: PoModalComponent
  @ViewChild("deleteGroup") deleteTheGroup: PoModalComponent

  actions: Array<PoTableAction> = [
    {
      action: this.actionsGroups.bind(this),
      icon: 'po-icon-info',
      label: 'Recompensar',
    },
    {
      action: this.deleteGroup.bind(this),
      icon: 'po-icon-info',
      label: 'Excluir',
    },
  ]

  public listQuests: QuestsModel[]
  public quest: QuestsModel
  public isUpdate = false
  public questUpdateId = ''

  reactiveForm: FormGroup
  reactiveFormGroup: FormGroup
  reactiveFormRecomp: FormGroup
  columns: Array<PoTableColumn>
  users: any = [];
  groupsSp: any = [];
  groupToDel: any



  //Po Notification
  message: string
  duration = 5000
  poType: string
  createdBy: string
  groupColabs: any
  groupData: any
  constructor(
    private fb: FormBuilder,
    private poNotification: PoNotificationService,
    private service: QuestsService,
    private userService: UserService,
    public auth: AuthService,
    private router: Router,
    private aprovalService: ApprovalCoinsService
  ) {
    this.userService.getGroupSuperPointz()
    this.createReactiveForm();
    this.createReactiveForm2();
    this.createReactiveFormGroup()
    this.columns = [
      { property: 'nameGroup', label: 'Nome do Grupo', visible: true },
      { property: 'createdBy', label: 'Criado por', visible: true },
      { property: 'lengthGroup', label: 'Quantidade de membros', visible: true },
      {
        property: 'dateFormat',
        label: 'Data De criação',
        type: 'date',
        visible: true,
      }
    ]
  }

  ngOnInit(): void {
    this.auth.user$.subscribe( async (credentials) => {
      if (credentials.userPerfil == '3') {
        this.router.navigate(['/sample'])
      } else {
        this.service.onQuestChanged.subscribe((quests) => {
          this.listQuests = quests
        })

        this.createdBy = credentials.displayName

        await this.userService.getAllUsers().then(async (res) =>{
          this.users = res.docs.map((users) =>{
            return {
              value: {uid: users.id, displayName: users.data().displayName, googlechatId: users.data().googlechatId},
              label: users.data().displayName
            }
          })
        })

        this.userService.groupsSp.subscribe((res) =>{
          this.groupsSp = res
        
        })
      }
    })   
  }

  openNewModal() {
    this.clearForm()
    this.isUpdate = false
    this.addGroup.open()
  }
  openNewModalReward() {
    this.clearFormReward()
    this.isUpdate = false
    this.rewardGroup.open()
  }
  
  SaveAction: PoModalAction = {
    action: () => {
      var fields = this.reactiveFormGroup.value
      let saveGroup = {} as SPointzUsers
     
      if (this.reactiveFormGroup.value) {
  
        saveGroup.nameGroup = fields.nameGroup
        saveGroup.usersGroup = fields.membersGroup
        saveGroup.createdBy = this.createdBy
        saveGroup.dateCreated = new Date();
      
        this.userService.addGroupSuperPointz(saveGroup).then((res) =>{
          this.addGroup.close();
          this.poType = 'sucess'
          this.message = 'Grupo criado com sucesso'
          this.showNotification(this.poType)
          this.userService.getGroupSuperPointz()
          this.clearFormGroup();
        })
        
        saveGroup.lengthGroup = fields.membersGroup.length
        // this.groupsSp.push(saveGroup)
       
    }else{
      this.poType = 'error'
        this.message = 'Preencha os campos corretamente !'
        this.showNotification(this.poType)
    }
    
    },
    label: 'Salvar',
  }

  CloseAction: PoModalAction = {
    action: () => {
      this.clearFormGroup();
      this.addGroup.close()
    },
    label: 'Cancelar',
  }

  SaveActionReward: PoModalAction = {
    action: () => {
      var fields = this.reactiveFormRecomp.value
      let saveReward = {} as any

      if (this.reactiveFormRecomp.valid) {
    
        saveReward.coins = fields.coinsValue
        saveReward.approvalFrom = 'Super Pointz';
        saveReward.displayName = fields.membersGroup.length <= 1 ? fields.membersGroup[0].displayName : 'Diversos';
        saveReward.usersGroup = fields.membersGroup;
        saveReward.sentBy = this.createdBy;
        saveReward.dateTimeStamp = new Date().getTime();
        saveReward.status = 1;
        saveReward.titleOfReward = fields.reasonText;
        saveReward.typeOfReward = 'Para Loja'
      
        if (this.reactiveFormRecomp.valid){
           this.aprovalService.newPost(saveReward).then()
           this.poType = 'sucess'
           this.message = 'Recompensa enviada para aprovação'
           this.showNotification(this.poType);
           this.rewardGroup.close()
        }else{
            this.poType = 'error'
            this.message = 'Campos invalidos'
            this.showNotification(this.poType);
        }
  
      }else{
    
          this.poType = 'error'
          this.message = 'Campos invalidos'
          this.showNotification(this.poType);
      }



    },
    label: 'Recompensar',
  }

  CloseActionRewards: PoModalAction = {
    action: () => {
      this.clearFormReward()
      this.rewardGroup.close()
    },
    label: 'Cancelar',
  }

  SaveRewardTheGroup: PoModalAction = {
    action: () => {
      var fields = this.reactiveForm.value
      let saveReward = {} as any
     
      if (this.reactiveForm.valid) {
        saveReward.coins = fields.coinsValue
        saveReward.approvalFrom = 'Super Pointz';
        saveReward.displayName = this.groupData.usersGroup.length <= 1 ? this.groupData.usersGroup[0].displayName : 'Diversos';
        saveReward.usersGroup = this.groupData.usersGroup;
        saveReward.sentBy = this.groupData.createdBy;
        saveReward.dateTimeStamp = new Date().getTime();
        saveReward.status = 1;
        saveReward.titleOfReward = fields.reasonText;
        saveReward.typeOfReward = 'Para Loja'
        if (saveReward.titleOfReward != '' && fields.coinsValue != 0 && fields.coinsValue != null && fields.coinsValue != ''){
          this.aprovalService.newPost(saveReward).then()
     
         
          this.poType = 'sucess'
          this.message = 'Recompensa enviada para aprovação'
          this.showNotification(this.poType);
          this.rewardTheGroup.close()
        }else{
          this.poType = 'error'
          this.message = 'Campos invalidos'
          this.showNotification(this.poType);
        }

      }else{
  
          this.poType = 'error'
          this.message = 'Campos invalidos'
          this.showNotification(this.poType);
      }
    
        
    },
    label: 'Recompensar',
  }

  CloseActionTheGroup: PoModalAction = {
    action: () => {
      this.reactiveForm.reset()
      this.rewardTheGroup.close()
    },
    label: 'Cancelar',
  }


  actionsGroups(item: any) {
    this.reactiveForm.controls['nameGroup'].setValue(item.nameGroup)
    this.reactiveForm.controls['membersGroup'].setValue(item.usersGroup)
    if (this.reactiveForm.value.reasonText == undefined){
      this.reactiveForm.value.reasonText = ''
    }
    this.groupColabs = item.usersGroup
    this.groupData = item

    this.rewardTheGroup.open()
  
  }
  deleteGroup(item: any) {
    this.groupToDel = item
    this.deleteTheGroup.open()
  }




  deleteGroupByID: PoModalAction = {
    action: () => {
      this.service.deleteGroupSp(this.groupToDel).then((res)=>{
          this.poType = 'sucess'
          this.message = 'Grupo deletado com sucesso'
          this.userService.getGroupSuperPointz()
          this.showNotification(this.poType);
          this.deleteTheGroup.close()
      }) 
    },
    label: 'Deletar Grupo',
  }

  CloseDeleteGroup: PoModalAction = {
    action: () => {
      this.deleteTheGroup.close()
    },
    label: 'Cancelar',
  }





  createReactiveForm() {

    this.reactiveForm = this.fb.group({
      nameGroup: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(30),
        ]),
      ],
      membersGroup: [Array, Validators.required],
      coinsValue: [Number, Validators.required],
      reasonText: [''],
    })
  }
  createReactiveForm2() {
    this.reactiveFormRecomp = this.fb.group({
      membersGroup: ['', Validators.required],
      coinsValue: [Number ,Validators.required],
      reasonText: ['',Validators.required],
    })
  }
  createReactiveFormGroup() {
    this.reactiveFormGroup = this.fb.group({
      membersGroup: ['', Validators.required],
      nameGroup: ['',Validators.required],
    })
  }

 
  clearForm() {
    this.reactiveForm.reset()
    this.reactiveForm = this.fb.group({
      nameGroup: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(30),
        ]),
      ],
      membersGroup: [Array, Validators.required],
      coinsValue: [Number, Validators.required],
      reasonText: [''],
    })
  }
  clearFormReward() {
    this.reactiveFormRecomp.reset()
    this.reactiveFormRecomp = this.fb.group({
      membersGroup: ['', Validators.required],
      coinsValue: [Number ,Validators.required],
      reasonText: ['',Validators.required],
    })
  }


  clearFormGroup() {
    this.reactiveFormGroup.reset()
    this.reactiveFormGroup = this.fb.group({
      membersGroup: ['', Validators.required],
      nameGroup: ['',Validators.required],
    })
  }

  showNotification(messageType) {
    const poNotification: PoNotification = {
      message: this.message,
      duration: this.duration,
      orientation: 1,
    }
    switch (messageType) {
      case 'success': {
        this.poNotification.success(poNotification)
        break
      }
      case 'error': {
        this.poNotification.error(poNotification)
        break
      }
      case 'warning': {
        this.poNotification.warning(poNotification)
        break
      }
      case 'information': {
        this.poNotification.information(poNotification)
        break
      }
      default: {
        this.poNotification.success(poNotification)
        break
      }
    }
    this.poType = ''
  }


}
