import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { QuestsModel } from 'app/Models/quests.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AuthService } from './auth.service';
import { UsersQuestModel } from 'app/Models/usersQuest.model';

@Injectable({
    providedIn: 'root'
})

export class SampleService implements Resolve<any> {

    quests: string[] = [];
    quest: any;
    routeParams: any;
    formData: QuestsModel;
    onQuestChanged: BehaviorSubject<any>;
    today: any = new Date();
    userId: string;
    invokeShoppingCart: any;

    constructor(
        private firestore: AngularFirestore,        
        private firestorage: AngularFireStorage,
        private datePipe: DatePipe,
        public auth: AuthService
    ) { 
        this.onQuestChanged = new BehaviorSubject([]);
        // this.onUseChanged = new BehaviorSubject([]);

        this.auth.user$.subscribe(credentials =>{
            if(credentials){
                this.userId = credentials.uid
            }
        })        
        this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getQuests(),
                // this.getQuestId()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getQuests(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/quests`, ref => ref.where('status', '==', true)).snapshotChanges().subscribe(action => {
                this.quests = []
                action.forEach(item => {
                    let data: any = item.payload.doc.data();
                    if ( data.startDate <= this.today ) {
                        if ( _.isEmpty(data.endDate) || data.endDate >= this.today ) {
                            this.quests.push({'id': item.payload.doc.id, ...data})
                        }
                    }
                });
                this.onQuestChanged.next(_.orderBy(this.quests, ['name'],['asc']));
                resolve(this.quests);
            });
        })
    }

    insertUserQuest(file: any, data: UsersQuestModel) {
        if (file == null){
            return new Promise((resolve, reject) => {
                this.firestore.collection(`${datastore}/usersQuest`).add(data)
                
            })
        }else{
            return new Promise((resolve, reject) => {
                this.firestore.collection(`${datastore}/usersQuest`).add(data).then(docRef => {
                    this.uploadFiles(file, docRef.id, resolve)
                });
            })
        }
    }

    uploadFiles(files: any, usersQuestId: string, resolve) {
        if (files.length == 0) {
          resolve(true)
        }
        for ( let i = 0; i < files.length; i++ ) {
            let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
            let arquivo = files.item(i);
            let location = (`${datastore}/usersQuest/${usersQuestId}/${dateNow}/${arquivo.name}`);
            let fileRef = this.firestorage.ref(`${datastore}/usersQuest/${usersQuestId}/${dateNow}/${arquivo.name}`);
            let task = fileRef.put(arquivo);
            return task.snapshotChanges().pipe(finalize(() => {
                fileRef.getDownloadURL().subscribe(link => {
                    this.firestore.doc(`${datastore}/usersQuest/${usersQuestId}`).update({
                        images: [{
                            imgLocation: location,
                            filename: arquivo.name,
                            imgLink: link
                        }]
                    });
                });
            })
          ).subscribe()
        }
    }
}
