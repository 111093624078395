

<h2 mat-dialog-title>Escreva seu comentario...</h2>
<mat-dialog-content class="mat-typography">
  <!-- <h3>Develop across all platforms</h3>
  <p>Learn one way to build applications with Angular and reuse your code and abilities.</p> -->
  <mat-form-field appearance="fill" style="width: 100%;">

    <textarea placeholder="Adicione um comentario..." #txtComentario [(ngModel)]="comment" matInput></textarea>
  </mat-form-field>
</mat-dialog-content>
<br>
<br>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="handleSaveComment()">Confirmar</button>
</mat-dialog-actions>