
<div class="po-row">
    <po-container class="po-lg-12 po-xl-12" style="padding: 0;">
        <div class="po-row">
            <po-slide [p-slides]="slideStore" [p-height]="250" [p-interval]="10000"> </po-slide>
         
            <po-tabs style="margin-bottom: 10px; display: flex; flex-direction: column;">
                <po-tab style="display: flex; flex-direction: column;" p-label="Loja Principal" [p-active]="true">
                    <div style="display: flex; flex-direction: column;">
                        <div>
                            <po-widget class="po-xl-3 po-lg-3" style="padding: 10px;" *ngFor="let item of listProducts | paginate: { itemsPerPage: 12, currentPage: p, id: 'listProduct' }">
                                <div class="po-text-center sample-widget-text-subtitle hlp-product-name">
                                    <strong>{{item.name}}</strong>
                                </div>
                                <div class="po-font-subtitle po-text-center">
                                    <img class="img-fixed-size" *ngFor="let image of item.images" [src]="image.imgLink" alt=""
                                        style="border-radius: 5px;">
                                </div>
                                <div *ngIf="item.quantity == 0">
                                    <div class="po-text-center po-mt-2 no-product">
                                        Sem estoque!
                                    </div>
                                    <div class="po-row hlp-margin-top-10">
                                        <div class="po-md-12 po-lg-12 hlp-no-padding">
                                            <button type="button" (click)="productModalOpen(item)" class="po-button po-text-ellipsis">
                                                <span class="po-icon po-icon-eye"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                
                                <div *ngIf="item.quantity > 0">
                                    <div class="po-text-center po-mt-2 product-price" *ngIf="userCash >= item.price">
                                        {{item.price}} <span class="po-icon po-icon-star-filled"
                                            style="color: #ffb300 !important"></span>
                                    </div>
                                    <div class="po-text-center po-mt-2 no-product" *ngIf="item.price > userCash">Sem Saldo
                                        ({{item.price}})</div>
                                    <div class="po-row hlp-margin-top-10">
                                        <div class="po-md-6 po-lg-6 hlp-no-padding">
                                            <button type="button" *ngIf="item.price > userCash" disabled=disabled
                                                class="po-button po-text-ellipsis btn-left">
                                                <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                            </button>
                                            <button type="button" *ngIf="userCash >= item.price" (click)="addProductCart(item)"
                                                class="po-button po-text-ellipsis btn-left">
                                                <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                            </button>
                                            <!-- <button type="button" *ngIf="userCash >= item.price" (click)="addProductCart(item)" class="po-button po-text-ellipsis btn-left">
                                                <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                            </button> -->
                                        </div>
                                        <div class="po-md-6 po-lg-6 hlp-no-padding">
                                            <button type="button" (click)="productModalOpen(item)"
                                                class="po-button po-text-ellipsis btn-right">
                                                <span class="po-icon po-icon-eye"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </po-widget>
                        </div>
                        <pagination-controls id="listProduct" previousLabel="Voltar" nextLabel="Proximo" (pageChange)="p = $event"></pagination-controls> 
                    </div>
                    
                </po-tab>
                
                <po-tab p-label="Loja Colaboradores" *ngIf="statusStoreCola === true">
                    <div *ngIf="listProductsColla.length == 0" style="text-align: center; display: flex; flex-direction: column; align-items: center; margin-top: 50px;">
                        <span style="font-weight: 800;">AINDA NAO EXISTEM PRODUTOS CADASTRADOS</span>
                        <img style="margin-top: 50px;" width="110px" src="/assets/images/ecommerce/semProduto.png" alt=""> 
                    </div>
                    
                    <div style="display: flex; flex-direction: column;" *ngIf="listProductsColla.length != 0" >
                        <div>
                            <po-widget  class="po-xl-3 po-lg-3" style="padding: 10px;" *ngFor="let item of listProductsColla | paginate: { itemsPerPage: 12, currentPage: c, id: 'listProductColla' }">
                                <div *ngIf="item.userid != userId" class="">
                                    <div class="po-text-center sample-widget-text-subtitle hlp-product-name">
                                        <strong>{{item.name}}</strong>
                                    </div>
                                    <div class="po-font-subtitle po-text-center">
                                        <img class="img-fixed-size" *ngFor="let image of item.images" [src]="image.imgLink" alt=""
                                            style="border-radius: 5px;">
                                    </div>
                                    <div *ngIf="item.quantity == 0">
                                        <div class="po-text-center po-mt-2 no-product">
                                            Sem estoque!
                                        </div>
                                        <div class="po-row hlp-margin-top-10">
                                            <div class="po-md-12 po-lg-12 hlp-no-padding">
                                                <button type="button" (click)="productModalOpen(item)" class="po-button po-text-ellipsis">
                                                    <span class="po-icon po-icon-eye"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="po-text-center po-mt-2 po-text-center sample-widget-text-subtitle hlp-product-name"><p>Quem Anunciou ? <span class="hoverName" p-tooltip="{{item.userName}}" style="font-weight: 800;">{{item.userName}}</span></p></div>
                                   
            
                                    <div *ngIf="item.quantity > 0">
                                        <div class="po-text-center po-mt-2 product-price" *ngIf="userCash >= item.price">
                                            {{item.price}} <span class="po-icon po-icon-star-filled"
                                                style="color: #ffb300 !important"></span>
                                        </div>
                                        <div class="po-text-center po-mt-2 no-product" *ngIf="item.price > userCash">Sem Saldo
                                            ({{item.price}})</div>
                                        <div class="po-row hlp-margin-top-10">
                                            <div class="po-md-6 po-lg-6 hlp-no-padding">
                                                <button type="button" *ngIf="item.price > userCash" disabled=disabled
                                                    class="po-button po-text-ellipsis btn-left">
                                                    <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                                </button>
                                                <button type="button" *ngIf="userCash >= item.price" (click)="addProductCartColla(item)"
                                                    class="po-button po-textmoedasParaColaborador-ellipsis btn-left">
                                                    <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                                </button>
                                                <!-- <button type="button" *ngIf="userCash >= item.price" (click)="addProductCart(item)" class="po-button po-text-ellipsis btn-left">
                                                    <span class="po-button-label ng-star-inserted po-icon po-icon-cart"></span>
                                                </button> -->
                                            </div>
                                            <div class="po-md-6 po-lg-6 hlp-no-padding">
                                                <button type="button" (click)="productModalOpen(item)"
                                                    class="po-button po-text-ellipsis btn-right">
                                                    <span class="po-icon po-icon-eye"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="item.userid == userId">
                                    <div class="po-text-center sample-widget-text-subtitle hlp-product-name" >
                                        <strong>{{item.name}}</strong>
                                    </div>
                                    <div class="po-font-subtitle po-text-center">
                                        <img class="img-fixed-size" *ngFor="let image of item.images" [src]="image.imgLink" alt=""
                                            style="border-radius: 5px;">
                                    </div>
                                    <div *ngIf="item.quantity == 0">
                                        <div class="po-text-center po-mt-2 no-product">
                                            Sem estoque!
                                        </div>
                                        <div class="po-row hlp-margin-top-10">
                                            <div class="po-md-12 po-lg-12 hlp-no-padding">
                                                <button type="button" (click)="productModalOpen(item)" class="po-button po-text-ellipsis">
                                                    <span class="po-icon po-icon-eye"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="po-text-center po-mt-2" style="white-space: nowrap; overflow: hidden; "><p>Quem Anunciou ? <span class="po-text-center sample-widget-text-subtitle hlp-product-name hoverName" style="font-weight: 800; white-space: nowrap">{{item.userName}}</span></p></div>
                    
                                    <div *ngIf="item.quantity > 0">
                                        <div class="po-text-center po-mt-2 product-price" *ngIf="userCash >= item.price">
                                            {{item.price}} <span class="po-icon po-icon-star-filled"
                                                style="color: #ffb300 !important"></span>
                                        </div>
                                        <div class="po-text-center po-mt-2 no-product" *ngIf="item.price > userCash">Sem Saldo
                                            ({{item.price}})</div>
                                        <div class="po-row hlp-margin-top-10">
                                            <div class="po-md-6 po-lg-6 hlp-no-padding" style="margin: 0 auto;">
                                                <button type="button" (click)="productModalOpen(item)"
                                                    class="po-button ">
                                                    <span class="po-icon po-icon-eye"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </po-widget>
                        </div>
                        <pagination-controls  id="listProductColla" previousLabel="Voltar" nextLabel="Proximo" (pageChange)="c = $event"></pagination-controls>
                    </div>
                </po-tab>
                 
            </po-tabs>
        </div>
    </po-container>
</div>



<po-modal #productModal p-click-out="false" p-title="Detalhes do Produto" [p-primary-action]="CloseAction">
    <po-widget class="po-lg-3" style="margin-top: 10px;">
        <div class="po-font-subtitle po-text-center">
            <img class="img-fixed-size" *ngFor="let item of productDetail.images" [src]="item.imgLink">
        </div>
    </po-widget>
    <po-widget class="po-lg-9 po-text-center" style="margin-top: 10px;">
        <div class="po-row">
            <po-info class="po-lg-8 po-text-center" p-label="Nome" p-value={{productDetail.name}}></po-info>
            <po-info class="po-lg-4 po-text-center" p-label="Valor" p-value={{productDetail.price}}></po-info>
            <po-info class="po-lg-8 po-text-center" p-label="Descrição" p-value={{productDetail.description}}></po-info>
            <po-info class="po-lg-4 po-text-center" p-label="Estoque" p-value={{productDetail.quantity}}></po-info>
        </div>
    </po-widget>
</po-modal>