

<div class="header mat-elevation-z4 primary" fxLayout="row" fxLayoutAlign="space-between center">


    <ng-container *ngIf="selectedContact === null">

        <div class="title ml-16" fxLayout="row" style="flex: 1;" fxLayoutAlign="start center"
            (click)="unfoldSidebarTemporarily()">
            <div style="width: 100%; display: flex;">
                <mat-icon class="s-32 fuse-white-fg">chat</mat-icon>
                <h3 class="ml-12">Timeline</h3>
            </div>
            <div style="width: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
                <po-button class="po-lg-6" p-type="primary" p-small="true" p-label="Filtro"
                    *ngIf="timelineService.isFilter == false" (click)="openFilterModal()" p-icon="po-icon-filter">
                </po-button>
                <po-button class="po-lg-12" p-type="danger" p-small="true" p-label="Limpar Filtro"
                    *ngIf="timelineService.isFilter == true" (click)="resetFilter()" p-icon="po-icon-delete">
                </po-button>
            </div>
        </div>

    </ng-container>


    <ng-container *ngIf="selectedContact !== null">

        <div class="title" fxLayout="row" fxLayoutAlign="start center">
            <img [src]="selectedContact.avatar" class="avatar mx-16">
            <h3 class="text-truncate">{{selectedContact.name}}</h3>
        </div>

    </ng-container>

    <button mat-icon-button class="toggle-sidebar-folded mr-8" (click)="foldSidebarTemporarily();resetChat();" fxHide
        fxShow.gt-md>
        <mat-icon class="secondary-text s-20">close</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-open mr-8" (click)="toggleSidebarOpen();resetChat();" fxHide.gt-md>
        <mat-icon class="secondary-text">close</mat-icon>
    </button>

</div>

<div class="content">

    <!-- Contacts -->
    <div id="contacts-list" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
        <div class="txt-vertical">
            <p>
                Timeline
            </p>

        </div>


        <div *ngFor="let contact of contacts" class="contacts-list-item" [ngClass]="contact.status"
            [class.active]="contact.id === selectedContact?.id" (click)="toggleChat(contact)">

            <img class="avatar" [src]="contact.avatar" [matTooltip]="contact.name" matTooltipPosition="left">
            <div class="unread-count" *ngIf="contact.unread">{{contact.unread}}</div>
            <div class="status-icon" [ngClass]="contact.status"></div>

        </div>

    </div>
    <!-- / Contacts -->

    <!-- Chat -->
    <div id="chat" fxLayout="column" fxFlex="1 1 auto">

        <div id="messages" class="messages" fxFlex="1 1 auto" fusePerfectScrollbar>

            <ng-container *ngIf="chat && chat.dialog && chat.dialog.length > 0">

                <div *ngFor="let message of chat.dialog; let i = index" class="message-row" [ngClass]="{
                            'me': message.who === user.id,
                            'contact': message.who !== user.id,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }">

                    <img *ngIf="shouldShowContactAvatar(message, i)" src="{{selectedContact.avatar}}" class="avatar">

                    <div class="bubble">
                        <div class="message">{{message.message}}</div>
                        <div class="time secondary-text">{{message.time | date:'short'}}</div>
                    </div>

                </div>

            </ng-container>

            <ng-container *ngIf="selectedContact && chat && chat.dialog && chat.dialog.length === 0">

                <div class="no-messages-icon">
                    <mat-icon class="s-128 fade-text">chat</mat-icon>
                </div>

                <div class="no-messages secondary-text">
                    Start a conversation by typing your message below.
                </div>

            </ng-container>

            <ng-container>
                <profile-timeline></profile-timeline>
            </ng-container>

        </div>

        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedContact">

            <form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)" fxFlex fxLayout="row"
                fxLayoutAlign="start center">

                <mat-form-field class="message-text" fxFlex floatLabel="never" appearance="standard">
                    <textarea matInput #replyInput ngModel name="message" placeholder="Type your message" [rows]="1"
                        [matTextareaAutosize]="true"></textarea>
                </mat-form-field>

                <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message">
                    <mat-icon class="secondary-text">send</mat-icon>
                </button>

            </form>

        </div>

        <div class="container-posts" style="margin: 10px !important; padding: 10px;">
            <div class="content-container-posts">

                <!-- <h3 class="po-field-title">Enviar Estrelas!</h3> -->
                <!-- <textarea class="text-area-custom" #timeLine name="textarea" p-auto-focus="true"
                    p-label="Enviar Estrelas"></textarea> -->
                <div class="po-row" *ngIf="auth.user$ | async as user">
                    <po-button class="po-lg-12" p-type="primary" p-label="RECOMPENSAR" *ngIf="user.trade != 0"
                        (click)="openNewModal()" p-icon="po-icon-star"></po-button>
                    <po-button class="po-lg-12" p-type="danger" p-label="Sem moeda" *ngIf="user.trade == 0"
                        p-icon="po-icon-star"></po-button>
                </div>

            </div>
        </div>
        <!-- / Chat -->
    </div>

</div>

<po-modal #modalRecompensa p-click-out="false" p-size="xl" p-title="Recompensar" [p-primary-action]="SaveAction"
    [p-secondary-action]="CloseAction">
    <form [formGroup]="reactiveFormRecompensa">
        <div class="po-row">
            <po-select class="po-sm-12 po-md-12" formControlName="users" p-clean p-label="Usuário *"
                [p-options]="userList"></po-select>
        </div>
        <div class="po-row">
            <po-select class="po-sm-12 po-md-12" formControlName="coins" p-clean p-label="Recompensa *"
                [p-options]="valuesCoins"></po-select>
        </div>
        <div class="po-row">
            <po-textarea #textAreaMotivo class="po-sm-12 po-md-12" formControlName="motivo" name="textarea *" p-label="Motivo *">
            </po-textarea>
            <div (click)="displayOnOFF()" style="width: 28px; height: 28px; padding: 5px; cursor: pointer; background-color: rgba(0, 0, 0, 0.1); position: relative; bottom: 36px; left: 8px;">
                <ngx-emoji [emoji]="{ id: 'grinning', skin: 3 }" size="18"></ngx-emoji>
            </div>
            <div #emojiDisplay>
                <emoji-mart title="Escolha o emoji…" emoji="point_up"  (emojiClick)="addEmoji($event)"></emoji-mart>
            </div>
        </div>
        
        <div class="po-row">
            <po-checkbox #checkedRecompensa formControlName="accept" style="margin-left: 10px;margin-top: 12px;"
                [(ngModel)]="disabled" p-label="Autorizo o debito para essa recompensação."
                (change)="checkChange($event)"> </po-checkbox>
        </div>
    </form>
</po-modal>

<po-modal #filterTimeline p-click-out="false" p-size="xl" p-title="Filtro" [p-primary-action]="SaveFilterAction"
    [p-secondary-action]="CloseFilterAction">
    <form [formGroup]="reactiveFormFilter">
        <div class="po-row">
            <po-select class="po-sm-12 po-md-12" formControlName="usersFilter" p-clean p-label="Usuário *"
                [p-options]="userListFilters"></po-select>
        </div>
        <div class="po-row">
            <po-select class="po-sm-12 po-md-12" formControlName="tipoFilter" p-clean p-label="Recompensa"
                [p-options]="valuesTipoFiltro"></po-select>
        </div>
    </form>
</po-modal>