import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SystemParametersModel } from 'app/models/systemparameters.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class SystemParamsService implements Resolve<any> {

    systemParams: any[];

    routeParams: any;

    onSystemParamsChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore
    ) { 
        this.onSystemParamsChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getSystemParams(),
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getSystemParams(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/systemparameters`).snapshotChanges().subscribe(action => {
                this.systemParams = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as SystemParametersModel),
                        favorite: ['detalhes']
                    } as SystemParametersModel
                });
                this.onSystemParamsChanged.next(_.orderBy(this.systemParams));
                resolve(this.systemParams);
            });
        })
    }

    saveParameter(data: SystemParametersModel) {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/systemparameters`).add(data);
        })
    }

    updateParameter(id: string, data: SystemParametersModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/systemparameters/${id}`).update(data);
        })       
    }
  
}
