import { Injectable } from '@angular/core';
import { ShoppingCartComponent } from 'app/layout/components/shopping-cart/shopping-cart.component';
import { ProductsService } from 'app/service/products.service';
import { ShoppingCartService } from 'app/service/shopping-cart.service';

import { FuseSidebarComponent } from './sidebar.component';

@Injectable({
    providedIn: 'root'
})
export class FuseSidebarService
{
    // Private
    private _registry: { [key: string]: FuseSidebarComponent } = {};

    /**
     * Constructor
     */
    constructor(private productsService: ProductsService, private updateCart: ShoppingCartService)
    {

    }

    /**
     * Add the sidebar to the registry
     *
     * @param key
     * @param sidebar
     */
    register(key, sidebar): void
    {
        // Check if the key already being used
        if ( this._registry[key] )
        {
            console.error(`The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`);

            return;
        }

        // Add to the registry
        this._registry[key] = sidebar;
    }

    /**
     * Remove the sidebar from the registry
     *
     * @param key
     */
    unregister(key): void
    {
        // Check if the sidebar exists
        if ( !this._registry[key] )
        {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);
        }

        // Unregister the sidebar
        delete this._registry[key];
    }

    /**
     * Return the sidebar with the given key
     *
     * @param key
     * @returns {FuseSidebarComponent}
     */
    getSidebar(key): FuseSidebarComponent
    {
        // Check if the sidebar exists
        if ( !this._registry[key] )
        {
            console.warn(`The sidebar with the key '${key}' doesn't exist in the registry.`);

            return;
        }
        if (key == 'shoppingCart'){
            
            this.updateCarrinho()
        
        }
        
        // Return the sidebar
        return this._registry[key];
    }
    
    
    async updateCarrinho(){
       
        let purchases = [] 
           
        let products = await JSON.parse(
            localStorage.getItem("shoppingCart")
        );
        
        // filter nos produtos do carrinho
        await products.filter(async(fil, index) =>{
            
            // verifica na db dos produtos dos colaboradores, se o produto esta sem estoque, e atualiza a quantidade dos produtos
            // quando o usuario abrir o carrinho
            await this.productsService.getProductsColla().then(async (res: any) =>{
                await res.forEach(async element => {
                    if (element.id == fil.id && element.quantity > 0){
                        fil.quantity = element.quantity
                        if (fil.amount > element.quantity){
                            fil.amount = element.quantity
                        }
                        purchases.push(fil)
                    }
                });
            })

            // verifica na db dos produtos da empresa, se o produto esta sem estoque, e atualiza a quantidade dos produtos
            // quando o usuario abrir o carrinho
            await this.productsService.getProducts().then(async (res2: any) =>{
                await res2.forEach(async element2 => {
                    if (element2.id == fil.id && element2.quantity > 0){
                        if ( fil.amount > element2.quantity){
                            fil.amount = element2.quantity
                        }
                        purchases.push(fil)
                    }
                });
            })
    

        
        localStorage.setItem('shoppingCart', JSON.stringify(purchases)) 
        this.updateCart.onUpdateShoppingCart();  
    })




    }



}

