import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { UsersComponent } from './users.component';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';
import { UserService } from 'app/service/user.service';
import { MatIconModule } from '@angular/material/icon';

import { PoAvatarModule, PoTabsModule } from '@po-ui/ng-components';
import {NgxPaginationModule} from 'ngx-pagination'
import { OrderModule } from 'ngx-order-pipe';
const routes = [
    {
        path : 'users',
        component: UsersComponent,
        resolve: {
            users: UserService
        }
    }
]

@NgModule({
    declarations: [
        UsersComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,

        MatIconModule,

        //PO Modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoTableModule,
        PoButtonModule,
        PoFieldModule,
        NgxPaginationModule,
        PoTabsModule,
        PoAvatarModule,
        OrderModule,
    ],
    exports:[
        UsersComponent
    ]
})

export class UsersModule { }
