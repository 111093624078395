import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { FuseSharedModule } from '@fuse/shared.module';
import { PoButtonModule, PoContainerModule, PoFieldModule, PoModalModule } from '@po-ui/ng-components';

import { ChatPanelComponent } from 'app/layout/components/chat-panel/chat-panel.component';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { TimelineModule } from '../timeline/timeline.module';
import { ModalPoComponent } from '../modal-po/modal-po.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        ChatPanelComponent,
        ModalPoComponent
    ],
    providers   : [
        ChatPanelService
    ],
    imports     : [
        
        FormsModule,
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatTooltipModule,
        MatRippleModule,
        MatDividerModule,
        MatCardModule,
        
        PoButtonModule,
        PoModalModule,
        TimelineModule,
        PoContainerModule, 
        PoFieldModule,
        PickerModule,
        EmojiModule
    ],
    exports     : [
        ChatPanelComponent
    ]
})
export class ChatPanelModule
{
}
