import { ProductsService } from 'app/service/products.service';
import { PurchasesCollaboratorService } from 'app/service/purchases-collaborator.service';
import { NotificationService } from './../../../service/notification.service';
import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { PoButtonComponent, PoNotification, PoNotificationService } from "@po-ui/ng-components";
import { ShoppingCartService } from "app/service/shopping-cart.service";
import { PurchasesModel } from "app/models/purchases.model";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { AuthService } from "app/service/auth.service";
import { UserModel } from "app/models/user.model";
import { UserService } from "app/service/user.service";
import { LogService } from "app/service/log.service";
import { StoreService } from "app/service/store.service";
import { HttpClient } from '@angular/common/http';
import { DisparaEmailService } from "app/service/dispara-email.service";
import { Router } from '@angular/router';


@Component({
    selector: "shopping-cart",
    templateUrl: "./shopping-cart.component.html",
    styleUrls: ["./shopping-cart.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [PoNotificationService],
})
export class ShoppingCartComponent implements OnInit {
    productsCart = [];
    productsTotal: number = 0;
    userId = "";
    userCash = 0;
    userCashCollab = 0;
    userName = "";
    email = "";
    verifyEstoque: number = 0
    public purchase: PurchasesModel;

    
    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    nameUserLogged: any;
    listProducts: any;
    listProductsColla: any;
    @ViewChildren('divProduto') divProduto: QueryList<ElementRef>
    @ViewChild('buttonFinalizar') buttonFinalizar: PoButtonComponent
  

    constructor(
        private service: ShoppingCartService,
        private userService: UserService,
        private poNotification: PoNotificationService,
        private _fuseSidebarService: FuseSidebarService,
        public auth: AuthService,
        private logService: LogService,
        private storeService: StoreService,
        private http: HttpClient,
        private servicemail: DisparaEmailService,
        private renderer: Renderer2,
        private notify: NotificationService,
        private serviceColla: PurchasesCollaboratorService,
        private updateAmount : ProductsService,
        private router: Router,
    
     
        
    ) {
        this.auth.user$.subscribe((credentials) => {
             this.nameUserLogged = credentials.displayName;
             this.userId = credentials.uid;
             this.userCash = credentials.cash;
             this.userName = credentials.displayName;
             this.email = credentials.email;
         
         })
    }
  


    
    async ngOnInit() {
        this.calcShoppingCartTotal();
        
        if (this.service.subsVar == undefined) {
            this.service.subsVar = this.service.invokeShoppingCart.subscribe(
                () => {
                    this.updateShoppingCart();
                    this.updateItemsOfCartPrice();
        
                    this.updateTeste();
                }
            );
        }
        
        await this.storeService.getProducts();

        await this.serviceColla.getProducts();

        if (localStorage.getItem("shoppingCart") !== null) {
            this.productsCart = JSON.parse(
                localStorage.getItem("shoppingCart")
            );
        }

        
        

        this.storeService.onProductChanged.subscribe((products) => {
            this.listProducts = products;
            
        });


        
        this.serviceColla.onProductChanged.subscribe((produtos) => {
            this.listProductsColla =  produtos
            // this.updateItemsOfCartPrice();
        })

        this.updateItemsOfCartPrice();
        this.calcShoppingCartTotal()
    }




    calcShoppingCartTotal() {
        this.productsTotal = 0;
        for (var item of this.productsCart) {
            this.productsTotal += item.price;
            // console.log(item.price)
        }
       
        this.updateTeste();
    }

    updateShoppingCart() {
        this.productsCart = [];
        this.productsCart = JSON.parse(localStorage.getItem("shoppingCart"));
       
        this.calcShoppingCartTotal();
        
    }

   
    removeProduct(item) {
        this.productsCart.forEach((element, index) => {
            if (element == item) this.productsCart.splice(index, 1);
        });
        this.productsTotal -= item.price;
        localStorage.clear();
        localStorage.setItem("shoppingCart", JSON.stringify(this.productsCart));
        this.calcShoppingCartTotal();
    }

    emptyCart() {
        this.productsCart = [];
        localStorage.clear();
        this.calcShoppingCartTotal();
    }

    updateTeste(){
        this.auth.user$.subscribe((credentials) => {
            this.nameUserLogged = credentials.displayName;
            this.userId = credentials.uid;
            this.userCash = credentials.cash;
            this.userName = credentials.displayName;
            this.email = credentials.email;
        })
        
    }

    async verifyProducts(){
        let arr = []
        let products = await JSON.parse(
            localStorage.getItem("shoppingCart")
        );
        await this.serviceColla.getProducts().then((res: any) =>{
            res.forEach(async element => {
                await products.forEach(async element2 => {
                    if (element.id == element2.id && element.quantity <= 0){
                       arr.push(await element)
                    }
                });      
            });
        })
        return arr.length
    }

    async checkout() {
        
        if (this.productsTotal == 0){
            const poNotification: PoNotification = {
                message: 'Voce nao pode efetuar uma compra no valor de 0',
                duration: 3000,
                orientation: 1,
            };
            this.poNotification.error(poNotification)
            
            return
        }

        if (await this.verifyProducts() > 0){
            const poNotification: PoNotification = {
                message: 'Existem produtos no seu carrinho fora de estoque ou com estoque abaixo da quantidade desejada, seu carrinho foi atualizado !',
                duration: 8000,
                orientation: 1,
            };
            this.poNotification.error(poNotification)
            this._fuseSidebarService.getSidebar("shoppingCart").toggleOpen();
            return
        }else{
            if (this.productsTotal <= this.userCash) {
                let valorNormal = 0
                let valorCollab = 0
               
                let produtosCollab = this.productsCart.filter((product) =>{
                    return product.purchaseColla == true
                })
    
                
               this.productsCart.forEach(element => {
                    if (element.purchaseColla == true){
                        valorCollab += element.price
                    }else if (element.purchaseColla != true){
                        valorNormal += element.price
                    }
               });
               
             
               let produtosNormal = this.productsCart.filter((product) =>{
                    return product.purchaseColla != true
                })
                
                let moedasLog = this.userCash;
                this.userCash = this.userCash - this.productsTotal;
                let imgs = ''
                let vendidoPor = ''
                let savePurchase = {} as PurchasesModel;
                let savePurchaseCollab = {} as PurchasesModel;
                let updateCashUser = {} as UserModel;
                let idCompra = ''
    
                
                savePurchase.amount = valorNormal;
                savePurchase.products = produtosNormal;
                savePurchase.purchaseDate = new Date();
                savePurchase.userId = this.userId;
                savePurchase.userName = this.userName;
                savePurchase.deliveryStatus = false;
                updateCashUser.cash = this.userCash;
            
    
                savePurchaseCollab.amount = valorCollab;
                savePurchaseCollab.products = produtosCollab;
                savePurchaseCollab.purchaseDate = new Date();
                savePurchaseCollab.userId = this.userId;
                savePurchaseCollab.userName = this.userName;
                savePurchaseCollab.deliveryStatus = false;
                
                
    
    
                await this.userService.updateUser(this.userId, updateCashUser)
          
    
    
                if (produtosCollab.length > 0){
                    await this.service.insertPurchaseCollab(savePurchaseCollab).then((idcompraCol) =>{
                        this.logService.insertLog({
                            pageName: "Carrinho de compra",
                            actionExecuted: "Compra Efetuada",
                            responsible: this.nameUserLogged,
                            userLoggedId: this.userId,
                            registeredDate: new Date(),
                            objectTransacted: JSON.stringify(savePurchaseCollab),
                            description:
                                "Moedas antes da Compra - Para Compra " +
                                moedasLog +
                                " Moedas depois da Compra - Para compra: " +
                                updateCashUser.cash,
                        });
                      
                        this.emptyCart();
                        let produtosString = ''
                        // Envia notify para o usuario da compra e os moderadores/admin
                        savePurchaseCollab.products.forEach(element => {
                            produtosString += `${element.name}, `
                        });
                        let dataNofity = new Date()
                        let dataTratada = `${dataNofity.getDate()}/${dataNofity.getMonth()+1}/${dataNofity.getFullYear()} as ${dataNofity.getHours()}:${dataNofity.getMinutes()}:${dataNofity.getSeconds()}`
                        const notify = {
                            dateTimestamp: new Date().getTime(),
                            description: `Voce efetuou uma compra do(s) produto(s) da loja de colaborador ${produtosString} no valor total de ${savePurchaseCollab.amount} moedas. Data e Hora da Compra ${dataTratada}`,
                            uid: this.userId,
                            view: false,
                            idPurchase: idcompraCol,
                            typeNotify: 'purchaseCol',
                            eraseNotify: false
                        };
                        this.notify.newNotification(notify)
            
                        // admin description: `O usuário ${this.nameUserLogged} efetuou uma compra de ${produtosString} no valor de ${savePurchase.amount} moedas`,
                        //
    
                        // atualiza estoque
                        this.serviceColla.updateEstoque(savePurchaseCollab)
                        
                        if(savePurchaseCollab.products.length != 1){
                            savePurchaseCollab.products.forEach(element => {
                                if (element.userName != undefined){
                                    vendidoPor = element.userName
                                    imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p><p style="color: black;">Vendido por : ${element.userName}</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                                }else{
                                    
                                    imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p> <p style="color: black;">Vendido por : GRUPO IV2</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                                }
                                
                            });
                        }else{ 
                            imgs = `<td><img style="width:100px;" src="${savePurchaseCollab.products[0].images[0].imgLink}" alt="" /><p style="color: black;">Produto : ${savePurchaseCollab.products[0].name}</p><p style="color: black;">Quantidade : ${savePurchaseCollab.products[0].amount}</p> <p style="color: black;">Valor : ${savePurchaseCollab.products[0].price}</p></td>`
                        }
            
                        
        
                        let data = {
                           template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                            <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                            <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Parabens pela compra ${this.nameUserLogged},</p>
                            <p style="font: message-box; color: teal; font-weight:bold; font-size:20px"">Dados do(s) Produto(s)</p>
                            <table style="width:900px;"><tr>${imgs}</tr></table>
                            <h2>Valor total da compra : ${savePurchaseCollab.amount}</h2>
                            <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                            <br />
                            <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                            <br />
                            <p style="font: message-box;">Att. Administrador do sistema</p>
                            </div>`,
                            emailTitle: "Compra efetuada com sucesso"
                        } as any
                        
                        this.message = "Compra efetuada com sucesso!";
                        this.poType = "success";
                        this.showNotification(this.poType);
                        
                        this.servicemail.sendEmail(this.email, data).then((res) =>{
                          
                        }).catch((error) =>{
                           
                        })
            
                    })
                }
                
                if (produtosNormal.length > 0){
                    await this.service.insertPurchase(savePurchase).then((idcompra) =>{
                   
                        this.logService.insertLog({
                            pageName: "Carrinho de compra",
                            actionExecuted: "Compra Efetuada",
                            responsible: this.nameUserLogged,
                            userLoggedId: this.userId,
                            registeredDate: new Date(),
                            objectTransacted: JSON.stringify(savePurchase),
                            description:
                                "Moedas antes da Compra - Para Compra " +
                                moedasLog +
                                " Moedas depois da Compra - Para compra: " +
                                updateCashUser.cash,
                        });
                        this.emptyCart();
                        let produtosString = ''
                        // Envia notify para o usuario da compra e os moderadores/admin
                        savePurchase.products.forEach(element => {
                            produtosString += `${element.name}, `
                        });
                        let dataNofity = new Date()
                        let dataTratada = `${dataNofity.getDate()}/${dataNofity.getMonth()+1}/${dataNofity.getFullYear()} as ${dataNofity.getHours()}:${dataNofity.getMinutes()}:${dataNofity.getSeconds()}`
                        const notify = {
                            dateTimestamp: new Date().getTime(),
                            description: `Voce efetuou uma compra do(s) produto(s) ${produtosString} no valor total de ${savePurchase.amount} moedas. Data e Hora da Compra ${dataTratada}`,
                            uid: this.userId,
                            view: false,
                            idPurchase: idcompra,
                            typeNotify: 'purchase',
                            eraseNotify: false
                        };
                        this.notify.newNotification(notify)
            
                        // admin description: `O usuário ${this.nameUserLogged} efetuou uma compra de ${produtosString} no valor de ${savePurchase.amount} moedas`,
                        //
            
                        
        
                        if(savePurchase.products.length != 1){
                            savePurchase.products.forEach(element => {
                                if (element.userName != undefined){
                                    vendidoPor = element.userName
                                    imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p><p style="color: black;">Vendido por : ${element.userName}</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                                }else{
                                    
                                    imgs += `<td><div style="box-shadow: 0px 0px 5px rgb(216, 216, 216);"><img style="width:100px;" src="${element.images[0].imgLink}" alt="" /></div><p style="color: black;">Produto : ${element.name}</p><p style="color: black;">Quantidade : ${element.amount}</p> <p style="color: black;">Vendido por : GRUPO IV2</p> <p style="color: black;">Valor : ${element.price}</p></td>`
                                }
                                
                            });
                        }else{ 
                            imgs = `<td><img style="width:100px;" src="${savePurchase.products[0].images[0].imgLink}" alt="" /><p style="color: black;">Produto : ${savePurchase.products[0].name}</p><p style="color: black;">Quantidade : ${savePurchase.products[0].amount}</p> <p style="color: black;">Valor : ${savePurchase.products[0].price}</p></td>`
                        }
            
                        
        
                        let data = {
                           template: `<div style="width:900px; margin-left: auto; margin-right: auto; text-align:center; font: message-box;">
                            <img style="width:900px;" src="https://i.ibb.co/StL1KmQ/banner01.png" alt="https://firebasestorage.googleapis.com/v0/b/gestor-iv2.appspot.com/o/gestor%20e-mail.gif?alt=media&token=2a932052-2153-40f0-ad25-d32afa52dc5b" /><br />
                            <p style="font: message-box; color: teal; font-weight:bold; font-size:20px">Parabens pela compra ${this.nameUserLogged},</p>
                            <p style="font: message-box; color: teal; font-weight:bold; font-size:20px"">Dados do(s) Produto(s)</p>
                            <table style="width:900px;"><tr>${imgs}</tr></table>
                            <h2>Valor total da compra : ${savePurchase.amount}</h2>
                            <p style="font: message-box; color: teal;">Acesse o Pointz <a href="https://iv2.pointz.fun/">clicando aqui!</a></p>
                            <br />
                            <img style="width:500px;" src="https://mcusercontent.com/ebccf581e448ff4016e5ae8ab/images/c0f0edce-a982-4c96-8762-b8af0611f134.png" alt="" /><br />
                            <br />
                            <p style="font: message-box;">Att. Administrador do sistema</p>
                            </div>`,
                            emailTitle: "Compra efetuada com sucesso"
                        } as any
                        
                        this.message = "Compra efetuada com sucesso!";
                        this.poType = "success";
                        this.showNotification(this.poType);
                        
                        this.servicemail.sendEmail(this.email, data).then((res) =>{
                          
                        }).catch((error) =>{
                           
                        })
            
                      
                       
                    })
    
                   
                }
                this._fuseSidebarService.getSidebar("shoppingCart").toggleOpen();
                this.router.navigate(["/sample"]);
    
            } else {
                this.message =
                    "Você não tem saldo suficiente para realizar esta compra!";
                this.poType = "warning";
                this.showNotification(this.poType);
            }
        }




       
        

        
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }

     updateItemsOfCartPrice() {
        
         this.productsCart.forEach(async (item) => {
            // item.price
            const [product] = this.listProducts.filter(
                (ref) => ref.id === item.id
            );
             
            const [product2] = await this.listProductsColla.filter((ref2) => ref2.id == item.id
            );
               
                
            if (item.purchaseColla === true){
                item.price = product2?.price * item?.amount

                this.calcShoppingCartTotal();
            }else{
                item.price = product?.price * item?.amount
                this.calcShoppingCartTotal();
             
            }
 
      ;
       
        });
        this.calcShoppingCartTotal();
    }






    
    handleUpdateAmount(item, isPlus: Boolean, index) {
        let itemAtual = this.divProduto.find((box) => box.nativeElement.id == index)
        // console.log(item)
        if (isPlus) {
            //Verifica o estoque de itens apenas de colaboradores
            if (item.amount >= item.quantity && item.purchaseColla === true){
                const poNotification: PoNotification = {
                    message: 'Voce atingiu o limite do estoque !!!',
                    duration: 3000,
                    orientation: 1,
                };
                this.poNotification.error(poNotification)
                
            }else{
                item.amount += 1;  
            }

        } else {
            item.amount -= 1;
        }

        


        
        //Evita que o usuario coloque quantidade negativas de itens
        if (item.amount < 1){
            item.amount = 0
             this.renderer.addClass(itemAtual.nativeElement, 'efeito-remove-item')
             this.buttonFinalizar.buttonElement.nativeElement.disabled = true
             setTimeout(() => {
                 this.removeProduct(item)
                 this.buttonFinalizar.buttonElement.nativeElement.disabled = false
             }, 800);
        }
    

        this.updateLocalStorage();

      
        this.updateItemsOfCartPrice();
    }

    updateLocalStorage() {
        const productsCartRef = this.productsCart.map((item) => {
            delete item.price;

            return item;
        });
        localStorage.setItem("shoppingCart", JSON.stringify(productsCartRef));
    }

  
}
