import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoinsModel } from 'app/models/coins.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})

export class CoinService implements Resolve<any> {

    coins: any[];
    coin: any;

    routeParams: any;

    formData: CoinsModel;

    onCoinsChanged: BehaviorSubject<any>;
    onCoinChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore
    ) { 
        this.onCoinsChanged = new BehaviorSubject([]);
        this.onCoinChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getCoins(),
                this.getCoinId()
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getCoins(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/coins`).snapshotChanges().subscribe(action => {
                this.coins = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as CoinsModel),
                        favorite: ['detalhes']
                    } as CoinsModel
                });
                this.onCoinsChanged.next(_.orderBy(this.coins, ['name'],['asc']));
                resolve(this.coins);
            });
        })
    }

    getCoinId(){
        if(this.routeParams.userId){
            return new Promise((resolve, reject) => {
                this.firestore.collection(`${datastore}/coins`).doc(this.routeParams.userId).valueChanges().subscribe(action => {
                    this.coin = action
                    this.onCoinChanged.next(this.coin)
                    resolve(this.coin)
                })
            })
        }        
    }

    insertCoin(data: CoinsModel) {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/coins`).add(data);
        })
    }

    updateCoin(id: string, data: CoinsModel){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/coins/${id}`).update(data);
        })       
    }
    deleteCoin(coinId: string){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/coins/${coinId}`).delete();
        });
    }
  
}
