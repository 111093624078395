import { datastore } from './../config';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, refCount } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UploadBannerServiceService {
  
  constructor(private firebase: AngularFirestore, private firestorage: AngularFireStorage) { }


  async getBannersTop(){
      return this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersTop').get().toPromise()
  }


  async getBannersLat(){
      return this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersLateral').get().toPromise()
  }

  async getImageLink(value: string, collection: string){
    return this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection(collection).ref
            .where("valueImage", "==", value).limit(1).get()
  }


  createInitialPhotos(colection: string){
    let starterPack = 'https://i.ibb.co/StL1KmQ/banner01.png'
    let value = 2
    let i = 0
    for (i; i<=value; i++){
      this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection(colection).add({valueImage: i.toString(), image: starterPack, dataUpdate: new Date})
    }
  
  }


  async uploadImageTop(file, numberimage){
     this.uploadFilesTop(file, numberimage)
  }



async uploadFilesTop(files: any, numberimage: string) {
  let imageOld = ''
  if (files.length == 0) {
    return
  }
 
  await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersTop').ref.where("valueImage", "==", numberimage).limit(1).get().then((res) =>{
    res.docs.map(value =>{
      imageOld = value.data().imgLocation
    })
  })

  for ( let i = 0; i < files.length; i++ ) {
      if (imageOld != undefined){
        this.firestorage.ref(imageOld).delete();
      }
      let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
      let arquivo = files.item(i);
      let location = (`${datastore}/bannersDashTop/${numberimage}/${dateNow}/${arquivo.name}`);
      let fileRef = this.firestorage.ref(`${datastore}/bannersDashTop/${numberimage}/${dateNow}/${arquivo.name}`);
      let task = fileRef.put(arquivo);
      // get notified when the download URL is available
      return task.snapshotChanges().pipe(finalize(() => {
          fileRef.getDownloadURL().subscribe(async link => {
            await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersTop').ref
            .where("valueImage", "==", numberimage).limit(1).get().then((res) =>{
           
             res.docs.map(async (dados) =>{
              
              let idImage = dados.id
              // console.log(idImage)
              // console.log(numberimage)
              await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersTop').doc(idImage).update({
                  image: link,
                  dataUpdate: new Date,
                  imgLocation: location
              });
          })
        }).catch((error) =>{
          
        })
      })
      })
    )
  }
}


async uploadFilesLat(files: any, numberimage: string) {
  let imageOld = ''
  if (files.length == 0) {
  
  }

  await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersLateral').ref.where("valueImage", "==", numberimage).limit(1).get().then((res) =>{
    res.docs.map(value =>{
      imageOld = value.data().imgLocation
    })
  })

  for ( let i = 0; i < files.length; i++ ) {
   
      if (imageOld != undefined){
        this.firestorage.ref(imageOld).delete();
      }
    
      let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
      let arquivo = files.item(i);
      let location = (`${datastore}/bannersDashLat/${numberimage}/${dateNow}/${arquivo.name}`);
      let fileRef = this.firestorage.ref(`${datastore}/bannersDashLat/${numberimage}/${dateNow}/${arquivo.name}`);
      let task = fileRef.put(arquivo);
      // get notified when the download URL is available
       
      return task.snapshotChanges().pipe(finalize(() => {
          fileRef.getDownloadURL().subscribe(async link => {
            await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersLateral').ref
            .where("valueImage", "==", numberimage).limit(1).get().then((res) =>{
             res.docs.map(async (dados) =>{
              let idImage = dados.id
              await this.firebase.doc(datastore).collection('pageLayout').doc('bannersDash').collection('bannersLateral').doc(idImage).update({
                  image: link,
                  dataUpdate: new Date,
                  imgLocation: location
              }).then((res) =>{
                
              })
          })
        })
      })
      })
    )
  }
}



}
