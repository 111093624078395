<div class="titulo-timeline">
    <h3 class="po-text-center">
        <strong>
            Carrinho
        </strong>
    </h3>
</div>
<div class="empty-cart" *ngIf="!productsCart?.length">
    <p style="width: 100%;" class="po-text-center">Carrinho Vazio =(</p>
</div>
<div class="shopping-cart-area" id="shopping-cart-area" *ngIf="productsCart?.length">
    <div  *ngFor="let item of productsCart; index as i">
        <div #divProduto class="card-shopping-cart" id="{{i}}"> 
            <div class="po-row">
                <div class="po-lg-4" style="padding: 0;">
                    <img *ngFor="let image of item.images" [src]="image.imgLink" class="product-img mr-0 mr-sm-16">
                </div>
                <div class="po-lg-8 po-text-center" style="padding: 0;">
                    <p style="display: flex; justify-content: space-between;">
                        <strong>{{item.name}}</strong>
                        <strong style="width: 80px;">
                            <span class="po-icon po-icon-minus-circle" *ngIf="item.amount != 0" style="cursor: pointer;"
                                (click)="handleUpdateAmount(item, false, i)"></span>
                                <span class="po-icon po-icon-minus-circle" *ngIf="item.amount == 0" disabled="true"  style="cursor: pointer; color: #cccccc;"
                                (click)="handleUpdateAmount(item, false, i)"></span>
                            <span style="margin: 0 1rem;">{{item.amount}}</span>
                            <span class="po-icon po-icon-plus-circle" style="cursor: pointer;"
                                (click)="handleUpdateAmount(item, true)"></span>
                        </strong>
                        <a href="javascript:;" class="remove-product" (click)="removeProduct(item)">X</a>  
                    </p>
                    <hr>
                    <p>
                        <strong>
                            {{item.price}} <span class="po-icon po-icon-star-filled"
                                style="color: #ffb300 !important"></span>
                        </strong>
    
                    </p>
                    <p class="description-product">
                        {{item.description}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="footer-shopping-cart" *ngIf="userCash >= productsTotal"> -->
<div class="footer-shopping-cart">
    <div class="po-lg-12 po-text-center" style="margin-bottom: 5px;">
        <h3 class="po-text-center">
            <strong>
                Total: {{productsTotal}} <span class="po-icon po-icon-star-filled"
                    style="color: #ffb300 !important"></span>
            </strong>
        </h3>
    </div>
    
    <po-button class="po-lg-6" p-label="Finalizar Compra" p-disabled="true" p-icon="po-icon-ok"
        *ngIf="!productsCart?.length"></po-button>
    <po-button class="po-lg-6" p-label="Finalizar Compra" #buttonFinalizar (p-click)="checkout()" p-icon="po-icon-ok"
        *ngIf="productsCart?.length"></po-button>
    <po-button class="po-lg-6" p-label="Limpar Carrinho" (p-click)="emptyCart()" p-icon="po-icon-delete"></po-button>
</div>
<div class="footer-shopping-cart" *ngIf="productsTotal > userCash">
    <div class="po-lg-12 po-text-center" style="margin-bottom: 5px;">
        <h3 class="po-text-center no-cash">
            Total: {{productsTotal}} - Sem Saldo =(
        </h3>
    </div>
    <po-button class="po-lg-12" p-label="Limpar Carrinho" (p-click)="emptyCart()" p-icon="po-icon-delete"></po-button>
</div>