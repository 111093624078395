import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { ChatPanelService } from "app/layout/components/chat-panel/chat-panel.service";
import {
    PoComboOption,
    PoModalAction,
    PoModalComponent,
    PoSelectOption,
} from "@po-ui/ng-components";
import { ModalPoComponent } from "../modal-po/modal-po.component";
import * as _ from "lodash";
import { AuthService } from "app/service/auth.service";
import { TimelineService } from "../timeline/timeline.service";
import { NotificationService } from "app/service/notification.service";

@Component({
    selector: "chat-panel",
    templateUrl: "./chat-panel.component.html",
    styleUrls: ["./chat-panel.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("modalRecompensa", { static: true })
    addCoinModal: PoModalComponent;
    @ViewChild("filterTimeline", { static: true })
    addFiltroModal: PoModalComponent;
    @ViewChild("checkedRecompensa") checkedRecom;

    userList: Array<PoSelectOption> = [];
    userListFilters: Array<PoSelectOption> = [];

    valuesCoins: Array<PoSelectOption> = [];
    valuesTipoFiltro: Array<PoSelectOption> = [
        { label: "Moedas doadas", value: "Recompensou" },
        { label: "Moedas recebidas", value: "Recompensado" },
        { label: "Ambos", value: "Ambos" },
    ];

    reactiveFormRecompensa: FormGroup;
    reactiveFormFilter: FormGroup;

    contacts: any[];
    disabled: boolean = true;
    chat: any;
    selectedContact: any;
    sidebarFolded: boolean;
    user: any;
    userData: any;
    @ViewChild("replyForm")
    private _replyForm: NgForm;

    @ViewChild("replyInput")
    private _replyInput: ElementRef;
    @ViewChild("textAreaMotivo") textAreaMotivo;
    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
    @ViewChild("emojiDisplay") emojiDisplay;
    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        public auth: AuthService,
        private fb: FormBuilder,
        public _chatPanelService: ChatPanelService,
        private _httpClient: HttpClient,
        private _fuseSidebarService: FuseSidebarService,
        private timelineService: TimelineService,
        private notificationService: NotificationService
    ) {
        // Set the defaults
        this.selectedContact = null;
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.createReactiveForm();
        this.createReactiveFilterForm();
        // // Load the contacts
        this._chatPanelService.getUsers();
        this._chatPanelService.getUsersFilters();
        this._chatPanelService.getCoinsDefinition();

        // Subscribe to the foldedChanged observable
        this._fuseSidebarService
            .getSidebar("chatPanel")
            .foldedChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((folded) => {
                this.sidebarFolded = folded;
            });

        this._chatPanelService.onUserChanged.subscribe((i) => {
            this.userList = i;
        });

        this._chatPanelService.onUserFiltersChanged.subscribe((i) => {
            this.userListFilters = i;
        });

        this._chatPanelService.onCoinsChanged.subscribe((i) => {
            this.valuesCoins = i;
            this.auth.user$.subscribe((credentials) => {
                this.valuesCoins = _.orderBy(
                    _.filter(this.valuesCoins, function (o) {
                        return o.value <= credentials.trade;
                    }),
                    ["value"],
                    ["asc"]
                );
            });
        });

        this.auth.user$.subscribe((credentials) => {
            this.userData = credentials;
        });
    }
    
    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find(
            (directive) => {
                return directive.elementRef.nativeElement.id === "messages";
            }
        );
        this.emojiDisplay.nativeElement.style.display = 'none';
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chat for the replies
     */
    private _prepareChatForReplies(): void {
        setTimeout(() => {
            // Focus to the reply input
            // this._replyInput.nativeElement.focus();

            // Scroll to the bottom of the messages list
            if (this._chatViewScrollbar) {
                this._chatViewScrollbar.update();

                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar("chatPanel").foldTemporarily();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar("chatPanel").unfoldTemporarily();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar("chatPanel").toggleOpen();
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.who === this.selectedContact.id &&
            ((this.chat.dialog[i + 1] &&
                this.chat.dialog[i + 1].who !== this.selectedContact.id) ||
                !this.chat.dialog[i + 1])
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (
            i === 0 ||
            (this.chat.dialog[i - 1] &&
                this.chat.dialog[i - 1].who !== message.who)
        );
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (
            i === this.chat.dialog.length - 1 ||
            (this.chat.dialog[i + 1] &&
                this.chat.dialog[i + 1].who !== message.who)
        );
    }

    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact): void {
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        if (this.selectedContact && contact.id === this.selectedContact.id) {
            // Reset
            this.resetChat();
        }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        else {
            // Unfold the sidebar temporarily
            this.unfoldSidebarTemporarily();

            // Set the selected contact
            this.selectedContact = contact;

            // Load the chat
            this._chatPanelService.getChat(contact.id).then((chat) => {
                // Set the chat
                this.chat = chat;

                // Prepare the chat for the replies
                this._prepareChatForReplies();
            });
        }
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        // Set the selected contact as null
        this.selectedContact = null;

        // Set the chat as null
        this.chat = null;
    }

    /**
     * Reply
     */
    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        // Message
        const message = {
            who: this.user.id,
            message: this._replyForm.form.value.message,
            time: new Date().toISOString(),
        };

        // Add the message to the chat
        this.chat.dialog.push(message);

        // Reset the reply form
        this._replyForm.reset();

        // Update the server
        this._chatPanelService
            .updateChat(this.chat.id, this.chat.dialog)
            .then((response) => {
                // Prepare the chat for the replies
                this._prepareChatForReplies();
            });
    }

    openNewModal() {
        this.reactiveFormRecompensa.reset();
        this.addCoinModal.open();
        setTimeout(() => {
            this.disabled = false;
        }, 0);
    }

    createReactiveForm() {
        this.reactiveFormRecompensa = this.fb.group({
            users: ["", Validators.required],
            coins: ["", Validators.required],
            motivo: ["", Validators.required],
            accept: [false, Validators.requiredTrue],
        });

        this.reactiveFormRecompensa.valueChanges.subscribe((form) => {
            this.SaveAction.disabled = !this.reactiveFormRecompensa.valid;
        });
    }

    createReactiveFilterForm() {
        this.reactiveFormFilter = this.fb.group({
            usersFilter: ["", Validators.required],
            tipoFilter: ["", Validators.required],
        });

        this.reactiveFormFilter.valueChanges.subscribe((form) => {
            this.SaveFilterAction.disabled = !this.reactiveFormFilter.valid;
        });
    }

    SaveAction: PoModalAction = {
        action: () => {
            let existCoin = _.filter(this.valuesCoins, {
                value: this.reactiveFormRecompensa.value.coins,
            });

            if (existCoin.length > 0) {
                this._chatPanelService.rewardedCoins(
                    this.reactiveFormRecompensa.value
                );


                // notificação
                const { coins, users } = this.reactiveFormRecompensa.value;
                const notify = {
                    dateTimestamp: new Date().getTime(),
                    description: `O usuário ${this.userData.displayName} te enviou ${coins} moedas`,
                    uid: users,
                    view: false,
                    eraseNotify: false,
                    typeNotify: 'recompensa'
                };
               
                this.notificationService.newNotification(notify);
                

                this.addCoinModal.close();
            }

            this.checkedRecom.checkboxValue = false;
        },
        disabled: true,
        label: "Salvar",
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.addCoinModal.close();
        },
        label: "Cancelar",
    };

    openFilterModal() {
        this.reactiveFormFilter.reset();
        this.addFiltroModal.open();
    }

    SaveFilterAction: PoModalAction = {
        action: () => {
            this.timelineService.filterTimeline(this.reactiveFormFilter.value);
            this.addFiltroModal.close();
        },
        label: "Filtrar",
    };

    CloseFilterAction: PoModalAction = {
        action: () => {
            this.addFiltroModal.close();
        },
        label: "Cancelar",
    };

    resetFilter() {
        this.timelineService.isFilter = false;
        this.timelineService.getDataPaginated();
    }

    checkChange(args) {
        this.reactiveFormRecompensa.setValue({
            ...this.reactiveFormRecompensa.value,
            accept: !args.target.checked,
            motivo: this.textAreaMotivo.inputEl.nativeElement.value
        });
        console.log(this.reactiveFormRecompensa.value)

    }
    addEmoji(event: any){
        
        this.textAreaMotivo.inputEl.nativeElement.value += event.emoji.native
        this.textAreaMotivo.modelLastUpdate += event.emoji.native
        // console.log(this.textAreaMotivo.inputEl.nativeElement.value)
        // console.log(this.textAreaMotivo.modelLastUpdate)
        
    }


    displayOnOFF(){
        this.emojiDisplay.nativeElement.style.display == 'none' ? this.emojiDisplay.nativeElement.style.display = 'block' : this.emojiDisplay.nativeElement.style.display = 'none';
    }



}
