import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatRippleModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PoTooltipModule } from '@po-ui/ng-components'
import { FuseSharedModule } from '@fuse/shared.module'
import { PoButtonModule } from '@po-ui/ng-components'

import { ChatPanelComponent } from 'app/layout/components/chat-panel/chat-panel.component'
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service'
import { ProfileTimelineComponent } from '../timeline/timeline.component'
import { DialogContentExampleDialog } from './modal-accept/modal.component'
import { ModalConfirmComent } from './modal-comment/modal.component'
import { TimelineService } from './timeline.service'

@NgModule({
  declarations: [
    ProfileTimelineComponent,
    DialogContentExampleDialog,
    ModalConfirmComent,
  ],
  providers: [TimelineService],
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,
    MatDividerModule,
    MatDialogModule,

    FuseSharedModule,

    PoButtonModule,
    PoTooltipModule,
  ],
  exports: [ProfileTimelineComponent],
})
export class TimelineModule {}
