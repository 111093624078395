
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-12">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">devices_other</mat-icon> Vendas
                    </h2>
                </div>
            </div>
            <section class="sectionFilter">
                <div class="details-main">
                    <div class="details">
                        <p>Em aberto: {{valueAberto}}</p>
                        <p>Andamento: {{valueAndamento}}</p>
                        <p>Aguardando: {{valueAguardando}}</p>
                        <p>Entregue: {{valueEntregue}}</p>
                        <p>Total: {{valueTotal}}</p>
                    </div>
                </div>
                
                <div class="masterSearch">
                    <div class="btnBox">
                        <button class="buttonExcell" (click)="downloadEx()"><img src="/assets/icons/exceldown/excelIcon.png" alt=""></button>
                    </div>
                    <div class="search">
                        <po-input p-label="Nome ou Status" class="inputSearch" name="nome" [(ngModel)]="nome" id="nome" (ngModelChange)="filterName()" type="text"></po-input>
                    </div>
                    <div class="startDate">
                        <po-datepicker name="startDate" [(ngModel)]="startDate" (ngModelChange)="filterName()" p-format="dd/mm/yyyy" p-label="Data Inicio"
                            >
                        </po-datepicker>
                    </div>
                    <div>
                        <po-datepicker name="endDate" [(ngModel)]="endDate" (ngModelChange)="filterName()" p-format="dd/mm/yyyy" p-label="Data Fim"
                            >
                        </po-datepicker>
                    </div>
                </div>
            </section>
            <hr>
            <div class="po-row">
                <po-tabs style="margin-top: 20px; margin-bottom: 10px;">
                    <po-tab p-label="Vendas Principal" [p-active]="true">
                        <po-table [p-items]="listSales | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p, id: 'listnormal'}" (p-sort-by)="sort($event)" p-sort="true" [p-striped]="true" [p-actions]="actions"
                        [p-columns]="columns" class="po-table-sales">
                        <ng-template p-table-column-template [p-property]="'purchaseDate'" let-value>
                            <div class="badge {{ value }}">{{value.toDate() | date: 'dd/MM/yyyy h:mm a' }}</div>
                        </ng-template>
                        <ng-template p-table-column-template [p-property]="'deliveryDate'" let-value>
                            <div *ngIf="value" class="badge {{ value }}">{{ value | date: 'dd/MM/yyyy' }}</div>
                        </ng-template>
                        </po-table>
                        <pagination-controls id="listnormal" previousLabel="Voltar" nextLabel="Proximo" directionLinks="true" (pageChange)="p = $event"></pagination-controls>
                    </po-tab>

                    <po-tab p-label="Vendas Colaboradores" [p-active]="true">
                        <po-table [p-items]="listSalesCollab | orderBy: key : reverse | paginate: {itemsPerPage: itemPage2, currentPage: c, id: 'listCollab'}" (p-sort-by)="sort($event)" p-sort="true" [p-striped]="true" [p-actions]="actionColab"
                        [p-columns]="columns2" class="po-table-sales">
                        <ng-template p-table-column-template [p-property]="'purchaseDate'" let-value>
                            <div class="badge {{ value }}">{{value.toDate() | date: 'dd/MM/yyyy h:mm a' }}</div>
                        </ng-template>
                        <ng-template p-table-column-template [p-property]="'deliveryDate'" let-value>
                            <div *ngIf="value" class="badge {{ value }}">{{ value | date: 'dd/MM/yyyy' }}</div>
                        </ng-template>
                        </po-table>
                        <pagination-controls id="listCollab" previousLabel="Voltar" nextLabel="Proximo" directionLinks="true" (pageChange)="c = $event"></pagination-controls>
                    </po-tab>
                </po-tabs>
            </div>
        </div>
    </po-container>
</div>






<po-modal #saleModal p-click-out="false" p-title="Detalhes da Compra" [p-primary-action]="SaveAction"
    [p-secondary-action]="CloseAction">
    <div *ngIf="sale">
        <form [formGroup]="reactiveForm">
            <div class="po-row">
                <div class="po-lg-7 po-text-center">
                    <po-info p-label="Colaborador" [p-value]="sale.userName"> </po-info>
                </div>
                <div class="po-lg-3 po-text-center">
                    <po-info p-label="Data da Compra"
                        [p-value]="sale.purchaseDate.toDate() | date: 'dd/MM/yyyy h:mm a'"> </po-info>
                </div>
                <div class="po-lg-2 po-text-center">
                    <div class="po-info">
                        <div class="po-info-container-title">
                            <span class="po-info-label"> Valor Total</span>
                        </div>
                        <div class="po-info-container-content">
                            <span class="po-info-value ng-star-inserted hlp-display-flex">
                                {{sale.amount}} <mat-icon class="secondary-text" style="color: #ffb300;">star_rate
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="po-row">
                <div class="po-lg-2"></div>
                <div class="po-lg-4 po-text-center">
                    <po-datepicker formControlName="deliveryDate" p-label="Entregue em" p-clean p-format="dd/mm/yyyy">
                    </po-datepicker>
                </div>
                <div class="po-lg-4 po-text-center">
                    <!-- <po-switch formControlName="deliveryStatus" p-label="Status" p-label-off="Aguardando"
                        p-label-on="Entregue"></po-switch> -->
                    <po-select #data name="select" formControlName="deliveryStatus" p-label="Status"
                        [p-options]="optionsStatus">
                    </po-select>
                </div>
            </div>
            <div class="po-row">
                <div class="po-lg-12 hlp-padding-accordion">
                    <po-accordion>
                        <po-accordion-item p-label="Produtos">
                            <div class="po-row hlp-border-bottom" *ngFor="let item of sale.products">
                                <div class="po-lg-2 hlp-no-padding-left po-text-center">
                                    <img *ngFor="let image of item.images" [src]="image.imgLink"
                                        class="hlp-img-thumbnail">
                                </div>
                                <div class="po-lg-10">
                                    <div class="po-row">
                                        <div class="po-lg-3">
                                            <po-info p-label="Nome" [p-value]="item.name"> </po-info>
                                        </div>
                                        <div class="po-lg-2">
                                            <po-info p-label="Quantidade" [p-value]="item.amount"> </po-info>
                                        </div>
                                        <div class="po-lg-2">
                                            <div class="po-info">
                                                <div class="po-info-container-title">
                                                    <span class="po-info-label"> Valor </span>
                                                </div>
                                                <div class="po-info-container-content">
                                                    <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                        {{item.price}} <mat-icon class="secondary-text"
                                                            style="color: #ffb300;">star_rate</mat-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="po-lg-5">
                                            <po-info p-label="Descrição" [p-value]="item.description"> </po-info>
                                        </div>
                                        <div class="po-lg-5" *ngIf="item.userName != undefined">
                                            <po-info p-label="Vendido Por" [p-value]="item.userName"></po-info>
                                        </div>
                                        <!-- <div *ngIf="item.purchaseColla == true" class="buttonsProduct po-lg-5">
                                            <po-select  style="width: 100%;" name="select" p-label="Status" [p-options]="statusOptions" [(ngModel)]="statusProductCol">
                                            
                                            </po-select>
                                            <po-button p-type="primary" p-label="Alterar Status" (p-click)="produtoColStatus(item.id, statusProductCol)"></po-button>
                                            
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </po-accordion-item>
                    </po-accordion>
                </div>
            </div>
            <div class="po-row observa" *ngIf="data.selectedValue == 4">
                <h3 class="title-observa">Observaçao</h3>
                <po-textarea  [(ngModel)]="observacao" [ngModelOptions]="{standalone: true}" class="obeserva-area"  > </po-textarea>
            </div>
            <div *ngIf="data.selectedValue == 4" >
                <div style="margin-top: 35px;">
                    <p>
                        Ao Alterar o status para <span style="color: rgb(252, 0, 0);">RECUSADO</span> O usuario <span style="font-weight: 800;">{{sale.userName}}</span>
                        Ira ter o estorno de {{sale.amount}} moedas
                    </p>
                </div>
            </div>
        </form>
    </div>
</po-modal>



<po-modal #saleColab p-click-out="false" p-title="Detalhes da Compra colaborador" [p-primary-action]="saveCollab" [p-secondary-action]="CloseActionCollab">
    <div *ngIf="sale">
        <form [formGroup]="reactiveForm">
            <div class="po-row">
                <div class="po-lg-7 po-text-center">
                    <po-info p-label="Colaborador" [p-value]="sale.userName"> </po-info>
                </div>
                <div class="po-lg-3 po-text-center">
                    <po-info p-label="Data da Compra"
                        [p-value]="sale.purchaseDate | date: 'dd/MM/yyyy h:mm a'"> </po-info>
                </div>
                <div class="po-lg-2 po-text-center">
                    <div class="po-info">
                        <div class="po-info-container-title">
                            <span class="po-info-label"> Valor Total</span>
                        </div>
                        <div class="po-info-container-content">
                            <span class="po-info-value ng-star-inserted hlp-display-flex">
                                {{sale.amount}} <mat-icon class="secondary-text" style="color: #ffb300;">star_rate
                                </mat-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="po-row">
                <div class="po-lg-2"></div>
                <div class="po-lg-4 po-text-center">
                    <po-datepicker formControlName="deliveryDate" p-label="Entregue em" p-clean p-format="dd/mm/yyyy">
                    </po-datepicker>
                </div>
                <div class="po-lg-4 po-text-center">
                    <!-- <po-switch formControlName="deliveryStatus" p-label="Status" p-label-off="Aguardando"
                        p-label-on="Entregue"></po-switch> -->
                    <po-select #data name="select" formControlName="deliveryStatus" p-label="Status"
                        [p-options]="optionsStatus">
                    </po-select>
                </div>
            </div>
            <div class="po-row">
                <div class="po-lg-12 hlp-padding-accordion">
                    <po-accordion>
                        <po-accordion-item p-label="Produtos">
                            <div class="po-row hlp-border-bottom" *ngFor="let item of sale.products">
                                <div class="po-lg-2 hlp-no-padding-left po-text-center">
                                    <img *ngFor="let image of item.images" [src]="image.imgLink"
                                        class="hlp-img-thumbnail">
                                </div>
                                <div class="po-lg-10">
                                    <div class="po-row">
                                        <div class="po-lg-3">
                                            <po-info p-label="Nome" [p-value]="item.name"> </po-info>
                                        </div>
                                        <div class="po-lg-2">
                                            <po-info p-label="Quantidade" [p-value]="item.amount"> </po-info>
                                        </div>
                                        <div class="po-lg-2">
                                            <div class="po-info">
                                                <div class="po-info-container-title">
                                                    <span class="po-info-label"> Valor </span>
                                                </div>
                                                <div class="po-info-container-content">
                                                    <span class="po-info-value ng-star-inserted hlp-display-flex">
                                                        {{item.price}} <mat-icon class="secondary-text"
                                                            style="color: #ffb300;">star_rate</mat-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="po-lg-5">
                                            <po-info p-label="Descrição" [p-value]="item.description"> </po-info>
                                        </div>
                                        <div class="po-lg-5" *ngIf="item.userName != undefined">
                                            <po-info p-label="Vendido Por" [p-value]="item.userName"></po-info>
                                        </div>
                                        <!-- <div *ngIf="item.purchaseColla == true" class="buttonsProduct po-lg-5">
                                            <po-select  style="width: 100%;" name="select" p-label="Status" [p-options]="statusOptions" [(ngModel)]="statusProductCol">
                                            
                                            </po-select>
                                            <po-button p-type="primary" p-label="Alterar Status" (p-click)="produtoColStatus(item.id, statusProductCol)"></po-button>
                                            
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </po-accordion-item>
                    </po-accordion>
                    <div *ngIf="data.selectedValue == true" >
                        <div *ngFor="let item2 of moedasParaColaborador" style="margin-top: 35px;">
                            <p>
                                Ao Alterar o status para <span style="color: rgb(8, 252, 0);">ENTREGUE</span> O usuario <span style="font-weight: 800;">{{item2.userName}}</span>
                                Recebera o valor Total de {{item2.valorTotal}} Moedas
                            </p>
                        </div>
                    </div>
            
                </div>
            </div>
            <div class="po-row observa" *ngIf="data.selectedValue == 4">
                <h3 class="title-observa">Observaçao</h3>
                <po-textarea  [(ngModel)]="observacao" [ngModelOptions]="{standalone: true}" class="obeserva-area"  > </po-textarea>
            </div>
            <div *ngIf="data.selectedValue == 4" >
                <div style="margin-top: 35px;">
                    <p>
                        Ao Alterar o status para <span style="color: rgb(252, 0, 0);">RECUSADO</span> O usuario <span style="font-weight: 800;">{{sale.userName}}</span>
                        Ira ter o estorno de {{sale.amount}} moedas
                    </p>
                </div>
            </div>
        </form>
    </div>
</po-modal>