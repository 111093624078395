import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { UserModel } from "app/models/user.model";
import { datastore } from "app/config";
import { BehaviorSubject, Observable } from "rxjs";
import * as _ from "lodash";
import { AuthService } from "./auth.service";
import { LogService } from "./log.service";
import { AngularFireAuth} from "@angular/fire/auth";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class UserService implements Resolve<any> {
    users: any[];
    user: any;
    loggedUserId: string;

    routeParams: any;

    formData: UserModel;
    groupsSp: BehaviorSubject<any>;
    onUserChanged: BehaviorSubject<any>;
    onUserMoedasChanged: BehaviorSubject<any>;
    onUseChanged: BehaviorSubject<any>;
    onUserRankingChanged: BehaviorSubject<any>;
    onloggedUserChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore,
        public auth: AuthService,
        private logService: LogService,
        private afauth: AngularFireAuth,
        private afauth2: AngularFireAuth,
        private firestorage: AngularFireStorage
    ) {
        this.onUserChanged = new BehaviorSubject([]);
        this.onUserMoedasChanged = new BehaviorSubject([]);
        this.onUseChanged = new BehaviorSubject([]);
        this.onUserRankingChanged = new BehaviorSubject([]);
        this.onloggedUserChanged = new BehaviorSubject([]);
        this.groupsSp = new BehaviorSubject([]);
        auth.user$.subscribe((credentials) => {
            this.loggedUserId = credentials.uid;
        });
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getUsers(),
                this.getUserId(),
                this.getUserProfilebyId(),
                this.getUserMoedas(),
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .onSnapshot((action) => {
                    this.users = action.docs.map((item) => {
                        return {
                            id: item.id,
                            ...(item.data() as UserModel),
                            favorite: ["detalhes"],
                        } as UserModel;
                    });
                    this.onUserChanged.next(
                        _.orderBy(this.users, ["displayName"], ["asc"])
                    );
                    const rank = {
                        rankCoins: _.orderBy(
                            this.users,
                            ["displayName", "cash"],
                            ["asc"]
                        ),
                        rankingRecived: _.orderBy(
                            this.users,
                            ["displayName", "cashRecivedToRank"],
                            ["asc"]
                        ),
                        rankGivers: _.orderBy(
                            this.users,
                            ["displayName", "tradesTotalToRank"],
                            ["asc"]
                        ),
                    };
                    this.onUserRankingChanged.next(rank);
                    resolve(this.users);
                });
        });
    }

    getUserMoedas() {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .onSnapshot((action) => {
                    this.users = action.docs.map((item) => {
                        return {
                            value: item.id,
                            label: item.data()["displayName"],
                            ativo: item.data()["ativo"],
                        };
                    });
                    this.users = _.filter(this.users, { ativo: true });
                    this.onUserMoedasChanged.next(
                        _.orderBy(this.users, ["label"], ["asc"])
                    );
                    resolve(this.users);
                });
        });
    }

    getUserId() {
        if (this.routeParams.userId) {
            return new Promise((resolve, reject) => {
                this.firestore
                    .collection(`${datastore}/users`)
                    .doc(this.routeParams.userId)
                    .valueChanges()
                    .subscribe((action) => {
                        this.user = action;
                        this.onUseChanged.next(this.user);
                        resolve(this.user);
                    });
            });
        }
    }
    getUserProfilebyId() {
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe((credentials) => {
                this.firestore
                    .collection(`${datastore}/users`)
                    .doc(credentials.uid)
                    .valueChanges()
                    .subscribe((action) => {
                        this.user = action;
                        this.onloggedUserChanged.next(this.user);
                        resolve(this.user);
                    });
            });
        });
    }

  



    insertUser(data: UserModel) {
        if (!data.registerdate) {
            data.registerdate = new Date();
        }
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/users`).add(data)
        })
    }

    async veryficaExistenciaEmail(){
        return this.firestore.collection(`${datastore}/users`).get().toPromise()
    }

    async updateUser(id: string, data: any) {
        // console.log(data)
        return await this.firestore.collection(`${datastore}/users`).doc(id).update(data);
        
    }


    async updateCoinsAfterDelivered(dados){
        dados.forEach(async userData => {
            let newValue = 0
            this.firestore.collection(`${datastore}/users`).doc(userData.userId.toString()).get().toPromise().then(async (dadosToUpdate) =>{
                newValue = dadosToUpdate.data().cash += userData.valorTotal
                await dadosToUpdate.ref.update({cash: newValue})
            }).catch((error) =>{
                return error
            })
        })
    }
    

    async updateCoinsAfterCancel(id: string, value: any) {
        let newValue = 0
        this.firestore.collection(`${datastore}/users`).doc(id.toString()).get().toPromise().then(async (dadosToUpdate) =>{
            newValue = dadosToUpdate.data().cash += value
            await dadosToUpdate.ref.update({cash: newValue})
        }).catch((error) =>{
            return error
        })
    }



    deleteUser(uid: any){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/users`).doc(uid).update({ativo: false})
        });
    }
    activeUser(uid: any){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/users`).doc(uid).update({ativo: true})
        });
    }
  
    async uploadImage(files, userId){
        let id = ''
        let imageOld: any
       
     
        await this.firestore.collection(`${datastore}/users`).doc(userId).get().toPromise().then(async (res) =>{
           if (res.data().avatar != undefined){
                imageOld = await res.data().avatar[0].imgLocation ? await res.data().avatar[0].imgLocation : undefined
           }else{
            imageOld = undefined
           }
        })  
        
        // console.log(imageOld)
            //verifica se ja tem foto, se ja existe, apaga a antiga
            if (imageOld != undefined || imageOld != null){
                // console.log('eae')
                this.firestorage.ref(imageOld).delete();

                for (let i = 0; i < files.length; i++ ) {
                    let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
                    let arquivo = files.item(0);
                
                    let location = (`${datastore}/usersAvatar/${userId}/${dateNow}/${arquivo.name}`);
                    let fileRef = this.firestorage.ref(`${datastore}/usersAvatar/${userId}/${dateNow}/${arquivo.name}`);
                    let task = fileRef.put(arquivo);
                    
                    // get notified when the download URL is available
                    return task.snapshotChanges().pipe(finalize(() => {
                        // console.log('e')
                        fileRef.getDownloadURL().subscribe(async link => {
                            await this.firestore.doc(`${datastore}/users/${userId}`).update({
                                avatar: [{
                                    avatarId: userId,
                                    date: new Date().toLocaleString(),
                                    time: new Date().getTime(),
                                    imgLocation: location,
                                    filename: arquivo.name,
                                    imgLink: link
                                }],
                                photoURL: link
                                }).then((res) =>{
                        
                                })
                            });
                        })
                    )}
                    
            }else{
                for ( let i = 0; i < files.length; i++ ) {
                    let dateNow = new Date().toLocaleString().replace(/[^A-Z0-9]+/ig, "");
                    let arquivo = files.item(0);
                
                    let location = (`${datastore}/usersAvatar/${userId}/${dateNow}/${arquivo.name}`);
                    let fileRef = this.firestorage.ref(`${datastore}/usersAvatar/${userId}/${dateNow}/${arquivo.name}`);
                    let task = fileRef.put(arquivo);
                    
                    // get notified when the download URL is available
                    return task.snapshotChanges().pipe(finalize(() => {
                        // console.log('e')
                        fileRef.getDownloadURL().subscribe(async link => {
                            await this.firestore.doc(`${datastore}/users/${userId}`).update({
                                avatar: [{
                                    avatarId: userId,
                                    date: new Date().toLocaleString(),
                                    time: new Date().getTime(),
                                    imgLocation: location,
                                    filename: arquivo.name,
                                    imgLink: link
                                }],
                                photoURL: link
                                }).then((res) =>{
                        
                                })
                            });
                        })
                    )}
                    
            }
       



        
            
    }


    updateMoedas2(checkedArredondar: boolean) {
        if (checkedArredondar) {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .get()
                .then((action) => {
                    let usersActive = action.docs.map((item) => {
                        if (item.data()["ativo"]) {
                                let values = {
                                    trade:
                                        300 -
                                        parseInt(
                                            _.isUndefined(item.data()["trade"])
                                                ? 0
                                                : item.data()["trade"]
                                        ) + parseInt(
                                            _.isUndefined(item.data()["trade"])
                                                ? 0
                                                : item.data()["trade"]
                                        ),
                                };

                                this.firestore
                                    .doc(`${datastore}/users/${item["id"]}`)
                                    .update(values);
                            }

                          
                        }
                    );
                })}
    }       //
               
    
    getMailById(userid){
        return this.firestore.doc(datastore).collection('users').doc(userid).get().toPromise()
    }


    updateMoedas(checkedAllUsers: boolean, data: any) {
        if (checkedAllUsers) {
            this.firestore
                .collection(`${datastore}/users`)
                .ref.where("userPerfil", "<", "4")
                .get()
                .then((action) => {
                    let usersActive = action.docs.map((item) => {
                        if (item.data()["ativo"]) {
                            if (data.coinTypeChoice == "1") {
                                let values = {
                                    cash:
                                        parseInt(data.valorSelect) +
                                        parseInt(
                                            _.isUndefined(item.data()["cash"])
                                                ? 0
                                                : item.data()["cash"]
                                        ),
                                };

                                this.firestore
                                    .doc(`${datastore}/users/${item["id"]}`)
                                    .update(values);
                            } else {
                                let values = {
                                    trade:
                                        parseInt(data.valorSelect) +
                                        parseInt(
                                            _.isUndefined(item.data()["trade"])
                                                ? 0
                                                : item.data()["trade"]
                                        ),
                                };

                                this.firestore
                                    .doc(`${datastore}/users/${item["id"]}`)
                                    .update(values);
                            }

                            // let typeRef =
                            //     data.coinTypeChoice == "1"
                            //         ? "Para Loja"
                            //         : "Para Recompensar";
                            // let newNotify = {
                            //     dateTimestamp: new Date().getTime(),
                            //     description: `Parabéns, você recebeu ${data.valorSelect} do ADM para ${typeRef}`,
                            //     uid: data.userSelect.uid,
                            // };
                            // this.firestore
                            //     .collection(`${datastore}/notification`)
                            //     .add(newNotify);
                        }
                    });
                });
        } else {
            data.userSelect.forEach((value) => {
                this.firestore
                    .collection(`${datastore}/users`)
                    .doc(value.id)
                    .get()
                    .subscribe((item) => {
                        if (item.data()["ativo"]) {
                            if (data.coinTypeChoice == "1") {
                                let values = {
                                    cash:
                                        parseInt(data.valorSelect) +
                                        parseInt(
                                            _.isUndefined(item.data()["cash"])
                                                ? 0
                                                : item.data()["cash"]
                                        ),
                                };

                                this.firestore
                                    .doc(`${datastore}/users/${item["id"]}`)
                                    .update(values);

                                this.logService.insertLog({
                                    pageName: "Usuário",
                                    actionExecuted: `Enviado ${
                                        data.valorSelect
                                    } moedas da loja para o usuário: ${
                                        item.data()["displayName"]
                                    }`,
                                    responsible: "Super Admin",
                                    userLoggedId: "",
                                    description: `Enviado ${
                                        data.valorSelect
                                    } moedas da loja para o usuário: ${
                                        item.data()["displayName"]
                                    }`,
                                    registeredDate: new Date(),
                                    objectTransacted: JSON.stringify(data),
                                });
                            } else {
                                let values = {
                                    trade:
                                        parseInt(data.valorSelect) +
                                        parseInt(
                                            _.isUndefined(item.data()["trade"])
                                                ? 0
                                                : item.data()["trade"]
                                        ),
                                };

                                this.firestore
                                    .doc(`${datastore}/users/${item["id"]}`)
                                    .update(values);

                                this.logService.insertLog({
                                    pageName: "Usuário",
                                    actionExecuted: `Enviado ${
                                        data.valorSelect
                                    } moedas de recompensa para um usuário: ${
                                        item.data()["displayName"]
                                    }`,
                                    responsible: "Super Admin",
                                    userLoggedId: "",
                                    description: `Enviado ${
                                        data.valorSelect
                                    } moedas de recompensa para um usuário: ${
                                        item.data()["displayName"]
                                    }`,
                                    registeredDate: new Date(),
                                    objectTransacted: JSON.stringify(data),
                                });
                            }

                            let typeRef =
                                data.coinTypeChoice == "1"
                                    ? "Para Loja"
                                    : "Para Recompensar";
                            // let newNotify = {
                            //     dateTimestamp: new Date().getTime(),
                            //     description: `Parabéns, você recebeu ${data.valorSelect} moedas do ADM ${typeRef}`,
                            //     uid: data.userSelect[0].uid,
                            // };
                        //     this.firestore
                        //         .collection(`${datastore}/notification`)
                        //         .add(newNotify);
                         }
                    });
            });
        }
    }



    getAllUsers(){
        return this.firestore.collection(`${datastore}/users`).get().toPromise()
    }

    addGroupSuperPointz(data: any){
        let id = this.firestore.createId()
        data.id = id
        return this.firestore.collection(`${datastore}/spGroups`).doc(id).set(data)
    }

    getGroupSuperPointz(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/spGroups`).get().subscribe((resp) =>{
            let data = resp.docs.map((groups) =>{
                let dateGroupFormated = groups.data().dateCreated.toDate()
                return {...groups.data(), lengthGroup: groups.data().usersGroup.length, dateFormat: dateGroupFormated} 
            })
            this.groupsSp.next(data)
            resolve(data)
            })
        })
    }
}
