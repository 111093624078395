
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">local_offer</mat-icon> Produtos
                    </h2>
                </div>
                <div class="po-lg-4">  
                    <po-button  p-label=" Novo Produto" p-icon="po-icon po-icon-plus" p-type="Primary" (p-click)="openNewModal()" class="pull-right"> </po-button>   
                </div>
            </div>
            <section class="sectionFilter">
                <div class="masterSearch">
                    <div class="btnBox">
                        <button class="buttonExcell" (click)="downloadEx()"><img src="/assets/icons/exceldown/excelIcon.png" alt=""></button>
                    </div>
                    <div class="search">
                        <po-input p-label="Nome ou Status" class="inputSearch" name="nome" id="nome" [(ngModel)]="nome" (ngModelChange)="filterName()"   type="text"></po-input>
                    </div>
                </div>
            </section>


            <hr>
            <div class="po-row">
                <po-table p-sort="true" [p-items]="listProducts | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p}" (p-sort-by)="sort($event)" [p-striped]="true" [p-actions]="actions" [p-columns]="columns" class="po-table-products"></po-table>
                <pagination-controls previousLabel="Voltar" nextLabel="Proximo" (pageChange)="p = $event"></pagination-controls> 
            </div>
            <po-modal #addProductModal p-click-out="false" p-title="Novo Produto" [p-primary-action]="SaveAction" [p-secondary-action]="CloseAction"> 
                <form #form="ngForm" [formGroup]="reactiveForm">
                    <div class="po-row">
                        <po-input class="po-md-12" formControlName="name" p-clean p-icon="po-icon-user" p-label="Nome *"> </po-input>
                    </div>    
                    <div class="po-row">
                        <po-input class="po-md-12" formControlName="description" p-clean p-icon="po-icon-info" p-label="Descrição *"> </po-input>
                    </div>    
                    <div class="po-row">
                        <po-number class="po-lg-3" formControlName="price" p-clean p-icon="po-icon-star" p-label="Valor *"> </po-number>
                        <po-number class="po-lg-3" formControlName="quantity" p-clean p-icon="po-icon-star" p-label="Estoque *"> </po-number>
                        <po-number class="po-lg-6" formControlName="category" p-clean p-icon="po-icon-star" p-label="Categoria *"> </po-number>
                    </div>
                    <div class="po-row hlp-margin-top-15 po-text-center">
                        <po-switch class="po-lg-6" formControlName="status" p-label="Produto Ativo" p-label-off="Inativo" p-label-on="Ativo"></po-switch>  
                        <po-switch class="po-lg-6" formControlName="showHome" p-label="Mostrar na Home?" p-label-off="Não" p-label-on="Sim"></po-switch>
                    </div>
                    <div class="po-row hlp-margin-top-15 hlp-margin-bottom-15">
                        <div class="po-lg-3"></div>
                        <div class="po-lg-6"> 
                            <button for="uploadFile" class="po-button po-text-ellipsis" (click)="initUpload()">
                                <span aria-hidden="true" class="po-icon po-icon-plus ng-star-inserted"></span>
                                <span class="po-button-label ng-star-inserted">Adicionar Imagem</span>
                                <input id="uploadFile" #fileInput style="display: none;" type="file" (change)="showUploadImage($event)">
                            </button>
                        </div>
                    </div>    
                    <div class="po-row">
                        <div class="po-lg-12 po-text-center" *ngIf="srcImage?.length">
                            <img [src]="srcImage" class="hlp-img-thumbnail">
                        </div>
                        <div class="po-lg-12 po-text-center" *ngIf="!srcImage?.length">
                            <img [src]="item.imgLink" *ngFor="let item of images" class="hlp-img-thumbnail">
                        </div>
                    </div>
                </form>
            </po-modal>
        </div>
    </po-container>
</div>

