import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { datastore } from "app/config";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class DomainCrudService {
    onTypeOfDomainChanged: BehaviorSubject<any>;
    typeReward;
    constructor(
        private firestore: AngularFirestore,
        private auth: AuthService
    ) {
        this.onTypeOfDomainChanged = new BehaviorSubject([]);
    }
    getDomains() {
        return new Promise((resolve, reject) => {
            this.auth.user$.subscribe((credentials) => {
                if (credentials) {
                    this.firestore
                        .collection(`${datastore}/rwDomains`)
                        .snapshotChanges()
                        .subscribe((action) => {
                            this.typeReward = action.map((item) => {
                                return {
                                    id: item.payload.doc.id,
                                    ...(item.payload.doc.data() as any),
                                } as any;
                            });
                            this.onTypeOfDomainChanged.next(
                                _.orderBy(this.typeReward, ["title"], ["asc"])
                            );
                            resolve(this.typeReward);
                        });
                }
            });
        });
    }

    updateDomain(id: string, data: any) {
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/rwDomains/${id}`).update(data);
        });
    }
    createDomain(data: any) {
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}`).collection("rwDomains").add(data);
        });
    }
    deleteDomain(coinId: string){
        return new Promise((resolve, reject) => {
            this.firestore.doc(`${datastore}/rwDomains/${coinId}`).delete();
        });
    }
}
