

<div id="forgot-password" class="inner-scroll" style="height: 100%;" fxLayout="row" fxLayoutAlign="start">

    <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <!-- <img src="assets/images/logos/gestor_logo_trans.png"> -->
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
        </div>

    </div>

    <div id="forgot-password-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="forgot-password-form">

            <div class="logo" fxHide.gt-xs>
                <!-- <img src="assets/images/logos/logo_gestor_trans.png"> -->
            </div>

            <div class="title">INFORME SEU EMAIL</div>

            <form #form="ngForm" name="forgoPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email é obrigatório
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Por favor entre com um e-mail valido!
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" style="color: white !important;" color="accent" (click)="resetPassword(form)"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid">
                    ENVIAR
                </button>

            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Voltar para o login</a>
            </div>

        </div>
    </div>
</div>
