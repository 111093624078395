import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { CoinComponent } from './coin.component';
import { PoContainerModule, PoModalModule, PoPageModule, PoWidgetModule, PoTableModule, PoButtonModule, PoFieldModule  } from '@po-ui/ng-components';
import { CoinService } from 'app/service/coin.service';
import { MatIconModule } from '@angular/material/icon';

const routes = [
    {
        path : 'coins',
        component: CoinComponent,
        resolve: {
            coins: CoinService
        }
    }
]

@NgModule({
    declarations: [
        CoinComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,

        MatIconModule,

        //PO Modules
        PoContainerModule,
        PoPageModule,
        PoWidgetModule,
        PoModalModule,
        PoTableModule,
        PoButtonModule,
        PoFieldModule

    ],
    exports:[
        CoinComponent
    ]
})
export class CoinModule { }
