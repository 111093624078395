import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelDownService {

  constructor() { }

  downLoad(obje: any){
    const newarray = []
    obje.forEach(element => {
       (element)
      const milliseconds = element.purchaseDate.seconds * 1000 //
      const dataCompra = new Date(milliseconds)
      const datacompracerto = `${dataCompra.getDate()}/${dataCompra.getMonth()+1}/${dataCompra.getFullYear()} as ${dataCompra.getHours()}:${dataCompra.getMinutes()}:${dataCompra.getSeconds()}`
      let dataEntrega: string
      let status: string
      if (element.deliveryStatus == 0){
        status = 'Em aberto'
      }else if(element.deliveryStatus == 1){
        status = 'Entregue'
      }else if (element.deliveryStatus == 2){
        status = 'Em andamento'
      }else if(element.deliveryStatus == 3){
        status = 'Aguardando entrega'
      }

      if (element.deliveryDate == undefined){
        dataEntrega = 'Data de entrega indisponivel'
      }else{
        dataEntrega = element.deliveryDate
      }

    newarray.push([element.userName, element.amount, dataEntrega, datacompracerto, status])
    
  const dateObject = new Date(milliseconds)
    });
     (newarray)
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Employee Data");
    let header=["Colaborador","Valor", "Data de entrega", "Data de compra", "Status"]
    let headerRow = worksheet.addRow(header);
    for (let x1 of JSON.parse(JSON.stringify(newarray)))
    {
      let x2=Object.keys(x1);
      let temp=[]
      for(let y of x2)
      {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    let fname="consulta de dados"

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
    });
      }
  

    downLoadQuests(obje: any){
        const newarray = []
        obje.forEach(element => {
          const milliseconds = element.sendDate.seconds * 1000 //
          const dataCompra = new Date(milliseconds)
          const datacompracerto = `${dataCompra.getDate()}/${dataCompra.getMonth()+1}/${dataCompra.getFullYear()} as ${dataCompra.getHours()}:${dataCompra.getMinutes()}:${dataCompra.getSeconds()}`
          let dataEntrega: string
          let status: string
          if (element.questStatus == 1){
            status = 'EM ANALISE'
          }else if(element.questStatus == 2){
            status = 'APROVADA'
          }
    
      newarray.push([element.userName, element.questName, element.reward, datacompracerto, status])
        
     
        });
         (newarray)
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Employee Data");
        let header=["Colaborador","Quest", "Recompensa", "Data de envio", "Status"]
        let headerRow = worksheet.addRow(header);
        for (let x1 of JSON.parse(JSON.stringify(newarray)))
        {
          let x2=Object.keys(x1);
          let temp=[]
          for(let y of x2)
          {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }
        let fname="consulta de dados"
    
        //add data and file name and download
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
        });
       }

       downLoadProducts(obje: any){
        const newarray = [];
        let descricao: string;
        let status: string
        obje.forEach(element => {
          if (element.status == true){
            status = 'ATIVO'
          }else if(element.questStatus == false){
            status = 'INATIVO'
          }
    
          if (element.description === '' || element.description === undefined){
              descricao = 'Descrição nao disponivel'
          }else{
            descricao = element.description
          }

        newarray.push([element.name, element.price, descricao, status])
     
        });

        
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Employee Data");
        let header=["Nome", "Valor", "Descrição", "Status"]
        let headerRow = worksheet.addRow(header);
        for (let x1 of JSON.parse(JSON.stringify(newarray)))
        {
          let x2=Object.keys(x1);
          let temp=[]
          for(let y of x2)
          {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }
        let fname="consulta de dados"
    
        //add data and file name and download
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
        });
       }


       downLoadLogs(obje: any){
        const newarray = [];
        let descricao: string;
        let status: string
        
        obje.forEach(element => {
          const milliseconds = element.registeredDate.seconds * 1000 //
          const dataCompra = new Date(milliseconds)
          const datacompracerto = `${dataCompra.getDate()}/${dataCompra.getMonth()+1}/${dataCompra.getFullYear()} as ${dataCompra.getHours()}:${dataCompra.getMinutes()}:${dataCompra.getSeconds()}`
          newarray.push([element.pageName, element.actionExecuted, element.responsible, datacompracerto, element.description, element.objectTransacted])
        });

        
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Employee Data");
        let header=["Nome da pagina", "Açao executada", "Responsavel", "Data do registro", "Descriçao", "Objeto transitado"]
        let headerRow = worksheet.addRow(header);
        for (let x1 of JSON.parse(JSON.stringify(newarray)))
        {
          let x2=Object.keys(x1);
          let temp=[]
          for(let y of x2)
          {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }
        let fname="consulta de dados"
    
        //add data and file name and download
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
        });
       }


       downLoadColaboradores(obje: any){
          const newarray = [];
          let ativo = ''
          let perfil = ''
          obje.forEach(element => {
            if (element.ativo == true){
                ativo = 'ATIVO'
            }else if(element.ativo == false){
                ativo = 'INATIVO'
            }else{
                ativo = 'SEM INFORMACAO'
            }
            if(element.userPerfil == 1){
                perfil = 'ADMIN'
            }else if(element.userPerfil == 3){
                perfil = 'USER'
            }
            newarray.push([element.firstName, element.lastName, element.displayName, element.email, element.cash, element.trade , perfil, ativo])
          });

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet("Employee Data");
          let header=["Nome", "Sobrenome", "Nome de Exibicao", "Email", "Moedas loja", "Moedas recompensa", "perfil", "Usuario ativo?"]
          let headerRow = worksheet.addRow(header);
          for (let x1 of JSON.parse(JSON.stringify(newarray)))
          {
            let x2=Object.keys(x1);
            let temp=[]
            for(let y of x2)
            {
              temp.push(x1[y])
            }
            worksheet.addRow(temp)
          }
          let fname="consulta de dados"
      
          //add data and file name and download
          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
          });
        }

}
