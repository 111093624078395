import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoinsModel } from 'app/models/coins.model';
import { datastore } from 'app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { LogsModule } from 'app/main/logs/logs.module';

@Injectable({
    providedIn: 'root'
})

export class LogService implements Resolve<any> {

    logs: any;

    routeParams: any;

    onLogChanged: BehaviorSubject<any>;

    constructor(
        private firestore: AngularFirestore
    ) { 
        this.onLogChanged = new BehaviorSubject([]);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
        this.routeParams = route.params;        
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getLogs(),
            ]).then(() => {
                    resolve();
                },
                reject
            );
        });
    }

    getLogs(){
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/logs`).snapshotChanges().subscribe(action => {
                this.logs = action.map(item => {
                    return {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as LogsModule),
                    } as LogsModule
                });
                this.onLogChanged.next(_.orderBy(this.logs));
                resolve(this.logs);
            });
        })
    }

    insertLog(data: LogsModule) {
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/logs`).add(data);
        })
    }
  
}
