import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    PoModalAction,
    PoModalComponent,
    PoNotification,
    PoNotificationService,
    
    
} from "@po-ui/ng-components";
import { ShoppingCartService } from "app/service/shopping-cart.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { ProductsModel } from "app/models/products.model";
import { StoreService } from "app/service/store.service";
import { AuthService } from "app/service/auth.service";
import { UserService } from "app/service/user.service";
import { PoTabsComponent } from '@po-ui/ng-components';
import { PurchasesCollaboratorService } from "app/service/purchases-collaborator.service";
import { Userproducts2 } from "app/models/userproducts";
import { StoreConfigurationServiceService } from "app/service/store-configuration.service";

@Component({
    selector: "store",
    templateUrl: "./store.component.html",
    styleUrls: ["./store.component.scss"],
    providers: [PoNotificationService],
})
export class StoreComponent implements OnInit {
    @ViewChild(PoModalComponent, { static: true })
    productModal: PoModalComponent;
    @ViewChild('userHover') userHover: ElementRef
    public listProducts: ProductsModel[];
    public listProductsColla: Userproducts2[];
    errormessage = "";
    slideStore = [];
    productsCart = [];
    productDetail: any = [];
    userCash: number;
    
    //Po Notification
    message: string;
    duration = 2500;
    poType: string;
    credentials: any;
    nameUserLogged: any;
    userId: any;
    userName: any;
    email: any;
    statusStoreCola: any
    p: Number = 0;
    c: Number = 0;
    constructor(
        private service: StoreService,
        private usersService: UserService,
        private shoppingCartService: ShoppingCartService,
        private _fuseSidebarService: FuseSidebarService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
        private serviceStore: StoreConfigurationServiceService,
        private serviceCollab : PurchasesCollaboratorService
    ) {
        if (localStorage.getItem("shoppingCart") !== null) {
            this.productsCart = JSON.parse(
                localStorage.getItem("shoppingCart")
            );
        }
     
        this.auth.user$.subscribe((credentials) => {
            this.userCash = credentials.cash;[
            this.userId = credentials.uid
            ]
        });

        this.slideStore = [
            "assets/images/banners/Banner01.png",
            "assets/images/banners/Banner02.png",
            "assets/images/banners/Banner03.png",
        ];
    }

    
    async ngOnInit(): Promise<void> {
        //Nao mostra os produtos do propio colaborador
        // await this.serviceCollab.getProducts2().then(async (res: any) =>{
        //     this.listProductsColla = await res.filter((el) =>{
        //         return el.approve === 2 && el.userid != this.userId
        //     })
        // })

        await this.serviceCollab.getProducts2().then(async (res: any) =>{
            this.listProductsColla = await res.filter((el) =>{
                return el.approve === 2
            })
        })

        // console.log(this.listProductsColla)
        this.service.onProductChanged.subscribe((products) => {
            this.listProducts = products;
        });
       
        this.shoppingCartService.resolve()
        
        await this.serviceStore.getStatusStore('colaboradores').then(async (res) =>{
            await res.forEach( async (element) => {
                let state = await element.data().status 
                 
                this.statusStoreCola = state
            });
            
        })
        
    }

    ngAfterViewInit(): void{
        
    }

    AddAction: PoModalAction = {
        action: () => {
            this.addProductCart(this.productDetail);
            
            this.productDetail = [];
            this.message = "Produto adicionado ao carrinho!";
            this.poType = "success";
            this.showNotification(this.poType);
            this.productModal.close();
        },
        label: "Adicionar ao Carrinho",
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.productDetail = [];
            this.productModal.close();
        },
        label: "Voltar",
    };

    productModalOpen(item) {
        this.productDetail = item;
        this.productModal.open();
    }

    addProductCart(ref) {
        // console.log('aq')
        const item = { ...ref };
        if (localStorage.getItem("shoppingCart") !== null) {
            this.productsCart = JSON.parse(
                localStorage.getItem("shoppingCart")
            );
        } else {
            this.productsCart = [];
        }
        delete item.price;
       

        const indexOf = this.productsCart.findIndex(
            (ref) => ref.id === item.id
        );

        
        if (indexOf >= 0) {
            this.productsCart[indexOf].amount += 1;
        } else {
            item.amount = 1;
            this.productsCart.push(item);
        }
       

        localStorage.clear();
        localStorage.setItem("shoppingCart", JSON.stringify(this.productsCart));
        this.shoppingCartService.onUpdateShoppingCart();
        this._fuseSidebarService.getSidebar("shoppingCart").toggleOpen();
    }



    addProductCartColla(ref) {
      
        const item = { ...ref };

        if (localStorage.getItem("shoppingCart") !== null) {
            this.productsCart = JSON.parse(
                localStorage.getItem("shoppingCart")
            );

        } else {
            this.productsCart = [];
        }
  
        let verificaQuantidade = this.productsCart.filter((el) =>{
            return ref.id === el.id
        })

        if ( verificaQuantidade.length != 0 && verificaQuantidade[0].amount >= ref.quantity){
            this.message = "Você atingiu o limite de estoque do produto, verifique o carrinho !";
            this.poType = "error";
            this.showNotification(this.poType);
        }else{
            delete item.price;
       
    
            const indexOf = this.productsCart.findIndex(
                (ref) => ref.id === item.id
            );
            
            if (indexOf >= 0) {
                this.productsCart[indexOf].amount += 1;
            } else {
                item.amount = 1;
                item.purchaseColla = true
                this.productsCart.push(item);
            }
            
            localStorage.clear();
            localStorage.setItem("shoppingCart", JSON.stringify(this.productsCart));
            this.shoppingCartService.onUpdateShoppingCart();
            this._fuseSidebarService.getSidebar("shoppingCart").toggleOpen()
           
            
        }
     
 

       
        
    }



    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }

    updatePriceOfCart() {}






}
