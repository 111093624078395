import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../../service/auth.service'
import { UserService } from 'app/service/user.service';
import { PoNotification, PoNotificationService } from '@po-ui/ng-components';

@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit
{
    loginForm: FormGroup;
    authError: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public auth: AuthService,
        private service: UserService,
        private poNotification: PoNotificationService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
         
        this.auth.eventAuthErrors$.subscribe(data => {
            this.authError = data;
        })

        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        }
    
    login(form: NgForm){
        const data = Object.assign({}, form.value);
         (data)
        this.service.veryficaExistenciaEmail().then((resp) =>{
            let emails = []
            resp.docs.forEach((dados) =>{
                emails.push(dados.data());
            })

            if (emails.find(users => users.email === data.email)){
                let user = emails.find(users => users.email === data.email)
                if (user.predefinido === true){
                    // existe a conta porem nao existe no authentication, ira chamar o metodo para criar conforme os dados do firebase
                    this.auth.createUserPredefine(user)
                }else if (user.predefinido == undefined || user.predefinido == false){
                    this.auth.login(data.email, data.password);
                }
            }else{
                const notifiy: PoNotification = {
                    message:
                        "Usuario nao existe no banco de dados",
                    duration: 10000,
                    orientation: 1,
                };
                this.poNotification.error(notifiy);
            }
        })
       

    }
}
