
<div class="po-xl-12 po-lg-12" style="padding: 10px 0">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-4">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">face</mat-icon>
                        Colaboradores
                    </h2>     
                </div>
                <div class="po-lg-8" style="padding: 10px; display: flex; flex-direction: column-reverse; align-items: flex-end; gap: 15px;" *ngIf="auth.user$ | async as user">
                    <div>
                        <po-button p-label=" Adm - Moedas" p-icon="po-icon po-icon-plus" *ngIf="user.userPerfil == 4"
                        p-type="Secondary" (p-click)="modalMoedas()" class="pull-right">
                        </po-button>
                        <po-button p-label=" Novo Colaborador" style="padding-right: 10px" p-icon="po-icon po-icon-plus"
                            p-type="Primary" (p-click)="openNewModal()" class="pull-right">
                        </po-button>
                        <po-button p-label="Solicitar Recompensa" style="padding-right: 10px" p-icon="po-icon po-icon-plus"
                            p-type="Primary" (p-click)="addSelecteds(); " class="pull-right">
                        </po-button>

                        
                    </div>

                    <div class="masterSearch">
                        <div class="btnBox">
                            <button class="buttonExcell"><img src="/assets/icons/exceldown/excelIcon.png" (click)="downloadEx()"  alt=""></button>
                        </div>
                        <div class="search">
                            <po-input p-placeholder="Nome de exibição" class="inputSearch" name="nome" id="nome" 
                            [(ngModel)]="nome" (ngModelChange)="filterName()" type="text"></po-input>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <po-tabs>
                <po-tab p-label="Lista Completa" [p-active]="true">
                    <div class="po-row">
                        <po-table p-sort="true" [p-items]="listusers | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p, id: 'pagination1'}" (p-sort-by)="sort($event)" [p-striped]="true" class="po-table-users"
                            [p-actions]="actions" [p-columns]="columns" p-selectable (p-selected)="sumTotal($event)"
                            (p-unselected)="decreaseTotal($event)">
                            <ng-template p-table-column-template [p-property]="'photoURL'" let-value>
                                <div>
                                    <img class="avatar" style="margin: 5px 0" id="photoURL" nome="photoURL" src="{{ value }}"
                                        alt="photoURL" />
                                </div>
                            </ng-template>
                        </po-table>
                        <pagination-controls id="pagination1" style="margin-top: 20px;" previousLabel="Voltar" nextLabel="Proximo" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </po-tab>
                
                    <po-tab p-label="Pendentes de Aprovação">
                        <div class="po-row">
                            <po-table p-sort="true"  [p-items]="listusersPending | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: c, id: 'pagination2'}" (p-sort-by)="sort($event)" [p-striped]="true" class="po-table-users"
                                [p-actions]="actions2" [p-columns]="columnsPending" p-selectable (p-selected)="sumTotal($event)"
                                (p-unselected)="decreaseTotal($event)">
                                <ng-template p-table-column-template [p-property]="'photoURL'" let-value>
                                    <div>
                                        <img class="avatar" style="margin: 5px 0" id="photoURL" nome="photoURL" src="{{ value }}"
                                            alt="Sem imagem" />
                                    </div>
                                </ng-template>
                            </po-table>
                            <pagination-controls id="pagination2"  style="margin-top: 20px;"  previousLabel="Voltar" nextLabel="Proximo" (pageChange)="c = $event"></pagination-controls>
                        </div>
                    </po-tab>
               
              </po-tabs>
            <po-modal #addUserModal p-click-out="false" p-title="Novo Usuário" [p-primary-action]="SaveAction"
                [p-secondary-action]="CloseAction">
                <form #form="ngForm" [formGroup]="reactiveForm">
                    <div class="po-row">
                        <po-input class="po-sm-12 po-md-6" formControlName="firstName" p-clean p-icon="po-icon-user"

                            p-label="Primeiro Nome *">
                        </po-input>
                        <po-input class="po-sm-12 po-md-6" formControlName="lastName" p-clean p-icon="po-icon-user"
                            p-label="Segundo Nome *">
                        </po-input>
                    </div>
                    <div class="po-row">
                        <!-- <po-datepicker class="po-sm-12 po-md-6" formControlName="birthday" p-label="Data de Nascimento" p-clean p-format="dd/mm/yyyy"> </po-datepicker> -->
                        <po-select class="po-sm-4 po-md-4" formControlName="userPerfil" p-clean
                            p-label="Perfil de Usuário *" [p-options]="userProfile"></po-select>
                        <po-email class="po-sm-8 po-md-8" formControlName="email" p-label="Email *" p-clean>
                        </po-email>
                    </div>
                    <div class="po-row">
                        <div *ngIf="auth.user$ | async as user">
                            <po-number *ngIf="user.userPerfil == 1" class="po-sm-6 po-md-4" formControlName="cash"
                                p-label="Moeda da Loja">
                            </po-number>
                            <po-number *ngIf="user.userPerfil == 1" class="po-sm-6 po-md-4" formControlName="trade"
                                p-label="Para recompensar">
                            </po-number>
                        </div>
                        <po-switch class="po-sm-4 po-md-4" formControlName="userStatus" p-label="Usuário Ativo"
                            p-label-off="Inativo" p-label-on="Ativo" p-auto-focus="true"></po-switch>
                    </div>
                    <div>
                        <po-input class="po-sm-12 po-md-6" formControlName="passwd" p-clean p-icon="po-icon-user"
                            p-label="Senha provisoria *">
                        </po-input>
                    </div>
                    <!-- <div class="po-row">
                        <po-upload class="po-sm-12 po-md-12" name="image" p-drag-drop-height="150" p-label="Foto do Perfil" p-drag-drop="true" p-url="assets/images/produtos"> </po-upload>
                    </div> -->
                </form>
            </po-modal>

            <po-modal #updateUserModal p-click-out="false" p-title="Atualizar usuario" [p-primary-action]="UpdateAction"
            [p-secondary-action]="CloseActionUpdate">
            <form #form="ngForm" [formGroup]="reactiveFormUpdate">
                <div class="po-row">
                    <po-input class="po-sm-12 po-md-6" formControlName="firstName" p-clean p-icon="po-icon-user"

                        p-label="Primeiro Nome *">
                    </po-input>
                    <po-input class="po-sm-12 po-md-6" formControlName="lastName" p-clean p-icon="po-icon-user"
                        p-label="Segundo Nome *">
                    </po-input>
                </div>
                <div class="po-row">
                    <!-- <po-datepicker class="po-sm-12 po-md-6" formControlName="birthday" p-label="Data de Nascimento" p-clean p-format="dd/mm/yyyy"> </po-datepicker> -->
                    <po-select class="po-sm-4 po-md-4" formControlName="userPerfil" p-clean
                        p-label="Perfil de Usuário *" [p-options]="userProfile"></po-select>
                </div>
                <div class="po-row">
                    <div *ngIf="auth.user$ | async as user">
                        <po-number *ngIf="user.userPerfil == 1" class="po-sm-6 po-md-4" formControlName="cash"
                            p-label="Moeda da Loja">
                        </po-number>
                        <po-number *ngIf="user.userPerfil == 1" class="po-sm-6 po-md-4" formControlName="trade"
                            p-label="Para recompensar">
                        </po-number>
                    </div>
                    <po-switch class="po-sm-4 po-md-4" formControlName="userStatus" p-label="Usuário Ativo"
                        p-label-off="Inativo" p-label-on="Ativo" p-auto-focus="true"></po-switch>
                </div>
                <!-- <div class="po-row">
                    <po-upload class="po-sm-12 po-md-12" name="image" p-drag-drop-height="150" p-label="Foto do Perfil" p-drag-drop="true" p-url="assets/images/produtos"> </po-upload>
                </div> -->
            </form>
        </po-modal>


    
        <po-modal #deleteUser p-click-out="false" p-title="Voce deseja desativar o usuario ?" [p-primary-action]="deleteSuccess" [p-secondary-action]="closeDelete">
                
        </po-modal> 
        <po-modal #activeuser p-click-out="false" p-title="Voce deseja ativar o usuario ?" [p-primary-action]="activeSuccess" [p-secondary-action]="closeActive">
                
        </po-modal> 


            <po-modal #userModalMoedas p-click-out="false" p-title="Controle de recompensas" [p-primary-action]="SaveActionMoedas"
                [p-secondary-action]="CloseActionMoedas">
                <form #form="ngForm" [formGroup]="reactiveFormMoeda">
                    <div class="po-row" style="padding-bottom: 10px">
                        <po-checkbox class="po-sm-12 po-md-12" *ngIf="!checkedArredondar" p-disabled="false" name="checkbox" p-label="Moedas para todos os usuários?"
                            (change)="selectAllUsers($event)">
                        </po-checkbox>
                        <po-checkbox class="po-sm-12 po-md-12" *ngIf="checkedArredondar" p-disabled="true" name="checkbox" p-label="Moedas para todos os usuários?"
                            (change)="selectAllUsers($event)">
                        </po-checkbox>
                        <po-checkbox class="po-sm-12 po-md-12" name="checkbox" p-label="Arredondar moedas de todos os usuários?"
                            (change)="selectAllUsers2($event)">
                        </po-checkbox>
                    </div>
                    <div class="po-row" *ngIf="!checkedAllUsers && !checkedArredondar">
                        <po-multiselect [p-required]="checkedAllUsers" class="po-sm-12 po-md-12" name="multiselect"
                            formControlName="userSelect" p-label="Selecione os usuários! *" [p-options]="options">
                        </po-multiselect>
                    </div>
                    <div class="po-row">
                        <po-select class="po-sm-6 po-md-6" *ngIf="!checkedArredondar"  formControlName="type" formControlName="coinTypeChoice"
                            p-clean p-label="Tipo da Moeda *" [p-options]="coinType" required></po-select>
                    </div>
                    <div class="po-row">
                        <po-number *ngIf="!checkedArredondar" class="po-sm-6 po-md-4" formControlName="valorSelect"
                            p-label="Valor *"></po-number>
                    </div>
                    <br />
                    <span style="font-weight: bold; padding-left: 8px">Obs. Campos com * são obrigatórios</span>
                </form>
            </po-modal>
        </div>
    </po-container>
</div>

<po-modal #modalSolicitation p-title="Solicitação de Recompensa" [p-primary-action]="SaveActionSolicMoeda"
    [p-secondary-action]="CloseSoliciModal">
    <div style="padding: 0.5rem; margin-bottom: 1rem">
        <div *ngFor="let user of selectedItems" class="container-content-modal" style="margin-right: 1rem;">
            <div class="container-avatar po-sm-8 po-md-8" style="margin: 0.5rem;">
                <po-avatar [p-src]="user.photoURL" class="img-avatar" p-size="sm">
                </po-avatar>
                <p>{{ user.displayName }}</p>
            </div>
        </div>
    </div>

    <br />
    <form #form="ngForm" [formGroup]="reactiveModalFormReward">
        <div class="po-row">
            <po-select [p-required]="checkedAllUsers" class="po-sm-12 po-md-6" name="multiselect"
                formControlName="reward" p-label="Selecione o tipo de Recompensa! *" [p-options]="optionsRewards"
                (p-change)="handleSelectModelChange($event)">
            </po-select>
            <po-input class="po-sm-12 po-md-6" formControlName="coins" p-clean p-icon="po-icon-star"
                p-label="Quantidade de estrelas" p-disabled="true">
            </po-input>
            <po-input class="po-sm-12 po-md-6" formControlName="typeOfReward" p-clean p-label="Tipo de Recompensa"
                p-disabled="true">
            </po-input>
        </div>

        <br />
        <span style="font-weight: bold; padding-left: 8px">Obs. Campos com * são obrigatórios</span>
    </form>
</po-modal>




<!-- Super pointz -->
<po-modal #modalSuperPointz p-title="Solicitação de Super pointz" [p-primary-action]="SaveActionSp"
    [p-secondary-action]="CloseSpModal">
    
    <div>
        <!-- [p-items]="listusers | orderBy: key : reverse | paginate: {itemsPerPage: itemPage, currentPage: p, id: 'pagination1'}" -->
        <h1>Grupos cadastrados</h1>
        <po-table p-sort="true"  [p-items]="listGroupsSp"  (p-sort-by)="sort($event)" [p-striped]="true" class="po-table-users"
                            [p-actions]="actions" [p-columns]="columns" p-selectable 
                            (p-unselected)="decreaseTotal($event)">
        <ng-template p-table-column-template [p-property]="'photoURL'" let-value>
            <div>
                <img class="avatar" style="margin: 5px 0" id="photoURL" nome="photoURL" src="{{ value }}"
                    alt="photoURL" />
            </div>
        </ng-template>
        </po-table>
    </div>

</po-modal>