
<div class="po-xl-12 po-lg-12" style="padding: 10px 0;">
    <po-container class="po-xl-12 po-lg-12">
        <div class="container-custom-01">
            <div class="po-row">
                <div class="po-lg-8">
                    <h2 class="align-title">
                        <mat-icon class="secondary-text hlp-margin-right-10">perm_data_setting</mat-icon> Parâmetros do Sistema
                    </h2>
                </div>
            </div>
            <hr>
            <div class="po-row po-sm-12 po-md-12">
                <form [formGroup]="reactiveForm" class="po-sm-12 po-md-12">
                    <!-- <div class="po-row po-sm-4 po-md-8">
                        <po-input class="po-sm-12 po-md-12" formControlName="gFunctionAPI" p-clean p-icon="po-icon-link" p-label="Cloud Function API"></po-input>
                    </div> -->
                    <div class="po-row po-sm-4 po-md-8">
                        <po-input class="po-sm-12 po-md-12" formControlName="webhookLink" p-clean p-icon="po-icon-link" p-label="Link do Webhook do Google Chat"></po-input>
                    </div>                    
                    <div class="po-sm-12 po-md-12">  
                        <po-button  p-label="Gravar" p-icon="po-icon po-icon-save" p-type="Primary" (p-click)="saveChanges()" class="pull-right"> </po-button>   
                    </div>
                </form>
            </div>
        </div>        
    </po-container>
</div>