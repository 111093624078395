import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ApprovalRewardComponent } from "./approval-reward.component";
import { RouterModule } from "@angular/router";
import {
    PoAccordionModule,
    PoAvatarModule,
    PoButtonModule,
    PoFieldModule,
    PoInfoModule,
    PoModalModule,
    PoTableModule,
    PoTagModule,
} from "@po-ui/ng-components";
import { PoContainerModule } from "@po-ui/ng-components";
import { MatIconModule } from "@angular/material/icon";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {NgxPaginationModule} from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { AuthGuard } from "app/service/auth.guard";
const routes = [
    {
        path: "rewards-approval",
        component: ApprovalRewardComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [ApprovalRewardComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        PoFieldModule,
        OrderModule,
        PoContainerModule,
        PoContainerModule,
        PoAccordionModule,
        PoInfoModule,
        PoTagModule,
        PoModalModule,
        PoTableModule,
        MatIconModule,
        PoButtonModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        PoAvatarModule,
    ],
})
export class ApprovalRewardModule {}
