import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { fuseAnimations } from '@fuse/animations'
import { AuthService } from 'app/service/auth.service'
import * as _ from 'lodash'
import * as moment from 'moment'

// import { ProfileService } from './profile.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DialogContentExampleDialog } from './modal-accept/modal.component'
import { ModalConfirmComent } from './modal-comment/modal.component'
import Post from './model-post'
import { TimelineService } from './timeline.service'

@Component({
  selector: 'profile-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfileTimelineComponent implements OnInit, OnDestroy {
  timeline = {
    posts: [],
    hasNext: true,
  }

  // Private
  private _unsubscribeAll: Subject<any>

  /**
   * Constructor
   *
   * @param {ProfileService} _profileService
   */
  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    public timelineService: TimelineService, // private _profileService: ProfileService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  userData
  ngOnInit(): void {
    // this.timelineService.getData()
    this.timelineService.getDataPaginated()

    this.auth.user$.subscribe((dataUser) => {
      this.userData = dataUser
    })

    this.timelineService.timelineFilterOnChanged.subscribe((item) => {
      this.timeline.posts = item
    })
    this.timelineService.timelineHasNext.subscribe((item) => {
      this.timeline.hasNext = item
    })

    // this._profileService.timelineOnChanged
    //     .pipe(takeUntil(this._unsubscribeAll))
    //     .subscribe(timeline => {
    //         this.timeline = timeline;
    //     });
  }

  handleGetNextPage() {
    this.timelineService.getNextPage()
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete()
  }
  @ViewChild('newPost', { static: true }) newPost: ElementRef
  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      data: {
        newPost: this.newPost,
        userData: this.userData,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.newPost.nativeElement.valu = ''
      }
    })
  }
  openDialogComment(comment, post) {
    const dialogRef = this.dialog.open(ModalConfirmComent, {
      data: {
        post: post,
        userData: this.userData,
      },
      width: '50%',
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }
  handleVisibleComments(event) {
    if (event.style.display === 'none') {
      event.style.display = 'block'
    } else {
      event.style.display = 'none'
    }
  }

  handleLike(post: Post) {
    const indexOf = post.like.findIndex(
      (item) => item.uid === this.userData.uid,
    )
    let dataPost = { ...post }
    if (indexOf === -1) {
      dataPost.like.push({
        displayName: this.userData.displayName,
        uid: this.userData.uid,
        avatar: this.userData.photoURL,
      })
    } else {
      dataPost.like.splice(indexOf, 1)
    }

    try {
      this.timelineService.setDoc(dataPost)
    } catch (error) {
      console.error(error)
    }
  }

  getUsersWhoLiked(post: Post) {
    return post.like.map((item) => item.displayName).concat(' ')
  }
}
