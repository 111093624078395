import { Component, OnInit, ViewChild } from "@angular/core";
import { PoModalAction, PoModalComponent, PoNotification, PoNotificationService } from "@po-ui/ng-components";
import { PurchasesModel } from "app/models/purchases.model";
import { NotificationService } from "app/service/notification.service";
import { PurchasesCollaboratorService } from "app/service/purchases-collaborator.service";
import { PurchasesService } from "app/service/purchases.service";
import { UserService } from "app/service/user.service";

@Component({
    selector: "purchases",
    templateUrl: "./purchases.component.html",
    styleUrls: ["./purchases.component.scss"],
})
export class PurchasesComponent implements OnInit {
    public myPurchases: PurchasesModel[];
    public myPurchasesCol: PurchasesModel[];
    product: any
    acceptBox: boolean
    moedasParaColaborador: any
    poType: string;
    message: string;
    duration: number;
    itemPage: Number = 10;
    itemPage2: Number = 10;
    p:Number = 0;
    c:Number = 0;
    constructor(private service: PurchasesService, private userservice: UserService, private notificationService: NotificationService, 
        private purchaseColService: PurchasesCollaboratorService,
        private poNotification: PoNotificationService) {}
    @ViewChild("modalconfirmRecive", { static: true }) modalconfirmRecive: PoModalComponent;
    quantity: number;

    ngOnInit(): void {

        this.service.onPurchasesChanged.subscribe((purchases) => {
            this.myPurchases = purchases;
        });

        this.service.onPurchasesChangedColla.subscribe((purchases2) => {
            this.myPurchasesCol = purchases2;
        });
        
        
    }

    ngAfterViewInit(): void{
        
    }

    
    getStatus(param) {
        if (param === true) {
            return "Entregue";
        } else if (param === false) {
            return "Em Aberto";
        } else if (param === "2") {
            return "Em Andamento";
        } else if (param === "3") {
            return "Aguardando Entrega";
        }
    }


    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1,
        };
        switch (messageType) {
            case "success": {
                this.poNotification.success(poNotification);
                break;
            }
            case "error": {
                this.poNotification.error(poNotification);
                break;
            }
            case "warning": {
                this.poNotification.warning(poNotification);
                break;
            }
            case "information": {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }
        }
        this.poType = "";
    }



    confirmRecive(purchase){
        this.product = []
        this.product = purchase as any
       
        //Remove duplicidade para pegar os IDS que existem na compra, para sim calcular as moedas que cada colaborador ira receber (Para nao bugar quando o usuario comprar de 2 ou mais colaboradores diferentes ao mesmo tempo)
        let values = this.product.products.filter(function (a) {
            return !this[JSON.stringify(a.userid)] && (this[JSON.stringify(a.userid)] = true && a.purchaseColla == true)
        }, Object.create(null))
            
              
        // Esvaziar o array toda vez
        this.moedasParaColaborador = []
        let auxARR = []
        values.forEach((data, index) => {
            let valorParaReceber = 0
            this.product.products.forEach(function(e, i) {
                if (e.purchaseColla === true && e.purchaseColla != undefined){
                    if (e.userid == data.userid){
                        valorParaReceber += e.price
                    }
                    auxARR[index] = {
                        valorTotal:  valorParaReceber,
                        userName: data.userName,
                        userId: data.userid,
                        
                    }
                    
                }else{
                    
                }
              
            })
           
        });
       

  
        this.moedasParaColaborador = auxARR

        this.modalconfirmRecive.open()

    }

    acceptTerm(){
        this.acceptBox = this.acceptBox == true ? this.acceptBox = false : this.acceptBox = true
        // console.log(this.acceptBox)
    }


    SaveAction: PoModalAction = {
        action: async () => {
        //    console.log(this.product)
           
           if (this.acceptBox == true){
                this.userservice.updateCoinsAfterDelivered(this.moedasParaColaborador).then((resp) =>{
                    this.moedasParaColaborador.forEach(async dadosUser => {
                        let notifySell = {
                        dateTimestamp: new Date().getTime(),
                        description: `Ola ${dadosUser.userName}, O Processo da sua venda foi concluido e voce recebeu ${dadosUser.valorTotal} Moedas, Aproveite !`,
                        uid: dadosUser.userId,
                        view: false,
                        typeNotify: 'sellProductCollaborator',
                        eraseNotify: false
                    };
                    
                    this.notificationService.newNotification(notifySell);
                    await this.purchaseColService.updateStatus(this.product.id, true)
                
                    });
                    this.message = "Moedas enviadas com sucesso, parabens pela compra";
                    this.poType = "success";
                    this.duration = 8000
                    this.showNotification(this.poType);
                    this.modalconfirmRecive.close();
                })


           }else{
                this.message = "Voce precisa confirmar que recebeu o produto !!!!! ";
                this.poType = "error";
                this.duration = 8000
                this.showNotification(this.poType);
           }
            
        },
        label: "Salvar",
    };
    CloseAction: PoModalAction = {
        action: () => {
            this.modalconfirmRecive.close();
        },
        label: "Cancelar",
    };

}
