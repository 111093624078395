import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { datastore } from "app/config";
import * as _ from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
interface Notification {
    id?: string;
    uid: string;
    description: string;
    dateTimestamp: number;

}
@Injectable({
    providedIn: "root",
})
export class NotificationService {
    onNotificationChanged: BehaviorSubject<any>;
    onNotificationChanged2: BehaviorSubject<any>;
    teste: Observable<any>
    myData = [];
    myData2 = [];
    notifications = [];
    onNextChanged: BehaviorSubject<Boolean>;
    constructor(private firestore: AngularFirestore) {
        this.onNotificationChanged = new BehaviorSubject([]);
        this.onNotificationChanged2 = new BehaviorSubject([]);
        this.onNextChanged = new BehaviorSubject(true);
    }

    newNotification(data: Notification) {
        // console.log(data)
        return new Promise((resolve, reject) => {
            this.firestore.collection(`${datastore}/notification`).add(data).then((res) =>{
                
            })
        });
    }
    
    async getAllNotifications(uid: string){
        
            this.firestore.collection(`${datastore}/notification`).get().subscribe((sub) =>{
                sub.docs.map(async (resp: any) =>{
                    
                    if (resp.data().uid === uid && resp.data().view === false) this.notifications.push(await resp.data())
                
                })
            })
            return this.notifications
      

    }


    async getNotificationByID(id: string){
        return this.firestore.doc(datastore).collection('notification').doc(id).get()
    }

    async updateNotificationByID(id: string, dados){
       
        return this.firestore.doc(datastore).collection('notification').doc(id).update(dados)
        
    }


    async getAllNotifications2(uid: string){
        let dataToReturn = {} as Notification
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/notification`, (ref) =>
                    ref
                        .where("uid", "==", uid)
                        .orderBy("dateTimestamp", "desc")
                        .limit(999)
                )
                .snapshotChanges()
                .subscribe(async (action) => {
                    let data = action.map(async (item: any) => {
                        if (item.payload.doc.data().view == false){
                            dataToReturn = {
                                 id: item.payload.doc.id,
                                ...(item.payload.doc.data() as Notification),
                            };
                        }else{
                            return
                        }

                        return dataToReturn;
                    });

                    data.forEach(element => {
                        element.then( (res) =>{
                             if (res != undefined){
                                this.myData2.push(res)
                             }
                        })
                    });
                    
                     this.myData2 = []
                     this.onNotificationChanged2.next(this.myData2)
                    
                     resolve(this.myData2)
                    
                    
                });
        });
  

}

    getNotifications(uid: string) {
        let dataToReturn = {} as Notification
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(`${datastore}/notification`, (ref) =>
                    ref
                        .where("uid", "==", uid)
                        .orderBy("dateTimestamp", "desc")
                        .limit(999)
                )

                .snapshotChanges()
                .subscribe((action) => {
                    let data = action.map((item: any) => {
                        //  if (item.payload.doc.data().eraseNotify != true){
                        
                            dataToReturn = {
                                id: item.payload.doc.id,
                                ...(item.payload.doc.data()  as Notification),
                            }
                        
                    
                        const indexOf = this.myData.findIndex(
                            (ref) => ref.id === dataToReturn.id
                        );
                        if (indexOf >= 0) {
                            this.myData.splice(indexOf, 1);
                        }

                        return dataToReturn;

                    });



                    if (data.length < 5) {
                        this.onNextChanged.next(false);
                    }

                    this.myData = []
                    this.myData.push(...data);


                    this.last = data[data.length - 1];
                    this.handleOrderBy();
                    this.onNotificationChanged.next(this.myData);
                    resolve(this.myData);
                });
        });
    }
    last;
    getNextPage(uid: string) {
         (this.last);
        this.firestore
            .collection(`${datastore}/notification`, (ref) =>
                ref
                    .where("uid", "==", uid)
                    .orderBy("dateTimestamp", "desc")
                    .startAfter(this.last?.dateTimestamp)
                    .limit(5)
            )
            .snapshotChanges()
            .subscribe((action: any) => {
                let data = action.map((item: any) => {
                    const dataToReturn = {
                        id: item.payload.doc.id,
                        ...(item.payload.doc.data() as Notification),
                    } as Notification;

                    const indexOf = this.myData.findIndex(
                        (ref) => ref.id === dataToReturn.id
                    );
                    if (indexOf >= 0) {
                        this.myData.splice(indexOf, 1);
                    }

                    return dataToReturn;
                });
                if (data.length < 5) {
                    this.onNextChanged.next(false);
                }

                this.myData.push(...data);
                this.last = data[data.length - 1];
                this.handleOrderBy();
                this.onNotificationChanged.next(this.myData);
            });
    }

    handleOrderBy() {
        this.myData = _.orderBy(this.myData, ["dateTimestamp"], ["desc"]);
    }

}
