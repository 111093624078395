import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { datastore } from 'app/config';

@Injectable({
  providedIn: 'root'
})
export class StoreConfigurationServiceService {
  routeParams: any
  constructor(private firestore: AngularFirestore) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {   
    this.routeParams = route.params; 
    return new Promise<void>((resolve, reject) => {
       resolve();
      // Promise.all([
            
        // ]).then(() => {
        //     resolve();
        // }, reject);
    });
}    

  async updateStatusStore(store: string, value: any){
      switch (store) {
        case 'colaborador':
          return await this.firestore.doc(datastore).collection('storeConfiguration').doc('colaboradores').update({status: value})
          break;
        case 'main':

          break;
        default:
          break;
      }
  }
  async getStatusStore(store: string){
      
      return await this.firestore.doc(datastore).collection('storeConfiguration').doc(store).get()

  }
  

}
