export const locale = {
    lang: "br",
    data: {
        NAV: {
            APPLICATIONS: "Aplicativos",
            DASHBOARD: {
                TITLE: "Dashboard",
            },

            STORE: {
                TITLE: "Loja",
            },
            MYPERFIL: {
                TITLE: "Meu Perfil",
            },
            MYPURCHASES: {
                TITLE: "Minhas Compras",
            },
            MYQUESTS: {
                TITLE: "Minhas Quests",
            },
            MYPRODUCTS: {
                TITLE: "Anunciar Produto",
            },
            MYSALES: {
                TITLE: "Minhas Vendas",
            },

            //--------------------------------------------------------------//

            ADMINISTRATION: "Administração",
            SALES: {
                TITLE: "Vendas",
            },
            TYPEREWARD: {
                TITLE: "Cadastro de recompensas",
            },
            QUESTS: {
                TITLE: "Quests",
            },
            APPROVALQUESTS: {
                TITLE: "Aprovação de Quests",
            },
            PRODUCTS: {
                TITLE: "Produtos",
            },
            APPROVE: {
                TITLE: "Aprovar Recompensa",
            },
            APPROVEPRODUCT: {
                TITLE: "Aprovação de Produtos",
            },
            DOMAINS: {
                TITLE: "Cadastro de Dominios",
            },
            CONFIGURATION: "Configuração",
            USERS: {
                TITLE: "Colaboradores",
            },
            COINS: {
                TITLE: "Moedas",
            },
            LOGS: {
                TITLE: "Logs",
            },
            SYSTEMPARAMS:{
                'TITLE': 'Parâmetros do Sistema'
            },
           STOREPARAMS:{
                'TITLE': 'Configurações da Loja'
            },
           SUPERPOINTZ:{
                'TITLE': 'Super Pointz'
            }
        },
    },
};
