import { Component, OnInit, ViewChild } from '@angular/core';
import { PoDialogConfirmLiterals, PoDialogService, PoModalAction, PoModalComponent, PoNotification, PoNotificationService, PoTableAction, PoTableColumn, PoTableColumnLabel } from '@po-ui/ng-components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CoinService } from 'app/service/coin.service';
import { CoinsModel } from 'app/models/coins.model';
import { AuthService } from 'app/service/auth.service';
import { Router } from '@angular/router';
import { runInThisContext } from 'vm';
import { LogService } from 'app/service/log.service';

@Component({
    selector: 'app-coin',
    templateUrl: './coin.component.html',
    styleUrls: ['./coin.component.scss']
})
export class CoinComponent implements OnInit {

    @ViewChild(PoModalComponent, { static: true }) addCoinModal: PoModalComponent;


    actions: Array<PoTableAction> = [
        { action: this.detailsCoin.bind(this), icon: 'po-icon-edit', label: 'Editar' },
        { action: this.verifyIfDeleteCoin.bind(this), icon: 'po-icon-edit', label: 'Excluir' }
    ];
    actionsConfig: Array<PoTableAction> = [
        { action: this.removeValueConfigCoin.bind(this), icon: 'po-icon-delete', label: 'Excluir' }
    ];

    listCoin: CoinsModel[];
    coinType = [];
    isUpdate = false
    coinUpdateId = '';
    columns: Array<PoTableColumn>;

    coinConfigList = [];
    hiddenConfigCoin = true;

    coinIdToDelete: any

    reactiveForm: FormGroup;

    //Po Notification
    message: string;
    duration = 2500;
    poType: string;

    literalsConfirm: PoDialogConfirmLiterals;

    nameUserLogged: any
    IdUserLogged: any

    constructor(
        private fb: FormBuilder,
        private service: CoinService,
        private poNotification: PoNotificationService,
        public auth: AuthService,
        private router: Router,
        private poAlert: PoDialogService,
        private logService: LogService
    ) {
        this.createReactiveForm();
        this.coinType = [
            { id: 1, label: 'Para a Loja', value: '1' },
            { id: 2, label: 'Para Recompensar', value: '2' }
        ]


        this.columns = [
            //{ property: 'icon', label: 'Icon', type: 'columnTemplate', visible: true },
            { property: 'name', label: 'Nome', visible: true },
            {
                property: 'type', label: 'Tipo', type: 'label', labels: <Array<PoTableColumnLabel>>[
                    {
                        value: '1',
                        color: 'color-03',
                        label: 'Para a Loja',
                        tooltip: 'Administrador do sistema'
                    },
                    {
                        value: '2',
                        color: 'color-05',
                        label: 'Para Recompensar',
                        tooltip: 'Organizador do sistema'
                    },
                ], visible: true
            },
            {
                property: 'status', label: 'Usuário Ativo?', type: 'label', labels: <Array<PoTableColumnLabel>>[
                    {
                        value: true ? true : '',
                        color: 'color-11',
                        label: 'Ativa',
                        tooltip: 'Usuário Ativo'
                    },
                    {
                        value: false,
                        color: 'color-07',
                        label: 'Inativa',
                        tooltip: 'Usuário Inativo'
                    },
                ], visible: true
            },
            { property: 'uid', label: '', visible: false },
        ]
    }

    ngOnInit(): void {
        //To control the user access, thsi acces if just for Admin users
        this.auth.user$.subscribe(credentials => {
            if (credentials.userPerfil != '1') {
                this.router.navigate(["/sample"])
            } else {

                this.nameUserLogged = credentials.displayName
                this.IdUserLogged = credentials.uid
                this.service.onCoinsChanged.subscribe(coins => {
                    this.listCoin = coins

                });
            }
        })

    }

    openNewModal() {
        this.clearForm();
        this.isUpdate = false;
        this.addCoinModal.open();
    }

    SaveAction: PoModalAction = {
        action: () => {
            let fields = this.reactiveForm.value;
            let saveCoin = {} as CoinsModel;
            if (this.reactiveForm.valid) {
                saveCoin.name = fields.name
                saveCoin.status = fields.status == null ? false : fields.status
                saveCoin.type = fields.type
                saveCoin.icon = fields.icon == undefined ? "" : fields.icon
                saveCoin.config = fields.type == '2' ? this.coinConfigList : [];


                if (this.verifyCoinRules(saveCoin.status, saveCoin.type, this.coinUpdateId)) {
                    if (!this.isUpdate) {
                        this.service.insertCoin(saveCoin)
                        this.message = 'Moeda cadastrada com sucesso!'
                        this.logService.insertLog({
                            pageName: 'Moeda',
                            actionExecuted: 'Criado nova moeda',
                            responsible: this.nameUserLogged,
                            userLoggedId: this.IdUserLogged,
                            registeredDate: new Date(),
                            objectTransacted: JSON.stringify(saveCoin)
                        })
                    } else {
                        this.service.updateCoin(this.coinUpdateId, saveCoin)
                        this.logService.insertLog({
                            pageName: 'Moeda',
                            actionExecuted: 'Update em uma moeda',
                            responsible: this.nameUserLogged,
                            userLoggedId: this.IdUserLogged,
                            registeredDate: new Date(),
                            objectTransacted: JSON.stringify(saveCoin)
                        })
                        this.coinUpdateId = ''
                        this.message = 'Moeda alterada com sucesso!'
                    }
                    this.poType = 'sucess'
                    this.addCoinModal.close();
                    this.clearForm();
                }
                this.showNotification(this.poType)
            } else {
                this.message = 'Preencha o formulário corretamente'
                this.poType = 'error'
                this.showNotification(this.poType)
            }
        },
        label: 'Salvar',
    };

    CloseAction: PoModalAction = {
        action: () => {
            this.addCoinModal.close();
        },
        label: 'Cancelar'
    };

    verifyCoinRules(saveStatus, saveType, coinId) {
        let countActive = 0
        let isValid = true;
        if (saveStatus) {
            this.listCoin.forEach(coin => {
                if (coin.status == true) {
                    countActive++
                    if (coin.type == saveType) {
                        if (!coinId || coin.id != coinId) {
                            isValid = false
                            this.message = 'Já existe uma moeda ativa com o memso tipo';
                            this.poType = 'error'
                        }

                    }
                }
            });
            if (countActive == 2 && isValid && !coinId) {
                isValid = false;
                this.message = 'Já existem duas moedas ativas';
                this.poType = 'error'
            }
        }
        if(saveType == 2 && this.coinConfigList.length == 0){

            isValid = false;
            this.message = 'Adicione ao menos um valor padrão de recomepensa';
            this.poType = 'error'
        }
        return isValid
    }

    detailsCoin(item: CoinsModel) {
        this.clearForm();
        this.isUpdate = true;
        this.reactiveForm = this.fb.group({
            name: [item.name],
            status: [item.status],
            type: [item.type],
            valueCoin: ['']
        });
        this.showConfigTable(item.type)
        this.coinConfigList = item.config ? item.config : []
        this.coinUpdateId = item.id
        this.addCoinModal.open();
    }

    createReactiveForm() {
        this.reactiveForm = this.fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            type: ['', Validators.compose([Validators.required])],
            icon: ['', /*Validators.compose([Validators.required])*/],
            status: [''],
            valueCoin: ['']
        });
    }

    clearForm() {
        this.reactiveForm.reset();
        this.coinConfigList = [];
        this.hiddenConfigCoin = true;
        // this.reactiveForm = this.fb.group({
        //     name: [''],
        //     type: [''],
        //     icon: [''],
        //     status: [''],
        //     valorConfigMoeda: ['']
        // });
    }

    showNotification(messageType: string) {
        const poNotification: PoNotification = {
            message: this.message,
            duration: this.duration,
            orientation: 1
        }
        switch (messageType) {
            case 'success': {
                this.poNotification.success(poNotification);
                break;
            }
            case 'error': {
                this.poNotification.error(poNotification);
                break;
            }
            case 'warning': {
                this.poNotification.warning(poNotification);
                break;
            }
            case 'information': {
                this.poNotification.information(poNotification);
                break;
            }
            default: {
                this.poNotification.success(poNotification);
                break;
            }

        }
        this.poType = ''
    }

    addItem() {
        let value = this.reactiveForm.get('valueCoin').value
        if (value) {
            this.coinConfigList.push({ Id: (this.coinConfigList.length + 1), Valor: value })
        } else {
            this.message = 'Preencha o valor para adicionar uma opção!'
            this.showNotification('error')
        }
        this.reactiveForm.controls['valueCoin'].reset();
    }

    removeValueConfigCoin(element) {
        let index = element.Id;
        let result = index - 1
        this.coinConfigList.splice(result, 1);
        if (this.coinConfigList.length > 0) {
            this.coinConfigList.forEach((element, index) => {
                element.Id = index + 1
            });
        }

    }

    showConfigTable(sendValue) {
        let value
        if (!sendValue) {
            value = this.reactiveForm.get('type').value
        } else {
            value = sendValue;
        }

        if (value == "2") {
            this.hiddenConfigCoin = false
        } else {
            this.hiddenConfigCoin = true
        }
    }

    confirmDialog() {
        this.poAlert.confirm({
            literals: this.literalsConfirm,
            title: "Atenção",
            message: 'Deseja realmente deletar esse item?',
            confirm: () => {
                this.service.deleteCoin(this.coinIdToDelete)
                this.coinIdToDelete = undefined
            },
            cancel: () => (undefined),
            close: () => (undefined)
        });
    }

    verifyIfDeleteCoin(item){
        this.coinIdToDelete = item.id;
        this.confirmDialog()
    }
    
    deleteCoin(){ 
        this.service.deleteCoin(this.coinIdToDelete)
        this.coinIdToDelete = undefined
    }

}

