// export const environmentPRD = {
//   production: true,
//   hmr: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyDKqb1ldYs4BaOFQfb3kShoX_MO9HEJUhU",
//     authDomain: "rewardsly-iv2-dev.firebaseapp.com",
//     projectId: "rewardsly-iv2-dev",
//     storageBucket: "rewardsly-iv2-dev.appspot.com",
//     messagingSenderId: "273191639133",
//     appId: "1:273191639133:web:054fc46473dcd499b315b4",
//     measurementId: "G-WGVXCK35DX"
//   },
//   url:"https://assinatura-gestor-tarefas-2-0.rj.r.appspot.com"
// }
// export const environmentPRD = {
//  production: true,
//   hmr: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyDptbXvhGvQBGSx-fpiKsL7uRXnq27cbtc",
//   authDomain: "pointz-totvs-rh-demo.firebaseapp.com",
//   projectId: "pointz-totvs-rh-demo",
//   storageBucket: "pointz-totvs-rh-demo.appspot.com",
//   messagingSenderId: "1044327949700",
//   appId: "1:1044327949700:web:7e84557b3f6fa0cccda9d1",
//   measurementId: "G-7KDBHPJS5P"
//   },
//   url:"https://assinatura-gestor-tarefas-2-0.rj.r.appspot.com"
// }
export const environmentPRD = {
 production: true,
  hmr: false,
  firebaseConfig: {
    apiKey: "AIzaSyAm1s5Za7Gtm-G_DbMnVvBC1AGfDLdS17A",
    authDomain: "pointz-iv2-demo.firebaseapp.com",
    projectId: "pointz-iv2-demo",
    storageBucket: "pointz-iv2-demo.appspot.com",
    messagingSenderId: "1083364285518",
    appId: "1:1083364285518:web:e20d8c374ceeda544e371f",
    measurementId: "G-BMGLYEZ939"
  },
  url:"https://assinatura-gestor-tarefas-2-0.rj.r.appspot.com"
}
